import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {AppPresentationState, initialState} from "./state"
import {UserSettings} from "@common/types/UserSettings"
import {Business} from "../../types/Business"
import {User} from "../../types/User"
import {BusinessMember} from "../../types/BusinessMember"
import {BusinessSettings} from "@common/types/business/BusinessSettings"
import {CommodityGroup} from "../../types/commodity/CommodityGroup"
import {PersistentStorage} from "../../PersistentStorage"
import {Locale} from "../../i18n/Localization"

export const slice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setCurrentBusinessMemberOpenZones: (state, action: PayloadAction<CommodityGroup[]>) => {
            state.current_business_member_open_zones = action.payload
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.user_id = action.payload
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        setUserSettings: (state, action: PayloadAction<UserSettings | undefined>) => {
            let settings = action.payload
            if (!settings) {
                console.debug("authentication.setUserSettings creating new default settings")
                const userId = state.user_id
                if (!userId) {
                    console.error("authentication.setUserSettings userId is not set")
                    return
                }
                settings = {did_request_account_deletion: false, id: userId, language: "english", qr_size_cm: 6.2, user_id: userId}
            }
            state.user_settings = settings
        },
        // TODO remove after "switch" type localizations will be migrated to i18n
        // This call only needed to update their state
        setLocale: (state, action: PayloadAction<Locale>) => {
            state.locale = action.payload
        },
        setGlobalAdmin: (state, action: PayloadAction<boolean>) => {
            state.isUserGlobalAdmin = action.payload
        },
        setBusinessSettings: (state, action: PayloadAction<BusinessSettings>) => {
            state.business_settings = action.payload
        },
        setAppPresentationState: (state, action: PayloadAction<AppPresentationState>) => {
            state.presentation_state = action.payload
        },
        setBusiness: (state, action: PayloadAction<Business>) => {
            state.business = action.payload
        },
        setBusinesses: (state, action: PayloadAction<Business[]>) => {
            state.businesses = action.payload
        },
        setBusinessMember: (state, action: PayloadAction<BusinessMember>) => {
            state.business_member = action.payload
        },
        setBusinessMembers: (state, action: PayloadAction<BusinessMember[]>) => {
            state.business_members = action.payload
        },
        changeBusiness: (state) => {
            PersistentStorage.lastSignInBusinessId = null
            setAppPresentationState(AppPresentationState.pickBusiness)
            let authenticationState = state
            authenticationState.business_member = undefined
            authenticationState.business = undefined
            authenticationState.presentation_state = AppPresentationState.pickBusiness
            return authenticationState
        },
        // Named to not clash with Firebase's signOut() function
        signOutDispatch: () => {
            PersistentStorage.lastSignInBusinessId = null
            setAppPresentationState(AppPresentationState.loggedOut)
            return initialState
        },
    },
})

export const {
    setAppPresentationState,
    setBusiness,
    setBusinesses,
    setUserId,
    setUserSettings,
    setLocale,
    setUser,
    setGlobalAdmin,
    setBusinessMember,
    setBusinessMembers,
    setBusinessSettings,
    setCurrentBusinessMemberOpenZones,
    changeBusiness,
    signOutDispatch,
} = slice.actions
export default slice.reducer
