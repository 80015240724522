import {Unsubscribe} from "firebase/firestore"
import {ErrorManager} from "../errorManager"

interface AppUnsubscribe {
    type: UnsubscribeType
    unsubscribe: Unsubscribe
}

export enum UnsubscribeType {
    commodity_events,
    commodity_groups,
    commodities,
}

export class UnsubscribeManager extends ErrorManager {
    unsubscribes: AppUnsubscribe[] = []

    updateUnsubscribes(type: UnsubscribeType, unsubscribe?: Unsubscribe) {
        this.removeUnsubscribe(type)
        if (unsubscribe === undefined) {
            return
        }
        let appListener: AppUnsubscribe = {
            type: type,
            unsubscribe: unsubscribe,
        }
        this.addListener(appListener)
    }
    removeAllUnsubscribes() {
        this.unsubscribes.forEach((unsubscribe) => {
            this.removeUnsubscribe(unsubscribe.type)
        })
        this.unsubscribes = []
    }
    removeUnsubscribe(type: UnsubscribeType) {
        let unsubscribe = this.unsubscribes.find((unsubscribe) => unsubscribe.type === type)
        if (unsubscribe === undefined) {
            return
        }
        unsubscribe.unsubscribe()
    }
    private addListener(listener: AppUnsubscribe) {
        this.unsubscribes.push(listener)
    }
}
