import React from "react"
import {useAppSelector} from "../../app/hooks"
import {lookerIframeUrl} from "../../components/authentication/selectors"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import {store} from "../../app/store"
import {setShowFullscreenModal} from "../../components/modal/slice"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import {currentStockLabels} from "../../components/localization/selectors"
import {useWindowDimensions} from "../../app/Other/UseWindowDimensions"

export const LookerIframeView = () => {
    const {width, height} = useWindowDimensions()
    const url = useAppSelector(lookerIframeUrl)

    return (
        <>
            <LookerAppBar />
            <iframe title={"Looker I Frame"} width={width} height={0.91 * height} src={url} frameBorder="0" allowFullScreen></iframe>
        </>
    )
}

export const LookerAppBar = () => {
    let labels = useAppSelector(currentStockLabels)

    return (
        <AppBar sx={{position: "relative"}}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={(e) => store.dispatch(setShowFullscreenModal(false))} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                    {labels.reports()}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}
