const TAG = "CommodityImageHelper"

export default class CommodityImageHelper {
    static getFilename(commodityId: string, commodityUrl: string): string {
        const segments = CommodityImageHelper.getSegments(commodityUrl)
        let fileName: string
        if (segments !== undefined) {
            // New version of commodity image path
            const [businessId, groupId] = segments
            fileName = `commodity/${businessId}/${groupId}/${commodityId}.jpg`
        } else {
            // Old version of commodity image path
            fileName = `${commodityId}.jpg`
        }

        return fileName
    }

    // Path variants:
    // New:
    // https://firebasestorage.googleapis.com:443/v0/b/timbereye-production.appspot.com/o/images%2Fcommodity%2FCD030D37-9D39-405A-A918-8335077D02E6%2F513A2200-E95F-4DFB-8489-E75167D49066%2F168144b5-2a06-4c9f-87cd-56021.jpg?alt=media&token=f8fdad25-38fe-4632-ba64-66712f4d1f81
    // Old:
    // https://firebasestorage.googleapis.com:443/v0/b/timbereye-production.appspot.com/o/images%2F%2B280.jpg?alt=media&token=7388b8ff-fe08-4082-973e-efabbdd67caf
    private static getSegments(url: string): [string, string] | undefined {
        const newFolder = "images%2Fcommodity%2F"
        const slash = "%2F"

        try {
            // https://firebasestorage.googleapis.com:443/v0/b/timbereye-production.appspot.com/o/images%2Fcommodity%2FCD030D37-9D39-405A-A918-8335077D02E6%2F513A2200-E95F-4DFB-8489-E75167D49066%2F168144b5-2a06-4c9f-87cd-56021.jpg?alt=media&token=f8fdad25-38fe-4632-ba64-66712f4d1f81
            let index = url.indexOf(newFolder)
            if (index < 0) return undefined

            // [ business id                      ]
            // CD030D37-9D39-405A-A918-8335077D02E6%2F513A2200-E95F-4DFB-8489-E75167D49066%2F168144b5-2a06-4c9f-87cd-56021.jpg?alt=media&token=f8fdad25-38fe-4632-ba64-66712f4d1f81
            url = url.substring(index + newFolder.length)
            index = url.indexOf(slash)
            const businessId = url.substring(0, index) // end index is exclusive

            // [ group id                         ]
            // 513A2200-E95F-4DFB-8489-E75167D49066%2F168144b5-2a06-4c9f-87cd-56021.jpg?alt=media&token=f8fdad25-38fe-4632-ba64-66712f4d1f81
            url = url.substring(index + slash.length)
            index = url.indexOf(slash)
            const groupId = url.substring(0, index)

            return [businessId, groupId]
        } catch (error) {
            console.error(TAG, "getSegments", error)
            return undefined
        }
    }
}
