import React, {useEffect, useState} from "react"
import {StyledDataGrid} from "../../../../dataTable/tables/sfc/styledDataGrid"
import {firebase} from "../../../../../app/store"

export function ConnectionsView() {
    const [rows, setRows] = useState<{id: number; name: string; type: string}[]>([])

    useEffect(() => {
        firebase.commodity_group.read.getRecentCommodityGroupsLinkedBusinesses(100).then((businesses) => {
            console.log(`ConnectionsView.getRecentCommodityGroupsLinkedBusinesses businesses.length:${businesses.length}`)

            let id = 0
            const rows = businesses.map((business) => {
                return {id: id++, name: business.business.name, type: business.type}
            })
            setRows(rows)
        })
    }, []) // Empty array deps = useEffect() runs once

    const columns = [
        {field: "name", headerName: "Business", width: 300},
        {field: "type", headerName: "Role", width: 125},
    ]

    return (
        <>
            <div style={{height: 315, width: "100%"}}>
                <StyledDataGrid rows={rows} columns={columns} disableColumnMenu disableChildrenSorting hideFooter />
            </div>
        </>
    )
}
