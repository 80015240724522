import CommodityGroupViewModel from "./viewModel/CommodityGroupViewModel"

export interface CommodityGroupsState {
    groups: CommodityGroupViewModel[]
    loading: boolean
}

export const initialState: CommodityGroupsState = {
    groups: [],
    loading: false,
}
