import {components, store} from "../../../app/store"
import {setQueryEndDate, setQueryStartDate, setSelectedTab} from "./slice"
import {setGroup} from "../../commodities/commodityGroup/slice"
import {setFullscreenModalType, setModalType, setShowFullscreenModal, setShowModal} from "../../modal/slice"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import CommodityGroupViewModel from "../../commodities/commodityGroups/viewModel/CommodityGroupViewModel"
import {FullscreenModalType, ModalType} from "../../modal/state"

export default class CommodityGroupDataTableComponent {
    viewCommodityGroupDetails = (id: string) => {
        let model = this.setModel(id)
        if (model === undefined) {
            return
        }
        components.commodity_group.loadCommodityGroupCommodities()
        store.dispatch(setFullscreenModalType(FullscreenModalType.CommodityGroup))
        store.dispatch(setShowFullscreenModal(true))
    }
    showCommodityGroupActions = (id: string) => {
        this.setModel(id)
        store.dispatch(setModalType(ModalType.Actions))
        store.dispatch(setShowModal(true))
    }
    setModel = (id: string): CommodityGroupViewModel | undefined => {
        let model = store.getState().commodityGroups.groups.find((model) => model.id === id)
        store.dispatch(setGroup(model))
        return model
    }
    setSelectedTab = (tab: CommodityGroupType) => {
        store.dispatch(setSelectedTab(tab))
        components.commodity_groups.listenForCommodityGroups()
    }
    setStart = (start: Date) => {
        store.dispatch(setQueryStartDate(start))
        components.commodity_groups.listenForCommodityGroups()
    }
    setEnd = (end: Date) => {
        store.dispatch(setQueryEndDate(end))
        components.commodity_groups.listenForCommodityGroups()
    }
}
