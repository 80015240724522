export class PersistentStorage {
    static get lastSignInBusinessId() {
        return localStorage.getItem("lastSignInBusinessId")
    }

    static set lastSignInBusinessId(businessId: string | null) {
        businessId ? localStorage.setItem("lastSignInBusinessId", businessId) : localStorage.removeItem("lastSignInBusinessId")
    }

    static get userLocale() {
        return localStorage.getItem("userLocale")
    }

    static set userLocale(language: string | null) {
        language ? localStorage.setItem("userLocale", language) : localStorage.removeItem("userLocale")
    }
}
