import React from "react"
import AboutPage from "./view/marketing/Pages/About"
import {LogInPage} from "./view/Auth/LogIn"
import Terms from "./view/marketing/Pages/Legal/Terms"
import Privacy from "./view/marketing/Pages/Legal/Privacy"
import App from "./App"
import {Route, Routes} from "react-router-dom"
import {ReusableMarketingPage} from "./view/marketing/Pages/Landing"

export const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/login" element={<LogInPage />}></Route>
        <Route path="/buy" element={<ReusableMarketingPage />}></Route>
        <Route path="/supply" element={<ReusableMarketingPage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
    </Routes>
)
