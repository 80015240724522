export interface Forest {
    id: string
    name: string
}

export namespace Forest {
    export function create(id: string, name: string): Forest {
        return {id, name} as Forest
    }
}
