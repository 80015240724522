import CommodityGroupForm from "./CommodityGroupForm"
import {CommodityWeight} from "@common/types/commodity/commodity"
import {CommodityGroupUserEntries} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"
import {setVesselSectionForm} from "../../commodityGroup/slice"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"

export default class VesselSectionForm extends CommodityGroupForm {
    max_weight_mt: string = ""

    constructor(group: CommodityGroup) {
        super(group)
        this.setMaxWeight()
        this.setState()
    }

    setMaxWeight() {
        let weight = this.group.user_entries.weight?.weight
        if (weight === undefined) {
            return
        }
        this.max_weight_mt = weight.toString()
    }
    update() {
        super.update()
        if (!this.max_weight_mt.length) {
            return
        }
        let number = parseInt(this.max_weight_mt)
        let weight: CommodityWeight = {
            weight: number,
        }
        let userEntries: CommodityGroupUserEntries = {...this.group.user_entries, weight: weight}
        this.updateUserEntries(userEntries)
    }
    //user interaction
    setState() {
        store.dispatch(setVesselSectionForm(this))
    }
    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }
    didEditMaxWeight(string: string) {
        this.max_weight_mt = string
        this.setState()
    }
}
