import StockGroupForm from "./StockGroupForm"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import Display from "../../../../utils/Display"
import Logger from "../../../../helpers/Logger"

const log = new Logger("StockGroupForm")

export default class LoadForm extends StockGroupForm {
    initialRow?: CommodityGroup
    row?: CommodityGroup
    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group, pickerOptions, linkedGroups)
        this.setRow()
        this.setProperties()
        this.setLength()
    }
    setRow() {
        if (this.linked_groups === undefined) {
            return
        }
        let linkedRows = this.linked_groups.filter((group) => group.type === CommodityGroupType.row)
        if (!linkedRows.length) {
            return
        }
        this.row = linkedRows[0]
        this.initialRow = linkedRows[0]
    }
    setProperties() {
        super.setProperties()
        const loadProperties = this.group?.user_entries?.properties?.log
        const rowProperties = this.row?.user_entries?.properties?.log

        if (loadProperties !== undefined) {
            this.properties = loadProperties
        }

        if (rowProperties !== undefined) {
            this.properties = rowProperties
        }
    }
    setLength() {
        super.setLength()
        let loadLength = this.group.user_entries?.length
        let rowLength = this.row?.user_entries?.length
        if (loadLength !== undefined) {
            this.length = Display.displayLengthM(loadLength.length, this.measurementSystem)
        }
        if (rowLength !== undefined) {
            this.length = Display.displayLengthM(rowLength.length, this.measurementSystem)
        }
    }
    update() {
        super.update()
        this.addLinkedGroup(this.row)
    }
    //user interaction
    didSelectRow(id: string) {
        let row = this.matchingOption(id)
        if (row === undefined) {
            return
        }
        log.d("didSelectRow", `Did Select Row: ${row.user_entries.identifiers.first_id} ${row.user_entries.length?.length}`)
        this.row = row
        this.setProperties()
        this.updateProperties()
        this.setLength()
        this.updateLength()
    }
}
