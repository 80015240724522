import React from "react"
import {Spacer10, Spacer30} from "./Styles/styles.js"
import {SText, LTextBold, MTextBold, SListItem} from "./Styles/fonts.js"

export const SectionView = (props) => (
    <div key={props.section.title}>
        {props.section.subsections &&
            props.section.subsections.map((item) => (
                <div key={item.title}>
                    <SubtitleView title={item.title} subtitle={item.subtitle} />
                    {item.text && (
                        <div>
                            <Spacer10 />
                            <TextView text={item.text} subtitle={item.subtitle} />
                            <Spacer10 />
                        </div>
                    )}
                    {item.listItems &&
                        item.listItems.map((item) => (
                            <div>
                                <ListItem key={item} text={item} />
                            </div>
                        ))}
                    <Spacer10 />
                </div>
            ))}
        {props.section.listItems &&
            props.section.listItems.map((item) => (
                <div>
                    <ListItem key={item} text={item} />
                    <Spacer10 />
                </div>
            ))}
        <Spacer30 />
    </div>
)

export const TitleView = ({title, subtitle}) => (
    <div>
        <LTextBold>{title}</LTextBold>
        {subtitle && <SText>{subtitle}</SText>}
    </div>
)

export const SubtitleView = ({title, subtitle}) => (
    <div>
        <MTextBold>{title}</MTextBold>
        {subtitle && <SText>{subtitle}</SText>}
    </div>
)

export const TextView = ({text, subtitle}) => (
    <div>
        <SText>{text}</SText>
        {subtitle && <SText>{subtitle}</SText>}
    </div>
)

export const ListItem = ({text}) => <SListItem>{text}</SListItem>
