import React from "react"
import PrivacyView from "../View/PrivacyView"

const Privacy = () => {
    let sectionOne = SectionOne()
    let sections = [sectionOne]

    return <PrivacyView sections={sections} />
}
export default Privacy

const SectionOne = () => {
    let one = {
        title: "Customer Information",
        subtitle: "TimberEye collects the following Customer information:",
        listItems: [
            "Email address",
            "Business name",
            "Business address",
            "Yards (e.g. yard names and addresses)",
            "Yard Members (e.g. emails and Access Rights)",
            "Business Partner Information (e.g. the names, gps coordinates, and addresses of trucking, logging partners, forestry and forest partners)",
        ],
    }

    let two = {
        title: "Yard Member Information",
        subtitle: "TimberEye collections the following yard member information:",
        listItems: ["Email address", "Yard Member Access Rights"],
    }

    let three = {
        title: "Camera Feed Information",
        subtitle:
            "We collect and store video feeds from Yard Members’ use of the TimberEye app when they use the app for the purpose of scaling logs or scanning QR Codes. We also collect images, device movement information, device version data, scanning session times, spatial mapping data (e.g. LiDAR positioning data), QR code data. We use this data to refine, improve and develop accurate measurement algorithms and log-measurement oriented computer vision models. We also record other information relevant to the Yard (e.g. Inbound Loads, Rows, Outbound Loads) during these scanning sessions.",
        listItems: [],
    }

    let four = {
        title: "Sharing Information",
        subtitle:
            "We do not share, sell or market your data except to provide the Services. We do not share potentially sensitive information about your Business that we collect: Business Name, Yards, Yard Members, partners (e.g. Loggers, Trucking Companies, Foresters), or your log-level inventory data. TimberEye works with third parties to provide the Services. Examples of how we share information include:",
        listItems: [
            "Securely processing payments",
            "Storing and backing up application data in the US and in remote facilities around the world",
            "Storing and backing up files in the US and in remote facilities around the world",
            "Processing high volumes of video data or images to assist in the development of computer vision models or software",
        ],
    }

    let five = {
        title: "Securing Your Information",
        subtitle:
            "We take several steps to protect your business’s data. We run daily backups that we store for a reasonable period of time (e.g. 2 weeks). We generate PDF Documents and CSV exports and share them at important events for your business (e.g. Shipment Completion). We manage authentication with industry standard email authentication practices, to prevent unwanted account access. We provide Access Rights to prevent certain Yard Members within your Business from accidentally destroying potentially valuable information. In case something goes wrong, we will do everything in our power to restore your business’s data from backups. However, TimberEye cannot guarantee the protection of any information you transmit to TimberEye or guarantee that your information will not be lost, altered, or destroyed.",
        listItems: [],
    }

    let six = {
        title: "Children’s Privacy",
        subtitle:
            "In order to use TimberEye as a Customer, you must be at least 18 years of age or older. In order to use TimberEye as a Yard Member, you must be over the age of 13. TimberEye does not knowingly collect or solicit any information from anyone under the age of 13 or knowingly allow such persons to register for the Service. The Service and its content are not directed at children under the age of 13. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us.",
        listItems: [],
    }

    let section = {
        subsections: [one, two, three, four, five, six],
    }

    return section
}
