import {AboutContent, AboutLabelsWeb, AboutsContent} from "../Web"
import {AboutContainerMobile, AboutImageMobile, AboutItemContainerMobile} from "./styles"

export const AboutMobile = (props: AboutsContent) => {
    return (
        <AboutContainerMobile>
            {props.items.map((item) => (
                <AboutItemMobile {...item} />
            ))}
        </AboutContainerMobile>
    )
}

export const AboutItemMobile = (props: AboutContent) => {
    return (
        <AboutItemContainerMobile>
            <AboutImageMobile src={props.url} />
            <AboutLabelsWeb {...props} />
        </AboutItemContainerMobile>
    )
}
