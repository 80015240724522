import styled from "styled-components"

export const QRsSectionContainerWeb = styled.div`
    padding-top: 35px;
    padding-left: 100px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
`

export const QRsContainerWeb = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 10px !important;
`

export const QRContainerWeb = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 35px;
    cursor: pointer;
    border: none;
`

export const QRImageWeb = styled.img`
    width: 100%;
    height: auto;
    border-radius: 12px;
    padding-right: 20px;
`
