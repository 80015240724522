import {AboutContainerWeb, AboutDescriptionWeb, AboutImageWeb, AboutItemContainerWeb, AboutLabelsContainerWeb} from "./styles"
import {Subtitle2Italic, Title4} from "../../../Styles/Fonts"

export const AboutWeb = (props: AboutsContent) => (
    <AboutContainerWeb>
        {props.items.map((item) => (
            <AboutItemWeb {...item} />
        ))}
    </AboutContainerWeb>
)

export const AboutItemWeb = (props: AboutContent) => {
    return (
        <AboutItemContainerWeb>
            <AboutImageWeb src={props.url} />
            <AboutLabelsWeb {...props} />
        </AboutItemContainerWeb>
    )
}

export const AboutLabelsWeb = (props: AboutContent) => {
    return (
        <AboutLabelsContainerWeb>
            <Title4>{props.name}</Title4>
            <Subtitle2Italic>{props.title}</Subtitle2Italic>
            <AboutDescriptionWeb>{props.description}</AboutDescriptionWeb>
        </AboutLabelsContainerWeb>
    )
}

export interface AboutsContent {
    items: AboutContent[]
}

export interface AboutContent {
    name: string
    title: string
    description: string
    url: string
}
