import {NavigationLinkBar} from "../../Navigation/NavigationLinkBar"
import {MarketingNavbar} from "../../Navigation/NavigationBar"
import React from "react"
import {SiteContainer, SiteContentContainer} from "../../Styles/style"
import {About} from "../../Content/About"
import {Footer} from "../../Navigation/Footer"

const AboutPage = () => {
    return (
        <SiteContainer>
            <SiteContentContainer>
                <NavigationLinkBar />
                <MarketingNavbar />
                <About />
                <Footer />
            </SiteContentContainer>
        </SiteContainer>
    )
}

export default AboutPage
