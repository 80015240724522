import {useAppSelector} from "../../../../app/hooks"
import {commodityGroupDetailLabels} from "../../../../components/localization/selectors"
import {ButtonGroupSelectors} from "../../../navbar/sfc/ButtonGroupSelectors"
import {store} from "../../../../app/store"
import React from "react"
import {commodityGroupTab} from "../../../../components/commodities/commodityGroup/selectors/selectors"
import {CommodityGroupDetailTabOption} from "../../../../components/commodities/commodityGroup/state"
import {setDetailTab} from "../../../../components/commodities/commodityGroup/slice"

export const CommodityGroupDetailViewTabSelector = () => {
    const selected = useAppSelector(commodityGroupTab)
    const localization = useAppSelector(commodityGroupDetailLabels)
    let options = Object.keys(CommodityGroupDetailTabOption)

    return (
        <ButtonGroupSelectors
            handleClick={(any) => store.dispatch(setDetailTab(any as CommodityGroupDetailTabOption))}
            options={options}
            localization={options.map((option) => localization.label(option as CommodityGroupDetailTabOption))}
            selected={selected}
        />
    )
}
