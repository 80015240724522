import {MagicLinkEmailForm} from "./MagicLinkEmailForm"
import React from "react"
import {AuthNavbar} from "../AuthNavbar"
import {AuthFooter} from "../AuthFooter"
import {AuthBodyContainer, AuthPageContainer} from "../../newStyles/AuthStyles"

export const LogInPage = () => (
    <AuthPageContainer>
        <AuthNavbar />
        <AuthBodyContainer>
            <MagicLinkEmailForm />
        </AuthBodyContainer>
        <AuthFooter />
    </AuthPageContainer>
)
