import VesselSectionForm from "../../../../components/commodities/commodityGroupForm/Forms/VesselSectionForm"
import {Section} from "./sfc/Section"
import {FormTextField} from "./sfc/FormTextFieldView"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export const VesselSectionFields = (props: VesselSectionFormProps) => (
    <Section>
        <FormTextField
            value={props.form.first_id}
            label={props.form.id_labels.firstId(CommodityGroupType.vessel_section)}
            onChange={(id) => props.form.didEditFirstId(id)}
        />
        <FormTextField value={props.form.max_weight_mt} label={"Max Weight (MT)"} onChange={(id) => props.form.didEditMaxWeight(id)} />
    </Section>
)

export interface VesselSectionFormProps {
    form: VesselSectionForm
}
