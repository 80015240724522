import {useEffect, useState} from "react"
import {useAppSelector} from "../../../../app/hooks"
import {imageWidth, perimeterPoints, selectedCommodity} from "../../../../components/commodities/editCommodity/selectors"
import {components, store} from "../../../../app/store"
import {setUserIsClicking} from "../../../../components/commodities/editCommodity/slice"
import {CommodityImageView} from "../../../detail/CommodityGroup/image/StaticCommodityImageView"
import QRCode from "react-qr-code"
import {QRViewProps} from "../../../dataTable/tables/sfc/styledDataGrid"

export const EditCommodityView = () => {
    const [didAppear, setDidAppear] = useState(false)
    const commodity = useAppSelector(selectedCommodity)
    const height = useAppSelector(imageWidth)
    const perimeter = useAppSelector(perimeterPoints)

    useEffect(() => {
        if (!didAppear) {
            components.edit_commodity.draw()
            setDidAppear(true)
        }
    }, [didAppear, setDidAppear])

    return (
        <div
            onPointerDown={() => store.dispatch(setUserIsClicking(true))}
            onPointerUp={components.edit_commodity.userStoppedHoldingClick}
            onPointerLeave={components.edit_commodity.userStoppedHoldingClick}
            onPointerCancel={components.edit_commodity.userStoppedHoldingClick}
            onPointerMove={(event) => components.edit_commodity.cursorDidMove(event.clientX, event.clientY, event.currentTarget.getBoundingClientRect())}
        >
            <>
                {commodity && (
                    <CommodityImageView tag={"B"} key={commodity.id} commodity={commodity.commodity} height={height} perimeter={perimeter} onClick={() => {}} />
                )}
            </>
        </div>
    )
}

export const QRView = (props: QRViewProps) => {
    const height = useAppSelector(imageWidth)

    return <QRCode value={props.commodity_id} height={height} width={height} />
}
