export class Font {
    static Regular = "Lato, sans-serif" // Regular (w400)
    static Bold = "Lato-Bold, sans-serif" // (w700)
    static Black = "Lato-Black, sans-serif" // Black equals "more bold" (w900)
}

/*
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/

export class FontSize {
    // Pixel sizes are taken from Brex
    static _1 = "2rem" // 32
    static _2 = "1.5rem" // 24
    static _3 = "1.25rem" // 20
    static _4 = "1rem" // 16
    static _5 = "0.875rem" // 14
    static _6 = "0.75rem" // 12
}

export const BorderRadius = "5px"
