import {store} from "../../../../app/store"
import {setModalType, setShowModal} from "../../../../components/modal/slice"
import {Button} from "@mui/material"
import {useAppSelector} from "../../../../app/hooks"
import {actionLabels} from "../../../../components/localization/selectors"
import {ModalType} from "../../../../components/modal/state"

export const AppBarActionsButton = () => {
    let actionsLabels = useAppSelector(actionLabels)

    return (
        <Button
            sx={{
                bgcolor: "#00EF0C",
                ":hover": {
                    bgcolor: "#00EF0C",
                    color: "#000",
                },
                color: "#fff",
                ml: 2,
                pl: 1,
                pt: 1,
            }}
            onClick={() => {
                store.dispatch(setModalType(ModalType.Actions))
                store.dispatch(setShowModal(true))
            }}
        >
            {actionsLabels.actionsButtonLabel()}
        </Button>
    )
}
