import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {initialState, MarketingTheme} from "./state"

export const slice = createSlice({
    name: "marketing",
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<MarketingTheme>) => {
            state.theme = action.payload
        },
    },
})

export const {setTheme} = slice.actions
export default slice.reducer
