import {QRsContainerMobile, QRsSectionContainerMobile} from "./styles"
import {qrsProps} from "../../../../../components/marketing/selectors"
import {MobileSectionTitle} from "../../Section/Mobile/styles"
import {QRWeb} from "../Web"

export const QRsMobile = () => {
    const props = qrsProps()

    return (
        <QRsSectionContainerMobile>
            <MobileSectionTitle>{props.title}</MobileSectionTitle>
            <QRsContainerMobile>
                {props.qrs.map((qr) => (
                    <QRWeb {...qr} />
                ))}
            </QRsContainerMobile>
        </QRsSectionContainerMobile>
    )
}
