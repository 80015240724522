import {MarketingNavbarLogo, MarketingNavigationButton, MarketingNavigationLink, MarketingNavigationRightButtonContainer, StyledMarketingNavbar} from "./styles"
import Navbar from "react-bootstrap/Navbar"
import React from "react"
import {useNavigate} from "react-router-dom"
import {constants} from "../../../../../config/constants"

export const WebMarketingNavbar = () => {
    const navigate = useNavigate()
    return (
        <StyledMarketingNavbar>
            <Navbar.Collapse className="justify-content-start">
                <MarketingNavbarLogo onClick={() => navigate("/")} />
                <MarketingNavigationLink onClick={() => navigate("/buy")}>{"Buy"}</MarketingNavigationLink>
                <MarketingNavigationLink onClick={() => navigate("/supply")}>{"Supply"}</MarketingNavigationLink>
                <MarketingNavigationLink onClick={() => navigate("/about")}>{"About"}</MarketingNavigationLink>
                <MarketingNavigationRightButtonContainer>
                    <MarketingNavigationButton onClick={() => window.open(constants.APP_STORE_URL, "_newtab")}>{"DOWNLOAD THE APP"}</MarketingNavigationButton>
                </MarketingNavigationRightButtonContainer>
            </Navbar.Collapse>
        </StyledMarketingNavbar>
    )
}
