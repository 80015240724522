import CommodityCreate from "./CommodityCreate"
import CommodityDelete from "./CommodityDelete"
import CommodityRead from "./CommodityRead"
import CommodityUpdate from "./CommodityUpdate"

export default class CommodityCRUD {
    create: CommodityCreate = new CommodityCreate()
    read: CommodityRead = new CommodityRead()
    update: CommodityUpdate = new CommodityUpdate()
    delete: CommodityDelete = new CommodityDelete()
}
