import {User} from "../../types/User"
import {collection, doc, getDoc, getDocs, query, setDoc, Timestamp, where} from "firebase/firestore"
import {db} from "../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import ArrayUtils from "@common/utils/ArrayUtils"

export default class UserDao {
    createUser = async (uid: string, email: string) => {
        let user: User = {
            id: uid,
            created_time: Timestamp.fromDate(new Date()),
            email: email,
        }
        await setDoc(doc(db, FIRCollectionPaths.USER, user.id), user)
        return user
    }

    getUser = async (id: string): Promise<User | undefined> => {
        try {
            const docRef = doc(db, FIRCollectionPaths.USER, id)
            const snap = await getDoc(docRef)
            if (snap.exists()) {
                return snap.data() as User
            }
            return undefined
        } catch (error) {
            return undefined
        }
    }

    async getUserByEmail(email: string): Promise<User | undefined> | never {
        const q = query(collection(db, FIRCollectionPaths.USER), where("email", "==", email))
        let snapshot = await getDocs(q)

        if (ArrayUtils.isEmpty(snapshot.docs)) {
            return undefined
        }

        return snapshot.docs[0].data() as User
    }

    async isUserExistsWithEmail(email: string) {
        return (await this.getUserByEmail(email)) !== undefined
    }
}
