import {UnselectedNavButton} from "../../styles"
import {store} from "../../../app/store"
import {setFullscreenModalType, setShowFullscreenModal} from "../../../components/modal/slice"
import {FullscreenModalType} from "../../../components/modal/state"
import {useAppSelector} from "../../../app/hooks"
import {isAdministrator, lookerReportExists} from "../../../components/authentication/selectors"
import {currentStockLabels} from "../../../components/localization/selectors"

export const ReportsButton = () => {
    const isAdmin = useAppSelector(isAdministrator)
    const reportExists = useAppSelector(lookerReportExists)
    const labels = useAppSelector(currentStockLabels)
    return (
        <>
            {isAdmin && reportExists && (
                <UnselectedNavButton
                    onClick={() => {
                        store.dispatch(setFullscreenModalType(FullscreenModalType.Reports))
                        store.dispatch(setShowFullscreenModal(true))
                    }}
                >
                    {labels.reports()}
                </UnselectedNavButton>
            )}
        </>
    )
}
