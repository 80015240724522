import Divider, {DividerProps} from "@mui/material/Divider"

interface SpacerProps extends DividerProps {
    space?: string
}

function Spacer(props: SpacerProps) {
    const {space, sx, ...rest} = props
    const direction = rest?.orientation === "vertical" ? "borderBottomWidth" : "borderRightWidth" /* default */
    return <Divider flexItem sx={{borderColor: "transparent", ...sx, ...{[direction]: space ? space : "1px" /* default */}}} {...rest} />
}

export const SpacerH10 = () => Spacer({space: "0.625rem"})

export const SpacerV14 = () => Spacer({space: "0.875rem", orientation: "vertical"})

export const SpacerV16 = () => Spacer({space: "1rem", orientation: "vertical"})

export const SpacerV24 = () => Spacer({space: "1.5rem", orientation: "vertical"})

export const SpacerV32 = () => Spacer({space: "2rem", orientation: "vertical"})

export const SpacerVPx = (props: {px: number}) => Spacer({space: `${props.px}px`, orientation: "vertical"})
