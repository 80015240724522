import * as React from "react"
import Dialog from "@mui/material/Dialog"
import {Transition} from "./sfc/FullscreenTransition"
import {useAppSelector} from "../../../app/hooks"
import {fullScreenModalType, showFullscreenOverlay} from "../../../components/modal/selectors"
import {setShowFullscreenModal} from "../../../components/modal/slice"
import {store} from "../../../app/store"
import {CommodityGroupDetailView} from "../../detail/CommodityGroup/CommodityGroupDetailView"
import {FullscreenModalType} from "../../../components/modal/state"
import {LookerIframeView} from "../../reports/LookerIframeView"

export const FullScreenDialog = () => {
    const show = useAppSelector(showFullscreenOverlay)
    const type = useAppSelector(fullScreenModalType)

    const renderContent = () => {
        switch (type) {
            case FullscreenModalType.Reports:
                return <LookerIframeView />
            case FullscreenModalType.CommodityGroup:
                return <CommodityGroupDetailView />
        }
    }
    return (
        <Dialog fullScreen open={show} onClose={(e) => store.dispatch(setShowFullscreenModal(false))} TransitionComponent={Transition}>
            <>{renderContent()}</>
        </Dialog>
    )
}
