import {RootState, store} from "../../app/store"
import {AuthenticationState} from "./state"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {MeasurementSystem} from "@common/types/commodity/commodity"
import {WeightUnits} from "@common/types/WeightsUnits"
import {LogScale} from "@common/types/LogScales"
import {AccessLevel} from "../../types/BusinessMember"
import {Business} from "../../types/Business"
import {BusinessSettings} from "@common/types/business/BusinessSettings"
import StringUtils from "@common/utils/StringUtils"
import {Locale, Localization} from "../../i18n/Localization"

export const presentationState = (state: RootState) => {
    let authenticationState = state.authentication as AuthenticationState
    return authenticationState.presentation_state
}

export const businessState = (state: RootState) => {
    let businessState = state.authentication as AuthenticationState
    return businessState.business
}

export function businessSettingsState(state: RootState): BusinessSettings | undefined {
    let businessState = state.authentication as AuthenticationState
    return businessState.business_settings
}

export const businesses = (state: RootState): Business[] => {
    if (state.authentication.businesses === undefined) {
        return []
    }
    let businesses = state.authentication.businesses.filter((business) => StringUtils.isNotEmpty(business.name))

    return businesses.sort((lhs, rhs) => (lhs.name > rhs.name ? 1 : -1))
}

export const isAdministrator = (state: RootState): boolean => {
    let businessState = state.authentication as AuthenticationState
    return businessState.business_member?.type === AccessLevel.Administrator
}

export const isUserReadonly = (state: RootState): boolean => {
    let authentication = state.authentication
    return authentication.business_member?.is_readonly ?? false
}

export const lookerIframeUrl = (state: RootState) => {
    let business = businessState(state)
    if (business?.looker_studio_report_url === undefined) {
        return ""
    }
    return business?.looker_studio_report_url
}

export const lookerReportExists = (state: RootState): boolean => {
    let business = businessState(state)
    if (business?.looker_studio_report_url === undefined) {
        return false
    }
    return true
}

export const currentUserEmail = (state: RootState) => {
    const user = state.authentication.user
    if (user === undefined) {
        return "No email"
    }
    return user.email
}

export const currentBusinessName = (state: RootState) => {
    const business = state.authentication.business
    if (business === undefined) {
        return "No Business"
    }
    return business.name
}

export const businessMemberCommodityGroupVisibility = (state: RootState): CommodityGroupType[] => {
    const member = state.authentication.business_member
    if (member === undefined) {
        return []
    }
    if (member.commodity_group_visibility === undefined) {
        return []
    }
    return member.commodity_group_visibility
}

export function locale(state: RootState): Locale {
    return Localization.userLocale
}

export const measurementSystem = (state: RootState): MeasurementSystem => {
    return state.authentication.business_settings?.measurement_system ?? MeasurementSystem.metric
}

export function measurementSystem2(): MeasurementSystem {
    return measurementSystem(store.getState())
}

export function scaleWeightUnitAsMeasurementSystem(): MeasurementSystem {
    const weightUnit = store.getState().authentication.business_settings?.scale_weight_unit ?? WeightUnits.kilograms
    return weightUnit === WeightUnits.kilograms ? MeasurementSystem.metric : MeasurementSystem.imperial
}

export const logScale = (state: RootState): LogScale => {
    let authState = state.authentication as AuthenticationState
    let scale = authState.business_settings?.scale
    if (scale === undefined) {
        return LogScale.JAS
    }
    return scale
}
