import React from "react"
import RingLoader from "react-spinners/RingLoader"
import {FormLoaderContainer, CenteredDiv} from "../styles"

export const Loader = () => {
    return (
        <CenteredDiv>
            <RingLoader color={"#00EF0C"} loading={true} size={50} />
        </CenteredDiv>
    )
}

export const FormLoader = () => {
    return (
        <FormLoaderContainer>
            <RingLoader color={"#00EF0C"} loading={true} size={50} />
        </FormLoaderContainer>
    )
}
