import MathUtils from "@common/utils/MathUtils"

export default class Utils {
    static isValidEmail(email: string): boolean {
        /*eslint-disable*/
        let regex =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let validated = email.match(regex)
        if (validated) {
            return true
        }
        return false
    }

    static safeDensity(weight: number, volume: number): number {
        if (weight <= 0) {
            return 0
        }
        if (volume <= 0) {
            return 0
        }
        return MathUtils.round2(weight / volume)
    }
}
