import {selectedGroupCommoditiesWithUrls} from "../../../../components/commodities/commodityGroup/selectors/selectors"
import {useAppSelector} from "../../../../app/hooks"
import {ImageList} from "@mui/material"
import {StaticCommodityImageView} from "./StaticCommodityImageView"
import {formatIdentifiablePerimeterPoints} from "../../../../components/commodities/editCommodity/selectors"
import useWindowDimensions from "../../../../app/Other/UseWindowDimensions"
import {components} from "../../../../app/store"

export const CommodityImagesView = () => {
    const {width} = useWindowDimensions()
    const commodities = useAppSelector(selectedGroupCommoditiesWithUrls)
    const COLUMNS = 5
    //const ROW_HEIGHT = 300

    return (
        <ImageList sx={{height: "100%", mb: 0}} cols={COLUMNS} variant="quilted">
            {commodities.map((commodity) => (
                <StaticCommodityImageView
                    tag={"A"}
                    key={commodity.id}
                    commodity={commodity.commodity}
                    height={width / COLUMNS}
                    onClick={() => components.edit_commodity.showCommodityDetailView(commodity.commodity)}
                    perimeter={formatIdentifiablePerimeterPoints(commodity.commodity.points?.perimeter_points, width / COLUMNS)}
                />
            ))}
        </ImageList>
    )
}
