import {constants} from "../../config/constants"
import {MenuItemProps} from "../../view/marketing/Navigation/NavigationListButton/model"
import {store} from "../../app/store"

export enum MarketingTheme {
    copilot = "Copilot",
    pickles = "Pickles",
    goat = "GOAT",
}
const buy: MenuItemProps = {item: "Buy", url: "/login"}
const supply: MenuItemProps = {item: "Supply", url: "/supply"}
const about: MenuItemProps = {item: "About Us", description: "Meet the TimberEye Team", url: "/about"}
const ios: MenuItemProps = {item: "iOS Application", description: "Download TimberEye", external_url: constants.APP_STORE_URL}
const qr: MenuItemProps = {item: "QR Code Printing", description: "Print to a Zebra Printer", external_url: constants.PRINT_APP_URL}
const video1: MenuItemProps = {item: "Videos: How It Works", description: "Learn about TimberEye", external_url: constants.HOW_IT_WORKS_URL}
const terms: MenuItemProps = {item: "Terms Of Use", url: "/terms"}
const privacy: MenuItemProps = {item: "Privacy Policy", url: "/privacy"}
const platformOptions: MenuItemProps[] = [buy, supply]
const resourceOptions: MenuItemProps[] = [about, ios, qr, video1]
const companyOptions: MenuItemProps[] = [terms, privacy]

export interface MarketingState {
    theme: MarketingTheme
    platform_options: MenuItemProps[]
    resources_options: MenuItemProps[]
    company_options: MenuItemProps[]
}

export const initialState: MarketingState = {
    theme: randomTheme(),
    platform_options: platformOptions,
    resources_options: resourceOptions,
    company_options: companyOptions,
}

function randomTheme(): MarketingTheme {
    return getRandomTheme(Object.values(MarketingTheme).map((item) => item))
}

function getRandomTheme(themes: string[]): MarketingTheme {
    const randomIndex = Math.floor(Math.random() * themes.length)
    return themes[randomIndex] as MarketingTheme
}

export function getRandomThemeNotCurrent(): MarketingTheme {
    const current = store.getState().marketing.theme
    const filterCurrent = Object.values(MarketingTheme)
        .map((item) => item)
        .filter((theme) => theme !== current)
    return getRandomTheme(filterCurrent)
}
