import {useAppSelector} from "../../../../app/hooks"
import {Button, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material"
import {firebase, store} from "../../../../app/store"
import {setShowModal} from "../../../../components/modal/slice"
import {businessSettingsState, businessState} from "../../../../components/authentication/selectors"
import Form from "react-bootstrap/Form"
import {useState} from "react"
import {LogScale} from "@common/types/LogScales"
import {BusinessSettings} from "@common/types/business/BusinessSettings"
import {MeasurementSystem} from "@common/types/commodity/commodity"
import {WeightUnits} from "@common/types/WeightsUnits"
import {ErrorManager} from "../../../../components/errorManager"
import Logger from "../../../../helpers/Logger"

const log = new Logger("EditBusinessModalView")

export const EditBusinessModalView = () => {
    const business = useAppSelector(businessState)
    const settings = useAppSelector(businessSettingsState)

    const [businessName, setBusinessName] = useState(business?.name || "")
    const [logScale, setLogScale] = useState(settings?.scale || LogScale.JAS)
    const [measurementSystem, setMeasurementSystem] = useState(settings?.measurement_system || MeasurementSystem.metric)
    const [weightUnit, setWeightUnit] = useState(settings?.scale_weight_unit || WeightUnits.kilograms)

    const onCancel = (event: any) => {
        store.dispatch(setShowModal(false))
    }

    const onSubmit = (event: any) => {
        event.preventDefault()

        if (!isBusinessNameValid(businessName)) return

        store.dispatch(setShowModal(false))

        if (business === undefined) return

        if (business.name !== businessName) {
            let updatedBusiness = {...business}
            updatedBusiness.name = businessName

            log.d("onSubmit 1", JSON.stringify(business))
            log.d("onSubmit 2", JSON.stringify(updatedBusiness))

            /* unawaited */
            firebase.business
                .updateBusiness(updatedBusiness)
                .then()
                .catch((error) => {
                    ErrorManager.recordErrorAndAlert(log.tag, "onSubmit", error)
                })
        }

        if (
            settings === undefined ||
            logScale !== settings.scale ||
            measurementSystem !== settings.measurement_system ||
            weightUnit !== settings.scale_weight_unit
        ) {
            let updatedSettings: BusinessSettings = {
                id: settings?.id || business.id,
                business_id: settings?.business_id || business.id,
                scale: logScale,
                measurement_system: measurementSystem,
                scale_weight_unit: weightUnit,
            }
            /* unawaited */
            firebase.businessSettings.set(updatedSettings).then()
        }
    }

    return (
        <>
            <DialogTitle fontSize={"x-large"}>Business settings</DialogTitle>
            <Divider />
            <DialogContent>
                <Form id="edit_business_form">
                    <Form.Group className="mb-3" controlId="formBusinessName">
                        <Form.Control
                            placeholder="Enter business name"
                            defaultValue={businessName}
                            onChange={(e: any) => setBusinessName(e.target.value)}
                            isInvalid={!isBusinessNameValid(businessName)}
                        />
                        <Form.Text className="text-muted">Business name</Form.Text>
                        <Form.Control.Feedback type="invalid">Business name is to short</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="logScale">Scale</Form.Label>
                        <Form.Select id="logScale" defaultValue={logScale} onChange={(e: any) => setLogScale(e.target.value)}>
                            <option value={LogScale.Doyle}>Doyle</option>
                            <option value={LogScale.International}>International</option>
                            <option value={LogScale.JAS}>JAS</option>
                            <option value={LogScale.Scribner}>Scribner</option>
                            <option value={LogScale.Huber}>Huber</option>
                            <option value={LogScale.CylinderShort}>Cylinder Short</option>
                            <option value={LogScale.CylinderAverage}>Cylinder Average</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="measurementSystem">System</Form.Label>
                        <Form.Select id="measurementSystem" defaultValue={measurementSystem} onChange={(e: any) => setMeasurementSystem(e.target.value)}>
                            <option value={MeasurementSystem.metric}>Metric</option>
                            <option value={MeasurementSystem.imperial}>Imperial</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="weightUnit">Weights</Form.Label>
                        <Form.Select id="weightUnit" defaultValue={weightUnit} onChange={(e: any) => setWeightUnit(e.target.value)}>
                            <option value={WeightUnits.kilograms}>Kilograms</option>
                            <option value={WeightUnits.pounds}>Pounds</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color={"primary"} sx={{fontWeight: "bold"}}>
                    Cancel
                </Button>
                <Button onClick={onSubmit} color={"primary"} sx={{fontWeight: "bold"}} variant={"contained"} autoFocus>
                    Save
                </Button>
            </DialogActions>
        </>
    )
}

function isBusinessNameValid(name: string) {
    return name.length >= 3
}
