import React from "react"
import {MenuItem, TextField} from "@mui/material"
import {LengthOptions} from "../../../../../components/commodities/commodityGroupForm/Helpers/LengthOption"

export const LengthSelectorView = (props: LengthsSelectorProps) => {
    return (
        <TextField
            id={"Log-Lengths"}
            error={props.value === undefined}
            select
            sx={{marginInlineStart: 0, marginInlineEnd: 0, mb: 2}}
            label={"Length"}
            value={props.value}
            onChange={(e) => props.on_change(e.target.value)}
        >
            {LengthOptions.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    )
}

interface LengthsSelectorProps {
    value?: number
    on_change: (number: string) => void
}
