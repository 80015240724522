import type {RootState} from "../../app/store"

export const showModal = (state: RootState) => {
    return state.modal.show
}
export const showFullscreenOverlay = (state: RootState) => {
    return state.modal.show_fullscreen
}
export const modalType = (state: RootState) => {
    return state.modal.modal_type
}
export const modalMaxWidth = (state: RootState) => {
    return state.modal.modal_max_width
}
export const fullScreenModalType = (state: RootState) => {
    return state.modal.full_screen_modal_type
}
