import {MarketingImage, MarketingSectionContainer, MarketingSectionLeftContainer, MarketingSectionRightContainer, MarketingSectionSubtitleDark} from "./styles"
import {DarkButton, LightButton, Title3Black} from "../../../Styles/Fonts"
import {constants} from "../../../../../config/constants"

const WebMarketingCover = (props: MarketingSectionProps) => (
    <MarketingSectionContainer>
        <MarketingSectionLeftContainer>
            <Title3Black>{props.title}</Title3Black>
            <MarketingSectionSubtitleDark>{props.subtitle}</MarketingSectionSubtitleDark>
            <NavigateButton path={"login"} label={"Get Started"} isWhite={false} />
        </MarketingSectionLeftContainer>
        <MarketingSectionRightContainer>
            <MarketingImage src={props.url} />
        </MarketingSectionRightContainer>
    </MarketingSectionContainer>
)

export const NavigateButton = (props: NavigateButtonProps) => {
    return (
        <>
            {props.isWhite && <DarkButton onClick={() => window.open(constants.APP_STORE_URL, "_newtab")}>{props.label}</DarkButton>}
            {!props.isWhite && <LightButton onClick={() => window.open(constants.APP_STORE_URL, "_newtab")}>{props.label}</LightButton>}
        </>
    )
}

export interface NavigateButtonProps {
    path: string
    label: string
    isWhite: boolean
}

export interface MarketingSectionProps {
    title: string
    subtitle: string
    url: string
}

export default WebMarketingCover
