export interface MagicLinkTrigger {
    id: string
    magic_link_email: string
    type: MagicLinkTriggerType
}

export enum MagicLinkTriggerType {
    create_magic_link_email = "create_magic_link_email",
    create_web_magic_link_email = "create_web_magic_link_email",
}

export interface GenericTrigger {
    id: string
    time: unknown
}
