import {collection, doc, onSnapshot, query, setDoc, where} from "firebase/firestore"
import {db} from "../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {UserSettings} from "@common/types/UserSettings"

export default class UserSettingsDao {
    createUserSettings = async (settings: UserSettings) => {
        return await setDoc(doc(db, FIRCollectionPaths.USER_SETTINGS, settings.id), settings)
    }

    listenForUserSettings = (id: string, completion: (userSettings?: UserSettings) => void) => {
        const q = query(collection(db, FIRCollectionPaths.USER_SETTINGS), where("user_id", "==", id))
        return onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                completion(snapshot.docs[0].data() as UserSettings)
            } else {
                completion(undefined)
            }
        })
    }
}
