import styled from "styled-components"

export const AboutContainerMobile = styled.div`
    padding-top: 35px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 100px;
`

export const AboutItemContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
`

export const AboutImageMobile = styled.img`
    width: 100% !important;
`
