import {MeasurementSystem} from "@common/types/commodity/commodity"
import {Locale} from "../../../i18n/Localization"

export class LengthLabel {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    label = (type: MeasurementSystem): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case MeasurementSystem.metric:
                        return "L(M)"
                    case MeasurementSystem.imperial:
                        return "L(Ft)"
                }
                break
            case Locale.pt:
                switch (type) {
                    case MeasurementSystem.metric:
                        return "C(M)"
                    case MeasurementSystem.imperial:
                        return "C(Pés)"
                }
                break
            case Locale.es:
                switch (type) {
                    case MeasurementSystem.metric:
                        return "L(M)"
                    case MeasurementSystem.imperial:
                        return "L(Pies)"
                }
        }
    }
}
