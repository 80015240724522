import {components, firebase, store} from "../../../../app/store"
import {CommodityGroupAction} from "../../commodityGroups/viewModel/CommodityGroupActionsOptions"
import {setShowModal} from "../../../modal/slice"

export class CommodityGroupActionsHandler {
    didSelectAction = async (action: CommodityGroupAction) => {
        let model = store.getState().commodityGroup.group
        store.dispatch(setShowModal(false))
        if (model === undefined) {
            return
        }
        let group = model.group
        switch (action) {
            case CommodityGroupAction.Ready:
                await firebase.commodity_group.update.ready(group)
                break
            case CommodityGroupAction.Edit:
                components.commodity_group_form.userDidTap(model)
                break
            case CommodityGroupAction.Close:
                await firebase.commodity_group.update.close(group)
                break
            case CommodityGroupAction.ReOpen:
                await firebase.commodity_group.update.reopen(group)
                break
            case CommodityGroupAction.Unready:
                await firebase.commodity_group.update.unready(group)
                break
            case CommodityGroupAction.Archive:
                await firebase.commodity_group.update.archive(group)
                break
            case CommodityGroupAction.UnArchive:
                await firebase.commodity_group.update.unarchive(group)
                break
            case CommodityGroupAction.Weights:
                components.weights_form.userDidTap(model)
                break
            case CommodityGroupAction.Allocations:
                await firebase.api_gateway.create.createAllocationsTrigger(group.id)
                break
            case CommodityGroupAction.UndoAllocations:
                await firebase.api_gateway.create.createUndoAllocationsTrigger(group.id)
                break
            case CommodityGroupAction.UndoGeneratedLogs:
                await firebase.api_gateway.create.createUndoGeneratedLogsTrigger(group.id)
                break
            case CommodityGroupAction.UpdateStock:
                await firebase.api_gateway.create.createStockUpdateTrigger(group.id)
                break
            case CommodityGroupAction.Ok:
                break
        }
    }
}
