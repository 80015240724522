export default class MathUtils {
    // For <= 0.5 round down
    // For > 0.5 round up
    static round0Special(number: number): number {
        const integer = Math.trunc(number)
        const fractional = number - integer
        if (fractional <= 0.5) {
            return Math.floor(number)
        } else {
            return Math.ceil(number)
        }
    }

    static isEven(n: number) {
        return n % 2 === 0
    }

    static isOdd(n: number) {
        return n % 2 !== 0
    }

    static round0(number: number): number {
        return Math.round(number)
    }

    static round1(number: number): number {
        return Math.round(number * 10) / 10
    }

    static round2(number: number): number {
        return Math.round(number * 100) / 100
    }

    static round3(number: number) {
        return Math.round(number * 1000) / 1000
    }

    static round4(number: number) {
        return Math.round(number * 10000) / 10000
    }

    static sum(array: number[]) {
        if (!array || array.length === 0) {
            return 0
        }
        return array.reduce((a, b) => a + b)
    }

    static mean(array: number[]) {
        if (!array || array.length === 0) {
            return 0
        }
        return array.reduce((a, b) => a + b) / array.length
    }

    static isEqual(a: number, b: number, epsilon: number): boolean {
        return Math.abs(a - b) < epsilon
    }
}
