import {CommodityGroupImage} from "@common/types/commodity/commodityGroup"
import CommodityGroupViewModel from "../commodityGroups/viewModel/CommodityGroupViewModel"
import CommodityViewModel from "../commodityGroups/viewModel/CommodityViewModel"
import CommodityGroupFormViewModel from "../commodityGroups/viewModel/CommodityGroupFormModel"

export enum CommodityGroupDetailTabOption {
    measurements = "measurements",
    log_list = "log_list",
    images = "images",
}

export interface CommodityGroupState {
    group?: CommodityGroupViewModel
    form?: CommodityGroupFormViewModel
    commodities: CommodityViewModel[]
    images: CommodityGroupImage[]
    loading: boolean
    form_loading: boolean
    tab: CommodityGroupDetailTabOption
}

export const initialState: CommodityGroupState = {
    commodities: [],
    images: [],
    loading: false,
    form_loading: false,
    tab: CommodityGroupDetailTabOption.measurements,
}
