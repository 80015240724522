import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {CommodityGroupDetailTabOption, initialState} from "./state"
import {CommodityGroupImage} from "@common/types/commodity/commodityGroup"
import CommodityGroupViewModel from "../commodityGroups/viewModel/CommodityGroupViewModel"
import CommodityViewModel from "../commodityGroups/viewModel/CommodityViewModel"
import CommodityGroupFormViewModel from "../commodityGroups/viewModel/CommodityGroupFormModel"
import InboundForm from "../commodityGroupForm/Forms/InboundForm"
import RowForm from "../commodityGroupForm/Forms/RowForm"
import OutboundForm from "../commodityGroupForm/Forms/OutboundForm"
import OrderForm from "../commodityGroupForm/Forms/OrderForm"
import VesselSectionForm from "../commodityGroupForm/Forms/VesselSectionForm"
import ZoneForm from "../commodityGroupForm/Forms/ZoneForm"
import VesselForm from "../commodityGroupForm/Forms/VesselForm"

export const slice = createSlice({
    name: "commodityGroup",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setFormLoading: (state, action: PayloadAction<boolean>) => {
            state.form_loading = action.payload
        },
        setForm: (state, action: PayloadAction<CommodityGroupFormViewModel | undefined>) => {
            state.form = action.payload
        },
        setGroup: (state, action: PayloadAction<CommodityGroupViewModel | undefined>) => {
            state.group = action.payload
        },
        setCommodities: (state, action: PayloadAction<CommodityViewModel[]>) => {
            state.commodities = action.payload
        },
        setImages: (state, action: PayloadAction<CommodityGroupImage[]>) => {
            state.images = action.payload
        },
        setDetailTab: (state, action: PayloadAction<CommodityGroupDetailTabOption>) => {
            state.tab = action.payload
        },
        setInboundForm: (state, action: PayloadAction<InboundForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, inbound: action.payload}
        },
        setRowForm: (state, action: PayloadAction<RowForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, row: action.payload}
        },
        setOutboundForm: (state, action: PayloadAction<OutboundForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, outbound: action.payload}
        },
        setOrderForm: (state, action: PayloadAction<OrderForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, order: action.payload}
        },
        setVesselForm: (state, action: PayloadAction<VesselForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, vessel: action.payload}
        },
        setVesselSectionForm: (state, action: PayloadAction<VesselSectionForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, vessel_section: action.payload}
        },
        setZoneForm: (state, action: PayloadAction<ZoneForm>) => {
            let model = state.form
            if (model === undefined) {
                return
            }
            state.form = {...model, zone: action.payload}
        },
    },
})

export const {
    setGroup,
    setCommodities,
    setImages,
    setDetailTab,
    setForm,
    setInboundForm,
    setRowForm,
    setOutboundForm,
    setOrderForm,
    setVesselForm,
    setVesselSectionForm,
    setZoneForm,
    setFormLoading,
    setLoading,
} = slice.actions
export default slice.reducer
