import * as React from "react"
import {MutableRefObject, useEffect, useRef} from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import {
    GridActionsCellItem,
    GridColDef,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarContainer,
} from "@mui/x-data-grid"
import {StyledDataGrid} from "../../../../dataTable/tables/sfc/styledDataGrid"
import {components} from "../../../../../app/store"
import {Unsubscribe} from "firebase/firestore"
import Logger from "../../../../../helpers/Logger"
import {v4 as uuid} from "uuid"
import {Forest} from "@common/types/Forest"
import {GridPreProcessEditCellProps} from "@mui/x-data-grid-premium"
import {Loader} from "../../../../loader"
import {useTranslation} from "react-i18next"

const log = new Logger("ForestsView")

export function ForestsView() {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [rows, setRows] = React.useState<GridRowsProp>([])
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const forestsUnsubscribe: MutableRefObject<Unsubscribe | null> = useRef(null)

    useEffect(() => {
        forestsUnsubscribe.current = components.forests.listen((forests) => {
            log.d("listen", `forests:${forests.length}`)
            setIsLoading(false)
            const rows = forests.map((forest) => {
                return {id: forest.id, name: forest.name}
            })
            setRows(rows)
        })

        return () => {
            if (forestsUnsubscribe.current) {
                forestsUnsubscribe.current()
                forestsUnsubscribe.current = null
            }
        }
    }, []) // Empty array deps = useEffect() runs once

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true
        }
    }

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}})
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}})
    }

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        })

        const editedRow = rows.find((row) => row.id === id)
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id))
        }
    }

    async function processRowUpdate(newRow: GridRowModel, originalRow: GridRowModel) {
        const forest = Forest.create(newRow.id, newRow.name)
        try {
            await components.forests.set(forest)
            const updatedRow = {...newRow, isNew: false}
            setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
            return updatedRow
        } catch (error) {
            log.e("processRowUpdate", error)
            return originalRow
        }
    }

    function onAddForest() {
        // Add only one row at a time (until it is saved)
        for (const row of rows) {
            if (row.isNew) {
                return
            }
        }

        const id = uuid()
        setRows((oldRows) => [{id, name: "", isNew: true}, ...oldRows])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: "name"},
        }))
    }

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel)
    }

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: t("name"),
            width: 250,
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value.length < 3
                return {...params.props, error: hasError}
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("actions"),
            width: 100,
            cellClassName: "actions",
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
                    ]
                }

                return [<GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />]
            },
        },
    ]

    return (
        <Box
            sx={{
                height: 500,
                width: 352,
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
            }}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: {onAddForest},
                    }}
                    hideFooter
                />
            )}
        </Box>
    )
}

function EditToolbar(props: {onAddForest: () => void}) {
    const {t} = useTranslation()

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={props.onAddForest}>
                {t("add")}
            </Button>
        </GridToolbarContainer>
    )
}
