import {RootState} from "../../../../app/store"
import {GridValidRowModel} from "@mui/x-data-grid-premium"
import {LogClass, Species} from "@common/types/commodity/commodity"
import MeasurementsTransformations from "../../helpers/MeasurementsTransformations"
import {CommodityColumnID, commodityColumnTypes} from "../Columns/selectors"
import CommodityViewModel from "../../../commodities/commodityGroups/viewModel/CommodityViewModel"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import DateFormatters from "../../../../utils/DateFormatters"
import CommodityEventViewModel from "../../../commodities/commodityGroups/viewModel/CommodityEventViewModel"
import Display from "../../../../utils/Display"
import {measurementSystem} from "../../../authentication/selectors"

export const commodityRows = (state: RootState): GridValidRowModel[] => {
    let commodities = state.commodityGroup.commodities
    return commodities.map((commodity) => commodityRow(commodity, state))
}

const commodityRow = (commodity: CommodityViewModel, state: RootState) => {
    var obj: Record<string, any> = {}
    let columnTypes = commodityColumnTypes()
    obj.id = commodity.id
    let inbound = "-"
    let row = "-"
    let outbound = "-"
    let stock = commodity.commodity.measurements
    let transformations = new MeasurementsTransformations(state, commodity.commodity.properties?.log?.log_class, stock)
    let spec = commodity.commodity.properties?.log?.species ? commodity.commodity.properties?.log?.species : Species.Pine
    let logClass = commodity.commodity.properties?.log?.log_class ? commodity.commodity.properties?.log?.log_class : LogClass.saw_log
    let created =
        DateFormatters.getFormattedDateFromTimestamp(commodity.commodity.time) + ", " + DateFormatters.getFormattedTimeFromTimestamp(commodity.commodity.time)

    const system = measurementSystem(state)
    var deduction = commodity.commodity.measurements?.deductions?.measurement?.deduction
    let diameterDeductions = deduction ? Display.displayLengthCmR2(deduction, system) : 0

    deduction = commodity.commodity.measurements?.deductions?.length?.deduction
    let lengthDeductions = deduction ? Display.displayLengthMR2(deduction, system) : 0

    let notes = commodity.commodity.measurements?.deductions?.operator_notes ? commodity.commodity.measurements?.deductions?.operator_notes : "-"
    let defectCodes = commodity.commodity.measurements?.deductions?.defect_codes ? commodity.commodity.measurements?.deductions?.defect_codes : "-"

    let weightOptional = commodity.commodity.measurements?.weight?.weight
    let weight = weightOptional ? Display.displayWeightR2(weightOptional, system) : 0

    let inboundEvent = eventOfType(commodity.events, CommodityGroupType.inbound_load)
    let rowEvent = eventOfType(commodity.events, CommodityGroupType.row)
    let outboundEvent = eventOfType(commodity.events, CommodityGroupType.outbound_load)

    if (inboundEvent !== undefined) {
        inbound = inboundEvent.group_first_id
    }
    if (rowEvent !== undefined) {
        row = rowEvent.group_first_id
    }
    if (outboundEvent !== undefined) {
        outbound = outboundEvent.group_first_id
    }

    columnTypes.forEach((column) => {
        switch (column.id) {
            case CommodityColumnID.system_generated:
                obj.system_generated = commodity.commodity.system_generated ? commodity.commodity.system_generated : false
                break
            case CommodityColumnID.inbound:
                obj.inbound = inbound
                break
            case CommodityColumnID.outbound:
                obj.outbound = outbound
                break
            case CommodityColumnID.row:
                obj.row = row
                break
            case CommodityColumnID.species:
                obj.species = spec
                break
            case CommodityColumnID.log_class:
                obj.log_class = logClass
                break
            case CommodityColumnID.diameter_class:
                obj.diameter_class = transformations.diameter_class_string
                break
            case CommodityColumnID.short:
                obj.short = transformations.short
                break
            case CommodityColumnID.rounded_sed:
                obj.rounded_sed = transformations.rounded_short
                break
            case CommodityColumnID.long:
                obj.long = transformations.long
                break
            case CommodityColumnID.avg:
                obj.avg = transformations.avg
                break
            case CommodityColumnID.length:
                obj.length = transformations.length
                break
            case CommodityColumnID.volume:
                obj.volume = transformations.volume
                break
            case CommodityColumnID.weight:
                obj.weight = weight
                break
            case CommodityColumnID.diameter_deductions:
                obj.diameter_deductions = diameterDeductions
                break
            case CommodityColumnID.length_deductions:
                obj.length_deductions = lengthDeductions
                break
            case CommodityColumnID.defect_codes:
                obj.defect_codes = defectCodes
                break
            case CommodityColumnID.operator_notes:
                obj.operator_notes = notes
                break
            case CommodityColumnID.event_summaries:
                obj.event_summaries = commodity.event_summaries
                break
            case CommodityColumnID.id:
                obj.id = commodity.id
                break
            case CommodityColumnID.created:
                obj.created = created
        }
    })
    return obj
}

function eventOfType(events: CommodityEventViewModel[], type: CommodityGroupType) {
    return events.find((event) => event.group_type === type)
}
