import {CommodityEvent} from "../../../../types/commodity/CommodityEvent"
import {setCommodities, setLoading} from "../slice"
import {firebase, store} from "../../../../app/store"
import {CommodityGroupActionsHandler} from "../actions/CommodityGroupActionsHandler"
import CommodityViewModel from "../../commodityGroups/viewModel/CommodityViewModel"
import {selectedGroup} from "../selectors/selectors"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import Logger from "../../../../helpers/Logger"
import ArrayUtils from "@common/utils/ArrayUtils"
import Utils from "@common/utils/Utils"

const log = new Logger("CommodityGroupComponent")

export default class CommodityGroupComponent {
    actions = new CommodityGroupActionsHandler()

    loadCommodityGroupCommodities = () => {
        let group = selectedGroup(store.getState())
        if (group === undefined) {
            return
        }
        store.dispatch(setLoading(true))
        if (group.group.type === CommodityGroupType.order) {
            return this.getOrderCommodities(group.group)
        }
        this.loadNonOrderCommodities(group.group)
    }

    private getOrderCommodities = (group: CommodityGroup) => {
        log.d("getOrderCommodities", `group:${group.id}`)
        this.getOutboundsCommodityEvents(group).then((events) => {
            this.commodityEventsCompletionHandler(events).then()
        })
    }

    // Listener removed, because commodity group may be huge, and it will cause problems, when new event will appear,
    // and we have to load everything again if previous download was not completed -
    // firestore will likely be overloaded with requests and will throw error
    private loadNonOrderCommodities = (group: CommodityGroup) => {
        log.d("loadNonOrderCommodities", `group:${group.id}`)
        firebase.commodity_event.read.getCommodityGroupCommodityEvents(group.id).then((events) => {
            this.commodityEventsCompletionHandler(events).then()
        })
    }

    private commodityEventsCompletionHandler = async (groupEvents: CommodityEvent[]) => {
        let models: CommodityViewModel[] = []
        try {
            let commodityIds = groupEvents.map((event) => event.commodity_id)
            let commodities = await firebase.commodity.read.getCommodities(commodityIds)
            const business = store.getState().authentication.business!
            let allEvents = await firebase.commodity_event.read.getAllEventsForCommodities(commodities, business!.id)
            let groupIds = allEvents.map((event) => event.commodity_group_id)
            let uniqueGroupIds = ArrayUtils.distinct(groupIds)
            const groups = await firebase.commodity_group.read.getCommodityGroups(uniqueGroupIds)
            models = commodities.map((commodity) => new CommodityViewModel(commodity, allEvents, groups, business))
        } catch (error) {
            log.e("commodityEventsCompletionHandler", error)
            return
        }

        store.dispatch(setCommodities(models))
        await Utils.delay(400)
        store.dispatch(setLoading(false))
    }

    //
    // If Selecting Commodity Group Of Type Order, Only Look at Outbound Load Commodity Events
    //
    private getOutboundsCommodityEvents = async (order: CommodityGroup): Promise<CommodityEvent[]> => {
        const outbounds = await firebase.commodity_group.read.getGroupsLinkedToGroupId(order.id, CommodityGroupType.outbound_load)
        const ids = outbounds.map((outbound) => outbound.id)
        const events = await firebase.commodity_event.read.getEventsForGroups(ids)
        return events
    }
}
