import {useMediaQuery} from "react-responsive"
import {AboutWeb} from "./Web"
import {AboutMobile} from "./Mobile"
import {useAppSelector} from "../../../../app/hooks"
import {aboutItems, marketingTheme} from "../../../../components/marketing/selectors"
import {Constants} from "../../../../config/constants"

export const About = () => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})
    const theme = useAppSelector(marketingTheme)
    const content = aboutItems(theme)

    return (
        <>
            {!isMobile && <AboutWeb items={content} />}
            {isMobile && <AboutMobile items={content} />}
        </>
    )
}
