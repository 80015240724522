import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {Forest} from "@common/types/Forest"
import {db} from "../db"
import {collection, doc, getDocs, onSnapshot, query, setDoc, Unsubscribe} from "firebase/firestore"
import ClassedError from "../../components/errorManager/ClassedError"
import {ErrorManager} from "../../components/errorManager"

// const log = new Logger("BusinessForestDao")
export default class BusinessForestDao {
    async set(businessId: string, forest: Forest) {
        const docRef = doc(db, FIRCollectionPaths.businessForest(businessId, forest.id))
        await setDoc(docRef, forest).catch((error) => reThrow("create", error))
    }

    async getForests(businessId: string) {
        const snapshot = await getDocs(collection(db, FIRCollectionPaths.businessForests(businessId)))
        return snapshot.docs.map((doc) => doc.data() as Forest)
    }

    listen(businessId: string, completion: (forests: Forest[]) => void): Unsubscribe {
        const q = query(collection(db, FIRCollectionPaths.businessForests(businessId)))
        return onSnapshot(
            q,
            (snapshot) => {
                const forests = snapshot.docs.map((doc) => {
                    return doc.data() as Forest
                })
                completion(forests)
            },
            (error) => reportError("listen.onSnapshot", error),
        )
    }
}

function reThrow(functionName: string, error: unknown) {
    throw new ClassedError("BusinessForestDao", functionName, error as Error)
}

function reportError(functionName: string, error: unknown) {
    ErrorManager.recordErrorAndAlert("BusinessForestDao", functionName, error as Error)
}
