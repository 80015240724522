export default class Convert {
    static metersToFeet = (meters: number) => meters * 3.2808
    static feetToMeters = (feet: number) => feet / 3.2808
    static inchesToCm = (inches: number) => inches * 2.54
    static cmToInches = (cm: number) => cm / 2.54
    static lbsToKgs = (lbs: number) => lbs / 2.20462
    static kgsToMt = (kgs: number) => kgs / 1000
    static kgsToLbs = (kgs: number) => kgs * 2.20462
    static lbsToShortTons = (lbs: number) => lbs / 2000
}
