import CommodityGroupForm from "./CommodityGroupForm"
import {store} from "../../../../app/store"
import {setVesselForm} from "../../commodityGroup/slice"

export default class VesselForm extends CommodityGroupForm {
    setState() {
        store.dispatch(setVesselForm(this))
    }
    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }
}
