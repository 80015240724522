import React from "react"
import {GridToolbar} from "@mui/x-data-grid-premium"
import {useAppSelector} from "../../../app/hooks"
import {selectedTab} from "../../../components/dataTables/commodityGroup/selectors"
import {components} from "../../../app/store"
import {commodityGroupsLoading} from "../../../components/commodities/commodityGroups/selectors"
import {Loader} from "../../loader"
import {commodityGroupColumns} from "../../../components/dataTables/commodityGroup/Columns/selectors"
import {rows} from "../../../components/dataTables/commodityGroup/Rows/selectors"
import {actionLabels} from "../../../components/localization/selectors"
import {retrieveFilterStateFromLocalStorage, saveFilterStateToLocalStorage} from "../../../components/dataTables/filterState"
import {CommodityGroupBoxStyledDataTableView} from "./sfc/styledDataGrid"

export const CommodityGroupDataTableView = () => {
    const labels = useAppSelector(actionLabels)
    const tab = useAppSelector(selectedTab)
    const tableRows = useAppSelector(rows)
    const tableColumns = useAppSelector(commodityGroupColumns)
    const loading = useAppSelector(commodityGroupsLoading)
    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <CommodityGroupBoxStyledDataTableView
                    rows={tableRows}
                    columns={tableColumns}
                    state={retrieveFilterStateFromLocalStorage(tab)}
                    onStateChange={(state) => saveFilterStateToLocalStorage(tab, state)}
                    toolbar={GridToolbar}
                    page_size={50}
                    onViewClick={components.data_table.viewCommodityGroupDetails}
                    onActionsClick={components.data_table.showCommodityGroupActions}
                    actionsTitle={labels.actionsButtonLabel()}
                    viewTitle={labels.viewButtonLabel()}
                    showActionButtons={true}
                />
            )}
        </>
    )
}
