import React from "react"
import {createRoot} from "react-dom/client"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import "bootstrap/dist/css/bootstrap.min.css"
import {Provider} from "react-redux"
import {store} from "./app/store"
import {BrowserRouter as Router} from "react-router-dom"
import {AppRoutes} from "./routes"
import ScrollToTop from "./view/marketing/Navigation/ScrollToTop"
import "./i18n/config"

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <AppRoutes />
            </Router>
        </Provider>
    </React.StrictMode>,
)

serviceWorker.unregister()
