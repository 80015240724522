import {initializeApp} from "firebase/app"
import {connectAuthEmulator, getAuth} from "firebase/auth"
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"
import {firebaseConfig} from "../config/firebase_config"
import {constants} from "../config/constants"

export const firebaseApp = initializeApp(firebaseConfig)

export const db = getFirestore(firebaseApp)
console.log("USE_FIREBASE_EMULATORS: " + constants.USE_FIREBASE_EMULATORS)
if (constants.USE_FIREBASE_EMULATORS) {
    console.log("Use emulators")
    connectFirestoreEmulator(db, "localhost", 8080)
    connectAuthEmulator(getAuth(), "http://localhost:9099")
}

// setLogLevel("debug") // Enable Firebase logging

export const firebaseStorage = getStorage(firebaseApp)
