import {Timestamp} from "firebase/firestore"
import {
    BQMigrationStatus,
    CommodityGroupCoordinates,
    CommodityGroupImages,
    CommodityGroupMeasurementsSummary,
    CommodityGroupStatus,
    CommodityGroupTagRange,
    CommodityGroupType,
    CommodityGroupUserEntries,
    CommodityType,
} from "@common/types/commodity/commodityGroup"
import {LinkedBusiness} from "../Business"
import {ZoneType} from "@common/types/ZoneType"

export interface CommodityGroup {
    id: string
    created_by_business_id: string
    created_by_user_id: string
    linked_business_ids: string[]
    linked_businesses: LinkedBusiness[]
    linked_commodity_group_ids: string[]
    last_updated_time?: Timestamp
    type: CommodityGroupType
    times: CommodityGroupTimes
    status: CommodityGroupStatus
    user_entries: CommodityGroupUserEntries
    stock?: CommodityGroupMeasurementsSummary
    bq_migration_status?: BQMigrationStatus
    commodity_type?: CommodityType
    zone_type?: ZoneType
    coordinates?: CommodityGroupCoordinates
    images?: CommodityGroupImages
    tag_range?: CommodityGroupTagRange
    audited?: boolean
    audit_complete?: boolean
    allocating?: boolean
    pending_internet_sync_label?: string
}

export namespace CommodityGroup {
    export function getName(group: CommodityGroup) {
        return group.user_entries.identifiers.first_id
    }

    export function name(group: CommodityGroup) {
        return `[${group.user_entries.identifiers.first_id}]`
    }

    export function idName(group: CommodityGroup) {
        return `[${group.id}]${CommodityGroup.name(group)}`
    }

    export function idNameType(group: CommodityGroup) {
        return `[${group.id}]${CommodityGroup.name(group)}[${group.type}]`
    }

    export function idType(group: CommodityGroup) {
        return `[${group.id}][${group.type}]`
    }
}

export interface CommodityGroupTimes {
    created: Timestamp
    ready?: Timestamp
    closed?: Timestamp
    archived?: Timestamp
}
