import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {Locale} from "../../../i18n/Localization"

export default class CommodityGroupTypeLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    singular = (type: CommodityGroupType): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Inbound Load"
                    case CommodityGroupType.row:
                        return "Row"
                    case CommodityGroupType.outbound_load:
                        return "Outbound Load"
                    case CommodityGroupType.order:
                        return "Order"
                    case CommodityGroupType.vessel:
                        return "Vessel"
                    case CommodityGroupType.vessel_section:
                        return "Compartment"
                    case CommodityGroupType.zone:
                        return "Zone"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Carga de Entrada"
                    case CommodityGroupType.row:
                        return "Pilha"
                    case CommodityGroupType.outbound_load:
                        return "Carga de Saída"
                    case CommodityGroupType.order:
                        return "Booking"
                    case CommodityGroupType.vessel:
                        return "Barco"
                    case CommodityGroupType.vessel_section:
                        return "Compartimento"
                    case CommodityGroupType.zone:
                        return "Zona"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Carga de Entrada"
                    case CommodityGroupType.row:
                        return "Pila"
                    case CommodityGroupType.outbound_load:
                        return "Carga de Salida"
                    case CommodityGroupType.order:
                        return "Booking"
                    case CommodityGroupType.vessel:
                        return "Barco"
                    case CommodityGroupType.vessel_section:
                        return "Compartimiento"
                    case CommodityGroupType.zone:
                        return "Zona"
                }
        }
    }

    plural = (type: CommodityGroupType): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Inbound Loads"
                    case CommodityGroupType.row:
                        return "Rows"
                    case CommodityGroupType.outbound_load:
                        return "Outbound Loads"
                    case CommodityGroupType.order:
                        return "Orders"
                    case CommodityGroupType.vessel:
                        return "Vessels"
                    case CommodityGroupType.vessel_section:
                        return "Compartments"
                    case CommodityGroupType.zone:
                        return "Zones"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Cargas de Entrada"
                    case CommodityGroupType.row:
                        return "Pilhas"
                    case CommodityGroupType.outbound_load:
                        return "Cargas de Saída"
                    case CommodityGroupType.order:
                        return "Bookings"
                    case CommodityGroupType.vessel:
                        return "Barcos"
                    case CommodityGroupType.vessel_section:
                        return "Compartimentos"
                    case CommodityGroupType.zone:
                        return "Zonas"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Cargas de Entrada"
                    case CommodityGroupType.row:
                        return "Pilas"
                    case CommodityGroupType.outbound_load:
                        return "Cargas de Salida"
                    case CommodityGroupType.order:
                        return "Bookings"
                    case CommodityGroupType.vessel:
                        return "Barcos"
                    case CommodityGroupType.vessel_section:
                        return "Compartimientos"
                    case CommodityGroupType.zone:
                        return "Zonas"
                }
        }
    }

    shortPlural = (type: CommodityGroupType): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Inbounds"
                    case CommodityGroupType.row:
                        return "Rows"
                    case CommodityGroupType.outbound_load:
                        return "Outbounds"
                    case CommodityGroupType.order:
                        return "Orders"
                    case CommodityGroupType.vessel:
                        return "Vessels"
                    case CommodityGroupType.vessel_section:
                        return "Compartments"
                    case CommodityGroupType.zone:
                        return "Zones"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Entradas"
                    case CommodityGroupType.row:
                        return "Pilhas"
                    case CommodityGroupType.outbound_load:
                        return "Saídas"
                    case CommodityGroupType.order:
                        return "Bookings"
                    case CommodityGroupType.vessel:
                        return "Barcos"
                    case CommodityGroupType.vessel_section:
                        return "Compartimentos"
                    case CommodityGroupType.zone:
                        return "Zonas"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Entradas"
                    case CommodityGroupType.row:
                        return "Pilas"
                    case CommodityGroupType.outbound_load:
                        return "Salidas"
                    case CommodityGroupType.order:
                        return "Bookings"
                    case CommodityGroupType.vessel:
                        return "Barcos"
                    case CommodityGroupType.vessel_section:
                        return "Compartimientos"
                    case CommodityGroupType.zone:
                        return "Zonas"
                }
        }
    }
}
