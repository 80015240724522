import * as React from "react"
import {PaddedCaretImage, PickerAlertContainer, PickerAlertMessage, PickerButtonView, PickerMenuItemContainer, PickerMenuItemFlexContainer} from "./styles"
import {Title1, Subtitle1} from "../../../../Styles/Fonts"
import {ButtonProps, ButtonSelectProps, MenuItemSelectProps} from "../../model"
import {store} from "../../../../../../app/store"
import {setTheme} from "../../../../../../components/marketing/slice"
import {getRandomThemeNotCurrent} from "../../../../../../components/marketing/state"

export const Button = (props: ButtonProps) => {
    const handleClick = () => {
        store.dispatch(setTheme(getRandomThemeNotCurrent()))
    }
    return <PickerButton select={handleClick} props={props} />
}

const PickerButton = (props: ButtonSelectProps) => (
    <PickerButtonView
        id="basic-button"
        onClick={props.select}
        style={{
            backgroundColor: "#61EEC1",
            color: "#000",
            marginLeft: 15,
            marginRight: 25,
            padding: "0px !important",
        }}
    >
        {props.props.title}
        <PaddedCaretImage alt="" src={"/refresh.png"} />
    </PickerButtonView>
)

export const NavigationMenuListItem = (props: MenuItemSelectProps) => (
    <PickerMenuItemContainer onClick={() => props.select(props.item)}>
        <PickerMenuItemFlexContainer onClick={() => props.select(props.item)}>
            <PickerAlertContainer>
                <Title1>{props.item.item}</Title1>
                {props.item.alert && <PickerAlertMessage>{props.item.alert}</PickerAlertMessage>}
            </PickerAlertContainer>
            {props.item.description && <Subtitle1>{props.item.description}</Subtitle1>}
        </PickerMenuItemFlexContainer>
    </PickerMenuItemContainer>
)
