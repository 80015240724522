import {CommodityGroup, CommodityGroupTimes} from "../../../../types/commodity/CommodityGroup"
import {store} from "../../../../app/store"
import {Timestamp} from "firebase/firestore"
import {
    CommodityGroupIdentifiers,
    CommodityGroupMeasurementsSummary,
    CommodityGroupStatus,
    CommodityGroupUserEntries,
    CommodityType,
} from "@common/types/commodity/commodityGroup"
import {CommodityProperties} from "@common/types/commodity/commodity"
import {v4 as uuid} from "uuid"
import CommodityGroupViewModel from "../../commodityGroups/viewModel/CommodityGroupViewModel"

export default class NewCommodityGroup {
    get = (): CommodityGroupViewModel | undefined => {
        let state = store.getState()
        let business = state.authentication.business
        let user = state.authentication.user
        if (business === undefined || user === undefined) {
            return undefined
        }

        let times: CommodityGroupTimes = {
            created: Timestamp.now(),
        }
        let type = state.dataTable.tab
        let stock: CommodityGroupMeasurementsSummary = {}
        let identifiers: CommodityGroupIdentifiers = {
            first_id: "",
        }
        let properties: CommodityProperties = {}
        let userEntries: CommodityGroupUserEntries = {
            identifiers: identifiers,
            properties: properties,
        }
        let id = uuid()
        let group: CommodityGroup = {
            commodity_type: CommodityType.Logs,
            created_by_business_id: business.id,
            created_by_user_id: user.id,
            id: id,
            linked_business_ids: [business.id],
            linked_businesses: [],
            linked_commodity_group_ids: [id],
            status: CommodityGroupStatus.Open,
            stock: stock,
            times: times,
            type: type,
            user_entries: userEntries,
        }
        let model = new CommodityGroupViewModel(group, business.name)
        return model
    }
}
