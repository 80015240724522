import {GridPoint, MidPoints} from "./commodity/commodity"
import Centroid from "./Centroid"

const MIN_INTERSECT_DISTANCE = 0.005

export class PerimeterPointUtils {
    static getShortestLongestPointPairs(responseItem: ScaleApiResponseItem): ShortestLongestPointPairs | undefined {
        const centroid = new Centroid(responseItem.normalized_perimeter)
        const center = centroid.centroid()
        return PerimeterPointUtils.findShortestLongestPointPairs(responseItem.normalized_perimeter, center)
    }

    static getCentimetersFromPoints(first: GridPoint, second: GridPoint, cmSizeReference: number): number {
        const distance = PerimeterPointUtils.distanceBetweenTwoPoints(first, second)
        const cm = distance * cmSizeReference
        const truncated = Math.round(cm * 10) / 10
        return truncated
    }

    static findLargestInteriorRectangle(points: GridPoint[]): GridPoint[] | undefined {
        let rectangleResults: RectangleResult[] = []

        points.map((first) => {
            let firstFilter = PerimeterPointUtils.filterOutPoint(first, points)
            firstFilter.map((second) => {
                let secondFilter = PerimeterPointUtils.filterOutPoint(second, firstFilter)
                let result = PerimeterPointUtils.findPointOnPerpendicularLine(first, second, secondFilter)
                if (result === undefined) {
                    return
                }
                let third = result.second
                let thirdFilter = PerimeterPointUtils.filterOutPoint(third, secondFilter)
                let secondResult = PerimeterPointUtils.findPointOnPerpendicularLine(second, third, thirdFilter)
                if (secondResult === undefined) {
                    return
                }
                let fourth = secondResult.second
                if (PerimeterPointUtils.isPointCloseToPointPerpendicularLine(first, second, fourth)) {
                    let rectangle = PerimeterPointUtils.getRectangleResult(first, second, third, fourth)
                    rectangleResults.push(rectangle)
                }
            })
        })
        let sortedLargest = rectangleResults.sort((a, b) => b.area - a.area)
        let largest = sortedLargest[0]
        if (largest === undefined) {
            return undefined
        }
        let final = [largest.first, largest.second, largest.third, largest.fourth]
        return final
    }

    private static roundUpIfNeeded(smallDiameter: number): number {
        if (!PerimeterPointUtils.isOdd(smallDiameter)) {
            return smallDiameter
        }
        let decimal = smallDiameter - Math.floor(smallDiameter)
        if (decimal < 0.6) {
            return smallDiameter
        }
        return Math.ceil(smallDiameter)
    }

    private static isOdd(number: number): boolean {
        let roundedDown = Math.floor(number)
        return roundedDown % 2 === 1
    }

    private static getPerimeterPointPair(first: GridPoint, second: GridPoint): PerimeterPointPair {
        const distance = PerimeterPointUtils.distanceBetweenTwoPoints(first, second)
        const pair: PerimeterPointPair = {first: first, second: second, distance: distance}
        return pair
    }

    private static distanceBetweenTwoPoints(first: GridPoint, second: GridPoint): number {
        return Math.sqrt(Math.pow(second.x - first.x, 2) + Math.pow(second.y - first.y, 2))
    }

    private static oppositeQudrants(first: GridPoint, second: GridPoint, center: GridPoint): boolean {
        const firstXDistanceToCenterX = first.x * 100 - center.x * 100
        const firstYDistanceToCenterY = first.y * 100 - center.y * 100
        const secondXDistanceToCenterX = second.x * 100 - center.x * 100
        const secondYDistanceToCenterY = second.y * 100 - center.y * 100

        if (PerimeterPointUtils.oppositeSigns(firstXDistanceToCenterX, secondXDistanceToCenterX)) {
            if (PerimeterPointUtils.oppositeSigns(firstYDistanceToCenterY, secondYDistanceToCenterY)) {
                return true
            }
        }
        return false
    }

    private static oppositeSigns(x: number, y: number): boolean {
        return (x ^ y) < 0
    }

    private static distanceFromLine(point1: GridPoint, point2: GridPoint, compare: GridPoint): number {
        return (
            Math.abs((point2.y - point1.y) * compare.x - (point2.x - point1.x) * compare.y + point2.x * point1.y - point2.y * point1.x) /
            Math.pow(Math.pow(point2.y - point1.y, 2) + Math.pow(point2.x - point1.x, 2), 0.5)
        )
    }

    private static findLikeliestIntersect(
        first: GridPoint,
        second: GridPoint,
        options: GridPoint[],
        minDistanceToLine: number,
        removeNear: boolean,
    ): ComparePointPair | undefined {
        const comparePairs: ComparePointPair[] = []

        options.map((comparePoint) => {
            const distanceFromLine = PerimeterPointUtils.distanceFromLine(first, second, comparePoint)
            let distanceBetweenPoints = PerimeterPointUtils.distanceBetweenTwoPoints(first, comparePoint)
            const comparePair: ComparePointPair = {first: first, second: comparePoint, distanceFromLine: distanceFromLine, distance: distanceBetweenPoints}
            if (comparePair.distance < 0.1) {
                if (removeNear) {
                    return
                }
            }
            comparePairs.push(comparePair)
        })

        const sortedByClosestToIntersect = comparePairs.sort((a, b) => {
            return a.distanceFromLine - b.distanceFromLine
        })
        if (sortedByClosestToIntersect.length) {
            const intersectCandidate = sortedByClosestToIntersect[0]
            if (intersectCandidate.distanceFromLine <= minDistanceToLine) {
                return intersectCandidate
            }
        }
        return undefined
    }

    private static findRandomPointOnLine(point: GridPoint, slope: number): GridPoint {
        let b = point.y - slope * point.x
        let randomX = 0.5
        let y = slope * randomX + b
        let randomPoint: GridPoint = {
            x: randomX,
            y: y,
        }
        return randomPoint
    }

    private static findPerpendicularSlope(first: GridPoint, second: GridPoint): number {
        let slope = (second.y - first.y) / (second.x - first.x)
        let perpendicular = -1 * (1 / slope)
        return perpendicular
    }

    private static findPointOnPerpendicularLine(first: GridPoint, second: GridPoint, points: GridPoint[]): ComparePointPair | undefined {
        let perpendicularSlope = PerimeterPointUtils.findPerpendicularSlope(first, second)
        let randomPointOnPerpendicularLine = PerimeterPointUtils.findRandomPointOnLine(second, perpendicularSlope)
        let third = PerimeterPointUtils.findLikeliestIntersect(second, randomPointOnPerpendicularLine, points, MIN_INTERSECT_DISTANCE, true)
        return third
    }

    private static getRectangleResult(first: GridPoint, second: GridPoint, third: GridPoint, fourth: GridPoint): RectangleResult {
        let sideOne = PerimeterPointUtils.distanceBetweenTwoPoints(first, second)
        let sideTwo = PerimeterPointUtils.distanceBetweenTwoPoints(second, third)
        let sideThree = PerimeterPointUtils.distanceBetweenTwoPoints(third, fourth)
        let sideFour = PerimeterPointUtils.distanceBetweenTwoPoints(fourth, first)
        let all = [sideOne, sideTwo, sideThree, sideFour]
        let sortedSmallest = all.sort((a, b) => a - b)
        let smallest = sortedSmallest[0]
        let largest = sortedSmallest[3]
        let area = smallest * largest
        let result: RectangleResult = {
            first: first,
            second: second,
            third: third,
            fourth: fourth,
            height: smallest,
            width: largest,
            area: area,
        }
        return result
    }

    private static filterOutPoint(point: GridPoint, points: GridPoint[]): GridPoint[] {
        return points.filter((compare) => {
            if (compare.x == point.x && compare.y == point.y) {
                return false
            }
            return true
        })
    }

    private static isPointCloseToPointPerpendicularLine(first: GridPoint, second: GridPoint, check: GridPoint): boolean {
        let perpendicularSlope = PerimeterPointUtils.findPerpendicularSlope(first, second)
        let randomPoint = PerimeterPointUtils.findRandomPointOnLine(first, perpendicularSlope)
        let distance = PerimeterPointUtils.distanceFromLine(first, randomPoint, check)
        let absolute = Math.abs(MIN_INTERSECT_DISTANCE)
        if (distance < absolute) {
            return true
        }
        return false
    }

    static findShortestLongestPointPairs(points: GridPoint[], midpoint: GridPoint): ShortestLongestPointPairs | undefined {
        const pointPairs: PerimeterPointPair[] = []

        points.map((point) => {
            const comparePoints = points.filter((otherPoint) => PerimeterPointUtils.oppositeQudrants(point, otherPoint, midpoint))
            if (comparePoints.length > 0) {
                let intersectCandidate = PerimeterPointUtils.findLikeliestIntersect(point, midpoint, comparePoints, 0.05, false)
                if (intersectCandidate === undefined) {
                    return
                }
                if (intersectCandidate !== undefined) {
                    const perimeterPointPair = PerimeterPointUtils.getPerimeterPointPair(intersectCandidate.first, intersectCandidate.second)
                    pointPairs.push(perimeterPointPair)
                }
            }
        })
        const sortedPairs = pointPairs.sort((a, b) => {
            return a.distance - b.distance
        })

        if (sortedPairs.length) {
            const shortest = sortedPairs[0]
            const longest = sortedPairs[sortedPairs.length - 1]
            const shortestLongest: ShortestLongestPointPairs = {longest: longest, shortest: shortest}
            return shortestLongest
        }
        return undefined
    }

    static midpointsFromShortestLongesPointPairs(points: GridPoint[], midpoint: GridPoint): MidPoints | undefined {
        let shortestLongest = PerimeterPointUtils.findShortestLongestPointPairs(points, midpoint)
        if (shortestLongest === undefined) {
            return
        }
        let mid: MidPoints = {
            first: [shortestLongest.shortest.first, shortestLongest.shortest.second],
            height: 0,
            second: [shortestLongest.longest.first, shortestLongest.longest.second],
            width: 0,
        }
        return mid
    }
}

export interface PerimeterPointPair {
    first: GridPoint
    second: GridPoint
    distance: number
}

export interface ShortestLongestPointPairs {
    shortest: PerimeterPointPair
    longest: PerimeterPointPair
}

export interface ComparePointPair {
    first: GridPoint
    second: GridPoint
    distanceFromLine: number
    distance: number
}

export interface RectangleResult {
    first: GridPoint
    second: GridPoint
    third: GridPoint
    fourth: GridPoint
    height: number
    width: number
    area: number
}

export interface ScaleApiResponse {
    items: ScaleApiResponseItem[]
}

export interface ScaleApiResponseItem {
    normalized_perimeter: GridPoint[]
    url: string
}
