import React from "react"
import {useTranslation} from "react-i18next"
import {AuthFooterContainer, FlexDiv} from "../../newStyles/AuthStyles"
import {Label6} from "../../newStyles/Labels"
import {DividerText6} from "../../newStyles/Divider"
import {Link6} from "../../newStyles/Links"

export function AuthFooter() {
    const {t} = useTranslation("auth")

    return (
        <AuthFooterContainer>
            <Label6>{t("copyright")}</Label6>
            <FlexDiv>
                <Link6 route="/terms">{t("user_agreement")}</Link6>
                <DividerText6 />
                <Link6 route="/privacy">{t("privacy_policy")}</Link6>
            </FlexDiv>
        </AuthFooterContainer>
    )
}
