import {TextField, Typography} from "@mui/material"
import React from "react"

export const FormSectionLabelView = (label: FormSectionLabel) => (
    <Typography fontSize={11} sx={{mt: 2, mb: 1}} variant="subtitle2" component="div">
        {" "}
        {label.label}{" "}
    </Typography>
)

export const FormTextField = (props: FormTextFieldViewProps) => (
    <TextField
        id={props.label}
        error={props.value === undefined || !props.value.length}
        label={props.label}
        variant="outlined"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
    />
)

interface FormTextFieldViewProps {
    label: string
    value?: string
    onChange: (arg0: string) => void
}

interface FormSectionLabel {
    label: string
}
