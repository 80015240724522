import React, {useState} from "react"
import {RowFlexContainerSpaced} from "../styles"
import {useAppSelector} from "../../app/hooks"
import {businesses} from "../../components/authentication/selectors"
import Form from "react-bootstrap/Form"
import {Loader} from "../loader"
import {components} from "../../app/store"
import {Autocomplete, TextField} from "@mui/material"
import {Business} from "../../types/Business"
import ArrayUtils from "@common/utils/ArrayUtils"
import StringUtils from "@common/utils/StringUtils"
import {useTranslation} from "react-i18next"
import {Button4, TextButton4} from "../newStyles/Buttons"
import {Label3} from "../newStyles/Labels"

export const BusinessPickerForm = () => {
    const {t} = useTranslation("auth")
    const [queryString, setQueryString] = React.useState("")
    const businessList = useAppSelector(businesses)
    const [myBusinesses, setMyBusinesses] = React.useState<Business[]>(businessList)

    const defaultBusiness = () => {
        if (ArrayUtils.isEmpty(businessList)) return null
        return businessList[0]
    }

    const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(defaultBusiness())

    const createLabelsMap = () => {
        let map: {[id: string]: string} = {}
        if (ArrayUtils.isNotEmpty(businessList)) {
            const names = new Set<string>()
            businessList.forEach((business) => {
                let name = business.name
                if (names.has(name)) {
                    map[business.id] = `${name} ${business.id}`
                } else {
                    names.add(name)
                    map[business.id] = `${name}`
                }
            })
        }

        return map
    }

    const [labelsMap] = React.useState(createLabelsMap())

    const textFieldDidChange = (queryString: string) => {
        setQueryString(queryString)

        if (StringUtils.isEmpty(queryString)) {
            return setMyBusinesses(businessList)
        }
        let regExp = new RegExp(queryString, "i") // Case insensitive regex
        let filtered = businessList.filter((business) => business.name.search(regExp) >= 0)
        setMyBusinesses(filtered)
    }

    const onBusinessPicked = (businessId: string | null) => {
        if (businessId === null) return setSelectedBusiness(null)

        const business = myBusinesses.find((business) => business.id === businessId)
        if (business === undefined) return setSelectedBusiness(null)

        setSelectedBusiness(business)
    }

    const onSubmit = (event: any) => {
        event.preventDefault()
        console.log(`BusinessPickerForm.onSubmit ${selectedBusiness?.id}`)
        if (selectedBusiness === null) {
            return
        }
        components.authentication.signInWithBusiness(selectedBusiness.id).then()
    }

    if (ArrayUtils.isEmpty(businessList)) {
        return <Loader />
    } else {
        return (
            <Form onSubmit={onSubmit}>
                <Form.Group controlId="formBusinessPicker">
                    <Label3>{t("pick_business")}</Label3>
                    <Autocomplete
                        disablePortal
                        id="business-picker-field"
                        options={myBusinesses.map((business) => business.id)}
                        sx={{marginInlineStart: 0, marginInlineEnd: 0, mb: 2}}
                        value={selectedBusiness?.id ?? ""}
                        getOptionLabel={(option) => labelsMap[option]}
                        onChange={(_event, newBusinessId) => {
                            onBusinessPicked(newBusinessId)
                        }}
                        renderInput={(params) => <TextField {...params} value={queryString} onChange={(e) => textFieldDidChange(e.target.value)} />}
                    />
                    <p />
                    <RowFlexContainerSpaced>
                        <Button4 type="submit">{t("sign_in")}</Button4>
                        <TextButton4
                            onClick={() => {
                                components.authentication.signOut()
                            }}
                        >
                            {t("sign_out")}
                        </TextButton4>
                    </RowFlexContainerSpaced>
                </Form.Group>
            </Form>
        )
    }
}
