import React, {JSXElementConstructor} from "react"
import {DataGridPremium, GridColDef, GridRenderCellParams, GridRowsProp, GridState} from "@mui/x-data-grid-premium"
import {gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid"
import {styled} from "@mui/material/styles"
import Pagination from "@mui/material/Pagination"
import PaginationItem from "@mui/material/PaginationItem"
import Box from "@mui/material/Box"
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium"
import {Button} from "@mui/material"
import {CommodityEventGridLabel, CommodityEventGridLabels} from "../../../styles"
import {QRView} from "../../../modals/modal/ModalViews/EditCommodityView"

export const CommodityGroupBoxStyledDataTableView = (props: CommodityGroupDataTableProps) => (
    <Box
        sx={{
            height: "90vh",
            width: "100%",
        }}
    >
        <CommodityGroupStyledDataTableView {...props} />
    </Box>
)

export const CommodityBoxStyledDataTableView = (props: CommodityDataTableProps) => (
    <Box
        sx={{
            height: "90vh",
            width: "100%",
        }}
    >
        <CommodityStyledDataTableView {...props} />
    </Box>
)
const CommodityGroupStyledDataTableView = (props: CommodityGroupDataTableProps) => {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: props.page_size,
        page: 0,
    })

    const columnsWithActionButton = (): GridColDef[] => {
        if (!props.showActionButtons) {
            return props.columns
        }
        const button = (title: string, onClick: (arg: string) => void) => ({
            field: title,
            key: title,
            headerName: "",
            width: 90,
            renderCell: (params: GridRenderCellParams<any>) => <Button onClick={() => onClick(params.row.id)}>{title}</Button>,
        })
        let viewDef = button(props.viewTitle, props.onViewClick) as GridColDef
        let editDef = button(props.actionsTitle, props.onActionsClick) as GridColDef
        let viewWithField = {...viewDef, field: "view"}
        let editWithField = {...editDef, field: "edit"}
        let firstCols = [viewWithField, editWithField]
        let newColumns = props.columns.concat(firstCols)
        return newColumns
    }

    return (
        <StyledDataGrid
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[props.page_size]}
            columns={columnsWithActionButton()}
            rows={props.rows}
            initialState={{...props.state, pinnedColumns: {left: ["view", "edit"]}}}
            density={"compact"}
            onStateChange={props.onStateChange}
            slots={{
                toolbar: props.toolbar,
                pagination: CustomPagination,
            }}
        />
    )
}

export const CommodityStyledDataTableView = (props: CommodityDataTableProps) => {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: props.page_size,
        page: 0,
    })

    return (
        <StyledDataGrid
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[props.page_size]}
            columns={props.columns}
            rows={props.rows}
            density={"compact"}
            getDetailPanelContent={({row}) => <CommodityEventDetailPanel eventSummaries={row.event_summaries} commodity_id={row.id} />}
            slots={{
                toolbar: props.toolbar,
                pagination: CustomPagination,
            }}
        />
    )
}

const CommodityEventDetailPanel = (props: CommodityEventDetailProps) => (
    <>
        <CommodityEventGridLabels>
            {props.eventSummaries.map((string, i) => (
                <CommodityEventGridLabel key={i}> {string}</CommodityEventGridLabel>
            ))}
        </CommodityEventGridLabels>
        <QRView commodity_id={props.commodity_id} />
    </>
)

interface CommodityEventDetailProps {
    eventSummaries: string[]
    commodity_id: string
}

export interface QRViewProps {
    commodity_id: string
}

export interface CommodityGroupDataTableProps {
    rows: GridRowsProp
    columns: GridColDef[]
    onStateChange: (state: GridState) => void
    state: GridInitialStatePremium | undefined
    toolbar: JSXElementConstructor<any>
    page_size: number
    actionsTitle: string
    viewTitle: string
    onActionsClick: (rowId: string) => void
    onViewClick: (rowId: string) => void
    showActionButtons: boolean
}

export interface CommodityDataTableProps {
    rows: GridRowsProp
    columns: GridColDef[]
    toolbar: JSXElementConstructor<any>
    page_size: number
}

export const StyledDataGrid = styled(DataGridPremium)(({theme}) => ({
    color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
    fontFamily: ["Lato-Bold", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
        display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
    },
    "& .MuiDataGrid-cell": {
        color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",
    },
    "& .button_style_cell": {
        color: "#00EF0C",
        fontWeight: "900",
    },
    "& .MuiPaginationItem-root": {
        borderRadius: 0,
    },
}))

export const CustomPagination = () => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1)}
        />
    )
}
