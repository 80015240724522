import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {scaleWeightUnitAsMeasurementSystem} from "../../../authentication/selectors"
import Display from "../../../../utils/Display"
import MathUtils from "@common/utils/MathUtils"

export default class CommodityGroupWeightsFormState {
    gross: string = ""
    empty: string = ""

    constructor(group: CommodityGroup) {
        let gross = group.user_entries.weight?.gross_weight
        let empty = group.user_entries.weight?.empty_weight
        if (gross === undefined || empty === undefined) {
            return
        }

        const system = scaleWeightUnitAsMeasurementSystem()
        this.gross = String(MathUtils.round0(Display.displayWeight(gross, system)))
        this.empty = String(MathUtils.round0(Display.displayWeight(empty, system)))
    }
}
