import {components, store} from "../../../app/store"
import {setActivePoint, setSelectedCommodity, setShortLongCm, setUpdatedDiameters, setUpdatedLogCenter, setUpdatedMidpoints, setUserIsClicking} from "./slice"
import EditCommodityUtils from "./editCommodityUtils"
import {selectedCommodity} from "./selectors"
import FirebaseCRUD from "../../../firebase"
import Centroid from "@common/types/Centroid"
import {CommodityPoints, IdentifiableGridPoint} from "@common/types/commodity/commodity"
import {Commodity} from "../../../types/commodity/Commodity"
import CommodityImageDrawing from "../../../view/detail/CommodityGroup/image/CommodityImageDrawing"
import {setModalType, setShowModal} from "../../modal/slice"
import {ModalType} from "../../modal/state"
import {PerimeterPointUtils} from "@common/types/PerimeterPointUtils"

export default class EditCommodityComponent extends EditCommodityUtils {
    drawing = new CommodityImageDrawing()
    firebase = new FirebaseCRUD()

    showCommodityDetailView = (commodity: Commodity) => {
        let isBusinessUser = false
        const userBusinessId = store.getState().authentication.business_member?.business_id
        if (commodity.created_by_business_id === userBusinessId) {
            isBusinessUser = true
        }
        if (isBusinessUser) {
            store.dispatch(setSelectedCommodity(commodity))
            store.dispatch(setModalType(ModalType.EditCommodity))
            store.dispatch(setShowModal(true))
            this.draw()
        }
    }

    cursorDidMove = (clientX: number, clientY: number, rect: DOMRect) => {
        let state = store.getState().editCommodity
        if (!state.user_is_clicking) {
            return
        }
        let point = this.translateCursorPointToImagePoint(clientX, clientY, rect)
        let nearPoint = this.nearestPoint(point)
        if (nearPoint === undefined) {
            return
        }
        if (state.active_point !== undefined) {
            if (nearPoint.id !== state.active_point.id) {
                return
            }
        }
        let pointToReplaceExistingPoint: IdentifiableGridPoint = {point: point, id: nearPoint.id}
        store.dispatch(setActivePoint(pointToReplaceExistingPoint))
        this.draw()
    }

    userStoppedHoldingClick = () => {
        store.dispatch(setActivePoint(undefined))
        store.dispatch(setUserIsClicking(false))
    }

    draw = () => {
        let state = store.getState()
        let commodity = selectedCommodity(state)
        if (commodity === undefined) {
            return
        }
        let points = state.editCommodity.perimeter_points
        let normalizedPerimeter = this.normalizedPerimeter(points, state.editCommodity.image_width_px)
        const centroid = new Centroid(normalizedPerimeter)
        const center = centroid.centroid()
        const pairs = PerimeterPointUtils.findShortestLongestPointPairs(normalizedPerimeter, center)
        if (pairs === undefined) {
            return
        }
        const midPoints = PerimeterPointUtils.midpointsFromShortestLongesPointPairs(normalizedPerimeter, center)
        const updatedCommodity = this.updateVolumeDiametersFromPoints(commodity.commodity, pairs)
        if (updatedCommodity === undefined) {
            return
        }
        this.drawing.draw(true, commodity.commodity, state.editCommodity.image_width_px, points, center, midPoints)
        if (midPoints === undefined) {
            return
        }
        this.updatePreviewDiameters(updatedCommodity)
        store.dispatch(setUpdatedDiameters(updatedCommodity))
        store.dispatch(setUpdatedLogCenter(center))
        store.dispatch(setUpdatedMidpoints(midPoints))
        return
    }

    updatePreviewDiameters = (commodity: Commodity) => {
        let diameters = commodity.measurements.diameters
        if (diameters === undefined) {
            return
        }
        store.dispatch(setShortLongCm([diameters.short, diameters.long]))
    }

    save = async () => {
        store.dispatch(setShowModal(false))
        let state = store.getState().editCommodity
        let commodity = state.updated_editing_commodity
        if (commodity === undefined) {
            return
        }
        if (commodity.points === undefined) {
            return
        }
        if (state.updated_editing_commodity === undefined) {
            return
        }
        if (state.updated_center === undefined) {
            return
        }
        if (state.perimeter_points === undefined) {
            return
        }
        if (state.updated_midpoints === undefined) {
            return
        }
        let normalizedPerimeter = this.normalizedPerimeter(state.perimeter_points, state.image_width_px)
        let points: CommodityPoints = {
            ...commodity.points,
            diameter_one_points: [state.updated_midpoints.first[0], state.updated_midpoints.first[1]],
            diameter_two_points: [state.updated_midpoints.second[0], state.updated_midpoints.second[1]],
            largest_defect_points: [],
            perimeter_points: normalizedPerimeter,
        }
        let updated: Commodity = {
            ...commodity,
            points: points,
        }
        await this.firebase.commodity.create.create(updated)
        components.commodity_group.loadCommodityGroupCommodities()
        await this.firebase.api_gateway.create.createEditCommodityTrigger(updated.id)
        return
    }
}
