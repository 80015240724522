import {Locale} from "../../../i18n/Localization"

export default class AuditLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    audit = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Audit"
            case Locale.pt:
                return "Auditoria"
            case Locale.es:
                return "Auditoría"
        }
    }
}
