import styled from "styled-components"
import {Box, Button} from "@mui/material"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Offcanvas from "react-bootstrap/Offcanvas"

//
// Navbar
//

export const StyledNavbar = styled(Navbar)`
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;
`

export const NavbarMenuButton = styled.button`
    width: 40px;
    padding: 0;
    background: transparent;
    border-color: transparent;
`

export const OffcanvasTitle = styled(Offcanvas.Title)`
    font-size: xx-large;
`

export const NavLink = styled(Nav.Link)`
    display: block;
    font-family: Lato-Black, sans-serif;
    font-size: large;
    padding-left: 1rem;
    padding-right: 2.5rem;
    color: black;
    cursor: pointer;
    &:hover,
    &:focus {
        color: black;
    }
`

export const NavAddButton = styled.button`
    font-family: "Lato-Black";
    font-size: 1.2rem;
    background-color: #00ef0c;
    border: none;
    text-align: center;
    color: #000 !important;
    border-radius: 0.375rem;
    height: 3.375rem;
    aspect-ratio: 1;
    margin-top: -0.375rem;
`

//
//
//

export const ActionsLabel = styled.p`
    font-family: "Lato-Black";
    min-width: 240px;
    font-size: 2rem;
    color: #000 !important;
    cursor: pointer;
`

export const MainView = styled.div`
    background-color: #f9f9f9;
    height: 100vh;
    display: flex;
    flex-direction: column;
`

export const BodyContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -4px;
`

export const DataTableTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 50px;
    padding-bottom: 5px;
    width: 100%;
`

export const DataTableTitleRightItems = styled.div`
    display: flex;
    flex-direction: row;
`

export const CenteredDiv = styled.div`
    position: absolute;
    left: 50%;
    top: 45%;
    -webkit-transform: translate(-50%, -45%);
    transform: translate(-50%, -45%);
`

export const FormLoaderContainer = styled.div`
    width: 480px;
    height: 480px;
    padding-top: 210px;
    padding-left: 200px;
`

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const UnselectedNavButton = styled(Button)`
    font-family: "Lato-Black" !important;
    font-size: 1rem !important;
    color: #000 !important;
    padding-left: 40px !important;
    padding-right: 25px !important;
    text-transform: capitalize !important;
    white-space: nowrap;
`

export const SelectedNavButton = styled(UnselectedNavButton)`
    background-color: #00ef0c !important;
`

export const RowFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const RowFlexContainerSpaced = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 8px;
    right: 8px;
`

export const CommodityEventGridLabels = styled.div`
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-left: 25px !important;
`

export const CommodityEventGridLabel = styled.p`
    font-family: "Lato-Black";
    font-size: 2rem;
    color: #000 !important;
`

export const StyledCommodityImageCanvs = styled.canvas`
    cursor: pointer;
`
