import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {initialState} from "./state"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export const slice = createSlice({
    name: "commodityGroupDataTable",
    initialState,
    reducers: {
        setQueryStartDate: (state, action: PayloadAction<Date>) => {
            let date = new Date(new Date(action.payload.setHours(23, 59, 59, 0)))
            state.query_start_date = date
        },
        setQueryEndDate: (state, action: PayloadAction<Date>) => {
            let date = new Date(new Date(action.payload.setHours(0, 0, 0, 0)))
            state.query_end_date = date
        },
        setSelectedTab: (state, action: PayloadAction<CommodityGroupType>) => {
            state.tab = action.payload
        },
        showCurrent: (state, action: PayloadAction<boolean>) => {
            state.show_current_stock = action.payload
        },
    },
})

export const {setQueryStartDate, setQueryEndDate, setSelectedTab, showCurrent} = slice.actions
export default slice.reducer
