import {createTheme} from "@mui/material/styles"
import {Font} from "../newStyles/Generic"

export const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "white",
                    color: "black",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#00EF0C",
            light: "#fff",
            contrastText: "#fff",
        },
        secondary: {
            main: "#fff",
            light: "#000",
            contrastText: "#000",
        },
        error: {
            main: "#00EF0C",
            light: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: Font.Black,
        // This is the font size in pixels. It is re-calculated to rem by mui. We set it because of 62.5% trick. Default font size was 14.
        // https://stackoverflow.com/questions/72077974/what-is-the-purpose-of-the-fontsize-theme-setting-when-all-typography-variants-a
        // https://mui.com/material-ui/customization/typography/
        // fontSize: 22.4,
    },
})
