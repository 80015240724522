import {GridPoint, IdentifiableGridPoint, MidPoints} from "@common/types/commodity/commodity"
import {Commodity} from "../../../types/commodity/Commodity"

export interface EditLogState {
    active_point?: IdentifiableGridPoint
    cursor_point_x_offset: number
    cursor_point_y_offset: number
    selected_commodity_id?: string
    perimeter_points: IdentifiableGridPoint[]
    user_is_clicking: boolean
    image_width_px: number
    short_cm: number
    long_cm: number
    updated_center?: GridPoint
    updated_editing_commodity?: Commodity
    updated_midpoints?: MidPoints
}

export const initialState: EditLogState = {
    cursor_point_x_offset: -5,
    cursor_point_y_offset: 58,
    perimeter_points: [],
    user_is_clicking: false,
    image_width_px: 600,
    short_cm: 0,
    long_cm: 0,
}
