import {RootState} from "../../../app/store"
import {CommodityGroupDataTableState} from "./state"

export const queryStartDate = (state: RootState) => {
    let dataTableState = state.dataTable as CommodityGroupDataTableState
    return dataTableState.query_start_date
}

export const queryEndDate = (state: RootState) => {
    let dataTableState = state.dataTable as CommodityGroupDataTableState
    return dataTableState.query_end_date
}

export const selectedTab = (state: RootState) => {
    let dataTableState = state.dataTable as CommodityGroupDataTableState
    return dataTableState.tab
}

export const showCurrentStock = (state: RootState) => {
    let dataTableState = state.dataTable as CommodityGroupDataTableState
    return dataTableState.show_current_stock
}
