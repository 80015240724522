import {collection, doc, onSnapshot, query, setDoc, where} from "firebase/firestore"
import {db} from "../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {BusinessSettings} from "@common/types/business/BusinessSettings"
import {ErrorManager} from "../../components/errorManager"

export default class BusinessSettingsDao {
    reportError(functionName: string, error: unknown) {
        ErrorManager.recordErrorAndAlert("BusinessSettingsDao", functionName, error as Error)
    }

    async set(settings: BusinessSettings) {
        try {
            return await setDoc(doc(db, FIRCollectionPaths.BUSINESS_SETTINGS, settings.id), settings)
        } catch (error) {
            this.reportError("set", error)
        }
    }

    listenForBusinessSettings(id: string, completion: (businessSettings?: BusinessSettings) => void) {
        const q = query(collection(db, FIRCollectionPaths.BUSINESS_SETTINGS), where("id", "==", id))
        return onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                completion(snapshot.docs[0].data() as BusinessSettings)
            } else {
                completion(undefined)
            }
        })
    }
}
