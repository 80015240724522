import {collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, Timestamp, Unsubscribe, where} from "firebase/firestore"
import {db} from "../db"
import {Business} from "../../types/Business"
import {v4 as uuid} from "uuid"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {ErrorManager} from "../../components/errorManager"
import {User} from "../../types/User"
import {QueryDocumentSnapshot} from "@firebase/firestore"

// const log = new Logger("BusinessDao")

export default class BusinessDao extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("BusinessCreate", error as Error)
    }
    createBusiness = async (user: User): Promise<Business> => {
        let business: Business = {
            created_time: Timestamp.now(),
            id: uuid(),
            name: user.email,
            created_by_user: user,
        }
        await setDoc(doc(db, FIRCollectionPaths.BUSINESS, business.id), business)
        return business
    }
    updateBusiness = async (business: Business): Promise<void> => {
        return await setDoc(doc(db, FIRCollectionPaths.BUSINESS, business.id), business)
    }
    listenForBusiness = (businessId: string, completion: (members: Business[]) => void): Unsubscribe => {
        const q = query(collection(db, FIRCollectionPaths.BUSINESS), where("id", "==", businessId))
        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => this.listenerCompletionHandler(querySnapshot.docs, completion),
            (error) => this.error(error),
        )
        return unsubscribe
    }
    private listenerCompletionHandler = (docs: Array<QueryDocumentSnapshot>, completion: (members: Business[]) => void) => {
        let businesses = this.completionHandler(docs)
        return completion(businesses)
    }
    searchBusinessesMatchingString = async (queryString: string): Promise<Business[]> => {
        try {
            const q = query(collection(db, FIRCollectionPaths.BUSINESS), where("name", ">=", queryString), where("name", "<=", queryString + "\uf8ff"))

            let docs = await getDocs(q)
            return this.completionHandler(docs.docs)
        } catch (error) {
            this.error(error)
            return []
        }
    }
    private completionHandler = (docs: Array<QueryDocumentSnapshot>): Business[] => {
        const members: Business[] = docs.map((doc) => {
            return doc.data() as Business
        })
        return members
    }

    getBusinesses = async (ids: string[]): Promise<Business[]> => {
        try {
            let promises = ids.map((id) => this.getBusiness(id))
            let businessesUndefineds = await Promise.all(promises)
            let businesses = businessesUndefineds.filter((business) => business !== undefined) as Business[]
            return businesses
        } catch (error) {
            this.error(error)
            return []
        }
    }
    getBusiness = async (id: string): Promise<Business | undefined> => {
        try {
            const docRef = doc(db, FIRCollectionPaths.BUSINESS, id)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) {
                return undefined
            }
            return docSnap.data() as Business
        } catch (error) {
            this.error(error)
            return undefined
        }
    }

    async getAllBusinesses() {
        const querySnapshot = await getDocs(collection(db, FIRCollectionPaths.BUSINESS))
        let businesses: Business[] = []
        querySnapshot.forEach((doc) => {
            businesses.push(doc.data() as Business)
        })

        return businesses
    }
}
