import {doc, setDoc} from "firebase/firestore"
import {db} from "../../../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {CommodityEvent} from "../../../../types/commodity/CommodityEvent"
import {ErrorManager} from "../../../../components/errorManager"

export default class CommodityEventCreate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityEventCreate", error as Error)
    }

    create = async (event: CommodityEvent): Promise<void> => {
        if (event.extras && event.extras.length > 0) {
            event.extras = `${event.extras}-web`
        } else {
            event.extras = "web"
        }

        try {
            await setDoc(doc(db, FIRCollectionPaths.COMMODITY_EVENTS, event.id), event)
            return
        } catch (error) {
            this.error(error)
            return undefined
        }
    }
}
