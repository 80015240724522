import {DialogContent, DialogTitle, Divider} from "@mui/material"
import React from "react"
import {ForestsView} from "./ForestsView"
import {ModalCloseButton} from "../ModalCloseButton"
import {useTranslation} from "react-i18next"

export function ForestsModalView() {
    const {t} = useTranslation("navigation")

    return (
        <>
            <DialogTitle fontSize={"large"}>{t("forests")}</DialogTitle>
            <ModalCloseButton />
            <Divider />
            <DialogContent>
                <ForestsView />
            </DialogContent>
        </>
    )
}
