import {BusinessType} from "@common/types/business/businessTypes"
import {Locale} from "../../../i18n/Localization"

export default class BusinessTypeLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    partners = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Partners"
            case Locale.pt:
                return "Parceiros"
            case Locale.es:
                return "Socios"
        }
    }

    businesses() {
        switch (this.language) {
            case Locale.en:
                return "Businesses"
            case Locale.pt:
            case Locale.es:
                return "Empresas"
        }
    }

    logProperties = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Log Properties"
            case Locale.pt:
                return "Propiedades De Toras"
            case Locale.es:
                return "Propiedades De Trosas"
        }
    }

    label = (type: BusinessType | string): string | undefined => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case BusinessType.Forester:
                        return "Forester"
                    case "Forest":
                        return "Forest"
                    case BusinessType.Logger:
                        return "Logger"
                    case BusinessType.Subcontractor:
                        return "Subcontractor"
                    case BusinessType.Trucker:
                        return "Trucker"
                    case BusinessType.Port:
                        return "Port"
                    case BusinessType.Exporter:
                        return "Exporter"
                    case BusinessType.Importer:
                        return "Importer"
                    case BusinessType.Sawmill:
                        return "Sawmill"
                    case BusinessType.Trading:
                        return "Trading"
                    case BusinessType.Other:
                        return "Other"
                }
                break
            case Locale.pt:
                switch (type) {
                    case BusinessType.Forester:
                        return "Produtor"
                    case "Forest":
                        return "Floresta"
                    case BusinessType.Logger:
                        return "Empresa Madeireira"
                    case BusinessType.Subcontractor:
                        return "Subcontratado"
                    case BusinessType.Trucker:
                        return "Caminháo"
                    case BusinessType.Port:
                        return "Porta"
                    case BusinessType.Exporter:
                        return "Exportador"
                    case BusinessType.Importer:
                        return "Importador"
                    case BusinessType.Sawmill:
                        return "Serraria"
                    case BusinessType.Trading:
                        return "Comercial"
                    case BusinessType.Other:
                        return "Outro"
                }
                break
            case Locale.es:
                switch (type) {
                    case BusinessType.Forester:
                        return "Proveedor"
                    case "Forest":
                        return "Monte"
                    case BusinessType.Logger:
                        return "Cosechador"
                    case BusinessType.Subcontractor:
                        return "Subcontratista"
                    case BusinessType.Trucker:
                        return "Transporte"
                    case BusinessType.Port:
                        return "Puerto"
                    case BusinessType.Exporter:
                        return "Exportadora"
                    case BusinessType.Importer:
                        return "Importadora"
                    case BusinessType.Sawmill:
                        return "Aserradero"
                    case BusinessType.Trading:
                        return "Comercio"
                    case BusinessType.Other:
                        return "Otro"
                }
        }
    }
}
