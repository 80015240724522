import {RootState} from "../../../../app/store"

export const selectedForm = (state: RootState) => {
    let formState = state.commodityGroup
    return formState.form
}
export const firstId = (state: RootState) => {
    let groupState = state.commodityGroup
    if (groupState.group === undefined) {
        return ""
    }
    return groupState.group.group.user_entries.identifiers.first_id
}
export const gross = (state: RootState): string => {
    let groupState = state.commodityGroup
    if (groupState.group === undefined) {
        return ""
    }
    return groupState.group?.weights.gross
}
export const empty = (state: RootState): string => {
    let groupState = state.commodityGroup
    if (groupState.group === undefined) {
        return ""
    }
    return groupState.group?.weights.empty
}
