import StockGroupForm from "./StockGroupForm"
import {MovementType} from "@common/types/MovementType"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupUserEntries} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"
import {setRowForm} from "../../commodityGroup/slice"
import {LinkedBusiness} from "../../../../types/Business"
import {BusinessType} from "@common/types/business/businessTypes"

export default class RowForm extends StockGroupForm {
    linked_business_types: BusinessType[] = [BusinessType.Sawmill, BusinessType.Other]
    movement: MovementType = MovementType.atOrigin

    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group, pickerOptions, linkedGroups)
        this.setMovement()
        this.setState()
    }
    setMovement() {
        if (this.group.user_entries.movement === undefined) {
            return
        }
        this.movement = this.group.user_entries.movement
    }
    update() {
        super.update()
        let userEntries: CommodityGroupUserEntries = {...this.group.user_entries, movement: this.movement}
        this.updateUserEntries(userEntries)
    }
    //user interaction
    setState() {
        store.dispatch(setRowForm(this))
    }
    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }
    didEditSecondId(id: string) {
        super.didEditSecondId(id)
        this.setState()
    }
    didEditThirdId(id: string) {
        super.didEditThirdId(id)
        this.setState()
    }
    didSelectLength(length: string) {
        super.didSelectLength(length)
        this.setState()
    }
    didSelectSpecies(species: string) {
        super.didSelectSpecies(species)
        this.setState()
    }
    didSelectLogClass(logClass: string) {
        super.didSelectLogClass(logClass)
        this.setState()
    }
    linkBusiness(newLink: LinkedBusiness) {
        super.linkBusiness(newLink)
        this.setState()
    }
    unLinkBusiness(type: BusinessType) {
        super.unLinkBusiness(type)
        this.setState()
    }
    didSelectMovement(movement: string) {
        this.movement = movement as MovementType
        this.setState()
    }
    didSelectZone(id: string) {
        super.didSelectZone(id)
        this.setState()
    }
    toggleEditLogProperties() {
        super.toggleEditLogProperties()
        this.setState()
    }
    toggleAddPartners() {
        super.toggleAddPartners()
        this.setState()
    }
}
