import {useMediaQuery} from "react-responsive"
import {MarketingSectionProps, WebRightSection} from "./Web"
import {WebLeftSection} from "./Web"
import MobileSection from "./Mobile"
import {Constants} from "../../../../config/constants"

export const LeftSection = (props: MarketingSectionProps) => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <WebLeftSection {...props} />}
            {isMobile && <MobileSection {...props} />}
        </>
    )
}

export const RightSection = (props: MarketingSectionProps) => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <WebRightSection {...props} />}
            {isMobile && <MobileSection {...props} />}
        </>
    )
}
