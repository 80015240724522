import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import CommodityGroupPickerForm from "./CommodityGroupPickerForm"
import {CommodityLength, CommodityProperties, LogClass, LogProperties, Species} from "@common/types/commodity/commodity"
import {LinkedBusiness} from "../../../../types/Business"
import {CommodityGroupIdentifiers, CommodityGroupStatus, CommodityGroupType, CommodityGroupUserEntries} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"
import {BusinessType} from "@common/types/business/businessTypes"
import {measurementSystem2} from "../../../authentication/selectors"
import Display from "../../../../utils/Display"
import Logger from "../../../../helpers/Logger"

const log = new Logger("StockGroupForm")

export default class StockGroupForm extends CommodityGroupPickerForm {
    edit_log_properties: boolean = false
    second_id: string = ""
    third_id: string = ""
    length: number = 5.8
    open_zones: CommodityGroup[] = []
    zone_rows: CommodityGroup[] = []
    zone?: CommodityGroup
    properties: LogProperties = {
        log_class: LogClass.saw_log,
        pruned: false,
        species: Species.Pine,
    }
    // This property should be stored here, in case if someone changes the business setting (in such case values that we will save will be invalid)
    protected readonly measurementSystem = measurementSystem2()

    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group, pickerOptions, linkedGroups)
        this.setSecondId()
        this.setThirdId()
        this.setLength()
        this.setProperties()
        this.setOpenZones()
        this.setZone()
        this.setZoneRows()
    }

    update() {
        super.update()
        this.updateSecondId()
        this.updateThirdId()
        this.updateLength()
        this.updateProperties()
        this.updateLinkedBusinesses()
        this.addLinkedGroup(this.zone)
    }

    setSecondId() {
        if (this.group.user_entries.identifiers.second_id === undefined) {
            return
        }
        this.second_id = this.group.user_entries.identifiers.second_id
    }

    setThirdId() {
        if (this.group.user_entries.identifiers.third_id === undefined) {
            return
        }
        this.third_id = this.group.user_entries.identifiers.third_id
    }

    setLength() {
        if (this.group.user_entries.length === undefined) {
            return
        }
        this.length = Display.displayLengthM(this.group.user_entries.length.length, this.measurementSystem)
    }

    setProperties() {
        if (this.group.user_entries.properties?.log === undefined) {
            return
        }
        this.properties = this.group.user_entries.properties.log
    }

    setZone() {
        let linkedZone = this.linked_groups?.find((group) => group.type === CommodityGroupType.zone)
        this.zone = linkedZone
        if (this.zone !== undefined) {
            return
        }
        this.zone = this.open_zones[0]
    }

    setOpenZones() {
        let state = store.getState()
        let openZones = state.authentication.current_business_member_open_zones
        if (openZones === undefined) {
            openZones = this.picker_options.filter((group) => group.type === CommodityGroupType.zone && group.status === CommodityGroupStatus.Open)
        }
        this.open_zones = openZones
    }

    setZoneRows() {
        let allRows = this.picker_options.filter((group) => group.type === CommodityGroupType.row)
        if (this.zone === undefined) {
            this.zone_rows = allRows
            return
        }
        this.zone_rows = allRows.filter((option) => {
            let zoneId = option.linked_commodity_group_ids.find((linkedId) => linkedId === this.zone?.id)
            if (zoneId === undefined) {
                return false
            }
            return true
        })
    }

    updateSecondId() {
        if (!this.second_id.length) {
            return
        }
        let updatedIdentifiers: CommodityGroupIdentifiers = {...this.group.user_entries.identifiers, second_id: this.second_id}
        this.updateIdentifiers(updatedIdentifiers)
    }

    updateThirdId() {
        if (!this.third_id.length) {
            return
        }
        let updatedIdentifiers: CommodityGroupIdentifiers = {...this.group.user_entries.identifiers, third_id: this.third_id}
        this.updateIdentifiers(updatedIdentifiers)
    }

    updateLength() {
        let type = this.group.type
        if (type === CommodityGroupType.inbound_load || type === CommodityGroupType.row || type === CommodityGroupType.outbound_load) {
            let length: CommodityLength = {
                length: Display.serverLengthM(this.length, this.measurementSystem),
            }
            let updatedUserEntries: CommodityGroupUserEntries = {...this.group.user_entries, length: length}
            this.updateUserEntries(updatedUserEntries)
        }
    }

    updateProperties() {
        let updatedProperties: CommodityProperties = {...this.group.user_entries.properties, log: this.properties}
        let userEntries: CommodityGroupUserEntries = {...this.group.user_entries, properties: updatedProperties}
        this.updateUserEntries(userEntries)
    }

    updateLinkedBusinesses() {
        let formIds = this.linked_businesses.map((linkedBusiness) => linkedBusiness.business.id)
        let existingIds = this.group.linked_business_ids
        let unique = this.uniqueUpdatingIds(formIds, existingIds)
        let updated = {...this.group, linked_business_ids: unique, linked_businesses: this.linked_businesses}
        this.group = updated
    }

    //User Interaction
    didEditSecondId(id: string) {
        this.second_id = id
    }

    didEditThirdId(id: string) {
        this.third_id = id
    }

    didSelectLength(length: string) {
        let number = parseFloat(length)
        log.d("didSelectLength", `Length: ${length}, Number: ${number}`)
        if (isNaN(number)) {
            return
        }
        this.length = number
    }

    didSelectSpecies(species: string) {
        this.properties = {...this.properties, species: species as Species}
    }

    didSelectLogClass(logClass: string) {
        this.properties = {...this.properties, log_class: logClass as LogClass}
    }

    linkBusiness(newLink: LinkedBusiness) {
        let removingExisting = this.linked_businesses.filter((business) => business.type !== newLink.type)
        this.linked_businesses = [...removingExisting, newLink]
    }

    unLinkBusiness(type: BusinessType) {
        if (!this.linked_businesses.length) {
            return
        }
        this.linked_businesses = this.linked_businesses.filter((business) => business.type !== type)
    }

    didSelectZone(id: string) {
        let zone = this.matchingOption(id)
        if (zone === undefined) {
            return
        }
        this.zone = zone
        this.setZoneRows()
    }

    toggleEditLogProperties() {
        this.edit_log_properties = !this.edit_log_properties
    }
}
