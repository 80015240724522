import {doc, getDoc} from "firebase/firestore"
import {db} from "../../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {Commodity} from "../../../types/commodity/Commodity"
import {ErrorManager} from "../../../components/errorManager"
import {Constants} from "../../../config/constants"
import Logger from "../../../helpers/Logger"
import ArrayUtils from "@common/utils/ArrayUtils"

const log = new Logger("CommodityRead")

export default class CommodityRead extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityRead", error as Error)
    }

    async getCommodities(ids: string[]): Promise<Commodity[]> {
        const idBatches: string[][] = ArrayUtils.split(ids, Constants.Firebase.Firestore.MaxReadBatchSize)
        log.d("getCommodities", `ids:${ids.length}, idBatches:${idBatches.length}`)

        const commodityBatches: Commodity[][] = []
        try {
            for (const idBatch of idBatches) {
                log.d("getCommodities", `batch:${idBatch.length}`)
                let promises = idBatch.map((id) => this.getCommodity(id))
                let commoditiesUndefineds = await Promise.all(promises)
                let commodities = commoditiesUndefineds.filter((commodity) => commodity !== undefined) as Commodity[]
                commodityBatches.push(commodities)
            }
        } catch (error) {
            this.error(error)
            throw error
        }

        const commodities = commodityBatches.flat()

        return this.sortDefectsFirst(commodities)
    }

    async getCommodity(id: string): Promise<Commodity | undefined> {
        const docRef = doc(db, FIRCollectionPaths.COMMODITIES, id)
        const docSnap = await getDoc(docRef)
        if (!docSnap.exists()) {
            return undefined
        }
        return docSnap.data() as Commodity
    }

    private sortDefectsFirst(commodities: Commodity[]): Commodity[] {
        let withDefects = commodities.filter(
            (commodity) => commodity.points?.largest_defect_points !== undefined && commodity.points?.largest_defect_points.length > 0,
        )
        let noDefects = commodities.filter(
            (commodity) => commodity.points?.largest_defect_points === undefined || commodity.points?.largest_defect_points.length === 0,
        )
        let combined = withDefects.concat(noDefects)
        return combined
    }
}
