import {Commodity} from "../../../../types/commodity/Commodity"
import {CommodityEvent} from "../../../../types/commodity/CommodityEvent"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {Business} from "../../../../types/Business"
import CommodityEventViewModel from "./CommodityEventViewModel"

export default class CommodityViewModel {
    id: string
    commodity: Commodity
    events: CommodityEventViewModel[] = []
    event_summaries: string[] = []

    constructor(commodity: Commodity, events: CommodityEvent[], groups: CommodityGroup[], business: Business) {
        this.id = commodity.id
        this.commodity = commodity
        let commodityEvents = events.filter((event) => event.commodity_id === commodity.id)
        let eventsSortedNewest = commodityEvents.sort((e1, e2) => e1.time.toMillis() - e2.time.toMillis())

        eventsSortedNewest.forEach((event) => {
            let eventGroup = groups.find((group) => group.id === event.commodity_group_id)
            if (eventGroup === undefined) {
                return
            }
            const viewModel = new CommodityEventViewModel(commodity, event, eventGroup, business)
            this.events.push(viewModel)
            this.event_summaries.push(viewModel.summary)
        })
    }
}
