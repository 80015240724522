import {doc, setDoc} from "firebase/firestore"
import {db} from "../../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {Commodity} from "../../../types/commodity/Commodity"
import {ErrorManager} from "../../../components/errorManager"

export default class CommodityCreate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityCreate", error as Error)
    }

    create = async (commodity: Commodity) => {
        try {
            await setDoc(doc(db, FIRCollectionPaths.COMMODITIES, commodity.id), commodity)
            return
        } catch (error) {
            this.error(error)
        }
    }
}
