import styled from "styled-components"
import {BorderRadius, Font, FontSize} from "./Generic"

//
// Standard (Contained)
//

export const Button2 = styled.button`
    font-family: ${Font.Bold};
    font-size: ${FontSize._2};
    text-transform: none; !important;
    border: none;
    border-radius: ${BorderRadius};
    color: #fff;
    background-color: #000;
`

export const Button3 = styled(Button2)`
    font-size: ${FontSize._3};
`
export const Button4 = styled(Button2)`
    font-size: ${FontSize._4};
`

//
// Text
//

// When style is extracted to a variable changes are not reflected in the styled component until page reload
const TextButtonStyle = `
    color: #000;
    background-color: #fff;
    width: fit-content;
`
// white-space: nowrap;

export const TextButton2 = styled(Button2)`
    ${TextButtonStyle};
`

export const TextButton3 = styled(Button3)`
    ${TextButtonStyle}
`

export const TextButton4 = styled(Button4)`
    ${TextButtonStyle}
`
