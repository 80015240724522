import styled from "styled-components"
import {FontSize} from "./Generic"

export const DividerText4 = styled.div`
    width: 1px;
    height: ${FontSize._4};
    font-size: ${FontSize._4};
    margin: 0.3em 12px;
    background-color: #000;
`

export const DividerText6 = styled(DividerText4)`
    height: ${FontSize._6};
    font-size: ${FontSize._6};
`
