import * as React from "react"
import {CommodityGroupAppBar} from "./CommodityGroupAppBar"
import {CommodityImagesView} from "./image/CommodityImagesView"
import {useAppSelector} from "../../../app/hooks"
import {commodityGroupLoading, commodityGroupTab} from "../../../components/commodities/commodityGroup/selectors/selectors"
import {CommodityGroupDetailTabOption} from "../../../components/commodities/commodityGroup/state"
import {CommoditiesDataTableView} from "../../dataTable/tables/CommoditiesDataTableView"
import {Loader} from "../../loader"
import {CommodityGroupImagesView} from "./groupImages/CommodityGroupImagesView"

export const CommodityGroupDetailView = () => {
    const detailTab = useAppSelector(commodityGroupTab)
    const loading = useAppSelector(commodityGroupLoading)

    const renderDetail = () => {
        switch (detailTab as CommodityGroupDetailTabOption) {
            case CommodityGroupDetailTabOption.measurements:
                return <CommodityImagesView />
            case CommodityGroupDetailTabOption.log_list:
                return <CommoditiesDataTableView />
            case CommodityGroupDetailTabOption.images:
                return <CommodityGroupImagesView />
        }
    }
    return (
        <>
            <CommodityGroupAppBar />
            {loading && <Loader />}
            {!loading && renderDetail()}
        </>
    )
}
