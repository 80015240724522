import Logger from "../../helpers/Logger"

// const log = new Logger("ErrorManager")

export class ErrorManager {
    handleError = (className: string, error: Error) => {
        ErrorManager.handleError(className, error)
    }

    static handleError(className: string, error: Error) {
        console.error("Error: ", className, error.message)
        alert(error.message)
    }

    static recordErrorAndAlert(tag: string, _function: string, error: Error) {
        Logger.e(tag, _function, error)
        alert(`${tag}.${_function}() ${error}`)
    }
}
