import React from "react"
import Navbar from "react-bootstrap/Navbar"
import {useTranslation} from "react-i18next"
import {AuthHeader, AuthNavbarAlignEndContainer, AuthNavbarLogo} from "../../newStyles/AuthStyles"
import {useNavigate} from "react-router-dom"
import {SpacerH10} from "../../newStyles/Spacer"
import {LanguageSelector2} from "./LanguageSelector2"
import {Link5} from "../../newStyles/Links"

export function AuthNavbar() {
    const {t} = useTranslation("auth")
    const navigate = useNavigate()

    return (
        <AuthHeader fixed="top">
            <Navbar.Collapse className="justify-content-end">
                <AuthNavbarLogo onClick={() => navigate("/")} />
                <AuthNavbarAlignEndContainer>
                    <Link5 route="/">{t("back_home")}</Link5>
                    <SpacerH10 />
                    <LanguageSelector2 />
                </AuthNavbarAlignEndContainer>
            </Navbar.Collapse>
        </AuthHeader>
    )
}
