import styled from "styled-components"
import {NavigationLinkBarContentContainer} from "../Components/styles"

export const MobileNavigationLinkBarContentContainer = styled(NavigationLinkBarContentContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #61eec1;
    padding: 12px;
    height: 60px;
`
