import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {Commodity} from "../../../../types/commodity/Commodity"
import {CommodityEvent} from "../../../../types/commodity/CommodityEvent"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {Business} from "../../../../types/Business"
import CommodityGroupTypeLabels from "../../../localization/labels/CommodityGroupTypeLabels"
import {locale} from "../../../authentication/selectors"
import DateFormatters from "../../../../utils/DateFormatters"
import {store} from "../../../../app/store"

export default class CommodityEventViewModel {
    id: string
    business_name: string
    group_type: CommodityGroupType
    group_first_id: string
    displayTime: string
    summary: string

    constructor(commodity: Commodity, event: CommodityEvent, group: CommodityGroup, business: Business) {
        let lang = locale(store.getState())
        this.id = event.id
        this.business_name = business.name
        this.group_type = group.type
        this.group_first_id = group.user_entries.identifiers.first_id
        this.displayTime = DateFormatters.getFormattedDateFromTimestamp(event.time) + ", " + DateFormatters.getFormattedTimeFromTimestamp(event.time)
        let labels = new CommodityGroupTypeLabels(lang)
        this.summary = this.business_name + ", " + labels.singular(this.group_type) + ", " + group.user_entries.identifiers.first_id + ", " + this.displayTime
    }
}
