import React from "react"
import {useAppSelector} from "../../../app/hooks"
import {businesses} from "../../../components/authentication/selectors"
import {NavLink} from "../../styles"
import {businessTypeLabels} from "../../../components/localization/selectors"

export function ChangeBusinessNavLink({eventKey}: {eventKey?: string}) {
    const businessList = useAppSelector(businesses)
    const labels = useAppSelector(businessTypeLabels)

    if (businessList.length > 1) {
        return <NavLink eventKey={eventKey}>{labels.businesses()}</NavLink>
    }

    return null
}
