import CommodityGroupViewModel from "../viewModel/CommodityGroupViewModel"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"

export default class CommodityGroupsZoneFilters {
    models: CommodityGroupViewModel[]

    constructor(models: CommodityGroupViewModel[]) {
        this.models = models
        if (!models.length) {
            return
        }
        let first = models[0]
        if (
            first.group.type !== CommodityGroupType.inbound_load &&
            first.group.type !== CommodityGroupType.row &&
            first.group.type !== CommodityGroupType.outbound_load
        ) {
            return
        }
        let zoneVisibility = store.getState().authentication.business_member?.zone_visibility
        this.models = this.applyZoneFilter(models, zoneVisibility)
    }

    applyZoneFilter(groups: CommodityGroupViewModel[], options?: string[]): CommodityGroupViewModel[] {
        if (options === undefined || !options.length) {
            return groups
        }
        let filtered: CommodityGroupViewModel[] = []
        const business = store.getState().authentication.business
        if (business === undefined) {
            return groups
        }

        groups.forEach((group) => {
            let groupZone = group.zone
            if (business.id === group.group.created_by_business_id) {
                if (options.find((option) => option === groupZone?.id) === undefined) {
                    return
                }
            }
            filtered.push(group)
        })
        return filtered
    }
}
