enum EventTriggersCollectionPaths {
    AUDIT_AUTO_RESOLVE_TRIGGERS = "audit_auto_resolve_triggers",
    ALLOCATIONS_TRIGGERS = "trigger_allocations",
    ALLOCATIONS_UNDO_TRIGGERS = "undo_allocations",
    EDIT_COMMODITY_TRIGGERS = "edit_commodity_triggers",
    UPDATE_STOCK_TRIGGERS = "trigger_update_stock",
    MAGIC_LINK_TRIGGERS = "trigger_magic_link",
    SEGMENTATION_TRIGGERS = "trigger_segmentation",
    INBOUND_WEIGHTS_FORM_TRIGGERS = "trigger_inbound_weights_form",
    INBOUND_ROW_CHANGE_TRIGGERS = "trigger_inbound_row_change",
    MIGRATION_TRIGGERS = "migration_triggers",
    UNDO_GENERATED_LOGS_TRIGGERS = "undo_generated_logs_triggers",
}

export default EventTriggersCollectionPaths
