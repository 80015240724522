import {Button, DialogActions} from "@mui/material"

export const FormCancelSaveButtonsView = (props: FormCancelSaveButtonsButtonsProps) => (
    <DialogActions>
        <Button
            sx={{
                bgcolor: "black",
                color: "white",
                ":hover": {
                    bgcolor: "#000",
                    color: "#fff",
                },
                fontWeight: "bold",
            }}
            onClick={(e) => props.cancel()}
        >
            {props.cancel_label}
        </Button>
        <Button color={"primary"} sx={{fontWeight: "bold"}} variant={"contained"} onClick={props.submit} autoFocus>
            {props.submit_label}
        </Button>
    </DialogActions>
)

export interface FormCancelSaveButtonsButtonsProps {
    cancel: () => void
    submit: () => void
    cancel_label: string
    submit_label: string
}
