import CommodityGroupViewModel from "../../commodityGroups/viewModel/CommodityGroupViewModel"
import CommodityGroupWeightsFormState from "../../commodityGroups/viewModel/CommodityGroupWeightsFormState"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityWeight} from "@common/types/commodity/commodity"
import {CommodityGroupUserEntries} from "@common/types/commodity/commodityGroup"
import {scaleWeightUnitAsMeasurementSystem} from "../../../authentication/selectors"
import Display from "../../../../utils/Display"

export default class CommodityGroupWeightsFormHelpers {
    static getUpdatedCommodityGroupFromWeightsForm(model: CommodityGroupViewModel, form: CommodityGroupWeightsFormState): CommodityGroup {
        let formGross = parseFloat(form.gross) // Kg or Lbs from Form
        let formEmpty = parseFloat(form.empty)
        if (isNaN(formGross) || isNaN(formEmpty)) {
            return model.group
        }

        const system = scaleWeightUnitAsMeasurementSystem()
        const gross = Display.serverWeight(formGross, system) // Kg for Server
        const empty = Display.serverWeight(formEmpty, system)
        const net = gross - empty
        const weight: CommodityWeight = {
            weight: net,
            gross_weight: gross,
            empty_weight: empty,
        }

        let userEntries: CommodityGroupUserEntries = {...model.group.user_entries, weight: weight}
        return {...model.group, user_entries: userEntries}
    }
}
