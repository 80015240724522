import {
    MarketingImage,
    MarketingSectionLeftContainer,
    MarketingSectionRightContainer,
    MarketingSectionRightContainerLeftPadding,
    MarketingSectionSubtitleDark,
} from "../../MarketingCover/Web/styles"
import {SectionContainer, SectionTitle} from "./styles"
import {NavigateButton} from "../../MarketingCover/Web"

const WebLeftSection = (props: MarketingSectionProps) => (
    <SectionContainer>
        <MarketingSectionRightContainerLeftPadding>
            <MarketingImage src={props.url} />
        </MarketingSectionRightContainerLeftPadding>
        <MarketingSectionLeftContainer>
            <SectionTitle>{props.title}</SectionTitle>
            <MarketingSectionSubtitleDark>{props.subtitle}</MarketingSectionSubtitleDark>
            <NavigateButton path={"login"} label={"Get Started"} isWhite={false} />
        </MarketingSectionLeftContainer>
    </SectionContainer>
)

const WebRightSection = (props: MarketingSectionProps) => (
    <SectionContainer>
        <MarketingSectionLeftContainer>
            <SectionTitle>{props.title}</SectionTitle>
            <MarketingSectionSubtitleDark>{props.subtitle}</MarketingSectionSubtitleDark>
            <NavigateButton path={"login"} label={"Get Started"} isWhite={false} />
        </MarketingSectionLeftContainer>
        <MarketingSectionRightContainer>
            <MarketingImage src={props.url} />
        </MarketingSectionRightContainer>
    </SectionContainer>
)

export interface MarketingSectionProps {
    title: string
    subtitle: string
    url: string
}

export {WebLeftSection, WebRightSection}
