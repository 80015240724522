import {Box, DialogContent, Divider} from "@mui/material"
import DialogTitle from "@mui/material/DialogTitle"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {FormCancelSaveButtonsView} from "./fields/sfc/FormCancelSaveButtonsView"
import {useAppSelector} from "../../../app/hooks"
import {store} from "../../../app/store"
import {setShowModal} from "../../../components/modal/slice"
import {InboundFields} from "./fields/InboundFields"
import {selectedTab} from "../../../components/dataTables/commodityGroup/selectors"
import {commodityGroupTypeLabels} from "../../../components/localization/selectors"
import {RowFields} from "./fields/RowFields"
import {OutboundFields} from "./fields/OutboundFields"
import {OrderFields} from "./fields/OrderFields"
import {VesselFields} from "./fields/VesselFields"
import {VesselSectionFields} from "./fields/VesselSectionFields"
import {ZoneFields} from "./fields/ZoneFields"
import {selectedForm} from "../../../components/commodities/commodityGroup/selectors/formSelectors"
import {commodityGroupFormLoading} from "../../../components/commodities/commodityGroup/selectors/selectors"
import {FormLoader} from "../../loader"
import React from "react"

export const CommodityGroupFormModalView = () => {
    const loading = useAppSelector(commodityGroupFormLoading)
    const form = useAppSelector(selectedForm)
    const type = useAppSelector(selectedTab)
    const typeLabels = useAppSelector(commodityGroupTypeLabels)

    const submit = () => {
        if (form === undefined) {
            return
        }
        switch (form.group.type) {
            case CommodityGroupType.row:
                if (form.row === undefined) {
                    return
                }
                form?.row.update()
                form?.row.submit()
                break
            case CommodityGroupType.outbound_load:
                if (form.outbound === undefined) {
                    return
                }
                form?.outbound.update()
                form?.outbound.submit()
                break
            case CommodityGroupType.order:
                if (form.order === undefined) {
                    return
                }
                form?.order.update()
                form?.order.submit()
                break
            case CommodityGroupType.vessel:
                if (form.vessel === undefined) {
                    return
                }
                form?.vessel.update()
                form?.vessel.submit()
                break
            case CommodityGroupType.vessel_section:
                if (form.vessel_section === undefined) {
                    return
                }
                form?.vessel_section.update()
                form?.vessel_section.submit()
                break
            case CommodityGroupType.zone:
                if (form.zone === undefined) {
                    return
                }
                form?.zone.update()
                form?.zone.submit()
                break
            case CommodityGroupType.inbound_load:
                if (form.inbound === undefined) {
                    return
                }
                form?.inbound.update()
                form?.inbound.submit()
        }
    }

    const renderFields = () => {
        if (form === undefined) {
            return
        }

        switch (type) {
            case CommodityGroupType.row:
                if (form.row === undefined) {
                    return
                }
                return <RowFields form={form.row} />
            case CommodityGroupType.outbound_load:
                if (form.outbound === undefined) {
                    return
                }
                return <OutboundFields form={form.outbound} />
            case CommodityGroupType.order:
                if (form.order === undefined) {
                    return
                }
                return <OrderFields form={form.order} />
            case CommodityGroupType.vessel:
                if (form.vessel === undefined) {
                    return
                }
                return <VesselFields form={form.vessel} />
            case CommodityGroupType.vessel_section:
                if (form.vessel_section === undefined) {
                    return
                }
                return <VesselSectionFields form={form.vessel_section} />
            case CommodityGroupType.zone:
                if (form.zone === undefined) {
                    return
                }
                return <ZoneFields form={form.zone} />
            case CommodityGroupType.inbound_load:
                if (form.inbound === undefined) {
                    return
                }
                return <InboundFields form={form.inbound} />
        }
    }

    return (
        <>
            {loading && <FormLoader />}
            {!loading && (
                <>
                    <DialogTitle fontSize={22} fontWeight={"bold"}>
                        {typeLabels.singular(type)}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Box sx={{"& .MuiTextField-root": {width: "18.5ch"}}}>{renderFields()}</Box>
                    </DialogContent>
                    <Divider />
                    <FormCancelSaveButtonsView
                        cancel={() => store.dispatch(setShowModal(false))}
                        submit={submit}
                        cancel_label={"Cancel"}
                        submit_label={"Save"}
                    />
                </>
            )}
        </>
    )
}
