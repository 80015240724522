import {RootState} from "../../../../app/store"
import {CommodityGroupAction} from "../../commodityGroups/viewModel/CommodityGroupActionsOptions"
import CommodityViewModel from "../../commodityGroups/viewModel/CommodityViewModel"
import {CommodityGroupImage} from "@common/types/commodity/commodityGroup"

export const commodityGroupLoading = (state: RootState) => {
    let group = state.commodityGroup
    return group.loading
}
export const commodityGroupFormLoading = (state: RootState) => {
    let group = state.commodityGroup
    return group.form_loading
}
export const ticketNumber = (state: RootState) => {
    let groupState = state.commodityGroup
    if (groupState.group === undefined) {
        return "Ticket NA"
    }
    return groupState.group.group.user_entries.identifiers.first_id
}
export const selectedGroup = (state: RootState) => {
    let group = state.commodityGroup
    return group.group
}
export const selectedCommodityGroupActions = (state: RootState): CommodityGroupAction[] => {
    let groupState = state.commodityGroup
    if (groupState.group === undefined) {
        return []
    }
    return groupState.group.actions
}
export const commodityGroupTab = (state: RootState) => state.commodityGroup.tab
export const selectedGroupCommoditiesWithUrls = (state: RootState): CommodityViewModel[] =>
    state.commodityGroup.commodities.filter((commodity) => commodity.commodity.url !== undefined)

export const selectedGroupImages = (state: RootState): CommodityGroupImage[] => {
    let group = state.commodityGroup.group
    if (group === undefined) {
        return []
    }
    let images = group.group.images?.images
    if (images === undefined) {
        return []
    }
    return images
}
