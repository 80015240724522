import {Business} from "../../types/Business"
import {User} from "../../types/User"
import {UserSettings} from "@common/types/UserSettings"
import {BusinessMember} from "../../types/BusinessMember"
import {BusinessSettings} from "@common/types/business/BusinessSettings"
import {CommodityGroup} from "../../types/commodity/CommodityGroup"
import {Locale} from "../../i18n/Localization"

export enum AppPresentationState {
    loggedOut,
    enterMagicLinkEmail,
    magicLinkEmailSent,
    pickBusiness,
    loggedIn,
}

export interface AuthenticationState {
    business?: Business
    businesses?: Business[]
    business_members?: BusinessMember[]
    business_member?: BusinessMember
    business_settings?: BusinessSettings
    presentation_state: AppPresentationState
    user_id?: string
    user?: User
    user_settings?: UserSettings
    locale?: Locale
    isUserGlobalAdmin: boolean
    current_business_member_open_zones?: CommodityGroup[]
}

export const initialState: AuthenticationState = {
    isUserGlobalAdmin: false,
    presentation_state: AppPresentationState.loggedOut,
}
