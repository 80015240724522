import styled from "styled-components"
import Navbar from "react-bootstrap/Navbar"
import {MouseEventHandler} from "react"
import Form from "react-bootstrap/Form"
import {Label5, LabelBlack1} from "./Labels"
import {Button3} from "./Buttons"
import {Font, FontSize} from "./Generic"
import Device from "./Device"

const HeaderHeight = "10.5vh"
const FooterHeight = "10.5vh"
const ContentWidth = Device.mediaMulti({xs: "width: 90vw", md: "width: 500px", lg: "width: 550px"})

export const AuthPageContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

// Navbar

export const AuthHeader = styled(Navbar)`
    width: 100%;
    height: ${HeaderHeight};
    padding: 0 3.2vw;
`

const AuthNavbarMenuButton = styled.div`
    padding: 0;
    cursor: pointer;
`

const AuthNavbarLogoImage = styled.img`
    ${Device.mediaMulti({xs: "width: 150px", md: "width: 180px", lg: "width: 200px"})}
`

export const AuthNavbarLogo = ({onClick}: {onClick?: MouseEventHandler}) => (
    <AuthNavbarMenuButton onClick={onClick}>
        <AuthNavbarLogoImage src="/navbar_logo_light.png" alt="" />
    </AuthNavbarMenuButton>
)

export const AuthNavbarAlignEndContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    width: 100%;
`

// Footer

export const FlexDiv = styled.div`
    display: flex;
`

export const AuthFooterContainer = styled.div`
    position: absolute;
    ${ContentWidth};
    height: ${FooterHeight};
    bottom: 0;
    display: flex;
    justify-content: space-between;
`

// Body

export const AuthBodyContainer = styled.div`
    position: absolute;
    top: ${HeaderHeight};
    bottom: ${FooterHeight};
    ${ContentWidth};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

// Form

export const AuthTitle = styled(LabelBlack1)`
    margin: 0;
`

export const AuthReminderLabel = styled(Label5)`
    padding: 1rem 1rem 1.25rem 1rem; // t r b l
    background-color: #00fff223;
    border: 1px solid #dadada;
`

export const AuthForm = styled(Form)`
    display: flex;
    flex-direction: column;
`

export const AuthMagicLinkEmailInput = styled(Form.Control)`
    font-family: ${Font.Regular};
    font-size: ${FontSize._4};
    padding-top: 1rem;
    padding-bottom: 1rem;
`

export const AuthMagicLinkButton = styled(Button3)`
    width: 100%;
    padding: 0.75rem;
`

// Magic Link Sent

export const MagicLinkBoxImage = styled.img`
    ${Device.mediaMulti({xs: "width: 64px", md: "width: 72px", lg: "width: 84px"})}
`

export const MagicLinkBox = styled.div`
    width: 100%;
    height: 40vh;
    padding: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #dadada;
    border-radius: 1rem;
`
