import CommodityGroupForm from "./CommodityGroupForm"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {ZoneType} from "@common/types/ZoneType"
import {store} from "../../../../app/store"
import {setZoneForm} from "../../commodityGroup/slice"

export default class ZoneForm extends CommodityGroupForm {
    zone_type: ZoneType = ZoneType.yard

    constructor(group: CommodityGroup) {
        super(group)
        this.setZoneType()
    }
    update() {
        super.update()
        let updated: CommodityGroup = {...this.group, zone_type: this.zone_type}
        this.group = updated
    }
    setZoneType() {
        if (this.group.zone_type === undefined) {
            return
        }
        this.zone_type = this.group.zone_type
    }
    setState() {
        store.dispatch(setZoneForm(this))
    }
    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }
}
