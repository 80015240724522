import {RootState, store} from "../../app/store"
import {MarketingTheme} from "./state"
import {MenuItemProps} from "../../view/marketing/Navigation/NavigationListButton/model"
import {MarketingSectionProps} from "../../view/marketing/Content/MarketingCover/Web"
import {QRProps, QRsProps} from "../../view/marketing/Content/QRs"
import {constants} from "../../config/constants"
import {AboutContent} from "../../view/marketing/Content/About/Web"

enum PageType {
    landing = "landing",
    about = "about",
    supply = "supply",
    buy = "buy",
}

enum SectionType {
    first = 1,
    second,
    third,
    fourth,
}

enum Employee {
    scott = "scott",
    alex = "alex",
    lucia = "lucia",
    victor = "victor",
    matthew = "matthew",
    oles = "oles",
}

export const marketingTheme = (state: RootState): MarketingTheme => {
    return state.marketing.theme
}

export const platformOptions = (state: RootState): MenuItemProps[] => {
    return state.marketing.platform_options
}

export const companyOptions = (state: RootState): MenuItemProps[] => {
    return state.marketing.company_options
}

export const resourcesOptions = (state: RootState): MenuItemProps[] => {
    return state.marketing.resources_options
}

export const themeAppIconUrl = (state: RootState): string => {
    const theme = state.marketing.theme
    return getThemeAppIconUrl(theme)
}

export const qrsProps = (): QRsProps => {
    const bucket = constants.MARKETING_PUBLIC_IMAGE_PATH
    const qrs = bucket + "qrs/"
    const first: QRProps = {
        key: "qr-ios-download",
        image_url: qrs + "app.png",
        label: "iOS Download",
        url: constants.APP_STORE_URL,
    }
    const second: QRProps = {
        key: "qr-printing",
        image_url: qrs + "print_new.png",
        label: "QR Printing",
        url: constants.PRINT_APP_URL,
    }
    const third: QRProps = {
        key: "qr-how-it-works",
        image_url: qrs + "how_new.png",
        label: "How It Works",
        url: constants.HOW_IT_WORKS_URL,
    }
    const fourth: QRProps = {
        key: "qr-watch-ad",
        image_url: qrs + "ad.png",
        label: "Watch The Ad",
        url: constants.AD_URL,
    }
    const props: QRsProps = {
        qrs: [first, second, third, fourth],
        title: "Join Copilot Today",
    }
    return props
}

export const imageUrlPrefix = (theme: MarketingTheme, number: number, path: string): string => {
    const bucket = constants.MARKETING_PUBLIC_IMAGE_PATH
    const themeUrl = marketingUrlFromTheme(theme)
    const pageType = pageTypeFromNavPath(path)
    const dirPath = "/" + pageType
    const urlPrefix = bucket + themeUrl + dirPath + "/" + number + ".png"
    return urlPrefix
}

export const aboutUrl = (theme: MarketingTheme, name: Employee): string => {
    const bucket = constants.MARKETING_PUBLIC_IMAGE_PATH
    const themeUrl = marketingUrlFromTheme(theme)
    const urlPrefix = bucket + themeUrl + "/about/" + name + ".png"
    return urlPrefix
}

export const getThemeAppIconUrl = (theme: MarketingTheme): string => {
    const bucket = constants.MARKETING_PUBLIC_IMAGE_PATH
    const themeUrl = marketingUrlFromTheme(theme)
    const urlPrefix = bucket + themeUrl + "/icon/icon.png"
    return urlPrefix
}

export const aboutItems = (theme: MarketingTheme): AboutContent[] => {
    console.log(aboutUrl(theme, Employee.scott))
    const scott: AboutContent = {
        description: "Product builder meets adventurer. If he’s not coding, he’s wingsuit basejumping into foreign ports.",
        name: "Scott Gregg",
        title: "Founder, CEO",
        url: aboutUrl(theme, Employee.scott),
    }
    const alex: AboutContent = {
        description: "5th gen forest products meets visionary. Alex’s family cut down trees so he can move forests.",
        name: "Alex Meyers",
        title: "Co-Founder, COO",
        url: aboutUrl(theme, Employee.alex),
    }
    const lucia: AboutContent = {
        description: "Small stature, massive skills. From fumigation to sawlog inventory, she's the beating heart of wood.",
        name: "Lucia Sorrondeguy",
        title: "Director Of Customer Success",
        url: aboutUrl(theme, Employee.lucia),
    }
    const victor: AboutContent = {
        description: "Silent but deadly. A decade of experience means he doesn’t miss. And when he speaks - he means it.",
        name: "Victor Licastro",
        title: "South America Operations",
        url: aboutUrl(theme, Employee.victor),
    }
    const matthew: AboutContent = {
        description: "From Blackhawk Pilot to Forestry Special Ops. No mission is impossible for this legend.",
        name: "Matthew Skidmore",
        title: "Global Sales",
        url: aboutUrl(theme, Employee.matthew),
    }
    const oles: AboutContent = {
        description: "Half man, half computer. This beast subsists on code for breakfast, lunch, and dinner.",
        name: "Oles Dovgusha",
        title: "Software Engineer",
        url: aboutUrl(theme, Employee.oles),
    }
    return [scott, alex, lucia, victor, matthew, oles]
}

export const marketingSectionProps = (theme: MarketingTheme, number: number, path: string): MarketingSectionProps => {
    const urlPrefix = imageUrlPrefix(theme, number, path)
    const pageType = pageTypeFromNavPath(path)
    const sectionType = number as SectionType
    let title = ""
    let subtitle = ""

    switch (pageType) {
        case PageType.landing:
            switch (sectionType) {
                case SectionType.first:
                    title = "A.I. Powered Forestry"
                    subtitle = "Buy and sell sawlogs with Copilot."
                    break
                case SectionType.second:
                    title = "Supply logs by leveling-up every operator."
                    subtitle = "Moisture is for chumps. Capture perfect volume using AI, and never transact on weight again."
                    break
                case SectionType.third:
                    title = "Buy logs with more data than you can imagine."
                    subtitle = "Drop the centimeter stick. Speed up your receiving and improve your data with AI based measurements."
                    break
                case SectionType.fourth:
                    title = "Connect with partners on the TimberEye network."
                    subtitle = "Watch every load, container, or vessel in real time as your partners scan with TimberEye."
                    break
            }
            break
        case PageType.about:
            break
        case PageType.supply:
            switch (sectionType) {
                case SectionType.first:
                    title = "Sell Direct To Foreign Mills"
                    subtitle = "Starting from any tract you manage"
                    break
                case SectionType.second:
                    title = "Load containers with powerful, AI based log scaling"
                    subtitle = "Capture perfect measurements of every piece you ship, and capture value the second you scan."
                    break
                case SectionType.third:
                    title = "Fulfill orders to foreign buyers with none of the hassle"
                    subtitle = "Take the guesswork out of exports, financing, contracting, and ocean freight with Copilot."
                    break
                case SectionType.fourth:
                    title = "Access foreign markets and sell to a global network"
                    subtitle = "Sell to the sawmill down the road, or thousands of mills overseas - and stabilize your business."
                    break
            }
            break
        case PageType.buy:
            switch (sectionType) {
                case SectionType.first:
                    title = "Buy From Landowners"
                    subtitle = "Shipped directly to your sawmill"
                    break
                case SectionType.second:
                    title = "Receive logs scaled with Artificial Intelligence"
                    subtitle = "Eliminate the guesswork in your complex international transactions, with perfect verifiable data."
                    break
                case SectionType.third:
                    title = "Watch orders unfold across the globe in real time"
                    subtitle = "Watch your logs as they move from forest, to container, to port, until they reach your mill."
                    break
                case SectionType.fourth:
                    title = "Competitive pricing, global access, no shortcuts"
                    subtitle = "Copilot extends the benefits of our automation and volume discounts, so we're always competitive."
                    break
            }
            break
    }
    return {
        subtitle: subtitle,
        title: title,
        url: urlPrefix,
    }
}

const marketingUrlFromTheme = (theme: MarketingTheme): string => {
    switch (theme) {
        case MarketingTheme.copilot:
            return "copilot"
        case MarketingTheme.pickles:
            return "pickles"
        case MarketingTheme.goat:
            return "goat"
    }
}

const pageTypeFromNavPath = (navPath: string): PageType => {
    if (navPath === "/") {
        return PageType.landing
    }
    return navPath.replace(/[/]/g, "") as PageType
}
