import {useMediaQuery} from "react-responsive"
import WebMarketingCover, {MarketingSectionProps} from "./Web"
import MobileMarketingCover from "./Mobile"
import {Constants} from "../../../../config/constants"

export const MarketingCover = (props: MarketingSectionProps) => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <WebMarketingCover {...props} />}
            {isMobile && <MobileMarketingCover {...props} />}
        </>
    )
}
