import {Business} from "../../../../types/Business"
import {doc, setDoc, Timestamp} from "firebase/firestore"
import {db} from "../../../db"
import {AccessLevel, BusinessMember} from "../../../../types/BusinessMember"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {ErrorManager} from "../../../../components/errorManager"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {User} from "../../../../types/User"

export default class BusinessMembersCreate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("BusinessMembersCreate", error as Error)
    }
    createBusinessMember = async (user: User, business: Business): Promise<BusinessMember | undefined> => {
        try {
            let member: BusinessMember = {
                business_id: business.id,
                email: user.email,
                type: AccessLevel.Administrator,
                created_time: Timestamp.fromDate(new Date()),
                commodity_group_visibility: Object.keys(CommodityGroupType) as CommodityGroupType[],
                id: user.id,
            }
            await setDoc(doc(db, FIRCollectionPaths.businessMember(member)), member)
            return member
        } catch (error) {
            this.error(error)
            return undefined
        }
    }
}
