import {RootState} from "../../../app/store"
import {CommodityMeasurements, LogClass, MeasurementSystem} from "@common/types/commodity/commodity"
import {logScale, measurementSystem} from "../../authentication/selectors"
import {LogScale} from "@common/types/LogScales"
import SouthAfricaClassHandler from "@common/common/SouthAfricaClassHandler"
import CalculateLogVolume from "@common/common/CalculateLogVolume"
import MathUtils from "@common/utils/MathUtils"
import Display from "../../../utils/Display"

export default class CommodityMeasurementsTransformations {
    state: RootState
    system: MeasurementSystem
    stock?: CommodityMeasurements
    log_class?: LogClass
    count: number = 0
    short: number = 0
    avg: number = 0
    rounded_short: number = 0
    long: number = 0
    volume: number = 0
    length: number = 0
    diameter_class_string: string = ""

    constructor(state: RootState, logClass?: LogClass, stock?: CommodityMeasurements) {
        this.state = state
        this.stock = stock
        this.system = measurementSystem(state)
        this.log_class = logClass
        this.setCount()
        this.setLength()
        this.setDiameters()
        this.setVolume()
    }

    setCount = () => {
        let count = 0

        if (this.stock?.count !== undefined) {
            count = this.stock.count
        }
        this.count = count
    }

    setLength = () => {
        this.length = Display.displayLengthMR2(this.stock?.length?.length ?? 0, this.system)
    }

    private setDiameters() {
        let smallCm = this.stock?.diameters?.short
        let longCm = this.stock?.diameters?.long
        if (smallCm === undefined || longCm === undefined) {
            return
        }

        this.short = Display.displayLengthCmR2(smallCm, this.system)
        this.long = Display.displayLengthCmR2(longCm, this.system)
        this.rounded_short = CalculateLogVolume.getJasRoundededShortDiameter(smallCm, longCm)
        this.avg = MathUtils.round2((this.short + this.long) / 2)
        this.diameter_class_string = new SouthAfricaClassHandler(this.short, this.long, this.length, this.log_class).class_string
    }

    setVolume = () => {
        let vol = 0
        let scale = logScale(this.state)

        switch (scale) {
            case LogScale.Doyle:
                vol = this.stock?.volume?.doyle ?? 0
                break
            case LogScale.International:
                vol = this.stock?.volume?.international ?? 0
                break
            case LogScale.JAS:
                vol = this.stock?.volume?.jas ?? 0
                break
            case LogScale.Scribner:
                vol = this.stock?.volume?.scribner ?? 0
                break
            case LogScale.Huber:
                vol = this.stock?.volume?.huber ?? 0
                break
            case LogScale.CylinderShort:
                vol = this.stock?.volume?.cylinder_short ?? 0
                break
            case LogScale.CylinderAverage:
                vol = this.stock?.volume?.cylinder_average ?? 0
                break
        }

        this.volume = MathUtils.round4(vol)
    }
}
