import {Timestamp} from "firebase/firestore"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export interface BusinessMember {
    id: string
    created_time: Timestamp
    business_id: string
    email: string
    type: AccessLevel
    restricted_to_scanning_only?: boolean
    is_readonly?: boolean
    commodity_group_visibility?: CommodityGroupType[]
    zone_visibility?: string[]
}

export enum AccessLevel {
    Administrator = "Administrator",
    Operator = "Operator",
}
