import {Locale} from "../../../i18n/Localization"

export default class DeductionLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    diameterDeductions = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Diameter Deductions"
            case Locale.pt:
                return "Deduções De Diâmetro"
            case Locale.es:
                return "Deducciones De Diámetro"
        }
    }

    lengthDeductions = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Length Deductions"
            case Locale.pt:
                return "Deduções De Comprimento"
            case Locale.es:
                return "Deducciones De Largo"
        }
    }

    operatorNotes = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Operator Notes"
            case Locale.pt:
                return "Notas do operador"
            case Locale.es:
                return "Notas del operador"
        }
    }

    defectCodes = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Defect Codes"
            case Locale.pt:
                return "Códigos De Defeito"
            case Locale.es:
                return "Códigos De Defectos"
        }
    }
}
