import CloseIcon from "@mui/icons-material/Close"
import {IconButton} from "@mui/material"
import React from "react"
import {store} from "../../../../app/store"
import {setShowModal} from "../../../../components/modal/slice"

export function ModalCloseButton() {
    function onCloseButtonClick(event: any) {
        console.log("ModalCloseButton.onCloseButtonClick")
        store.dispatch(setShowModal(false))
    }

    return (
        <IconButton
            aria-label="close"
            onClick={onCloseButtonClick}
            sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    )
}
