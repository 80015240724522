import React from "react"
import {OuterContainer, VStack} from "../../Styles/styles.js"
import {SText, XLTextBold} from "../../Styles/fonts.js"
import {SectionView} from "../../LegalComponents"

const TermsView = ({sections}) => (
    <OuterContainer>
        <VStack>
            <XLTextBold>Terms Of Service </XLTextBold>
            <SText> Effective: December 2nd, 2023 </SText>
            <SText>
                These Terms of Service describe your rights and obligations when using the TimberEye web and mobile applications and platform (the “Services”).
                These terms apply to you if you are a Customer (defined below), or a Yard Member (defined below). These terms constitute a binding Contract
                between TimberEye, Inc. and you. We appreciate you being part of the TimberEye community, and we hope you will read these terms carefully.
            </SText>
            {sections.map((section) => (
                <SectionView key={section.title} section={section} />
            ))}
        </VStack>
    </OuterContainer>
)

export default TermsView
