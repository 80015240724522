import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, Unsubscribe, where, limit} from "firebase/firestore"
import {db} from "../../../db"
import {QueryDocumentSnapshot} from "@firebase/firestore"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {store} from "../../../../app/store"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {ErrorManager} from "../../../../components/errorManager"
import {LinkedBusiness} from "../../../../types/Business"

export default class CommodityGroupRead extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityGroupRead", error as Error)
    }
    listenForCommodityGroups = (completion: (groups: CommodityGroup[]) => void): Unsubscribe | undefined => {
        let state = store.getState()
        let businessId = state.authentication.business?.id
        if (businessId === undefined) {
            return
        }
        let type = state.dataTable.tab
        console.log("listenForCommodityGroups", `businessId:${businessId}`)

        const q = query(
            collection(db, FIRCollectionPaths.COMMODITY_GROUPS),
            where("linked_business_ids", "array-contains", businessId),
            where("type", "==", type),
            where("times.created", ">=", state.dataTable.query_start_date),
            where("times.created", "<=", state.dataTable.query_end_date),
            orderBy("times.created", "desc"),
        )
        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => this.listenerCompletionHandler(querySnapshot.docs, completion),
            (error) => this.error(error),
        )
        return unsubscribe
    }
    listenForOpenZones = (businessId: string, completion: (groups: CommodityGroup[]) => void): Unsubscribe => {
        const q = query(
            collection(db, FIRCollectionPaths.COMMODITY_GROUPS),
            where("linked_business_ids", "array-contains", businessId),
            where("type", "==", CommodityGroupType.zone),
        )
        const unsubscribe = onSnapshot(
            q,
            (querySnapshot) => this.listenerCompletionHandler(querySnapshot.docs, completion),
            (error) => this.error(error),
        )
        return unsubscribe
    }
    getCommodityGroupsByTypes = async (types: CommodityGroupType[]): Promise<CommodityGroup[]> => {
        try {
            let state = store.getState()
            let businessId = state.authentication.business?.id
            const q = query(
                collection(db, FIRCollectionPaths.COMMODITY_GROUPS),
                where("linked_business_ids", "array-contains", businessId),
                where("type", "in", types),
                orderBy("times.created", "desc"),
            )
            const snapshot = await getDocs(q)
            return this.commodityGroupsFromDocs(snapshot.docs)
        } catch (error) {
            this.error(error)
            return []
        }
    }
    getGroupsLinkedToGroupId = async (groupId: string, type: CommodityGroupType): Promise<CommodityGroup[]> => {
        try {
            const q = query(
                collection(db, FIRCollectionPaths.COMMODITY_GROUPS),
                where("linked_commodity_group_ids", "array-contains", groupId),
                where("type", "==", type),
                orderBy("times.created", "desc"),
            )
            const snapshot = await getDocs(q)
            return this.commodityGroupsFromDocs(snapshot.docs)
        } catch (error) {
            this.error(error)
            return []
        }
    }
    getCommodityGroups = async (ids: string[]): Promise<CommodityGroup[]> => {
        try {
            let promises = ids.map((id) => this.getCommodityGroup(id))
            let groupsUndefineds = await Promise.all(promises)
            let groups = groupsUndefineds.filter((group) => group !== undefined) as CommodityGroup[]
            return groups
        } catch (error) {
            this.error(error)
            return []
        }
    }
    getCommodityGroup = async (id: string): Promise<CommodityGroup | undefined> => {
        try {
            const docRef = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, id)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) {
                return undefined
            }
            return docSnap.data() as CommodityGroup
        } catch (error) {
            this.error(error)
            return undefined
        }
    }

    // TODO test
    async getRecentCommodityGroupsLinkedBusinesses(limit_: number): Promise<LinkedBusiness[]> {
        try {
            let businessId = store.getState().authentication.business!.id

            const q = query(collection(db, FIRCollectionPaths.COMMODITY_GROUPS), where("linked_business_ids", "array-contains", businessId), limit(limit_))
            const snapshot = await getDocs(q)
            const groups = this.commodityGroupsFromDocs(snapshot.docs)

            let map: Record<string, LinkedBusiness> = {}
            for (const group of groups) {
                for (const link of group.linked_businesses) {
                    map[link.business.id] = link
                }
            }

            let uniqueBusinesses = Object.values(map)

            map = {}
            for (const link of uniqueBusinesses) {
                map[link.business.name] = link
            }

            let noDuplicateNames = Object.values(map)

            return noDuplicateNames
        } catch (error) {
            this.error(error)
            return []
        }
    }

    private listenerCompletionHandler = (docs: Array<QueryDocumentSnapshot>, completion: (members: CommodityGroup[]) => void) =>
        completion(this.commodityGroupsFromDocs(docs))

    private commodityGroupsFromDocs = (docs: Array<QueryDocumentSnapshot>): CommodityGroup[] => {
        const groups: CommodityGroup[] = docs.map((doc) => {
            return doc.data() as CommodityGroup
        })
        return groups
    }
}
