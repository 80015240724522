import React from "react"
import {TextField, MenuItem} from "@mui/material"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {CommodityGroup} from "../../../../../types/commodity/CommodityGroup"
import {useAppSelector} from "../../../../../app/hooks"
import {commodityGroupTypeLabels} from "../../../../../components/localization/selectors"

export const CommodityGroupPickerView = (props: CommodityGroupPickerProps) => {
    let labels = useAppSelector(commodityGroupTypeLabels)

    const selectedItem = () => {
        if (props.selection !== undefined) {
            return props.selection
        }
        if (props.options.length) {
            return props.options[0].id
        }
    }

    return (
        <>
            {props.options.length > 0 && (
                <TextField
                    id={props.type}
                    error={!(props.selection !== undefined && props.selection)}
                    select
                    sx={{marginInlineStart: 0, marginInlineEnd: 0, mb: 2}}
                    label={labels.singular(props.type)}
                    value={selectedItem()}
                    onChange={(e) => props.on_change(e.target.value)}
                >
                    {props.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.user_entries.identifiers.first_id}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </>
    )
}

interface CommodityGroupPickerProps {
    type: CommodityGroupType
    options: CommodityGroup[]
    selection?: string
    on_change: (id: string) => void
}
