import {firebase} from "../../app/store"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {CommodityGroup} from "../../types/commodity/CommodityGroup"
import ArrayUtils from "@common/utils/ArrayUtils"
import {BusinessCache} from "../../firebase/cache/IdCaches"

class CommodityHistoryResult {
    readonly history: CommodityHistoryItem[]

    constructor(history: CommodityHistoryItem[]) {
        this.history = history
    }
}

class CommodityHistoryItem {
    readonly businessName: string
    readonly commodityGroupType: CommodityGroupType
    readonly commodityGroupName: string
    readonly eventDateTime: Date

    constructor(businessName: string, commodityGroupType: CommodityGroupType, commodityGroupName: string, eventDateTime: Date) {
        this.businessName = businessName
        this.commodityGroupType = commodityGroupType
        this.commodityGroupName = commodityGroupName
        this.eventDateTime = eventDateTime
    }
}

export class CommodityHistoryCommodityNotFoundError extends Error {
    constructor(message: string) {
        super(message)
        this.name = "CommodityHistoryCommodityNotFoundError"
    }
}

export class CommodityHistoryComponent {
    async getCommodityHistory(commodityId: string) {
        const commodity = await firebase.commodity.read.getCommodity(commodityId)
        if (!commodity) {
            throw new CommodityHistoryCommodityNotFoundError(commodityId)
        }

        const events = await firebase.commodity_event.read.getAllEventsForCommodities([commodity], undefined)
        events.sort((a, b) => a.time.seconds - b.time.seconds)

        const groupIds = ArrayUtils.distinct(events.map((event) => event.commodity_group_id))
        const groups = await firebase.commodity_group.read.getCommodityGroups(groupIds)

        const businessCache = new BusinessCache()
        const historyItems: CommodityHistoryItem[] = []
        for (const event of events) {
            const group = groups.find((group) => group.id === event.commodity_group_id)
            if (!group) {
                continue
            }

            const business = await businessCache.get(group.created_by_business_id)
            if (!business) {
                throw Error("Business not found")
            }

            historyItems.push(new CommodityHistoryItem(business.name, group.type, CommodityGroup.getName(group), event.time.toDate()))
        }

        return new CommodityHistoryResult(historyItems)
    }
}
