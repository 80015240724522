import {useAppSelector} from "../../../../app/hooks"
import {EditCommodityView, QRView} from "./EditCommodityView"
import {editingLogId, editingLogLongCm, editingLogShortCm} from "../../../../components/commodities/editCommodity/selectors"
import {Button, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material"
import {components, store} from "../../../../app/store"
import {setShowModal} from "../../../../components/modal/slice"
import {RowFlexContainer} from "../../../styles"
import {isUserReadonly} from "../../../../components/authentication/selectors"

export const EditCommodityModalView = () => {
    const isReadonly = useAppSelector(isUserReadonly)
    const logId = useAppSelector(editingLogId)
    const shortCm = useAppSelector(editingLogShortCm)
    const longCm = useAppSelector(editingLogLongCm)

    return (
        <>
            <DialogTitle fontSize={22} fontWeight={"bold"}>
                {"Log Details: " + shortCm + " x " + longCm + ", " + logId}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <RowFlexContainer>
                    <LogDetailModalLeftView />
                    <LogDetailModalRightView />
                </RowFlexContainer>
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} sx={{fontWeight: "bold"}} onClick={(e) => store.dispatch(setShowModal(false))}>
                    {"Cancel"}
                </Button>
                <Button
                    color={"primary"}
                    sx={{fontWeight: "bold"}}
                    variant={"contained"}
                    disabled={isReadonly}
                    onClick={(e) => components.edit_commodity.save()}
                    autoFocus
                >
                    {"Save"}
                </Button>
            </DialogActions>
        </>
    )
}

export const LogDetailModalLeftView = () => <EditCommodityView />

export const LogDetailModalRightView = () => {
    const logId = useAppSelector(editingLogId)
    return <QRView commodity_id={logId} />
}

/*
//<EditLogDiameterLabels>{shortCm + " x " + longCm}</EditLogDiameterLabels>

*/
