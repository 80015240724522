import {useState, useEffect} from "react"

function getWindowWidth() {
    const {innerWidth: width} = window
    return {
        width,
    }
}

export default function useWindowWidth() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowWidth())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowWidth())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowWidthHeight())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowWidthHeight())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}

function getWindowWidthHeight() {
    const {innerWidth: width, innerHeight: height} = window
    return {
        width,
        height,
    }
}
