import {Section} from "./sfc/Section"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {CommodityGroupPickerView} from "./sfc/CommodityGroupPickerView"
import InboundForm from "../../../../components/commodities/commodityGroupForm/Forms/InboundForm"
import {FormTextField} from "./sfc/FormTextFieldView"
import {SwitchView} from "./sfc/SwitchView"
import {LengthSelectorView} from "./sfc/LengthSelectorView"
import {SpeciesSelectorView} from "./sfc/SpeciesSelectorView"
import {LogClassSelectorView} from "./sfc/LogClassSelectorView"
import {LinkedBusinessSelectorView2} from "./sfc/LinkedBusinessSelectorView"
import {BusinessType} from "@common/types/business/businessTypes"
import {ForesterSelectorView} from "./sfc/ForesterSelectorView"
import {LengthOptionsRange} from "../../../../components/commodities/commodityGroupForm/Helpers/LengthOption"

export interface InboundFormProps {
    form: InboundForm
}

export const InboundFields = (props: InboundFormProps) => {
    return (
        <>
            <Section>
                <CommodityGroupPickerView
                    selection={props.form.zone?.id}
                    type={CommodityGroupType.zone}
                    options={props.form.open_zones}
                    on_change={(id) => props.form.didSelectZone(id)}
                />
                <CommodityGroupPickerView
                    selection={props.form.row?.id}
                    type={CommodityGroupType.row}
                    options={props.form.open_rows}
                    on_change={(id) => props.form.didSelectRow(id)}
                />
            </Section>
            <Section>
                <FormTextField
                    value={props.form.first_id}
                    label={props.form.id_labels.firstId(CommodityGroupType.inbound_load)}
                    onChange={(id) => props.form.didEditFirstId(id)}
                />
                <FormTextField
                    value={props.form.second_id}
                    label={props.form.id_labels.secondId(CommodityGroupType.inbound_load)}
                    onChange={(id) => props.form.didEditSecondId(id)}
                />
                <FormTextField
                    value={props.form.third_id}
                    label={props.form.id_labels.thirdId(CommodityGroupType.inbound_load)}
                    onChange={(id) => props.form.didEditThirdId(id)}
                />
            </Section>
            <Section>
                <SwitchView
                    value={props.form.add_partners}
                    onChange={() => props.form.toggleAddPartners()}
                    label={props.form.business_type_labels.partners()}
                />
                {props.form.add_partners && (
                    <>
                        <ForesterSelectorView form={props.form} />
                        <LinkedBusinessSelectorView2 type={BusinessType.Logger} form={props.form} />
                        <LinkedBusinessSelectorView2 type={BusinessType.Trucker} form={props.form} />
                    </>
                )}
            </Section>
            <Section>
                <SwitchView
                    value={props.form.edit_log_properties}
                    onChange={() => props.form.toggleEditLogProperties()}
                    label={props.form.business_type_labels.logProperties()}
                />
                {props.form.edit_log_properties && (
                    <>
                        <LengthSelectorView value={LengthOptionsRange.rounded(props.form.length)} on_change={(length) => props.form.didSelectLength(length)} />
                        <SpeciesSelectorView value={props.form.properties.species} on_change={(species) => props.form.didSelectSpecies(species)} />
                        <LogClassSelectorView value={props.form.properties.log_class} on_change={(logClass) => props.form.didSelectLogClass(logClass)} />
                    </>
                )}
            </Section>
        </>
    )
}
