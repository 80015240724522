import styled from "styled-components"
import {Subtitle2, Subtitle2White} from "../../../Styles/Fonts"

export const MarketingSectionContainer = styled.div`
    padding-top: 15px;
    padding-bottom: 75px;
    padding-left: 100px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    background-color: white;
`

export const MarketingSectionContainerLight = styled(MarketingSectionContainer)`
    background-color: white;
`

export const MarketingSectionLeftContainer = styled.div`
    display: flex;
    flex: 1 1 0px;
    align-items: flex-start;
    flex-direction: column;
    padding: 50px;
`

export const MarketingSectionRightContainer = styled.div`
    display: flex;
    flex: 1 1 0px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 50px;
`

export const MarketingSectionRightContainerLeftPadding = styled(MarketingSectionRightContainer)`
    padding-left: 50px;
`

export const MarketingTitleWhite = styled.p`
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
`

export const MarketingImage = styled.img`
    width: 520px;
    height: 520px;
    border-radius: 12px;
`

export const MarketingSectionSubtitleWhite = styled(Subtitle2White)`
    margin-top: 25px;
`

export const MarketingSectionSubtitleDark = styled(Subtitle2)`
    margin-top: 25px;
`
