import CommodityEventCreate from "./CRUD/CommodityEventCreate"
import CommodityEventDelete from "./CRUD/CommodityEventDelete"
import CommodityEventRead from "./CRUD/CommodityEventRead"
import CommodityEventUpdate from "./CRUD/CommodityEventUpdate"

export default class CommodityEventCRUD {
    create: CommodityEventCreate = new CommodityEventCreate()
    read: CommodityEventRead = new CommodityEventRead()
    update: CommodityEventUpdate = new CommodityEventUpdate()
    delete: CommodityEventDelete = new CommodityEventDelete()
}
