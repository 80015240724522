// https://www.freecodecamp.org/news/how-to-add-localization-to-your-react-app/

import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import {Locale, Localization} from "./Localization"

function resourceSet(locale) {
    return {
        common: require(`./locales/${locale}/common.json`),
        language: require(`./locales/${locale}/language.json`),
        auth: require(`./locales/${locale}/auth.json`),
        navigation: require(`./locales/${locale}/navigation.json`),
        commodityGroupType: require(`./locales/${locale}/commodityGroupType.json`),
        commodityHistory: require(`./locales/${locale}/view/commodityHistory.json`),
    }
}

i18n.use(initReactI18next)
    .init({
        lng: Localization.userLocale,
        fallbackLng: Localization.defaultLocale,
        resources: {
            en: resourceSet(Locale.en),
            es: resourceSet(Locale.es),
            pt: resourceSet(Locale.pt),
        },
        ns: ["common"],
        defaultNS: "common",
    })
    .then()

i18n.languages = [Locale.en, Locale.es, Locale.pt]

export default i18n
