import styled from "styled-components"
import {Subtitle2} from "../../../Styles/Fonts"

export const AboutContainerWeb = styled.div`
    padding-top: 35px;
    padding-left: 200px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px;
`

export const AboutImageWeb = styled.img`
    width: 500px !important;
    padding-right: 50px;
`

export const AboutDescriptionWeb = styled(Subtitle2)`
    margin-top: 20px;
    max-width: 600px;
`

export const AboutItemContainerWeb = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 100px;
`

export const AboutLabelsContainerWeb = styled.div`
    display: flex;
    flex-direction: column;
`
