import styled from "styled-components"

export const StyledMarketingFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 80px;
    padding-left: 200px;
    padding-right: 200px;
    background-color: #000;
`

export const FooterLinksSectionTitleContainer = styled.div`
    margin-bottom: 30px;
`

export const FooterLinksSectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin-top: 50px;
`

export const FooterLinkSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`

export const FooterLink = styled.a`
    font-family: "Lato";
    color: #fff;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 14px;
    text-decoration: none;
    cursor: pointer;
`

export const FooterSocialContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
`

export const FooterCopyrightContainer = styled.div`
    display: flex;
    margin-left: auto;
`

export const StyledSocialLinkImage = styled.img`
    cursor: pointer;
`
