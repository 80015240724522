import {GridState} from "@mui/x-data-grid-premium"
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium"
import {initialColumnsState} from "../commodityGroup/Columns/selectors"

export const saveFilterStateToLocalStorage = (type: string, state?: GridState) => {
    if (state === undefined || state === null) {
        return
    }
    let json = JSON.stringify(state)
    localStorage.setItem(type, json)
}

export const retrieveFilterStateFromLocalStorage = (string: string): GridInitialStatePremium | undefined => {
    const saved = localStorage.getItem(string)
    if (saved === undefined || saved === null) {
        let initialState = initialColumnsState()
        return initialState
    }
    const initialValue = JSON.parse(saved)
    return initialValue || ""
}
