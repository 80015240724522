import {RootState} from "../../../../app/store"
import {GridColDef, GridColumnsInitialState} from "@mui/x-data-grid-premium"
import {columnLabels} from "../../../localization/selectors"
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium"

export const commodityColumns = (state: RootState): GridColDef[] => {
    let types = commodityColumnTypes()
    return types.map((type) => commodityColumnDef(type, state))
}

export enum CommodityColumnID {
    id = "id",
    created = "created",
    system_generated = "system_generated",
    species = "species",
    log_class = "log_class",
    diameter_class = "diameter_class",
    length = "length",
    short = "short",
    long = "long",
    rounded_sed = "rounded_sed",
    avg = "avg",
    volume = "volume",
    weight = "weight",
    diameter_deductions = "diameter_deductions",
    length_deductions = "length_deductions",
    defect_codes = "defect_codes",
    operator_notes = "operator_notes",
    event_summaries = "event_summaries",
    inbound = "inbound",
    row = "row",
    outbound = "outbound",
}

export const commodityColumnTypes = (): CommodityColumnType[] => Object.keys(CommodityColumnID).map((id) => columnType(id as CommodityColumnID))

export const commodityColumnDef = (type: CommodityColumnType, state: RootState): GridColDef => ({
    field: type.id,
    headerName: columnLabels(state).commodityLabel(type.id),
    width: type.width,
    resizable: true,
})

export const initialColumnsState = (): GridInitialStatePremium => {
    let columns: GridColumnsInitialState = {
        columnVisibilityModel: {
            id: true,
        },
    }
    let state: GridInitialStatePremium = {
        columns: columns,
    }
    return state
}

export interface CommodityColumnType {
    id: CommodityColumnID
    width: number
    hide: boolean
}

const columnType = (id: CommodityColumnID): CommodityColumnType => {
    switch (id) {
        case CommodityColumnID.id:
            return {
                id: id,
                width: 350,
                hide: false,
            }
        case CommodityColumnID.created:
            return {
                id: id,
                width: 180,
                hide: false,
            }
        case CommodityColumnID.species:
            return {
                id: id,
                width: 100,
                hide: false,
            }
        case CommodityColumnID.system_generated:
            return {
                id: id,
                width: 100,
                hide: false,
            }
        case CommodityColumnID.length:
            return {
                id: id,
                width: 70,
                hide: false,
            }
        case CommodityColumnID.log_class:
            return {
                id: id,
                width: 80,
                hide: false,
            }
        case CommodityColumnID.diameter_class:
            return {
                id: id,
                width: 100,
                hide: false,
            }
        case CommodityColumnID.short:
            return {
                id: id,
                width: 70,
                hide: false,
            }
        case CommodityColumnID.rounded_sed:
            return {
                id: id,
                width: 70,
                hide: false,
            }
        case CommodityColumnID.long:
            return {
                id: id,
                width: 70,
                hide: false,
            }
        case CommodityColumnID.avg:
            return {
                id: id,
                width: 70,
                hide: false,
            }
        case CommodityColumnID.volume:
            return {
                id: id,
                width: 70,
                hide: false,
            }
        case CommodityColumnID.weight:
            return {
                id: id,
                width: 80,
                hide: false,
            }
        case CommodityColumnID.inbound:
            return {
                id: id,
                width: 100,
                hide: false,
            }
        case CommodityColumnID.row:
            return {
                id: id,
                width: 100,
                hide: false,
            }
        case CommodityColumnID.outbound:
            return {
                id: id,
                width: 100,
                hide: false,
            }
        case CommodityColumnID.diameter_deductions:
            return {
                id: id,
                width: 200,
                hide: false,
            }
        case CommodityColumnID.length_deductions:
            return {
                id: id,
                width: 200,
                hide: false,
            }
        case CommodityColumnID.defect_codes:
            return {
                id: id,
                width: 200,
                hide: false,
            }
        case CommodityColumnID.operator_notes:
            return {
                id: id,
                width: 250,
                hide: false,
            }
        case CommodityColumnID.event_summaries:
            return {
                id: id,
                width: 200,
                hide: true,
            }
    }
}
