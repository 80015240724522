import {Autocomplete, TextField} from "@mui/material"
import {BusinessType} from "@common/types/business/businessTypes"
import {Business, LinkedBusiness} from "../../../../../types/Business"
import {firebase} from "../../../../../app/store"
import React from "react"
import {AutocompleteInputChangeReason} from "@mui/base/useAutocomplete/useAutocomplete"
import {useAppSelector} from "../../../../../app/hooks"
import {v4 as uuid} from "uuid"
import {businessTypeLabels} from "../../../../../components/localization/selectors"
import InboundForm from "../../../../../components/commodities/commodityGroupForm/Forms/InboundForm"

interface LinkedBusinessSelectorViewProps {
    type: BusinessType // in
    linked: LinkedBusiness[] // in
    link: (bus: LinkedBusiness) => void // out
    unlink: (type: BusinessType) => void // out
}

// Alias
export const LinkedBusinessSelectorView2 = (props: {type: BusinessType; form: InboundForm}) => (
    <LinkedBusinessSelectorView
        key={props.type}
        type={props.type}
        linked={props.form.linked_businesses}
        link={(bus) => props.form.linkBusiness(bus)}
        unlink={(type) => props.form.unLinkBusiness(type)}
    />
)

export const LinkedBusinessSelectorView = (props: LinkedBusinessSelectorViewProps) => {
    const labels = useAppSelector(businessTypeLabels)
    const [queryString, setQueryString] = React.useState("")
    const [businesses, setBusinesses] = React.useState<Business[]>([])

    const textFieldDidChange = (queryString: string) => {
        setQueryString(queryString)

        if (queryString.length === 0) unlinkBusiness()
        if (queryString.length < 3) {
            return
        }

        const querySnapshot = queryString
        firebase.business.searchBusinessesMatchingString(queryString).then((businesses) => {
            // Ignore old search results when query is changed
            if (queryString !== querySnapshot) return

            setBusinesses(businesses)

            // If search returns only the single business, link it immediately
            if (businesses.length === 1) {
                linkBusiness(businesses[0])
            }
        })
    }

    const onInputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        switch (reason) {
            case "clear":
                unlinkBusiness()
                break
            default:
                break
        }
    }

    /**
     * Business picked from dropdown list.
     */
    const onBusinessPicked = (businessName: string | null) => {
        if (businessName === null) return

        const business = businesses.find((business) => business.name === businessName)
        if (business === undefined) return

        linkBusiness(business)
    }

    const getSelectedItem = () => {
        return props.linked.find((link) => link.type === props.type)?.business.name ?? ""
    }

    const linkBusiness = (business: Business) => {
        let link: LinkedBusiness = {
            business: business,
            id: uuid(),
            type: props.type,
        }
        props.link(link)
    }

    const unlinkBusiness = () => {
        setQueryString("")
        setBusinesses([])
        props.unlink(props.type)
    }

    return (
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                onInputChange={onInputChange}
                options={businesses.map((business) => business.name)}
                sx={{width: 300}}
                value={getSelectedItem()}
                onChange={(_event, newValue) => {
                    onBusinessPicked(newValue)
                }}
                renderInput={(params) => (
                    <TextField {...params} value={queryString} onChange={(e) => textFieldDidChange(e.target.value)} label={labels.label(props.type)} />
                )}
            />
        </>
    )
}
