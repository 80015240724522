import {SectionTitle} from "../../Section/Web/styles"
import {qrsProps} from "../../../../../components/marketing/selectors"
import {QRContainerWeb, QRImageWeb, QRsContainerWeb, QRsSectionContainerWeb} from "./styles"
import {QRProps} from "../index"
import {Title5} from "../../../Styles/Fonts"

export const QRsWeb = () => {
    const props = qrsProps()
    return (
        <QRsSectionContainerWeb>
            <SectionTitle>{props.title}</SectionTitle>
            <QRsContainerWeb>
                {props.qrs.map((qr) => (
                    <QRWeb {...qr} />
                ))}
            </QRsContainerWeb>
        </QRsSectionContainerWeb>
    )
}

export const QRWeb = (props: QRProps) => (
    <QRContainerWeb onClick={() => window.open(props.url, "_newtab")}>
        <QRImageWeb src={props.image_url} />
        <Title5>{props.label}</Title5>
    </QRContainerWeb>
)
