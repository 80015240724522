import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupStatus, CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"

export enum CommodityGroupAction {
    Edit,
    Close,
    ReOpen,
    Ready,
    Unready,
    Archive,
    UnArchive,
    Weights,
    Allocations,
    UndoAllocations,
    UndoGeneratedLogs,
    UpdateStock,
    Ok,
}

export default class CommodityGroupActionsOptions {
    actions: CommodityGroupAction[]

    constructor(group: CommodityGroup) {
        this.actions = this.setGroupOptions(group)
        const isGlobalAdmin = store.getState().authentication.isUserGlobalAdmin
        const isReadOnly = CommodityGroupActionsOptions.isReadOnly(group)
        if (
            isGlobalAdmin &&
            !isReadOnly &&
            (group.type === CommodityGroupType.inbound_load || group.type === CommodityGroupType.row || group.type === CommodityGroupType.outbound_load)
        ) {
            this.actions.push(CommodityGroupAction.UndoGeneratedLogs)
        }
    }

    private static isReadOnly(group: CommodityGroup) {
        const member = store.getState().authentication.business_member
        if (!member) {
            return false
        }
        const isShareRecipient = group.created_by_business_id !== member.business_id
        const isMemberReadOnly = member.is_readonly ?? false
        return isMemberReadOnly || isShareRecipient
    }

    private setGroupOptions(group: CommodityGroup): CommodityGroupAction[] {
        const member = store.getState().authentication.business_member
        if (!member) {
            return []
        }

        const isShareRecipient = group.created_by_business_id !== member.business_id
        const isMemberReadOnly = member.is_readonly ?? false
        const isReadOnly = isMemberReadOnly || isShareRecipient
        if (isReadOnly) {
            return [CommodityGroupAction.Ok]
        }

        switch (group.type) {
            case CommodityGroupType.inbound_load:
            case CommodityGroupType.outbound_load:
                return this.setLoadOptions(group)
            case CommodityGroupType.row:
                return this.setRowOptions(group)
            case CommodityGroupType.order:
                return this.setOrderOptions(group)
            case CommodityGroupType.vessel:
            case CommodityGroupType.vessel_section:
            case CommodityGroupType.zone:
                return this.setVesselOptions(group)
        }
    }

    private setLoadOptions = (group: CommodityGroup): CommodityGroupAction[] => {
        switch (group.status) {
            case CommodityGroupStatus.Open:
                return [
                    CommodityGroupAction.Edit,
                    CommodityGroupAction.Weights,
                    CommodityGroupAction.Close,
                    CommodityGroupAction.Archive,
                    CommodityGroupAction.UpdateStock,
                ]
            case CommodityGroupStatus.Closed:
                return [CommodityGroupAction.ReOpen, CommodityGroupAction.Edit, CommodityGroupAction.Archive, CommodityGroupAction.UpdateStock]
            case CommodityGroupStatus.Archived:
                return [CommodityGroupAction.UnArchive]
            default:
                return []
        }
    }

    private setRowOptions = (group: CommodityGroup): CommodityGroupAction[] => {
        switch (group.status) {
            case CommodityGroupStatus.Open:
                return [CommodityGroupAction.Ready, CommodityGroupAction.Edit, CommodityGroupAction.Archive, CommodityGroupAction.UpdateStock]
            case CommodityGroupStatus.Closed:
                return [
                    CommodityGroupAction.ReOpen,
                    CommodityGroupAction.Edit,
                    CommodityGroupAction.Archive,
                    CommodityGroupAction.Allocations,
                    CommodityGroupAction.UndoAllocations,
                    CommodityGroupAction.UpdateStock,
                ]
            case CommodityGroupStatus.Ready:
                return [
                    CommodityGroupAction.Unready,
                    CommodityGroupAction.Edit,
                    CommodityGroupAction.Archive,
                    CommodityGroupAction.Allocations,
                    CommodityGroupAction.UndoAllocations,
                    CommodityGroupAction.UpdateStock,
                ]
            case CommodityGroupStatus.Archived:
                return [CommodityGroupAction.UnArchive]
        }
    }

    private setOutboundOptions = (group: CommodityGroup): CommodityGroupAction[] => {
        switch (group.status) {
            case CommodityGroupStatus.Open:
                return [
                    CommodityGroupAction.Edit,
                    CommodityGroupAction.Weights,
                    CommodityGroupAction.Close,
                    CommodityGroupAction.Archive,
                    CommodityGroupAction.UpdateStock,
                ]
            case CommodityGroupStatus.Closed:
                return [CommodityGroupAction.ReOpen, CommodityGroupAction.Edit, CommodityGroupAction.Archive, CommodityGroupAction.UpdateStock]
            case CommodityGroupStatus.Archived:
                return [CommodityGroupAction.UnArchive]
            default:
                return []
        }
    }

    private setOrderOptions = (group: CommodityGroup): CommodityGroupAction[] => {
        switch (group.status) {
            case CommodityGroupStatus.Open:
                return [CommodityGroupAction.Edit, CommodityGroupAction.Close, CommodityGroupAction.Archive, CommodityGroupAction.UpdateStock]
            case CommodityGroupStatus.Closed:
                return [CommodityGroupAction.ReOpen, CommodityGroupAction.Edit, CommodityGroupAction.Archive, CommodityGroupAction.UpdateStock]
            case CommodityGroupStatus.Archived:
                return [CommodityGroupAction.UnArchive]
            default:
                return []
        }
    }

    private setVesselOptions = (group: CommodityGroup): CommodityGroupAction[] => {
        switch (group.status) {
            case CommodityGroupStatus.Open:
                return [CommodityGroupAction.Edit, CommodityGroupAction.Archive]
            case CommodityGroupStatus.Archived:
                return [CommodityGroupAction.UnArchive]
            default:
                return []
        }
    }
}
