enum FIRCollectionPaths {
    BUSINESS = "businesses_v2",
    BUSINESS_MEMBERS_V2 = "business_members_v2",
    BUSINESS_SETTINGS = "business_settings",
    BUSINESS_INVITES = "invites",
    BUSINESS_FORESTS = "forests",
    BIG_QUERY_COMMODITIES = "big_query_commodities",
    BIG_QUERY_COMMODITY_GROUPS = "big_query_commodity_groups",
    BIG_QUERY_COMMODITY_EVENTS = "big_query_commodity_events",
    COMMODITIES = "commodities",
    COMMODITY_GROUPS = "commodity_groups",
    COMMODITY_GROUP_STOCK_UPDATE = "commodity_group_stock_update",
    COMMODITY_EVENTS = "commodity_events",
    USER = "users_v2",
    USER_SETTINGS = "user_settings_v2",
    SERVICES_STATE = "services_state",
    GLOBAL_ADMINS = "global_admins",
    BACKUP_COLLECTIONS = "backup_collections",
}

interface BusinessMember {
    business_id: string
    email: string
}

// Extends enum
namespace FIRCollectionPaths {
    export function businessMember(member: BusinessMember) {
        return `${FIRCollectionPaths.BUSINESS}/${member.business_id}/${FIRCollectionPaths.BUSINESS_MEMBERS_V2}/${member.email}`
    }

    export function businessInviteCollection(businessId: string) {
        return `${FIRCollectionPaths.BUSINESS}/${businessId}/${FIRCollectionPaths.BUSINESS_INVITES}`
    }

    export function businessInvite(businessId: string, invitationEmail: string) {
        return `${businessInviteCollection(businessId)}/${invitationEmail}`
    }

    export function businessForests(businessId: string) {
        return `${FIRCollectionPaths.BUSINESS}/${businessId}/${FIRCollectionPaths.BUSINESS_FORESTS}`
    }

    export function businessForest(businessId: string, forestId: string) {
        return `${businessForests(businessId)}/${forestId}`
    }
}

export default FIRCollectionPaths
