import {Section} from "./sfc/Section"
import ZoneForm from "../../../../components/commodities/commodityGroupForm/Forms/ZoneForm"
import {FormTextField} from "./sfc/FormTextFieldView"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export const ZoneFields = (props: ZoneFormProps) => (
    <Section>
        <FormTextField
            value={props.form.first_id}
            label={props.form.id_labels.firstId(CommodityGroupType.zone)}
            onChange={(id) => props.form.didEditFirstId(id)}
        />
    </Section>
)

export interface ZoneFormProps {
    form: ZoneForm
}
