import {Locale} from "../../../i18n/Localization"
import {CommodityGroupStatus} from "@common/types/commodity/commodityGroup"

export class StatusLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    label = (type: CommodityGroupStatus): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupStatus.Open:
                        return "Created"
                    case CommodityGroupStatus.Ready:
                        return "Ready"
                    case CommodityGroupStatus.Closed:
                        return "Closed"
                    case CommodityGroupStatus.Archived:
                        return "Archived"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupStatus.Open:
                        return "Abertura"
                    case CommodityGroupStatus.Ready:
                        return "Pronta"
                    case CommodityGroupStatus.Closed:
                        return "Fechado"
                    case CommodityGroupStatus.Archived:
                        return "Arquivado"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupStatus.Open:
                        return "Apertura"
                    case CommodityGroupStatus.Ready:
                        return "Pronto"
                    case CommodityGroupStatus.Closed:
                        return "Cerrado"
                    case CommodityGroupStatus.Archived:
                        return "Arquivado"
                }
        }
    }

    statusLabel = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Status"
            case Locale.pt:
            case Locale.es:
                return "Estado"
        }
    }
}
