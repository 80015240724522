import {Timestamp} from "firebase/firestore"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export interface CommodityGroupDataTableState {
    tab: CommodityGroupType
    query_start_date: Date
    query_end_date: Date
    show_current_stock: boolean
}

export const initialState: CommodityGroupDataTableState = {
    tab: CommodityGroupType.inbound_load,
    query_start_date: Timestamp.fromMillis(Date.now() - 0.3e10).toDate(),
    query_end_date: new Date(new Date(Date.now()).setHours(23, 59, 59, 0)),
    show_current_stock: false,
}
