import styled from "styled-components"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"

export const PickerButtonView = styled(Button)`
    font-family: "Lato-Black" !important;
    font-size: 16px !important;
    text-transform: none !important;
    border: none !important;
    margin-top: 2px !important;
`

export const PaddedCaretImage = styled.img`
    width: 16px !important;
    padding-left: 5px !important;
    padding-top: 3px !important;
`

export const PickerAlertContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const PickerMenuItemContainer = styled(MenuItem)`
    margin-bottom: 5px !important;
`

export const PickerMenuItemFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const PickerAlertMessage = styled.p`
    font-family: "Lato-Black" !important;
    font-size: 11px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #000;
    border-radius: 15px;
    color: #fff;
    margin-left: 10px !important;
`
