import {selectedGroupImages} from "../../../../components/commodities/commodityGroup/selectors/selectors"
import {useAppSelector} from "../../../../app/hooks"
import {ImageList} from "@mui/material"
import {useWindowDimensions} from "../../../../app/Other/UseWindowDimensions"

export const CommodityGroupImagesView = () => {
    const {width} = useWindowDimensions()
    const images = useAppSelector(selectedGroupImages)
    const COLUMNS = 2

    return (
        <ImageList sx={{height: "100%", mb: 0}} cols={COLUMNS} variant="quilted">
            {images.map((image) => (
                <img src={`${image.url_with_bounding_boxes}`} srcSet={`${image.url_with_bounding_boxes}`} width={width / COLUMNS} alt={"img"} loading="lazy" />
            ))}
        </ImageList>
    )
}
