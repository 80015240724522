import {firebase, store} from "../../../../app/store"
import CommodityGroupViewModel from "../../commodityGroups/viewModel/CommodityGroupViewModel"
import {setGroup} from "../../commodityGroup/slice"
import CommodityGroupWeightsFormState from "../../commodityGroups/viewModel/CommodityGroupWeightsFormState"
import {setShowModal} from "../../../modal/slice"
import CommodityGroupWeightsFormHelpers from "./WeightsFormHelpers"
import Logger from "../../../../helpers/Logger"

const log = new Logger("WeightsFormActionsHandler")

export default class WeightsFormActionsHandler {
    setGross = (string: string) => {
        let group = store.getState().commodityGroup.group
        if (group === undefined) {
            return
        }
        let updatedWeightsFormForm = {...group.weights, gross: string}
        return this.updateWeightsForm(group, updatedWeightsFormForm)
    }
    setEmpty = (string: string) => {
        let group = store.getState().commodityGroup.group
        if (group === undefined) {
            return
        }
        let updatedWeightsFormForm = {...group.weights, empty: string}
        return this.updateWeightsForm(group, updatedWeightsFormForm)
    }
    updateWeightsForm = (group: CommodityGroupViewModel, form: CommodityGroupWeightsFormState) => {
        let updated = {...group, weights: form}
        return store.dispatch(setGroup(updated))
    }

    submit() {
        store.dispatch(setShowModal(false))
        let group = store.getState().commodityGroup.group
        if (group === undefined) {
            return
        }
        let updated = CommodityGroupWeightsFormHelpers.getUpdatedCommodityGroupFromWeightsForm(group, group.weights)
        firebase.commodity_group.create.createCommodityGroup(updated).then()
        firebase.api_gateway.create.createInboundWeightsFormTrigger(group.id).then()
        log.d("submit", `groupId: ${group.id}`)
    }
}
