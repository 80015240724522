import {GPSCoordinates} from "../Location"
import {MovementType} from "../MovementType"
import {CommodityLength, CommodityMeasurements, CommodityProperties, CommodityWeight} from "./commodity"

export interface CommodityGroupCoordinates {
    coordinates?: [GPSCoordinates]
    center?: GPSCoordinates
}

export interface CommodityGroupImages {
    offline_image_ids?: string[]
    images?: CommodityGroupImage[]
}

export interface CommodityGroupImage {
    id: string
    url_no_bounding_boxes: string
    url_with_bounding_boxes?: string
    bounding_box_count?: number
}

export enum CommodityGroupStatus {
    Open = "Open",
    Ready = "Ready",
    Closed = "Closed",
    Archived = "Archived",
}

export enum CommodityType {
    Logs = "Logs",
    Lumber = "Lumber",
}

export interface CommodityGroupMeasurementsSummary {
    all_time?: CommodityMeasurements
    current?: CommodityMeasurements
}

export interface CommodityGroupUserEntries {
    identifiers: CommodityGroupIdentifiers
    properties?: CommodityProperties
    movement?: MovementType
    length?: CommodityLength // Meters
    weight?: CommodityWeight // Kg
}

export interface CommodityGroupIdentifiers {
    first_id: string
    second_id?: string
    third_id?: string
}

export interface CommodityGroupTagRange {
    range_start: number
    range_end: number
    missing_tags: number[]
    tag_range_complete: boolean
}

export enum CommodityGroupType {
    inbound_load = "inbound_load",
    row = "row",
    outbound_load = "outbound_load",
    order = "order",
    vessel = "vessel",
    vessel_section = "vessel_section",
    zone = "zone",
}

export namespace CommodityGroupTypeUtils {
    export function isOutboundFamily(type: CommodityGroupType): boolean {
        switch (type) {
            case CommodityGroupType.outbound_load:
            case CommodityGroupType.order:
            case CommodityGroupType.vessel:
            case CommodityGroupType.vessel_section:
                return true
            default:
                return false
        }
    }
}

export interface CommodityGroupCoordinates {
    coordinates?: [GPSCoordinates]
    center?: GPSCoordinates
}

export enum BQMigrationStatus {
    Ready = "Ready",
    Started = "Started",
    Completed = "Completed",
}
