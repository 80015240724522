import {v4 as uuid} from "uuid"
import {ErrorManager} from "../../../components/errorManager"
import {GenericTrigger, MagicLinkTrigger, MagicLinkTriggerType} from "@common/types/triggers/MagicLinkTrigger"
import {doc, setDoc, Timestamp} from "firebase/firestore"
import {db} from "../../db"
import APIGatewayCollectionPaths from "@common/types/collections/EventTriggersCollectionPaths"

export default class APIGatewayCreate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("APIGatewayCreate", error as Error)
    }
    createStockUpdateTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.UPDATE_STOCK_TRIGGERS)
    }
    createInboundWeightsFormTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.INBOUND_WEIGHTS_FORM_TRIGGERS)
    }
    createInboundRowChangeTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.INBOUND_ROW_CHANGE_TRIGGERS)
    }
    createAllocationsTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.ALLOCATIONS_TRIGGERS)
    }
    createUndoAllocationsTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.ALLOCATIONS_UNDO_TRIGGERS)
    }
    createUndoGeneratedLogsTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.UNDO_GENERATED_LOGS_TRIGGERS)
    }
    createEditCommodityTrigger = async (id: string) => {
        return await this.createGenericTrigger(id, APIGatewayCollectionPaths.EDIT_COMMODITY_TRIGGERS)
    }
    createGenericTrigger = async (id: string, path: APIGatewayCollectionPaths) => {
        try {
            let trigger: GenericTrigger = {
                id: id,
                time: Timestamp.now(),
            }
            await setDoc(doc(db, path, trigger.id), trigger)
            return
        } catch (error) {
            this.error(error)
            return undefined
        }
    }
    createMagicLinkTrigger = async (email: string) => {
        try {
            let trigger: MagicLinkTrigger = {
                id: uuid(),
                type: MagicLinkTriggerType.create_web_magic_link_email,
                magic_link_email: email,
            }
            await setDoc(doc(db, APIGatewayCollectionPaths.MAGIC_LINK_TRIGGERS, trigger.id), trigger)
            return
        } catch (error) {
            console.log("ERROR ON MAGIC LINK")
            console.log(error)
            this.error(error)
            return undefined
        }
    }
}
