import CommodityImageDrawing from "./CommodityImageDrawing"
import {useEffect} from "react"
import {Commodity} from "../../../../types/commodity/Commodity"
import {IdentifiableGridPoint} from "@common/types/commodity/commodity"
import {StyledCommodityImageCanvs} from "../../../styles"

export const StaticCommodityImageView = (props: CommodityImageViewProps) => {
    useEffect(() => {
        const drawing = new CommodityImageDrawing()
        drawing.draw(false, props.commodity, props.height, props.perimeter)
    }, [props.height, props.commodity, props.perimeter])

    return <CommodityImageView {...props} />
}

export const CommodityImageView = (props: CommodityImageViewProps) => (
    <StyledCommodityImageCanvs
        id={props.tag + props.commodity.id}
        onClick={props.onClick}
        key={props.commodity.id}
        width={props.height}
        height={props.height}
    />
)

export interface CommodityImageViewProps {
    tag: string
    height: number
    commodity: Commodity
    perimeter: IdentifiableGridPoint[]
    onClick: () => void
}
