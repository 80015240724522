import React from "react"
import {useAppSelector} from "../../app/hooks"
import {queryEndDate, queryStartDate} from "../../components/dataTables/commodityGroup/selectors"
import {DataTableTitleContainer, DataTableTitleRightItems} from "../styles"
import {components} from "../../app/store"
import DatePicker from "./sfc/DatePicker"
import {datelabels} from "../../components/localization/selectors"
import Stack from "@mui/material/Stack"
import {Box} from "@mui/material"

export const DatePickerView = () => {
    const start = useAppSelector(queryStartDate)
    const end = useAppSelector(queryEndDate)
    const labels = useAppSelector(datelabels)

    return (
        <DataTableTitleContainer>
            <DataTableTitleRightItems>
                <Box sx={{mr: 1}}>
                    <Stack direction="row" spacing={1}>
                        <DatePicker label={labels.since() + ":"} date={start} handleChange={components.data_table.setStart} marginRight={1} />
                        <DatePicker label={labels.until() + ":"} date={end} handleChange={components.data_table.setEnd} marginRight={1} />
                    </Stack>
                </Box>
            </DataTableTitleRightItems>
        </DataTableTitleContainer>
    )
}
