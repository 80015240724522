import {store} from "../../../app/store"
import {PerimeterPointUtils, ShortestLongestPointPairs} from "@common/types/PerimeterPointUtils"
import {
    CommodityDiameters,
    CommodityMeasurements,
    GridPoint,
    IdentifiableGridPoint,
    IdentifiablePointDistance,
    Species,
} from "@common/types/commodity/commodity"
import {Commodity} from "../../../types/commodity/Commodity"
import CalculateLogVolume from "@common/common/CalculateLogVolume"

export default class EditCommodityUtils {
    distanceBetweenPoints = (base: GridPoint, point: IdentifiableGridPoint): IdentifiablePointDistance => {
        let left = Math.pow(point.point.x - base.x, 2)
        let right = Math.pow(point.point.y - base.y, 2)
        let distance = Math.pow(left + right, 0.5)
        return {point: point, distance: distance}
    }

    nearestPoint = (compare: GridPoint): IdentifiableGridPoint | undefined => {
        let state = store.getState().editCommodity

        let pointsWithDistances: IdentifiablePointDistance[] = state.perimeter_points.map((point) => {
            let distance = this.distanceBetweenPoints(compare, point)
            return distance
        })
        let sorted = pointsWithDistances.sort((a, b) => a.distance - b.distance)
        let first = sorted[0]
        if (first === undefined) {
            return
        }
        return first.point
    }

    translateCursorPointToImagePoint = (clientX: number, clientY: number, rect: DOMRect): GridPoint => {
        let x = clientX - rect.left
        let y = clientY - rect.top
        return {x: x, y: y}
    }

    normalizedPerimeter = (perimeter: IdentifiableGridPoint[], width: number): GridPoint[] => {
        let points: GridPoint[] = perimeter.map((point) => {
            let gridPoint: GridPoint = {x: point.point.x / width, y: point.point.y / width}
            return gridPoint
        })
        return points
    }

    updateVolumeDiametersFromPoints = (commodity: Commodity, pairs: ShortestLongestPointPairs): Commodity | undefined => {
        let diameters = commodity.measurements?.diameters
        let cmPerWidth = commodity.measurements?.diameters?.cm_per_width
        if (diameters === undefined) {
            return undefined
        }
        if (cmPerWidth === undefined) {
            return undefined
        }
        let reference = Math.max(cmPerWidth, cmPerWidth)
        const small = PerimeterPointUtils.getCentimetersFromPoints(pairs.shortest.first, pairs.shortest.second, reference)
        const large = PerimeterPointUtils.getCentimetersFromPoints(pairs.longest.first, pairs.longest.second, reference)
        let species: Species = commodity.properties?.log?.species ? commodity.properties?.log?.species : Species.Pine
        let volume = new CalculateLogVolume(large, small, species, commodity.measurements.length, commodity.measurements.deductions).volume()
        let average = small + large / 2
        let updatedDiameters: CommodityDiameters = {
            ...diameters,
            short: small,
            long: large,
            average: average,
        }
        let updatedMeasurements: CommodityMeasurements = {
            ...commodity.measurements,
            diameters: updatedDiameters,
            volume: volume,
        }
        let updatedCommodity: Commodity = {
            ...commodity,
            measurements: updatedMeasurements,
        }
        return updatedCommodity
    }
}
