import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {MovementType} from "@common/types/MovementType"
import {CommodityGroupStatus, CommodityGroupType} from "@common/types/commodity/commodityGroup"
import LoadForm from "./LoadForm"
import {LinkedBusiness} from "../../../../types/Business"
import {BusinessType} from "@common/types/business/businessTypes"
import {store} from "../../../../app/store"
import {setOutboundForm} from "../../commodityGroup/slice"
import CommodityUtils from "../../commodityGroups/listeners/CommodityUtils"

export default class OutboundForm extends LoadForm {
    linked_business_types: BusinessType[] = [BusinessType.Subcontractor]
    movement: MovementType = MovementType.atOrigin
    order?: CommodityGroup
    vessel_section?: CommodityGroup
    ready_rows: CommodityGroup[] = []
    open_orders: CommodityGroup[] = []
    open_vessel_sections: CommodityGroup[] = []

    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group, pickerOptions, linkedGroups)
        this.setOrder()
        this.setMovement()
        this.setVesselSection()
        this.setReadyRows()
        this.setOpenOrders()
        this.setOpenVesselSections()
        this.setState()
    }

    setOrder() {
        let groupOrder = this.linkedGroupOfType(CommodityGroupType.order)
        if (groupOrder === undefined) {
            return
        }
        this.order = groupOrder
    }

    setMovement() {
        let movement = this.group.user_entries.movement
        if (movement === undefined) {
            return
        }
        this.movement = movement
    }

    setVesselSection() {
        let vesselSection = this.linkedGroupOfType(CommodityGroupType.vessel_section)
        if (vesselSection === undefined) {
            return
        }
        this.vessel_section = vesselSection
    }

    setReadyRows() {
        this.ready_rows = this.zone_rows.filter((row) => row.status === CommodityGroupStatus.Ready)
        if (this.ready_rows.find((row) => row.id === this.row?.id) !== undefined) {
            return
        }
        this.row = this.ready_rows[0]
    }

    setOpenOrders() {
        this.open_orders = CommodityUtils.openGroupsOfType(this.picker_options, CommodityGroupType.order)
        if (this.order !== undefined) {
            return
        }
        this.order = this.open_orders[0]
    }

    setOpenVesselSections() {
        this.open_vessel_sections = CommodityUtils.openGroupsOfType(this.picker_options, CommodityGroupType.vessel_section)
        if (this.vessel_section !== undefined) {
            return
        }
        this.vessel_section = this.open_vessel_sections[0]
    }

    update() {
        super.update()
        this.addLinkedGroup(this.order)
        this.addLinkedGroup(this.vessel_section)
        this.updateMovement()
    }

    private updateMovement() {
        let userEntries = {...this.group.user_entries, movement: this.movement}
        this.updateUserEntries(userEntries)
    }

    //user interaction
    private setState() {
        store.dispatch(setOutboundForm(this))
    }

    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }

    didEditSecondId(id: string) {
        super.didEditSecondId(id)
        this.setState()
    }

    didEditThirdId(id: string) {
        super.didEditThirdId(id)
        this.setState()
    }

    didSelectLength(length: string) {
        super.didSelectLength(length)
        this.setState()
    }

    didSelectSpecies(species: string) {
        super.didSelectSpecies(species)
        this.setState()
    }

    didSelectLogClass(logClass: string) {
        super.didSelectLogClass(logClass)
        this.setState()
    }

    linkBusiness(newLink: LinkedBusiness) {
        super.linkBusiness(newLink)
        this.setState()
    }

    unLinkBusiness(type: BusinessType) {
        super.unLinkBusiness(type)
        this.setState()
    }

    didSelectMovement(movement: string) {
        this.movement = movement as MovementType
        this.setState()
    }

    didSelectOrder(id: string) {
        let order = this.matchingOption(id)
        if (order === undefined) {
            return
        }
        this.order = order
        this.setState()
    }

    didSelectVesselSection(id: string) {
        let section = this.matchingOption(id)
        if (section === undefined) {
            return
        }
        this.vessel_section = section
        this.setState()
    }

    didSelectRow(id: string) {
        super.didSelectRow(id)
        this.setState()
    }

    didSelectZone(id: string) {
        super.didSelectZone(id)
        this.setReadyRows()
        this.setState()
    }

    toggleEditLogProperties() {
        super.toggleEditLogProperties()
        this.setState()
    }

    toggleAddPartners() {
        super.toggleAddPartners()
        this.setState()
    }
}
