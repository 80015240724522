import styled from "styled-components"

export const NavigationLinkBarContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #61eec1;
    padding: 12px;
    height: 40px;
`

export const NavigationLinkBarCenterContentContainer = styled.div`
    flex: 0 1 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`

export const MobileNavigationLinkBarCenterContentContainer = styled.div`
    text-align: center;
`

export const NavigationLinkBarThemeSelectorContainer = styled.div`
    margin-left: auto;
`
