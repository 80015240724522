import React from "react"
import {ButtonGroupSelectors} from "../sfc/ButtonGroupSelectors"
import {useAppSelector} from "../../../app/hooks"
import {businessMemberCommodityGroupVisibility} from "../../../components/authentication/selectors"
import {selectedTab} from "../../../components/dataTables/commodityGroup/selectors"
import {components} from "../../../app/store"
import {commodityGroupTypeLabels} from "../../../components/localization/selectors"

export const CommodityGroupTypeSelector = () => {
    let labels = useAppSelector(commodityGroupTypeLabels)
    const options = useAppSelector(businessMemberCommodityGroupVisibility)
    const selected = useAppSelector(selectedTab)

    return (
        <ButtonGroupSelectors
            handleClick={components.data_table.setSelectedTab}
            localization={options.map((option) => labels.shortPlural(option))}
            options={options}
            selected={selected}
        />
    )
}
