import React from "react"
import {OuterContainer, VStack} from "../../Styles/styles.js"
import {SText, XLTextBold} from "../../Styles/fonts.js"

import {SectionView} from "../../LegalComponents"

const PrivacyView = ({sections}) => (
    <OuterContainer>
        <VStack>
            <XLTextBold>Privacy Policy </XLTextBold>
            <SText> Effective: December 2nd, 2023 </SText>
            <SText>
                This Privacy Policy explains how TimberEye collects, stores, shares, and protects information in relation to your use of the Services. For
                important definitions, please refer to the opening section of TimberEye’s Terms of Service.
            </SText>
            <SText>
                We take data security and privacy very seriously. Any information we collect and store is exclusively for the purpose of developing, improving
                and refining the Services.
            </SText>
            {sections.map((section) => (
                <SectionView key={section.title} section={section} />
            ))}
        </VStack>
    </OuterContainer>
)

export default PrivacyView
