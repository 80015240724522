import CommodityGroupCreate from "./CRUD/CommodityGroupCreate"
import CommodityGroupRead from "./CRUD/CommodityGroupRead"
import CommodityGroupUpdate from "./CRUD/CommodityGroupUpdate"
import CommodityGroupDelete from "./CRUD/CommodityGroupDelete"

export default class CommodityGroupCRUD {
    create: CommodityGroupCreate = new CommodityGroupCreate()
    read: CommodityGroupRead = new CommodityGroupRead()
    update: CommodityGroupUpdate = new CommodityGroupUpdate()
    delete: CommodityGroupDelete = new CommodityGroupDelete()
}
