import {RootState} from "../../../app/store"
import {v4 as uuid} from "uuid"
import {GridPoint, IdentifiableGridPoint} from "@common/types/commodity/commodity"
import CommodityViewModel from "../commodityGroups/viewModel/CommodityViewModel"
import {Commodity} from "../../../types/commodity/Commodity"
import {firebaseStorage} from "../../../firebase/db"
import {ref, getDownloadURL} from "firebase/storage"
import {constants} from "../../../config/constants"
import CommodityImageHelper from "@common/common/CommodityImageHelper"

export const selectedCommodity = (state: RootState) => {
    let selectedCommodityId = state.editCommodity.selected_commodity_id
    return getSelectedCommodityFromId(selectedCommodityId, state)
}

export const getSelectedCommodityFromId = (id: string | undefined, state: RootState): CommodityViewModel | undefined => {
    let all = state.commodityGroup.commodities
    let first = all.find((commodity) => commodity.id === id)
    return first
}

export const imageWidth = (state: RootState) => {
    let imageWidth = state.editCommodity.image_width_px
    return imageWidth
}

export const formatIdentifiablePerimeterPoints = (points: GridPoint[] | undefined, height: number): IdentifiableGridPoint[] => {
    if (points === undefined) {
        return []
    }
    let identifiables = points.map((point) => {
        let identifiable: IdentifiableGridPoint = {
            id: uuid(),
            point: {x: point.x * height, y: point.y * height},
        }
        return identifiable
    })
    return identifiables
}

export const perimeterPoints = (state: RootState) => {
    return state.editCommodity.perimeter_points
}

export const editingLogShortCm = (state: RootState) => {
    return state.editCommodity.short_cm
}

export const editingLogLongCm = (state: RootState) => {
    return state.editCommodity.long_cm
}

export const editingLogId = (state: RootState): string => {
    const id = state.editCommodity.selected_commodity_id
    if (id === undefined) {
        return ""
    }
    return id
}

export const getCommodityImageUrlFromServer = async (commodity: Commodity): Promise<string | undefined> => {
    if (constants.USE_FIREBASE_EMULATORS) {
        return commodity.url?.toString()
    }
    const unstableUrl = commodity.url
    if (unstableUrl === undefined) {
        return
    }

    const fileName = CommodityImageHelper.getFilename(commodity.id, unstableUrl)
    const storageRef = ref(firebaseStorage, `images/${fileName}`)
    const downloadURL = await getDownloadURL(storageRef)
    return downloadURL
}
