import {MobileNavigationLinkBarCenterContentContainer} from "../Components/styles"
import {NavigationLinkBarCenterContent} from "../Components/centerContent"
import {MobileNavigationLinkBarContentContainer} from "./styles"

export const MobileNavigationLinkBar = () => (
    <MobileNavigationLinkBarContentContainer>
        <MobileNavigationLinkBarCenterContent />
    </MobileNavigationLinkBarContentContainer>
)

export const MobileNavigationLinkBarCenterContent = () => (
    <MobileNavigationLinkBarCenterContentContainer>
        <NavigationLinkBarCenterContent />
    </MobileNavigationLinkBarCenterContentContainer>
)
