import BusinessDao from "./Business/BusinessDao"
import BusinessMembersCRUD from "./Business/BusinessMembers"
import CommodityCRUD from "./Commodity/Commodity"
import CommodityEventCRUD from "./Commodity/CommodityEvent"
import CommodityGroupCRUD from "./Commodity/CommodityGroup"
import APIGatewayCRUD from "./ApiGateway"
import GlobalAdmins from "./GlobalAdmins"
import BusinessSettingsDao from "./Business/BusinessSettingsDao"
import UserDao from "./User/UserDao"
import BusinessInviteDao from "./Business/BusinessInviteDao"
import UserSettingsDao from "./User/UserSettingsDao"
import BusinessForestDao from "./Business/BusinessForestDao"

export default class FirebaseCRUD {
    api_gateway: APIGatewayCRUD = new APIGatewayCRUD()
    business = new BusinessDao()
    businessSettings = new BusinessSettingsDao()
    businessInvite = new BusinessInviteDao()
    businessForests = new BusinessForestDao()
    business_members: BusinessMembersCRUD = new BusinessMembersCRUD()
    commodity: CommodityCRUD = new CommodityCRUD()
    commodity_event: CommodityEventCRUD = new CommodityEventCRUD()
    commodity_group: CommodityGroupCRUD = new CommodityGroupCRUD()
    settings = new UserSettingsDao()
    user = new UserDao()
    global_admins = new GlobalAdmins()
}
