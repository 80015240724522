import {useMediaQuery} from "react-responsive"
import {WebNavigationLinkBar} from "./Web"
import {MobileNavigationLinkBar} from "./Mobile"
import {Constants} from "../../../../config/constants"

export const NavigationLinkBar = () => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <WebNavigationLinkBar />}
            {isMobile && <MobileNavigationLinkBar />}
        </>
    )
}
