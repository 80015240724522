import BusinessMembersCreate from "./CRUD/BusinessMembersCreate"
import BusinessMembersRead from "./CRUD/BusinessMembersRead"
import BusinessMembersUpdate from "./CRUD/BusinessMembersUpdate"
import BusinessMembersDelete from "./CRUD/BusinessMembersDelete"

export default class BusinessMembersCRUD {
    create: BusinessMembersCreate = new BusinessMembersCreate()
    read: BusinessMembersRead = new BusinessMembersRead()
    update: BusinessMembersUpdate = new BusinessMembersUpdate()
    delete: BusinessMembersDelete = new BusinessMembersDelete()
}
