import {RootState} from "../../../../app/store"
import {GridColDef, GridColumnsInitialState} from "@mui/x-data-grid-premium"
import {selectedTab} from "../selectors"
import {columnLabels} from "../../../localization/selectors"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {GridInitialStatePremium} from "@mui/x-data-grid-premium/models/gridStatePremium"

export const commodityGroupColumns = (state: RootState): GridColDef[] => {
    let groupTypes = groupColumnTypes(state)
    let columns = groupTypes.map((type) => commodityGroupColumnDef(type, state))
    return columns
}

export const groupColumnTypes = (state: RootState): CommodityGroupColumnType[] => {
    let selectedType = selectedTab(state)
    let types = allColumnTypes()
    let groupColumnTypes = types.filter((type) => type.types.includes(selectedType))
    return groupColumnTypes
}

export const initialColumnsState = (): GridInitialStatePremium => {
    let columns: GridColumnsInitialState = {
        columnVisibilityModel: {
            business: true,
            second_id: false,
            third_id: false,
            ready_date: false,
            closed_date: false,
            archived_date: false,
            created_date: false,
            status: false,
            zone: false,
            row: false,
            order: false,
            vessel: false,
            hold: false,
            movement: false,
            log_class: false,
        },
    }
    let state: GridInitialStatePremium = {
        columns: columns,
    }
    return state
}

export const commodityGroupColumnDef = (type: CommodityGroupColumnType, state: RootState): GridColDef => ({
    field: type.id,
    headerName: columnLabels(state).commodityGroupLabel(type.id),
    width: type.width,
    resizable: true,
})

export interface CommodityGroupColumnType {
    types: CommodityGroupType[]
    id: CommodityGroupColumnID
    width: number
    hide: boolean
}

export enum CommodityGroupColumnID {
    business = "business",
    first_id = "first_id",
    zone = "zone",
    row = "row",
    order = "order",
    vessel = "vessel",
    hold = "hold",
    second_id = "second_id",
    third_id = "third_id",
    created_date = "created_date",
    ready_date = "ready_date",
    closed_date = "closed_date",
    archived_date = "archived_date",
    status = "status",
    count = "count",
    volume = "volume",
    scan_weight = "scan_weight",
    weight = "weight",
    gross = "gross",
    empty = "empty",
    density = "density",
    sed = "sed",
    length = "length",
    species = "species",
    log_class = "log_class",
    movement = "movement",
    audited = "audited",
    forester = "forester",
    forest = "forest",
    logger = "logger",
    trucker = "trucker",
    vision_count = "vision_count",
}

export const allColumnTypes = (): CommodityGroupColumnType[] => Object.keys(CommodityGroupColumnID).map((id) => columnType(id as CommodityGroupColumnID))

const columnType = (id: CommodityGroupColumnID): CommodityGroupColumnType => {
    switch (id) {
        case CommodityGroupColumnID.business:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.row, CommodityGroupType.outbound_load],
                width: 150,
                hide: false,
            }
        case CommodityGroupColumnID.vision_count:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.forester:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.forest:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.logger:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.trucker:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.second_id:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.third_id:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.ready_date:
            return {
                id: id,
                types: [CommodityGroupType.row],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.closed_date:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.archived_date:
            return {
                id: id,
                types: [
                    CommodityGroupType.inbound_load,
                    CommodityGroupType.outbound_load,
                    CommodityGroupType.row,
                    CommodityGroupType.order,
                    CommodityGroupType.zone,
                    CommodityGroupType.vessel,
                    CommodityGroupType.vessel_section,
                ],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.length:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 120,
                hide: true,
            }
        case CommodityGroupColumnID.species:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 120,
                hide: true,
            }
        case CommodityGroupColumnID.log_class:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 120,
                hide: true,
            }
        case CommodityGroupColumnID.movement:
            return {
                id: id,
                types: [CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 120,
                hide: true,
            }
        case CommodityGroupColumnID.created_date:
            return {
                id: id,
                types: [
                    CommodityGroupType.inbound_load,
                    CommodityGroupType.outbound_load,
                    CommodityGroupType.row,
                    CommodityGroupType.order,
                    CommodityGroupType.zone,
                    CommodityGroupType.vessel,
                    CommodityGroupType.vessel_section,
                ],
                width: 200,
                hide: true,
            }
        case CommodityGroupColumnID.status:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 120,
                hide: true,
            }
        case CommodityGroupColumnID.zone:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.row:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load],
                width: 150,
                hide: true,
            }
        case CommodityGroupColumnID.order:
            return {
                id: id,
                types: [CommodityGroupType.outbound_load],
                width: 150,
                hide: false,
            }
        case CommodityGroupColumnID.hold:
            return {
                id: id,
                types: [CommodityGroupType.outbound_load],
                width: 150,
                hide: false,
            }
        case CommodityGroupColumnID.count:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.sed:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.scan_weight:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.weight:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.gross:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.empty:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.volume:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.density:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.outbound_load, CommodityGroupType.row],
                width: 100,
                hide: false,
            }
        case CommodityGroupColumnID.first_id:
            return {
                id: id,
                types: [
                    CommodityGroupType.inbound_load,
                    CommodityGroupType.outbound_load,
                    CommodityGroupType.row,
                    CommodityGroupType.order,
                    CommodityGroupType.zone,
                    CommodityGroupType.vessel,
                    CommodityGroupType.vessel_section,
                ],
                width: 150,
                hide: false,
            }
        case CommodityGroupColumnID.audited:
            return {
                id: id,
                types: [CommodityGroupType.inbound_load, CommodityGroupType.row],
                width: 150,
                hide: false,
            }
        case CommodityGroupColumnID.vessel:
            return {
                id: id,
                types: [CommodityGroupType.outbound_load, CommodityGroupType.order],
                width: 150,
                hide: false,
            }
    }
}
