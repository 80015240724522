import {LogScale} from "@common/types/LogScales"
import {Locale} from "../../../i18n/Localization"

export class VolumeLabel {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    label = (scale: LogScale): string => {
        switch (this.language) {
            case Locale.en:
                switch (scale) {
                    case LogScale.Doyle:
                    case LogScale.International:
                    case LogScale.Scribner:
                        return "BF"
                    case LogScale.JAS:
                    case LogScale.Huber:
                    case LogScale.CylinderShort:
                    case LogScale.CylinderAverage:
                        return "CBM"
                }
                break
            case Locale.pt:
                switch (scale) {
                    case LogScale.Doyle:
                    case LogScale.International:
                    case LogScale.Scribner:
                        return "BF"
                    case LogScale.JAS:
                    case LogScale.Huber:
                    case LogScale.CylinderShort:
                    case LogScale.CylinderAverage:
                        return "MCB"
                }
                break
            case Locale.es:
                switch (scale) {
                    case LogScale.Doyle:
                    case LogScale.International:
                    case LogScale.Scribner:
                        return "BF"
                    case LogScale.JAS:
                    case LogScale.Huber:
                    case LogScale.CylinderShort:
                    case LogScale.CylinderAverage:
                        return "MCB"
                }
        }
    }
}
