import {deleteField, doc, updateDoc} from "firebase/firestore"
import {db} from "../../db"
import {GridPoint} from "@common/types/commodity/commodity"
import {ErrorManager} from "../../../components/errorManager"

export default class CommodityUpdate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityUpdate", error as Error)
    }
    updateLogPointsWithSegmentationResultAndDeleteKnotPoints = async (
        id: string,
        perimeter: GridPoint[],
        shortest: GridPoint[],
        longest: GridPoint[],
    ): Promise<void> => {
        try {
            const logPointsRef = doc(db, "log_points", id)
            const pointUpdate = {
                perimeter_points: perimeter,
                "log_points.mid_points.first": shortest,
                "log_points.mid_points.second": longest,
                largest_knot_points: deleteField(),
            }
            await updateDoc(logPointsRef, pointUpdate)
            return
        } catch (error) {
            this.error(error)
            return
        }
    }
}
