import styled from "styled-components"
import {Font, FontSize} from "./Generic"

//
// Regular
//

export const Label1 = styled.p`
    font-family: ${Font.Regular};
    font-size: ${FontSize._1};
    display: inline-block;
`

export const Label2 = styled(Label1)`
    font-size: ${FontSize._2};
`
export const Label3 = styled(Label1)`
    font-size: ${FontSize._3};
`
export const Label4 = styled(Label1)`
    font-size: ${FontSize._4};
`
export const Label5 = styled(Label1)`
    font-size: ${FontSize._5};
`

export const Label6 = styled(Label1)`
    font-size: ${FontSize._6};
`

//
// Bold
//

export const LabelBold1 = styled(Label1)`
    font-family: ${Font.Bold};
`
export const LabelBold2 = styled(Label2)`
    font-family: ${Font.Bold};
`
export const LabelBold3 = styled(Label3)`
    font-family: ${Font.Bold};
`
export const LabelBold4 = styled(Label4)`
    font-family: ${Font.Bold};
`
export const LabelBold5 = styled(Label5)`
    font-family: ${Font.Bold};
`

//
// Black
//

export const LabelBlack1 = styled(Label1)`
    font-family: ${Font.Black};
`
export const LabelBlack2 = styled(Label2)`
    font-family: ${Font.Black};
`
export const LabelBlack3 = styled(Label3)`
    font-family: ${Font.Black};
`
export const LabelBlack4 = styled(Label4)`
    font-family: ${Font.Black};
`
export const LabelBlack5 = styled(Label5)`
    font-family: ${Font.Black};
`
