import {MarketingSectionProps} from "../Web"
import {MobileMarketingImage} from "../../MarketingCover/Mobile/styles"
import {MobileSectionContainer, MobileSectionSubtitle, MobileSectionTitle} from "./styles"

const MobileSection = (props: MarketingSectionProps) => (
    <MobileSectionContainer>
        <MobileSectionTitle>{props.title}</MobileSectionTitle>
        <MobileSectionSubtitle>{props.subtitle}</MobileSectionSubtitle>
        <MobileMarketingImage src={props.url} />
    </MobileSectionContainer>
)

export default MobileSection
