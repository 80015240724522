import styled from "styled-components"

export const GreenButton = styled.a`
    width: 180px;
    margin-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 22px;
    background-color: #00ef0f;
    border: none;
    color: #fff;
    font-family: "Lato-Bold";
    border-radius: 8px;

    &:hover {
        text-decoration: none;
        color: #fff;
    }
`

export const VStack = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const OuterContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 100px;
    margin-left: 200px;
    margin-right: 200px;
    background-color: #fff !important;

    @media (max-width: 768px) {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 25px;
        margin-right: 25px;
    }
`

export const LandingRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
        margin-right: 50px;
    }
`

export const FullScreenFlexView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
`

export const Spacer10 = styled.div`
    width: 10px;
    height: 10px;
`

export const Spacer30 = styled.div`
    width: 30px;
    height: 30px;
`
