import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import CommodityGroupActionsOptions, {CommodityGroupAction} from "./CommodityGroupActionsOptions"
import CommodityGroupWeightsFormState from "./CommodityGroupWeightsFormState"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export default class CommodityGroupViewModel {
    id: string
    actions: CommodityGroupAction[]
    group: CommodityGroup
    business_name?: string
    linked_groups?: CommodityGroup[]
    weights: CommodityGroupWeightsFormState
    row?: CommodityGroup
    zone?: CommodityGroup
    order?: CommodityGroup
    vessel?: CommodityGroup
    vessel_section?: CommodityGroup

    constructor(group: CommodityGroup, businessName?: string, linked_groups?: CommodityGroup[], vessel?: CommodityGroup) {
        this.id = group.id
        this.group = group
        this.business_name = businessName
        this.actions = new CommodityGroupActionsOptions(group).actions
        this.linked_groups = linked_groups
        this.weights = new CommodityGroupWeightsFormState(group)
        if (this.linked_groups === undefined) {
            return
        }
        this.row = this.linked_groups.find((group) => group.type === CommodityGroupType.row)
        this.zone = this.linked_groups.find((group) => group.type === CommodityGroupType.zone)
        this.order = this.linked_groups.find((group) => group.type === CommodityGroupType.order)
        this.vessel = vessel
        this.vessel_section = this.linked_groups.find((group) => group.type === CommodityGroupType.vessel_section)
    }
}
