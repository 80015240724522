import React from "react"
import {MenuItem, TextField} from "@mui/material"
import {LogClass} from "@common/types/commodity/commodity"

export const LogClassSelectorView = (props: LogClassSelectorProps) => {
    return (
        <TextField
            id={"Log-Class"}
            error={props.value === undefined}
            select
            sx={{marginInlineStart: 0, marginInlineEnd: 0, mb: 2}}
            label={"Log Class"}
            value={props.value}
            onChange={(e) => props.on_change(e.target.value)}
        >
            {Object.keys(LogClass).map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    )
}

interface LogClassSelectorProps {
    value?: LogClass
    on_change: (logClass: string) => void
}
