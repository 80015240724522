import React from "react"
import TermsView from "../View/TermsView"

const Terms = () => {
    let sectionOne = SectionOne()
    let sections = [sectionOne]

    return <TermsView sections={sections} />
}
export default Terms

const SectionOne = () => {
    let one = {
        title: "Important Definitions",
        subtitle: "",
        listItems: [
            "Business: A Business describes the top-level organization created by a TimberEye Customer. A Business must have a unique name.",
            "Yard: A Yard describes a location managed by a Business. A Yard must have a unique name. A Business may create multiple Yards.",
            "Yard Member: A Yard Member is anyone that joins any existing Business and Yard to use the TimberEye web or mobile application.",
            "Access Rights: Access Rights may be assigned to a “Yard Member” and include “Administrator”, “Manager”, or “Worker”. Administrators have full access to Yard and Business information. Managers and Workers are restricted from deleting certain types of information, and are restricted from viewing certain Business Information. TimberEye reserves the right to modify Access Rights at any time, at its sole discretion.",
            "Customer: A Customer is anyone that creates a new Business using the TimberEye web or mobile applications. A “Customer” is the organization that you represent in creating the new Business, and agreeing to this Contract. If the Business is not set up by someone that is formally affiliated with an organization, the Customer is the individual creating the Business. For example, if you sign up with a personal email address to try the TimberEye application, and create a Business in the process, you are the Customer. If you sign up using a corporate email domain, the organization representing the domain is the customer.",
            "Scaling Logs: Scaling Logs is when TimberEye uses the phone camera to approximate the diameter of the face of a log.",
            "Usage: Usage is the number of logs scaled in a Business in a given time period (e.g. weekly, monthly, annually). Usage represents the cumulative Logs Scaled by all Yard Members and all Yards in a Business.",
            "TimberEye is a technology platform designed to provide quality log-level data for Business and their Yards and Yard Members. The platform, including the website and mobile application, enables Business to create Yards, invite Yard Members, Scale Logs, track inventory, and generate documents (collectively “Services”)",
        ],
    }

    let two = {
        title: "Using TimberEye As A Customer",
        subtitle: "To use the Services as a Customer, you must have full legal capacity to enter into a contract and be at least 18 years old.",
        listItems: [],
    }

    let three = {
        title: "",
        subtitle:
            "When you create your TimberEye account, you have the option to voluntarily add information about your Business. This information may include Business name, address, and contact information. Besides your Business name, you can update, remove, or modify this information at any time.",
        listItems: [],
    }

    let four = {
        title: "",
        subtitle: "You may use the Services to create Yards that your Business manages. You must add at least one Yard, and you may add multiple Yards.",
        listItems: [],
    }

    let five = {
        title: "",
        subtitle:
            "You may add or remove Yard Members to your Yards at any time. When you invite a Yard Member, you will provide their email, and Access Rights. Once a Yard Member is added, their Access Rights cannot be modified. Yard Members will receive an email, and at that point they can access your Business’s account.",
        listItems: [],
    }

    let six = {
        title: "",
        subtitle:
            "Upon creating a Business, you will automatically be enrolled in a trial period with TimberEye. During that period, you will not be billed for any Usage, and a TimberEye representative will attempt to contact you and verify your Business. After we have verified your Business, we will end your trial period, and you may start incurring charges. If we are unable to verify your Business, we may end your trial or restrict your account.",
        listItems: [],
    }

    let seven = {
        title: "",
        subtitle: "You will be billed based on: ",
        listItems: ["Your Usage", "The TimberEye price per Log Scaled"],
    }

    let eight = {
        title: "",
        subtitle:
            "TimberEye offers a standard list price per Log Scaled. You may qualify for a discount relative to this list price. TimberEye reserves the right to modify its standard list price at any time, at its sole discretion. If a discount has been awarded, TimberEye will honor the discount price for the term agreed upon by TimberEye and you.",
        listItems: [],
    }

    let nine = {
        title: "",
        subtitle:
            "Each week, you will receive an email invoice for your Usage. If there is no usage, you will not be invoiced. You will be expected to pay this invoice within 7 days. If an invoice goes unpaid, we may block your account and restrict your access to the Services. We process payments using Stripe. By using the Stripe payment processing services you agree to the Stripe Terms of Service Agreement.",
        listItems: [],
    }

    let ten = {
        title: "Using TimberEye As A Yard Member",
        subtitle: "To use the Services as a Yard Member, you must be at least 13 years old.",
        listItems: [],
    }

    let eleven = {
        title: "",
        subtitle:
            "As a Yard Member, you are able to use the Services, according to your Access Rights, for any Yard in which you are a Yard Member. For example, you may use the Services to create inbound loads, scale logs, and export documents for a Yard where you work.",
        listItems: [],
    }

    let twelve = {
        title: "Representations Disclaimer of Warranties",
        subtitle:
            "EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, THE SERVICES AND ALL RELATED COMPONENTS AND INFORMATION ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CUSTOMER ACKNOWLEDGES THAT WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.",
        listItems: [],
    }

    let thirteen = {
        title: "Limitation of Liability",
        subtitle:
            "OTHER THAN IN CONNECTION WITH A PARTY’S INDEMNIFICATION OBLIGATIONS HEREUNDER, IN NO EVENT WILL EITHER CUSTOMER’S OR TIMBEREYE’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THE CONTRACT (WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY) EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER HEREUNDER IN THE TWELVE (12) MONTHS PRECEDING THE LAST EVENT GIVING RISE TO LIABILITY. THE FOREGOING WILL NOT LIMIT CUSTOMER’S PAYMENT OBLIGATIONS FOR USE OF THE SERVICES. IN NO EVENT WILL EITHER CUSTOMER OR ANY MEMBER OF TIMBEREYE HAVE ANY LIABILITY TO THE OTHER PARTY OR TO ANY THIRD PARTY FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.",
        listItems: [],
    }

    let fourteen = {
        title: "Indemnification",
        subtitle:
            "CUSTOMER WILL AGREE TO DEFEND, REIMBURSE OR COMPENSATE US AND HOLD TIMBEREYE HARMLESS FROM ANY CLAIM OR DEMAND (INCLUDING LEGAL FEES) MADE OR INCURRED BY ANY THIRD PARTY DUE TO OR ARISING OUT OF YOUR BREACH OF THIS AGREEMENT, BREACH OF ANY LAW AND/OR USE OF THE SERVICES.",
        listItems: [],
    }

    let section = {
        subsections: [one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen],
    }

    return section
}
