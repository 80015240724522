import {configureStore} from "@reduxjs/toolkit"
import authenticationReducer from "../components/authentication/slice"
import commodityGroupsReducer from "../components/commodities/commodityGroups/slice"
import dataTableReducer from "../components/dataTables/commodityGroup/slice"
import editCommodityReducer from "../components/commodities/editCommodity/slice"
import commodityGroupReducer from "../components/commodities/commodityGroup/slice"
import marketingReducer from "../components/marketing/slice"
import modalReducer from "../components/modal/slice"
import Components from "../components"
import FirebaseCRUD from "../firebase"

export const components = new Components()
export const firebase = new FirebaseCRUD()
export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        commodityGroup: commodityGroupReducer,
        commodityGroups: commodityGroupsReducer,
        dataTable: dataTableReducer,
        editCommodity: editCommodityReducer,
        modal: modalReducer,
        marketing: marketingReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
