import {Locale} from "../../../i18n/Localization"
import {MeasurementSystem} from "@common/types/commodity/commodity"

export class DiameterLabel {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    label = (type: MeasurementSystem): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case MeasurementSystem.metric:
                        return "CM"
                    case MeasurementSystem.imperial:
                        return "Inches"
                }
                break
            case Locale.pt:
                switch (type) {
                    case MeasurementSystem.metric:
                        return "CM"
                    case MeasurementSystem.imperial:
                        return "Polegadas"
                }
                break
            case Locale.es:
                switch (type) {
                    case MeasurementSystem.metric:
                        return "CM"
                    case MeasurementSystem.imperial:
                        return "Pulgadas"
                }
        }
    }

    short = (): string => {
        switch (this.language) {
            case Locale.en:
                return "D1"
            case Locale.pt:
                return "D1"
            case Locale.es:
                return "D1"
        }
    }

    long = (): string => {
        switch (this.language) {
            case Locale.en:
                return "D2"
            case Locale.pt:
                return "D2"
            case Locale.es:
                return "D2"
        }
    }
}
