import {RootState} from "../../app/store"
import CommodityGroupTypeLabels from "./labels/CommodityGroupTypeLabels"
import {locale, logScale, measurementSystem} from "../authentication/selectors"
import CommodityGroupIDLabels from "./labels/CommodityGroupIDLabels"
import DateLabel from "./labels/DateLabel"
import {ColumnLabels} from "./labels/ColumnLabels"
import {selectedTab} from "../dataTables/commodityGroup/selectors"
import BusinessTypeLabels from "./labels/BusinessTypeLabels"
import {ShowCurrentStockLabels} from "./labels/ShowCurrentStockLabels"
import {CommodityGroupDetailLabels} from "./labels/CommodityGroupDetailLabels"
import ActionTypeLabels from "./labels/ActionTypeLabels"
import {WeightLabel} from "./labels/WeightLabel"
import {WeightUnits} from "@common/types/WeightsUnits"

export const commodityGroupTypeLabels = (state: RootState): CommodityGroupTypeLabels => {
    let lang = locale(state)
    return new CommodityGroupTypeLabels(lang)
}

export const commodityGroupIDLabels = (state: RootState): CommodityGroupIDLabels => {
    let lang = locale(state)
    return new CommodityGroupIDLabels(lang)
}

export const actionLabels = (state: RootState): ActionTypeLabels => {
    let lang = locale(state)
    return new ActionTypeLabels(lang)
}

export const datelabels = (state: RootState): DateLabel => {
    let lang = locale(state)
    return new DateLabel(lang)
}

export const weightLabels = (state: RootState): WeightLabel => {
    let lang = locale(state)
    let system = measurementSystem(state)
    const units = state.authentication.business_settings?.scale_weight_unit ?? WeightUnits.kilograms
    return new WeightLabel(lang, system, units)
}

export const businessTypeLabels = (state: RootState): BusinessTypeLabels => {
    let lang = locale(state)
    return new BusinessTypeLabels(lang)
}

export const currentStockLabels = (state: RootState): ShowCurrentStockLabels => {
    let lang = locale(state)
    return new ShowCurrentStockLabels(lang)
}

export const columnLabels = (state: RootState): ColumnLabels => {
    let lang = locale(state)
    let type = selectedTab(state)
    let system = measurementSystem(state)
    let scale = logScale(state)
    return new ColumnLabels(lang, type, system, scale)
}

export const commodityGroupDetailLabels = (state: RootState): CommodityGroupDetailLabels => {
    let lang = locale(state)
    return new CommodityGroupDetailLabels(lang)
}
