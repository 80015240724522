import React, {useState} from "react"
import {NavAddButton, NavLink, StyledNavbar} from "../styles"
import {DatePickerView} from "./DatePickerView"
import Navbar from "react-bootstrap/Navbar"
import Offcanvas from "react-bootstrap/Offcanvas"
import {CommodityGroupTypeSelector} from "./selectors/CommodityGroupTypeSelector"
import {LanguageSelector} from "./selectors/LanguageSelector"
import {NavbarLogo} from "./sfc/NavbarLogo"
import {components, store} from "../../app/store"
import {ReportsButton} from "./sfc/ReportsButton"
import {StockPerspectiveRadioView} from "./selectors/StockPerspectiveRadioView"
import {ChangeBusinessNavLink} from "./sfc/ChangeBusinessNavLink"
import Nav from "react-bootstrap/Nav"
import {useAppSelector} from "../../app/hooks"
import {setModalType, setShowModal} from "../../components/modal/slice"
import {ModalType} from "../../components/modal/state"
import {isUserReadonly} from "../../components/authentication/selectors"
import {useTranslation} from "react-i18next"

export const NavbarView = () => {
    const {t} = useTranslation("navigation")
    const [tAuth] = useTranslation("auth")
    const isReadonly = useAppSelector(isUserReadonly)
    const [showOffcanvasMenu, setShowOffcanvasMenu] = useState(false)

    const onMenuItemSelected = (eventKey: string | null) => {
        // Required to hide Offcanvas component before showing modal dialog.
        // Otherwise, by unknown reason, we got 100% processor load and our dialog is not working. ¯\_(ツ)_/¯
        setShowOffcanvasMenu(false)

        switch (eventKey) {
            case ModalType.Connections:
            case ModalType.Forests:
            case ModalType.EditBusiness:
            case ModalType.CommodityHistory:
                store.dispatch(setModalType(eventKey))
                store.dispatch(setShowModal(true))
                break
            case "businesses":
                components.authentication.changeBusiness()
                break
            case "signout":
                components.authentication.signOut()
                break
        }
    }

    return (
        <>
            {/* Top Navbar */}

            <StyledNavbar fixed="top">
                <Navbar.Collapse className="justify-content-start">
                    <NavbarLogo onClick={() => setShowOffcanvasMenu(true)} />
                    <CommodityGroupTypeSelector />
                    <LanguageSelector />
                    <StockPerspectiveRadioView />
                    <ReportsButton />
                    <DatePickerView />
                    {!isReadonly && <NavAddButton onClick={() => components.commodity_group_form.userDidTap(undefined)}>{"+"}</NavAddButton>}
                </Navbar.Collapse>
            </StyledNavbar>

            {/* Left sidebar collapsible menu */}

            <Offcanvas show={showOffcanvasMenu} className="w-auto" onHide={() => setShowOffcanvasMenu(false)}>
                <Offcanvas.Body>
                    <Nav className="flex-column" onSelect={onMenuItemSelected}>
                        {!isReadonly && <NavLink eventKey={ModalType.Connections}>Connections</NavLink>}
                        {!isReadonly && <NavLink eventKey={ModalType.EditBusiness}>Business</NavLink>}
                        <ChangeBusinessNavLink eventKey="businesses" />
                        {!isReadonly && <NavLink eventKey={ModalType.Forests}>{t("forests")}</NavLink>}
                        <NavLink eventKey={ModalType.CommodityHistory}>{t("log_history")}</NavLink>
                        <NavLink eventKey="signout">{tAuth("sign_out")}</NavLink>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
