import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {initialState} from "./state"
import CommodityGroupViewModel from "./viewModel/CommodityGroupViewModel"

export const slice = createSlice({
    name: "commodityGroups",
    initialState,
    reducers: {
        setGroups: (state, action: PayloadAction<CommodityGroupViewModel[]>) => {
            state.groups = action.payload
        },
        setCommodityGroupsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    },
})

export const {setGroups, setCommodityGroupsLoading} = slice.actions
export default slice.reducer
