import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {FullscreenModalType, initialState, ModalType} from "./state"
import {CommodityGroupImage} from "@common/types/commodity/commodityGroup"

export const slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setLoadImages: (state, action: PayloadAction<CommodityGroupImage[]>) => {
            state.images = action.payload
        },
        setShowModal: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload
        },
        setFullscreenModalType: (state, action: PayloadAction<FullscreenModalType>) => {
            state.full_screen_modal_type = action.payload
        },
        setShowFullscreenModal: (state, action: PayloadAction<boolean>) => {
            state.show_fullscreen = action.payload
        },
        setModalType: (state, action: PayloadAction<ModalType>) => {
            switch (action.payload) {
                case ModalType.CommodityGroupForm:
                case ModalType.WeightsForm:
                    state.modal_max_width = "xs"
                    break
                case ModalType.Forests:
                    state.modal_max_width = "md"
                    break
                case ModalType.Connections:
                case ModalType.EditCommodity:
                case ModalType.CommodityHistory:
                    state.modal_max_width = "lg"
                    break
            }
            state.modal_type = action.payload
        },
    },
})

export const {setShowModal, setModalType, setLoadImages, setShowFullscreenModal, setFullscreenModalType} = slice.actions
export default slice.reducer
