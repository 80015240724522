import React from "react"
import {BodyContainer, MainView} from "../styles"
import {CommodityGroupDataTableView} from "./tables/CommodityGroupDataTableView"
import {NavbarView} from "../navbar/NavbarView"
import {ModalView} from "../modals/modal"
import {FullScreenDialog} from "../modals/fullscreen"

export const HomeView = () => (
    <MainView>
        <NavbarView />
        <BodyContainer>
            <CommodityGroupDataTableView />
        </BodyContainer>
        <ModalView />
        <FullScreenDialog />
    </MainView>
)
