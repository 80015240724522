import React from "react"
import {FooterSocial} from "../Web"
import {StyledMarketingFooterMobile} from "./styles"

export const MobileFooter = () => {
    return (
        <StyledMarketingFooterMobile>
            <FooterSocial />
        </StyledMarketingFooterMobile>
    )
}
