import CommodityGroupForm from "../../../../components/commodities/commodityGroupForm/Forms/CommodityGroupForm"
import {Section} from "./sfc/Section"
import {FormTextField} from "./sfc/FormTextFieldView"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"

export const VesselFields = (props: VesselFormProps) => (
    <Section>
        <FormTextField
            value={props.form.first_id}
            label={props.form.id_labels.firstId(CommodityGroupType.vessel)}
            onChange={(id) => props.form.didEditFirstId(id)}
        />
    </Section>
)

export interface VesselFormProps {
    form: CommodityGroupForm
}
