import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {deleteField, doc, Timestamp, updateDoc} from "firebase/firestore"
import {db} from "../../../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {CommodityGroupStatus} from "@common/types/commodity/commodityGroup"
import {ErrorManager} from "../../../../components/errorManager"

// const log = new Logger("CommodityGroupUpdate")

export default class CommodityGroupUpdate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("BusinessCreate", error as Error)
    }

    close = async (group: CommodityGroup) => {
        try {
            const ref = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, group.id)
            const update = {
                "times.closed": Timestamp.now(),
                status: CommodityGroupStatus.Closed.toString(),
                last_updated_time: Timestamp.now(),
            }
            await updateDoc(ref, update)
        } catch (error) {
            this.error(error)
        }
    }

    reopen = async (group: CommodityGroup) => {
        try {
            const ref = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, group.id)
            const update = {
                "times.closed": deleteField(),
                status: CommodityGroupStatus.Open.toString(),
                last_updated_time: Timestamp.now(),
            }
            await updateDoc(ref, update)
        } catch (error) {
            this.error(error)
        }
    }

    ready = async (group: CommodityGroup) => {
        try {
            const ref = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, group.id)
            const update = {
                "times.ready": Timestamp.now(),
                status: CommodityGroupStatus.Ready.toString(),
                last_updated_time: Timestamp.now(),
            }
            await updateDoc(ref, update)
        } catch (error) {
            this.error(error)
        }
    }

    unready = async (group: CommodityGroup) => {
        try {
            const ref = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, group.id)
            const update = {
                "times.ready": deleteField(),
                status: CommodityGroupStatus.Open.toString(),
                last_updated_time: Timestamp.now(),
            }
            await updateDoc(ref, update)
        } catch (error) {
            this.error(error)
        }
    }

    archive = async (group: CommodityGroup) => {
        try {
            const ref = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, group.id)
            const update = {
                "times.archived": Timestamp.now(),
                status: CommodityGroupStatus.Archived.toString(),
                last_updated_time: Timestamp.now(),
            }
            await updateDoc(ref, update)
        } catch (error) {
            this.error(error)
        }
    }

    unarchive = async (group: CommodityGroup) => {
        try {
            const ref = doc(db, FIRCollectionPaths.COMMODITY_GROUPS, group.id)
            const update = {
                "times.archived": deleteField(),
                status: CommodityGroupStatus.Open.toString(),
                last_updated_time: Timestamp.now(),
            }
            await updateDoc(ref, update)
        } catch (error) {
            this.error(error)
        }
    }
}
