import {Timestamp} from "firebase/firestore"

export default class DateFormatters {
    static getFormattedDateFromTimestamp = (time?: Timestamp): string => {
        if (time === undefined) {
            return ""
        }
        const date = time.toDate()
        const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        return DateFormatters.getFormattedDateFromDate(newDate)
    }
    static getFormattedTimeFromTimestamp = (time?: Timestamp): string => {
        if (time === undefined) {
            return ""
        }
        const date = time.toDate()
        const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        return DateFormatters.getFormattedTimeFromDate(newDate)
    }

    static getFormattedDateFromDate = (date?: Date): string => {
        if (date === undefined) {
            return ""
        }
        const formattedDate =
            (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
            "/" +
            (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
            "/" +
            date.getFullYear()
        return formattedDate
    }

    static getFormattedTimeFromDate = (date?: Date): string => {
        if (date === undefined) {
            return ""
        }
        return date.toISOString().substring(11, 19)
    }
}
