import * as React from "react"
import {DialogContent, List, ListItemButton} from "@mui/material"
import {useAppSelector} from "../../../../app/hooks"
import {selectedCommodityGroupActions} from "../../../../components/commodities/commodityGroup/selectors/selectors"
import {components} from "../../../../app/store"
import {actionLabels} from "../../../../components/localization/selectors"
import {ActionsLabel} from "../../../styles"

export const ActionsModalView = () => {
    let actions = useAppSelector(selectedCommodityGroupActions)
    let labels = useAppSelector(actionLabels)

    return (
        <DialogContent>
            <List component="nav" aria-label="main mailbox folders">
                {actions.map((action) => (
                    <ListItemButton key={action} onClick={(event) => components.commodity_group.actions.didSelectAction(action)}>
                        {" "}
                        <ActionsLabel>{labels.label(action)}</ActionsLabel>
                    </ListItemButton>
                ))}
            </List>
        </DialogContent>
    )
}
