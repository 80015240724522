import {Section} from "../../CommodityGroup/fields/sfc/Section"
import {useAppSelector} from "../../../../app/hooks"
import {weightLabels} from "../../../../components/localization/selectors"
import {FormTextField} from "../../CommodityGroup/fields/sfc/FormTextFieldView"
import {empty, gross} from "../../../../components/commodities/commodityGroup/selectors/formSelectors"
import {components} from "../../../../app/store"

export const WeightsFormFields = () => {
    let labels = useAppSelector(weightLabels)
    let gr = useAppSelector(gross)
    let emp = useAppSelector(empty)

    return (
        <Section>
            <FormTextField value={gr} label={labels.gross()} onChange={components.commodity_group_form.weights.setGross} />
            <FormTextField value={emp} label={labels.empty()} onChange={components.commodity_group_form.weights.setEmpty} />
        </Section>
    )
}
