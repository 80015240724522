import {UnsubscribeManager, UnsubscribeType} from "../../../unsubscribeManager"
import {firebase, store} from "../../../../app/store"
import {setCommodityGroupsLoading, setGroups} from "../slice"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import CommodityUtils from "./CommodityUtils"
import CommodityGroupViewModel from "../viewModel/CommodityGroupViewModel"
import CommodityGroupsZoneFilters from "../zoneFilters/CommodityGroupsZoneFilters"
import {CommodityGroupCache} from "../../../../firebase/cache/IdCaches"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import ArrayUtils from "@common/utils/ArrayUtils"
import Utils from "@common/utils/Utils"

// const log = new Logger("CommodityGroups")

export default class CommodityGroups extends UnsubscribeManager {
    utils = new CommodityUtils()

    listenForCommodityGroups = () => {
        this.removeAllUnsubscribes()
        store.dispatch(setCommodityGroupsLoading(true))
        let unsubscribe = firebase.commodity_group.read.listenForCommodityGroups(this.commodityGroupsCompletionHandler)
        if (unsubscribe === undefined) {
            return
        }
        this.updateUnsubscribes(UnsubscribeType.commodity_groups, unsubscribe)
        return
    }

    commodityGroupsCompletionHandler = async (groups: CommodityGroup[]) => {
        const commodityGroupCache = new CommodityGroupCache()

        let linkedGroupIds = this.utils.commodityGroupsLinkedGroupIds(groups)
        let groupIds = groups.map((group) => group.id)
        let unique = ArrayUtils.distinct(linkedGroupIds)
        let linkedIds = unique.concat(groupIds)
        let businessIds = groups.map((group) => group.created_by_business_id)
        let linkedGroups = await firebase.commodity_group.read.getCommodityGroups(linkedIds)
        let businesses = await firebase.business.getBusinesses(businessIds)
        let all = linkedGroups.concat(groups)

        let models: CommodityGroupViewModel[] = []
        for (const group of all) {
            let groupLinkedGroups = this.utils.commodityGroupLinkedGroups(group, linkedGroups)
            const vessel = await getVessel(commodityGroupCache, group, groupLinkedGroups)
            let groupBusiness = businesses.find((business) => business.id === group.created_by_business_id)
            let model = new CommodityGroupViewModel(group, groupBusiness?.name, groupLinkedGroups, vessel)
            models.push(model)
        }

        let finalModels = groupIds.map((groupId) => models.find((model) => model.id === groupId))
        let noUndefineds = finalModels.filter((model) => model !== undefined) as CommodityGroupViewModel[]
        let filteredForBusinessMemberZoneVisibility = new CommodityGroupsZoneFilters(noUndefineds).models
        store.dispatch(setGroups(filteredForBusinessMemberZoneVisibility))
        await Utils.delay(500)
        store.dispatch(setCommodityGroupsLoading(false))
    }
}

async function getVessel(
    commodityGroupCache: CommodityGroupCache,
    group: CommodityGroup,
    groupLinkedGroups: CommodityGroup[],
): Promise<CommodityGroup | undefined> {
    let order: CommodityGroup | undefined
    if (group.type === CommodityGroupType.order) {
        order = group
    } else {
        order = CommodityUtils.groupOfType(groupLinkedGroups, CommodityGroupType.order)
    }

    if (order) {
        return getVesselFromOrder(commodityGroupCache, order)
    }
}

async function getVesselFromOrder(commodityGroupCache: CommodityGroupCache, order: CommodityGroup): Promise<CommodityGroup | undefined> {
    const linkedGroups = await commodityGroupCache.getAll(order.linked_commodity_group_ids)
    return CommodityUtils.groupOfType(linkedGroups, CommodityGroupType.vessel)
}
