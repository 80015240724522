import {collectionGroup, getDocs, onSnapshot, query, where} from "firebase/firestore"
import {db} from "../../../db"
import {BusinessMember} from "../../../../types/BusinessMember"
import {QueryDocumentSnapshot} from "@firebase/firestore"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {ErrorManager} from "../../../../components/errorManager"
import {User} from "../../../../types/User"

export default class BusinessMembersRead extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("BusinessMembersRead", error as Error)
    }
    getUserBusinessMembers = async (user: User): Promise<BusinessMember[]> => {
        try {
            const snapshot = await getDocs(queryUserBusinessMembers(user))
            return this.completionHandler(snapshot.docs)
        } catch (error) {
            this.error(error)
            return []
        }
    }
    listenForUserBusinessMembers = (user: User, completion: (members: BusinessMember[]) => void) => {
        const unsubscribe = onSnapshot(
            queryUserBusinessMembers(user),
            (querySnapshot) => this.listenerCompletionHandler(querySnapshot.docs, completion),
            (error) => this.error(error),
        )
        return unsubscribe
    }
    private listenerCompletionHandler = (docs: Array<QueryDocumentSnapshot>, completion: (members: BusinessMember[]) => void) => {
        let members = this.completionHandler(docs)
        return completion(members)
    }
    private completionHandler = (docs: Array<QueryDocumentSnapshot>): BusinessMember[] => {
        const members: BusinessMember[] = docs.map((doc) => {
            return doc.data() as BusinessMember
        })
        return members
    }
}

function queryUserBusinessMembers(user: User) {
    return query(collectionGroup(db, FIRCollectionPaths.BUSINESS_MEMBERS_V2), where("email", "==", user.email))
}
