import styled from "styled-components"
import {Subtitle2, Subtitle2White, Title4} from "../../../Styles/Fonts"

export const Title4WhiteMobile = styled(Title4)`
    color: #fff;
    -webkit-text-stroke: 2px white;
    line-height: 60px;
`

export const Title4BlackMobile = styled(Title4)`
    color: #000;
    -webkit-text-stroke: 2px black;
    line-height: 60px;
`

export const LabelContainer = styled.div`
  display: flex,
  flex-direction: column;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  background-color: white;
`

export const LabelItemContainer = styled.div`
    display: flex;
`

export const MobileMarketinSubtitleWhite = styled(Subtitle2White)`
    margin-top: 35px;
    margin-bottom: 35px;
`

export const MobileMarketinSubtitleBlack = styled(Subtitle2)`
    margin-top: 35px;
    margin-bottom: 35px;
`

export const MobileMarketingImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 12px;
`
