import styled from "styled-components"

export const QRsSectionContainerMobile = styled.div`
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`

export const QRsContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 25px !important;
`
