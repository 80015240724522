import {doc, setDoc, Timestamp} from "firebase/firestore"
import {db} from "../../../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {ErrorManager} from "../../../../components/errorManager"

export default class CommodityGroupCreate extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityGroupCreate", error as Error)
    }

    async createCommodityGroup(group: CommodityGroup) {
        try {
            let updated = {...group, last_updated_time: Timestamp.now()}
            await setDoc(doc(db, FIRCollectionPaths.COMMODITY_GROUPS, updated.id), updated)
        } catch (error) {
            this.error(error)
        }
    }
}
