export default class StringUtils {
    static isEmpty(str: string) {
        return str.length === 0
    }

    static isNotEmpty(str: string) {
        return str.length !== 0
    }

    static isNullOrEmpty(str?: string) {
        return str === undefined || str.length === 0
    }

    static isNotNullOrEmpty(str?: string) {
        return !StringUtils.isNullOrEmpty(str)
    }
}
