import {NavigationLinkBarCenterContentContainer, NavigationLinkBarContentContainer} from "../Components/styles"
import {NavigationLinkBarCenterContent} from "../Components/centerContent"
import {NavigationLinkBarThemePicker} from "../Components/ThemePicker"

export const WebNavigationLinkBar = () => (
    <NavigationLinkBarContentContainer>
        <WebNavigationLinkBarCenterContent />
        <NavigationLinkBarThemePicker />
    </NavigationLinkBarContentContainer>
)

export const WebNavigationLinkBarCenterContent = () => (
    <NavigationLinkBarCenterContentContainer>
        <NavigationLinkBarCenterContent />
    </NavigationLinkBarCenterContentContainer>
)
