import {MarketingSectionProps} from "../Web"
import {LabelContainer, MobileMarketingImage, MobileMarketinSubtitleBlack, Title4BlackMobile} from "./styles"

const MobileMarketingCover = (props: MarketingSectionProps) => (
    <LabelContainer>
        <Title4BlackMobile>{props.title}</Title4BlackMobile>
        <MobileMarketinSubtitleBlack>{props.subtitle}</MobileMarketinSubtitleBlack>
        <MobileMarketingImage src={props.url} />
    </LabelContainer>
)

export default MobileMarketingCover
