import {collection, doc, onSnapshot, orderBy, query, setDoc, Timestamp, Unsubscribe, updateDoc} from "firebase/firestore"
import {db} from "../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {ErrorManager} from "../../components/errorManager"
import {BusinessInvite} from "../../types/BusinessInvite"
import {deleteDoc} from "@firebase/firestore"
import ClassedError from "../../components/errorManager/ClassedError"
import businessInvite = FIRCollectionPaths.businessInvite
import businessInviteCollection = FIRCollectionPaths.businessInviteCollection

export default class BusinessInviteDao {
    async create(inviterBusinessId: string, inviteeEmail: string) {
        const now = Timestamp.now()
        const invite: BusinessInvite = {
            invitee_email: inviteeEmail,
            created_time: now,
            invite_requested_time: now,
        }

        await setDoc(this.inviteDoc(inviterBusinessId, inviteeEmail), invite).catch((error) => reThrow("create", error))
    }

    async reInvite(inviterBusinessId: string, inviteeEmail: string) {
        await updateDoc(this.inviteDoc(inviterBusinessId, inviteeEmail), {invite_requested_time: Timestamp.now()}).catch((error) => reThrow("reInvite", error))
    }

    async delete(inviterBusinessId: string, inviteeEmail: string) {
        await deleteDoc(this.inviteDoc(inviterBusinessId, inviteeEmail)).catch((error) => reThrow("delete", error))
    }

    listen(inviterBusinessId: string, completion: (invites: BusinessInvite[]) => void): Unsubscribe {
        const q = query(collection(db, businessInviteCollection(inviterBusinessId)), orderBy("created_time", "asc"))

        return onSnapshot(
            q,
            (snapshot) => {
                const invites = snapshot.docs.map((doc) => {
                    return doc.data() as BusinessInvite
                })
                completion(invites)
            },
            (error) => reportError("listen.onSnapshot", error),
        )
    }

    private inviteDoc(inviterBusinessId: string, inviteeEmail: string) {
        return doc(db, businessInvite(inviterBusinessId, inviteeEmail))
    }
}

function reThrow(functionName: string, error: unknown) {
    throw new ClassedError("BusinessInviteDao", functionName, error as Error)
}

function reportError(functionName: string, error: unknown) {
    ErrorManager.recordErrorAndAlert("BusinessInviteDao", functionName, error as Error)
}
