import {Locale} from "../../../i18n/Localization"

export class PropertiesLabel {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    species = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Species"
            case Locale.pt:
                return "Espécies"
            case Locale.es:
                return "Especies"
        }
    }

    logClass = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Commodity"
            case Locale.pt:
                return "Tipo"
            case Locale.es:
                return "Tipo"
        }
    }

    movement = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Movement"
            case Locale.pt:
                return "Movimento"
            case Locale.es:
                return "Movimiento"
        }
    }

    count = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Count"
            case Locale.pt:
                return "Conta"
            case Locale.es:
                return "Cuenta"
        }
    }
}
