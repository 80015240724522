import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {Locale} from "../../../i18n/Localization"

export default class CommodityGroupIDLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    firstId = (type: CommodityGroupType): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                    case CommodityGroupType.outbound_load:
                        return "Ticket"
                    case CommodityGroupType.row:
                        return "First ID"
                    case CommodityGroupType.order:
                        return "Order"
                    case CommodityGroupType.vessel:
                        return "Vessel Name"
                    case CommodityGroupType.vessel_section:
                        return "Vessel Section Name"
                    case CommodityGroupType.zone:
                        return "Zone Name"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                    case CommodityGroupType.outbound_load:
                        return "Carga"
                    case CommodityGroupType.row:
                        return "ID"
                    case CommodityGroupType.order:
                        return "Booking"
                    case CommodityGroupType.vessel:
                        return "Navio"
                    case CommodityGroupType.vessel_section:
                        return "Compartimento"
                    case CommodityGroupType.zone:
                        return "Zona"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                    case CommodityGroupType.outbound_load:
                        return "Remito"
                    case CommodityGroupType.row:
                        return "ID"
                    case CommodityGroupType.order:
                        return "Booking"
                    case CommodityGroupType.vessel:
                        return "Barco"
                    case CommodityGroupType.vessel_section:
                        return "Compartimiento"
                    case CommodityGroupType.zone:
                        return "Zona"
                }
        }
    }

    secondId = (type: CommodityGroupType): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                    case CommodityGroupType.outbound_load:
                        return "Plate"
                    case CommodityGroupType.row:
                        return "Second ID"
                    default:
                        return ""
                }
            case Locale.pt:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                    case CommodityGroupType.outbound_load:
                        return "ID do Caminhão"
                    case CommodityGroupType.row:
                        return "Segundo ID"
                    default:
                        return ""
                }
            case Locale.es:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                    case CommodityGroupType.outbound_load:
                        return "Matricula"
                    case CommodityGroupType.row:
                        return "Segundo ID"
                    default:
                        return ""
                }
        }
    }

    thirdId = (type: CommodityGroupType): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Driver"
                    case CommodityGroupType.outbound_load:
                        return "Seal"
                    case CommodityGroupType.row:
                        return "Third ID"
                    default:
                        return ""
                }
            case Locale.pt:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Motorista"
                    case CommodityGroupType.outbound_load:
                        return "Seal"
                    case CommodityGroupType.row:
                        return "Terceiro ID"
                    default:
                        return ""
                }
            case Locale.es:
                switch (type) {
                    case CommodityGroupType.inbound_load:
                        return "Chofer"
                    case CommodityGroupType.outbound_load:
                        return "Seal"
                    case CommodityGroupType.row:
                        return "Tercera ID"
                    default:
                        return ""
                }
        }
    }
}
