import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import InboundForm from "../../commodityGroupForm/Forms/InboundForm"
import RowForm from "../../commodityGroupForm/Forms/RowForm"
import OutboundForm from "../../commodityGroupForm/Forms/OutboundForm"
import OrderForm from "../../commodityGroupForm/Forms/OrderForm"
import VesselSectionForm from "../../commodityGroupForm/Forms/VesselSectionForm"
import ZoneForm from "../../commodityGroupForm/Forms/ZoneForm"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import VesselForm from "../../commodityGroupForm/Forms/VesselForm"

export default class CommodityGroupFormViewModel {
    id: string = ""
    group: CommodityGroup
    inbound?: InboundForm
    row?: RowForm
    outbound?: OutboundForm
    order?: OrderForm
    vessel?: VesselForm
    vessel_section?: VesselSectionForm
    zone?: ZoneForm

    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        this.id = group.id
        this.group = group
        switch (group.type) {
            case CommodityGroupType.inbound_load:
                this.inbound = new InboundForm(group, pickerOptions, linkedGroups)
                break
            case CommodityGroupType.row:
                this.row = new RowForm(group, pickerOptions, linkedGroups)
                break
            case CommodityGroupType.outbound_load:
                this.outbound = new OutboundForm(group, pickerOptions, linkedGroups)
                break
            case CommodityGroupType.order:
                this.order = new OrderForm(group, pickerOptions, linkedGroups)
                break
            case CommodityGroupType.vessel:
                this.vessel = new VesselForm(group)
                break
            case CommodityGroupType.vessel_section:
                this.vessel_section = new VesselSectionForm(group)
                break
            case CommodityGroupType.zone:
                this.zone = new ZoneForm(group)
                break
        }
    }
}
