import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupStatus, CommodityGroupType} from "@common/types/commodity/commodityGroup"

export default class CommodityUtils {
    commodityGroupsLinkedGroupIds = (groups: CommodityGroup[]): string[] => groups.map((linkedGroup) => linkedGroup.linked_commodity_group_ids).flat()

    commodityGroupLinkedGroups = (group: CommodityGroup, linkedGroups: CommodityGroup[]): CommodityGroup[] => {
        let linked = group.linked_commodity_group_ids.map((linkedId) => {
            let linkedGroup = linkedGroups.find((group) => group.id === linkedId)
            return linkedGroup
        })
        return linked.filter((group) => group !== undefined) as CommodityGroup[]
    }

    static groupOfType(groups: CommodityGroup[], type: CommodityGroupType) {
        return groups.find((group) => group.type === type)
    }

    static groupsOfType(groups: CommodityGroup[], type: CommodityGroupType) {
        return groups.filter((group) => group.type === type)
    }

    static openGroupsOfType(groups: CommodityGroup[], type: CommodityGroupType) {
        return groups.filter((group) => group.type === type && group.status === CommodityGroupStatus.Open)
    }
}
