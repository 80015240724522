import {CommodityGroupStatus, CommodityGroupType} from "@common/types/commodity/commodityGroup"
import CommodityGroupTypeLabels from "./CommodityGroupTypeLabels"
import CommodityGroupIDLabels from "./CommodityGroupIDLabels"
import {MeasurementSystem} from "@common/types/commodity/commodity"
import {LogScale} from "@common/types/LogScales"
import {CommodityGroupColumnID} from "../../dataTables/commodityGroup/Columns/selectors"
import {CommodityColumnID} from "../../dataTables/commodities/Columns/selectors"
import {StatusLabels} from "./StatusLabels"
import {MeasurementLabels} from "./MeasurementLabels"
import {PropertiesLabel} from "./PropertiesLabel"
import DeductionLabels from "./DeductionLabels"
import AuditLabels from "./AuditLabels"
import BusinessTypeLabels from "./BusinessTypeLabels"
import {BusinessType} from "@common/types/business/businessTypes"
import {Locale} from "../../../i18n/Localization"

export class ColumnLabels {
    language: Locale
    type: CommodityGroupType
    system: MeasurementSystem
    scale: LogScale
    status: StatusLabels
    measurement: MeasurementLabels
    deductions: DeductionLabels
    properties: PropertiesLabel
    groupTypeLabels: CommodityGroupTypeLabels
    groupIdLabels: CommodityGroupIDLabels
    auditLabels: AuditLabels
    businessTypeLabels: BusinessTypeLabels

    constructor(language: Locale, type: CommodityGroupType, system: MeasurementSystem, scale: LogScale) {
        this.language = language
        this.type = type
        this.system = system
        this.scale = scale
        this.status = new StatusLabels(language)
        this.measurement = new MeasurementLabels(language, system)
        this.groupTypeLabels = new CommodityGroupTypeLabels(language)
        this.groupIdLabels = new CommodityGroupIDLabels(language)
        this.properties = new PropertiesLabel(language)
        this.deductions = new DeductionLabels(language)
        this.auditLabels = new AuditLabels(language)
        this.businessTypeLabels = new BusinessTypeLabels(language)
    }

    commodityGroupLabel = (id: CommodityGroupColumnID): string | undefined => {
        switch (id) {
            case CommodityGroupColumnID.gross:
                return "Gross"
            case CommodityGroupColumnID.empty:
                return "Empty"
            case CommodityGroupColumnID.business:
                return "Business"
            case CommodityGroupColumnID.forester:
                return this.businessTypeLabels.label(BusinessType.Forester)
            case CommodityGroupColumnID.forest:
                return this.businessTypeLabels.label("Forest")
            case CommodityGroupColumnID.logger:
                return this.businessTypeLabels.label(BusinessType.Logger)
            case CommodityGroupColumnID.trucker:
                return this.businessTypeLabels.label(BusinessType.Trucker)
            case CommodityGroupColumnID.audited:
                return this.auditLabels.audit()
            case CommodityGroupColumnID.second_id:
                return this.groupIdLabels.secondId(this.type)
            case CommodityGroupColumnID.third_id:
                return this.groupIdLabels.thirdId(this.type)
            case CommodityGroupColumnID.ready_date:
                return this.status.label(CommodityGroupStatus.Ready)
            case CommodityGroupColumnID.closed_date:
                return this.status.label(CommodityGroupStatus.Closed)
            case CommodityGroupColumnID.archived_date:
                return this.status.label(CommodityGroupStatus.Archived)
            case CommodityGroupColumnID.length:
                return this.measurement.length.label(this.system)
            case CommodityGroupColumnID.species:
                return this.properties.species()
            case CommodityGroupColumnID.log_class:
                return this.properties.logClass()
            case CommodityGroupColumnID.movement:
                return this.properties.movement()
            case CommodityGroupColumnID.count:
                return this.properties.count()
            case CommodityGroupColumnID.created_date:
                return this.status.label(CommodityGroupStatus.Open)
            case CommodityGroupColumnID.status:
                return this.status.statusLabel()
            case CommodityGroupColumnID.zone:
                return this.groupTypeLabels.singular(CommodityGroupType.zone)
            case CommodityGroupColumnID.row:
                return this.groupTypeLabels.singular(CommodityGroupType.row)
            case CommodityGroupColumnID.order:
                return this.groupTypeLabels.singular(CommodityGroupType.order)
            case CommodityGroupColumnID.sed:
                return this.measurement.diameter.label(this.system)
            case CommodityGroupColumnID.scan_weight:
                return `Scan ${this.measurement.weight.label(this.type)}`
            case CommodityGroupColumnID.weight:
                return this.measurement.weight.label(this.type)
            case CommodityGroupColumnID.volume:
                return this.measurement.volume.label(this.scale)
            case CommodityGroupColumnID.density:
                return this.measurement.weight.label(this.type) + "/" + this.measurement.volume.label(this.scale)
            case CommodityGroupColumnID.first_id:
                return this.groupIdLabels.firstId(this.type)
            case CommodityGroupColumnID.vision_count:
                return "Vision Count"
        }
    }

    commodityLabel = (id: CommodityColumnID): string | undefined => {
        const system = " (" + this.measurement.diameter.label(this.system) + ")"
        const sm = " (" + this.measurement.diameter.label(MeasurementSystem.metric) + ")"
        switch (id) {
            case CommodityColumnID.event_summaries:
                return "Tracking"
            case CommodityColumnID.volume:
                return this.measurement.volume.label(this.scale)
            case CommodityColumnID.diameter_deductions:
                return this.deductions.diameterDeductions()
            case CommodityColumnID.length_deductions:
                return this.deductions.lengthDeductions()
            case CommodityColumnID.defect_codes:
                return this.deductions.defectCodes()
            case CommodityColumnID.operator_notes:
                return this.deductions.operatorNotes()
            case CommodityColumnID.created:
                return this.status.label(CommodityGroupStatus.Open)
            case CommodityColumnID.length:
                return this.measurement.length.label(this.system)
            case CommodityColumnID.species:
                return this.properties.species()
            case CommodityColumnID.log_class:
                return this.properties.logClass()
            case CommodityColumnID.short:
                return this.measurement.diameter.short() + system
            case CommodityColumnID.long:
                return this.measurement.diameter.long() + system
            case CommodityColumnID.id:
                return "ID"
            case CommodityColumnID.rounded_sed:
                return "RD" + sm // Because it's calculated as JAS rounded short diameter, which is cm
            case CommodityColumnID.avg:
                return "Avg" + system
            case CommodityColumnID.diameter_class:
                return "Class"
            case CommodityColumnID.weight:
                return "Weight"
            case CommodityColumnID.inbound:
                return "Inbound"
            case CommodityColumnID.row:
                return "Row"
            case CommodityColumnID.outbound:
                return "Outbound"
        }
    }
}
