import {CommodityGroupDetailTabOption} from "../../commodities/commodityGroup/state"
import {Locale} from "../../../i18n/Localization"

export class CommodityGroupDetailLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    label = (type: CommodityGroupDetailTabOption): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupDetailTabOption.measurements:
                        return "Measurements"
                    case CommodityGroupDetailTabOption.log_list:
                        return "Log List"
                    case CommodityGroupDetailTabOption.images:
                        return "Images"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupDetailTabOption.measurements:
                        return "Medidas"
                    case CommodityGroupDetailTabOption.log_list:
                        return "Toras"
                    case CommodityGroupDetailTabOption.images:
                        return "Imagens"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupDetailTabOption.measurements:
                        return "Medidas"
                    case CommodityGroupDetailTabOption.log_list:
                        return "Trosas"
                    case CommodityGroupDetailTabOption.images:
                        return "Imagenes"
                }
        }
    }
}
