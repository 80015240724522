import styled from "styled-components"

export const SiteContainer = styled.div`
    display: flex;
    background-color: #61eec1;
    justify-content: center;
    height: 100%;
`

export const SiteContentContainer = styled.div`
    width: 100%;
    max-width: calc(1408px);
    background-color: #fff;
`
