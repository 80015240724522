import {CommodityGroupAction} from "../../commodities/commodityGroups/viewModel/CommodityGroupActionsOptions"
import {Locale} from "../../../i18n/Localization"

export default class ActionTypeLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    actionsButtonLabel = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Actions"
            case Locale.pt:
                return "Ações"
            case Locale.es:
                return "Acciones"
        }
    }

    viewButtonLabel = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Details"
            case Locale.pt:
                return "Detalhes"
            case Locale.es:
                return "Detalles"
        }
    }

    label = (type: CommodityGroupAction): string => {
        switch (this.language) {
            case Locale.en:
                switch (type) {
                    case CommodityGroupAction.Allocations:
                        return "Run Allocations"
                    case CommodityGroupAction.UndoAllocations:
                        return "Undo Allocations"
                    case CommodityGroupAction.Edit:
                        return "Edit"
                    case CommodityGroupAction.Close:
                        return "Close"
                    case CommodityGroupAction.ReOpen:
                        return "ReOpen"
                    case CommodityGroupAction.Ready:
                        return "Ready"
                    case CommodityGroupAction.Unready:
                        return "Unready"
                    case CommodityGroupAction.Archive:
                        return "Archive"
                    case CommodityGroupAction.UnArchive:
                        return "UnArchive"
                    case CommodityGroupAction.Weights:
                        return "Weights"
                    case CommodityGroupAction.UndoGeneratedLogs:
                        return "Undo Generated Logs (TimberEye only)"
                    case CommodityGroupAction.UpdateStock:
                        return "Update Stock"
                    case CommodityGroupAction.Ok:
                        return "Ok"
                }
                break
            case Locale.pt:
                switch (type) {
                    case CommodityGroupAction.Allocations:
                        return "Run Allocations"
                    case CommodityGroupAction.UndoAllocations:
                        return "Undo Allocations"
                    case CommodityGroupAction.Edit:
                        return "Modificar"
                    case CommodityGroupAction.Close:
                        return "Fechar"
                    case CommodityGroupAction.ReOpen:
                        return "Re Abrir"
                    case CommodityGroupAction.Ready:
                        return "Marcar Pronta"
                    case CommodityGroupAction.Unready:
                        return "Desmarcar Pronta"
                    case CommodityGroupAction.Archive:
                        return "Marcar Como Arquivado"
                    case CommodityGroupAction.UnArchive:
                        return "Desmarcar Como Arquivado"
                    case CommodityGroupAction.Weights:
                        return "Pesos"
                    case CommodityGroupAction.UndoGeneratedLogs:
                        return "Undo Generated Logs (TimberEye only)"
                    case CommodityGroupAction.UpdateStock:
                        return "Update Stock"
                    case CommodityGroupAction.Ok:
                        return "Ok"
                }
                break
            case Locale.es:
                switch (type) {
                    case CommodityGroupAction.Allocations:
                        return "Run Allocations"
                    case CommodityGroupAction.UndoAllocations:
                        return "Undo Allocations"
                    case CommodityGroupAction.Edit:
                        return "Editar"
                    case CommodityGroupAction.Close:
                        return "Cerrar"
                    case CommodityGroupAction.ReOpen:
                        return "Re Abrir"
                    case CommodityGroupAction.Ready:
                        return "Marcar Listo"
                    case CommodityGroupAction.Unready:
                        return "Desmarcar Listo"
                    case CommodityGroupAction.Archive:
                        return "Marcar Archivado"
                    case CommodityGroupAction.UnArchive:
                        return "Desmarcar Archivado"
                    case CommodityGroupAction.Weights:
                        return "Pesos"
                    case CommodityGroupAction.UndoGeneratedLogs:
                        return "Undo Generated Logs (TimberEye only)"
                    case CommodityGroupAction.UpdateStock:
                        return "Update Stock"
                    case CommodityGroupAction.Ok:
                        return "Ok"
                }
        }
    }
}
