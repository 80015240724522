import React, {useEffect} from "react"
import {useAppSelector} from "./app/hooks"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import "./App.css"
import {presentationState} from "./components/authentication/selectors"
import {AppPresentationState} from "./components/authentication/state"
import {theme} from "./view/styles/muiTheme"
import {ThemeProvider} from "@mui/material"
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {LicenseInfo} from "@mui/x-license-pro"
import {components} from "./app/store"
import {Loader} from "./view/loader"
import {HomeView} from "./view/dataTable"
import {BusinessPickerView} from "./view/BusinessPicker/BusinessPickerView"
import {ReusableMarketingPage} from "./view/marketing/Pages/Landing"
LicenseInfo.setLicenseKey("ea36b97f68f1271e2dd42b1751fd1f43Tz04ODI2NSxFPTE3NDQ0NzMzMDMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y")

const App = () => {
    const auth = getAuth()
    const presentation = useAppSelector(presentationState)

    useEffect(() => {
        components.authentication.magicLink.handleMagicLinkRedirectIfLinkPresent()
    })

    useEffect(() => {
        onAuthStateChanged(auth, (user: any) => {
            console.log(`App.onAuthStateChanged ${user?.email}`)
            if (user?.email === undefined || user?.uid === undefined) {
                return
            }
            components.authentication.startSignIn(user.email, user.uid).then()
        })
        //components.authentication.startSignIn("stgregg23@gmail.com", "aQZdb3PiswbL79BpkCnimcga1aN2").then()
    }, [auth])

    switch (presentation) {
        case AppPresentationState.enterMagicLinkEmail:
        case AppPresentationState.magicLinkEmailSent:
        case AppPresentationState.loggedOut:
            return <ReusableMarketingPage />
        case AppPresentationState.pickBusiness:
            return <BusinessPickerView />
        case AppPresentationState.loggedIn:
            return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ThemeProvider theme={theme}>
                        <HomeView />
                    </ThemeProvider>
                </LocalizationProvider>
            )
    }
    return <Loader />
}

export default App
