export enum BusinessType {
    Forester = "Forester",
    Logger = "Logger",
    Subcontractor = "Subcontractor",
    Trucker = "Trucker",
    Port = "Port",
    Exporter = "Exporter",
    Importer = "Importer",
    Sawmill = "Sawmill",
    Trading = "Trading",
    Other = "Other",
}
