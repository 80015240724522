import {BusinessType} from "@common/types/business/businessTypes"
import {ForestSelectorView} from "./ForestSelectorView"
import {LinkedBusinessSelectorView} from "./LinkedBusinessSelectorView"
import InboundForm from "../../../../../components/commodities/commodityGroupForm/Forms/InboundForm"
import React, {useEffect} from "react"
import {LinkedBusiness} from "../../../../../types/Business"
import {firebase} from "../../../../../app/store"
import {Forest} from "@common/types/Forest"
import Logger from "../../../../../helpers/Logger"
import {ErrorManager} from "../../../../../components/errorManager"

const log = new Logger("ForesterSelectorView")

export function ForesterSelectorView(props: {form: InboundForm}) {
    const [forests, setForests] = React.useState<Forest[]>([])
    const [forester, setForester] = React.useState<LinkedBusiness | undefined>(findForester())
    const [forest, setForest] = React.useState<Forest | undefined>(findForest())
    const [showForestSelector, setShowForestSelector] = React.useState<boolean>(false)

    useEffect(() => {
        const _forester = forester
        setShowForestSelector(_forester !== undefined)
        if (_forester === undefined) {
            setForests([])
            return
        }

        const foresterIdSnapshot = _forester.business.id
        firebase.businessForests
            .getForests(foresterIdSnapshot)
            .then((forests) => {
                setForests(foresterIdSnapshot === forester?.business.id ? forests : [])
                const forest = findForest3(forester?.forest?.id, forests)
                setForest(forest)
            })
            .catch((error) => {
                ErrorManager.recordErrorAndAlert(log.tag, "useEffect[forester]", error)
            })
    }, [forester])

    function findForester() {
        return props.form.linked_businesses.find((biz) => biz.type === BusinessType.Forester)
    }

    function findForest() {
        const _forester = forester
        if (_forester === undefined) return undefined
        return findForest2(_forester.id)
    }

    function findForest2(forestId: string | undefined) {
        return findForest3(forestId, forests)
    }

    function findForest3(forestId: string | undefined, forests: Forest[]) {
        if (forestId === undefined) return undefined
        return forests.find((forest) => forest.id === forestId)
    }

    function linkBusiness(biz: LinkedBusiness) {
        props.form.linkBusiness(biz)
        setForester(biz)
        setShowForestSelector(true)
    }

    function unlinkBusiness(type: BusinessType) {
        props.form.unLinkBusiness(type)
        setShowForestSelector(false)
        setForester(undefined)
    }

    function linkForest(forest?: Forest) {
        if (!forester) return

        const _forester = {...forester}
        if (forest) {
            _forester.forest = forest
        } else {
            delete _forester.forest
        }
        linkBusiness(_forester)
        setForest(findForest2(forest?.id))
    }

    return (
        <>
            <LinkedBusinessSelectorView
                key={BusinessType.Forester}
                type={BusinessType.Forester}
                linked={props.form.linked_businesses}
                link={linkBusiness}
                unlink={unlinkBusiness}
            />
            {showForestSelector && <ForestSelectorView forests={forests} forest={forest} link={linkForest} />}
        </>
    )
}
