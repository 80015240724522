import {isMetric, MeasurementSystem} from "@common/types/commodity/commodity"
import Convert from "@common/utils/Convert"
import MathUtils from "@common/utils/MathUtils"

export default class Display {
    //
    // To display
    //

    // @return Meter or Feet
    static displayLengthM(metricLengthM: number, system: MeasurementSystem) {
        return isMetric(system) ? metricLengthM : Convert.metersToFeet(metricLengthM)
    }

    // @return Meter or Feet (Rounded to 2 decimal places)
    static displayLengthMR2(metricLengthM: number, system: MeasurementSystem) {
        return MathUtils.round2(Display.displayLengthM(metricLengthM, system))
    }

    // @return Cm or Inch
    static displayLengthCm(metricLengthCm: number, system: MeasurementSystem) {
        return isMetric(system) ? metricLengthCm : Convert.cmToInches(metricLengthCm)
    }

    // @return Cm or Inch (Rounded to 2 decimal places)
    static displayLengthCmR2(metricLengthCm: number, system: MeasurementSystem) {
        return MathUtils.round2(Display.displayLengthCm(metricLengthCm, system))
    }

    // @return Kg or Lbs
    static displayWeight(weightKg: number, system: MeasurementSystem) {
        return isMetric(system) ? weightKg : Convert.kgsToLbs(weightKg)
    }

    // @return Kg or Lbs
    static displayWeightR2(weightKg: number, system: MeasurementSystem) {
        return MathUtils.round2(Display.displayWeight(weightKg, system))
    }

    // @return Metric Tons or Short Tons
    static displayWeightTons(weightKg: number, system: MeasurementSystem) {
        if (isMetric(system)) {
            return Convert.kgsToMt(weightKg)
        } else {
            return Convert.lbsToShortTons(Convert.kgsToLbs(weightKg))
        }
    }

    //
    // To store in the backend
    //

    // @return Meter
    static serverLengthM(value: number, system: MeasurementSystem) {
        return isMetric(system) ? value : Convert.feetToMeters(value)
    }

    // @return Kg
    static serverWeight(value: number, system: MeasurementSystem) {
        return isMetric(system) ? value : Convert.lbsToKgs(value)
    }
}
