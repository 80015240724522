import styled from "styled-components"
import Button from "@mui/material/Button"

export const LabelBoldBlack = styled.p`
    font-family: "Lato-Bold";
    color: #000;
    font-size: 16px;
    margin-bottom: 0px;
    display: inline-block;
`

export const LabelBoldWhite = styled(LabelBoldBlack)`
    color: #fff;
`

export const Title1 = styled.p`
    font-family: "Lato-Black";
    color: #000;
    font-size: 16px;
    margin-bottom: 0px;
`

export const Title2 = styled(Title1)`
    font-size: 18px;
`

export const Title2White = styled(Title2)`
    color: #fff;
`

export const Title3 = styled(Title1)`
    font-size: 70px;
`

export const Title3White = styled(Title3)`
    color: #fff;
    -webkit-text-stroke: 4px white;
    line-height: 80px;
`

export const Title3Black = styled(Title3)`
    color: #000;
    -webkit-text-stroke: 4px black;
    line-height: 80px;
`

export const Title4 = styled(Title1)`
    font-size: 45px;
`

export const Title4White = styled(Title4)`
    color: #fff;
    -webkit-text-stroke: 2px white;
    line-height: 30px;
`

export const Title5 = styled(Title1)`
    font-size: 35px;
`

export const Subtitle1 = styled.p`
    font-family: "Lato";
    color: #000;
    font-size: 16px;
    margin-bottom: 0px;
`

export const Subtitle2 = styled(Subtitle1)`
    font-size: 24px;
`

export const Subtitle2White = styled(Subtitle2)`
    color: #fff;
`

export const Subtitle2Italic = styled(Subtitle2)`
    font-style: italic;
`

export const Subtitle3 = styled(Subtitle1)`
    font-size: 18px;
`

export const Subtitle4 = styled(Subtitle1)`
    font-size: 20px;
`

export const LabelBoldBlackUnderlinedLink = styled.a`
    font-family: "Lato-Bold";
    color: #000;
    font-size: 16px;
    margin-bottom: 0px;
    display: inline-block;
    text-decoration: underline;
    font-style: oblique;
    font-weight: bold;
`

export const PaddedSpan = styled.span`
    margin-left: 4px;
    padding-bottom: 4px;
`

export const DarkButton = styled(Button)`
    font-family: "Lato-Black" !important;
    color: #000 !important;
    font-size: 20px !important;
    background-color: #fff !important;
    margin-top: 30px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
`

export const LightButton = styled(DarkButton)`
    color: #000 !important;
    background-color: #61eec1 !important;
`
