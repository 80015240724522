import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import * as React from "react"
import {store} from "../../../app/store"
import {setShowFullscreenModal} from "../../../components/modal/slice"
import {CommodityGroupDetailViewTabSelector} from "./sfc/CommodityGroupDetailViewTabSelector"
import {useAppSelector} from "../../../app/hooks"
import {firstId} from "../../../components/commodities/commodityGroup/selectors/formSelectors"
import {AppBarActionsButton} from "./sfc/AppBarActionsButton"

export const CommodityGroupAppBar = () => {
    let title = useAppSelector(firstId)

    return (
        <AppBar sx={{position: "relative"}}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={(e) => store.dispatch(setShowFullscreenModal(false))} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                    {title}
                </Typography>
                <CommodityGroupDetailViewTabSelector />
                <AppBarActionsButton />
            </Toolbar>
        </AppBar>
    )
}
