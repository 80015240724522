import styled from "styled-components"
import "./legal.css"

export const CoverTitle = styled.p`
    font-weight: 800;
    fontsize: 7vw;
    font-family: "Lato-Black";
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #fff;

    @media (max-width: 700px) {
        fontsize: 40px;
    }
`
export const CoverSubtitle = styled.p`
    font-weight: 800;
    fontsize: 2.85vw;
    font-family: "Lato-Bold";
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #fff;
    align-items: center;

    @media (max-width: 700px) {
        font-size: 18px;
    }
`

export const XLTextBold = styled.p`
    font-family: "Lato-Black";
    font-weight: 800;
    font-size: 40px;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`

export const LTextBold = styled.p`
    font-family: "Lato-Black";
    font-weight: 800;
    font-size: 35px;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`

export const MTextBold = styled.p`
    font-weight: 800;
    font-size: 28px;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`

export const SText = styled.p`
    font-weight: 600;
    font-size: 16px;
    padding: 1px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`

export const SListItem = styled.li`
    font-weight: 600;
    font-size: 16px;
    padding: 1px;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`
