import {useMediaQuery} from "react-responsive"
import {WebMarketingNavbar} from "./Web"
import {MobileMarketingNavbar} from "./Mobile"
import {Constants} from "../../../../config/constants"

export const MarketingNavbar = () => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <WebMarketingNavbar />}
            {isMobile && <MobileMarketingNavbar />}
        </>
    )
}
