export class LoadMultiple<T> {
    readonly _function: (ids: string[]) => Promise<T[] | undefined>
    readonly idFieldName: string

    constructor(_function: (ids: string[]) => Promise<T[] | undefined>, idFieldName: string) {
        this._function = _function
        this.idFieldName = idFieldName
    }
}

export class IdCache<T extends {[index: string]: any}> {
    private readonly idValueMap = new Map<string, T>()
    private readonly fetchFunction: (id: string) => Promise<T | undefined>
    private readonly loadMultiple: LoadMultiple<T> | undefined

    constructor(tag: string, fetchFunction: (id: string) => Promise<T | undefined>, loadMultiple: LoadMultiple<T> | undefined = undefined) {
        this.fetchFunction = fetchFunction
        this.loadMultiple = loadMultiple
    }

    set(id: string, value: T) {
        this.idValueMap.set(id, value)
    }

    setAll(values: T[], idFieldName: string) {
        for (const value of values) {
            const key = value[idFieldName] as string
            this.set(key, value)
        }
    }

    async get(id: string): Promise<T | undefined> {
        if (this.idValueMap.has(id)) {
            return this.idValueMap.get(id)!
        }

        const value = await this.fetchFunction(id)
        if (value !== undefined) {
            this.idValueMap.set(id, value)
        }

        return value
    }

    async getAll(ids: string[]): Promise<T[]> {
        if (!this.loadMultiple) {
            return []
        }

        const idsToLoad = ids.filter((id) => !this.idValueMap.has(id))
        const existingItems = this.getExistingItems(ids)
        if (!idsToLoad.length) {
            return existingItems
        }

        const newItems = await this.loadMultiple._function(ids)
        if (!newItems) {
            return existingItems
        }

        for (const item of newItems) {
            const key = item[this.loadMultiple.idFieldName] as string
            this.idValueMap.set(key, item)
            existingItems.push(item)
        }

        return existingItems
    }

    private getExistingItems(ids: string[]): T[] {
        const result: T[] = []
        for (const id of ids) {
            const item = this.idValueMap.get(id)
            if (item) {
                result.push(item)
            }
        }
        return result
    }
}
