import {Button, DialogContent, DialogTitle, Divider} from "@mui/material"
import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {StyledDataGrid} from "../../../dataTable/tables/sfc/styledDataGrid"
import Form from "react-bootstrap/Form"
import {components} from "../../../../app/store"
import {QRView} from "./EditCommodityView"
import {ErrorManager} from "../../../../components/errorManager"
import {CommodityHistoryCommodityNotFoundError} from "../../../../components/commodityHistory/CommodityHistoryComponent"
import {Loader} from "../../../loader"
import {ModalCloseButton} from "./ModalCloseButton"
import DateFormatters from "../../../../utils/DateFormatters"

enum SearchState {
    None,
    Searching,
}

export function CommodityHistoryModalView() {
    const {t} = useTranslation(["common", "navigation", "commodityGroupType", "commodityHistory"])
    const tNav = (key: string) => t(key, {ns: "navigation"})
    const tGroupType = (key: string) => t(key, {ns: "commodityGroupType"})
    const tView = (key: string) => t(key, {ns: "commodityHistory"})

    const [commodityId, setCommodityId] = useState("")
    const [savedCommodityId, setsavedCommodityId] = useState("")
    const [searchState, setSearchState] = useState(SearchState.None)
    const [rows, setRows] = useState<{id: number; data: string}[]>([])

    function onTapSearch(event: any) {
        event.preventDefault()
        setSearchState(SearchState.Searching)
        setsavedCommodityId(commodityId)

        components.commodityHistory
            .getCommodityHistory(commodityId)
            .then((result) => {
                let rowId = 0
                const newRows = result.history.map((item) => {
                    const groupType = tGroupType(item.commodityGroupType)
                    const dateTime = `${DateFormatters.getFormattedDateFromDate(item.eventDateTime)}, ${DateFormatters.getFormattedTimeFromDate(item.eventDateTime)}`
                    return {id: rowId++, data: `${item.businessName}, ${groupType}, ${item.commodityGroupName}, ${dateTime}`}
                })
                setRows(newRows)
            })
            .catch((error) => {
                setRows([])
                if (error instanceof CommodityHistoryCommodityNotFoundError) {
                    alert(tView("commodity_not_found"))
                } else {
                    showErrorAlert("onTapSearch", error)
                }
            })
            .finally(() => {
                setSearchState(SearchState.None)
            })
    }

    const columns = () => [{field: "data", headerName: "data", width: 550}]

    return (
        <div>
            <DialogTitle fontSize={"large"}>{tNav("log_history")}</DialogTitle>
            <ModalCloseButton />
            <Divider />
            <DialogContent style={{width: 600}}>
                <Form id="invites_form">
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="commodityIdInput">{tView("log_id")}</Form.Label>
                        <Form.Control
                            id="commodityIdInput"
                            type="search"
                            placeholder={tView("enter_log_id")}
                            onChange={(e) => setCommodityId(e.target.value)}
                        />
                        <Button type="submit" onClick={onTapSearch} disabled={commodityId.length < 1 || searchState === SearchState.Searching}>
                            {t("search")}
                        </Button>
                    </Form.Group>
                </Form>
                <div style={{height: 250, width: 550}}>
                    <StyledDataGrid rows={rows} columns={columns()} disableColumnMenu disableChildrenSorting hideFooter />
                </div>
                <QRView commodity_id={savedCommodityId} />
                {(() => {
                    switch (searchState) {
                        case SearchState.Searching:
                            return <Loader />
                        default:
                            return null
                    }
                })()}
            </DialogContent>
        </div>
    )
}

function showErrorAlert(functionName: string, error: unknown) {
    ErrorManager.recordErrorAndAlert("LogHistoryModalView", functionName, error as Error)
}
