import {Locale} from "../../../i18n/Localization"

export class ShowCurrentStockLabels {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    reports = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Reports"
            case Locale.pt:
                return "Relatórios"
            case Locale.es:
                return "Informes"
        }
    }

    label = (): string => {
        switch (this.language) {
            case Locale.en:
                return "Current"
            case Locale.pt:
                return "Atual"
            case Locale.es:
                return "Actual"
        }
    }
}
