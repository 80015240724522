import styled from "styled-components"
import Navbar from "react-bootstrap/Navbar"
import {MouseEventHandler} from "react"
import {themeAppIconUrl} from "../../../../../components/marketing/selectors"
import {useAppSelector} from "../../../../../app/hooks"

export const StyledMarketingNavbar = styled(Navbar)`
    padding-left: 40px;
    padding-right: 40px;
    background-color: #fff;
    height: 100px;
    z-index: 1000 !important;
`

export const MarketingNavbarMenuButton = styled.div`
    background: transparent;
    border-color: transparent;
    margin-left: 0 !important;
    padding: 0 !important;
    margin-right: 20px !important;
    cursor: pointer;
`

export const MarketingNavbarLogo = ({onClick}: {onClick?: MouseEventHandler}) => {
    const url = useAppSelector(themeAppIconUrl)
    return (
        <MarketingNavbarMenuButton onClick={onClick}>
            <img alt="" src={url} width="250" />
        </MarketingNavbarMenuButton>
    )
}

export const MarketingNavigationLink = styled.button`
    font-family: "Lato-Black" !important;
    font-size: 20px !important;
    text-transform: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background-color: #fff !important;
    border: none !important;
    white-space: nowrap !important;
`

export const MarketingNavigationButton = styled.button`
    font-family: "Lato-Black" !important;
    font-size: 16px !important;
    text-transform: none !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    background-color: #61eec1 !important;
    border: none !important;
    white-space: nowrap !important;
    border-radius: 24px !important;
`

export const MarketingNavigationRightButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 50px;
    padding-bottom: 5px;
    width: 100%;
`
