import {collection, getDocs, orderBy, query, where} from "firebase/firestore"
import {db} from "../../../db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {QueryDocumentSnapshot} from "@firebase/firestore"
import {CommodityEvent} from "../../../../types/commodity/CommodityEvent"
import {ErrorManager} from "../../../../components/errorManager"
import {Commodity} from "../../../../types/commodity/Commodity"
import {Constants} from "../../../../config/constants"
import Logger from "../../../../helpers/Logger"
import ArrayUtils from "@common/utils/ArrayUtils"

const log = new Logger("CommodityEventRead")

export default class CommodityEventRead extends ErrorManager {
    error = (error: unknown) => {
        this.handleError("CommodityEventRead", error as Error)
    }

    async getCommodityGroupCommodityEvents(groupId: string) {
        const q = query(collection(db, FIRCollectionPaths.COMMODITY_EVENTS), where("commodity_group_id", "==", groupId), orderBy("time", "desc"))
        let snapshot = await getDocs(q)
        return this.completionHandler(snapshot.docs)
    }

    async getAllEventsForCommodities(commodities: Commodity[], businessId: string | undefined): Promise<CommodityEvent[]> {
        const commodityIds = commodities.map((commodity) => commodity.id)
        const commodityBatches: string[][] = ArrayUtils.split(commodityIds, Constants.Firebase.Firestore.MaxInQueryBatchSize)
        log.d("getAllEventsForCommodities", `commodityIds:${commodityIds.length}, commodityBatches:${commodityBatches.length}`)

        const eventBatches: CommodityEvent[][] = []
        const maxQueries = Constants.Firebase.Firestore.MaxConcurrentQueries
        try {
            for (let iBatch = 0; iBatch < commodityBatches.length; iBatch += maxQueries) {
                log.d("getAllEventsForCommodities", `batch:${iBatch}`)
                const slice = commodityBatches.slice(iBatch, iBatch + maxQueries)
                const events = await this.queryAllEventsForCommoditiesBatch(slice, businessId)
                eventBatches.push(events)
            }
        } catch (error) {
            this.error(error)
            throw error
        }

        return eventBatches.flat()
    }

    private async queryAllEventsForCommoditiesBatch(ids: string[][], businessId: string | undefined): Promise<CommodityEvent[]> {
        if (ids.length > Constants.Firebase.Firestore.MaxConcurrentQueries)
            throw new Error(`Too many queries in batch:${ids.length}, max:${Constants.Firebase.Firestore.MaxInQueryBatchSize}`)

        const promises: Promise<CommodityEvent[]>[] = ids.map((subIds) => this.queryAllEventsForCommodities(subIds, businessId))
        const events = await Promise.all(promises)
        return events.flat()
    }

    private async queryAllEventsForCommodities(ids: string[], businessId: string | undefined): Promise<CommodityEvent[]> {
        if (ids.length > Constants.Firebase.Firestore.MaxInQueryBatchSize)
            throw new Error(`Too many ids in query:${ids.length}, max:${Constants.Firebase.Firestore.MaxInQueryBatchSize}`)

        let constraints = [where("commodity_id", "in", ids)]
        if (businessId) {
            constraints.push(where("created_by_business_id", "==", businessId))
        }
        const q = query(collection(db, FIRCollectionPaths.COMMODITY_EVENTS), ...constraints)
        let snapshot = await getDocs(q)
        return this.completionHandler(snapshot.docs)
    }

    getEventsForGroups = async (ids: string[]): Promise<CommodityEvent[]> => {
        try {
            const promises = ids.map((id) => this.getEventsForGroup(id))
            const arrays = await Promise.all(promises)
            return arrays.flat()
        } catch (error) {
            this.error(error)
            return []
        }
    }

    private async getEventsForGroup(id: string): Promise<CommodityEvent[]> {
        try {
            let q = query(collection(db, FIRCollectionPaths.COMMODITY_EVENTS), where("commodity_group_id", "==", id))
            let snapshot = await getDocs(q)
            return this.completionHandler(snapshot.docs)
        } catch (error) {
            this.error(error)
            return []
        }
    }

    private completionHandler(docs: Array<QueryDocumentSnapshot>): CommodityEvent[] {
        const events: CommodityEvent[] = docs.map((doc) => {
            return doc.data() as CommodityEvent
        })
        return events
    }
}
