import React from "react"
import {BusinessPickerForm} from "./BusinessPickerForm"
import {AuthBodyContainer, AuthPageContainer} from "../newStyles/AuthStyles"
import {AuthNavbar} from "../Auth/AuthNavbar"
import {AuthFooter} from "../Auth/AuthFooter"

export const BusinessPickerView = () => {
    return (
        <AuthPageContainer>
            <AuthNavbar />
            <AuthBodyContainer>
                <BusinessPickerForm />
            </AuthBodyContainer>
            <AuthFooter />
        </AuthPageContainer>
    )
}
