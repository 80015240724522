import {doc} from "firebase/firestore"
import {db} from "./db"
import FIRCollectionPaths from "@common/types/collections/FirestoreCollectionPaths"
import {getDocFromServer} from "@firebase/firestore"

export default class GlobalAdmins {
    async isUserGlobalAdmin(userId: string) {
        let docRef = doc(db, FIRCollectionPaths.GLOBAL_ADMINS, userId)
        const document = await getDocFromServer(docRef)
        if (!document.exists()) {
            return false
        }
        let retrievedUserId = document.get("user_id") as string
        return userId === retrievedUserId
    }
}
