import React from "react"
import {
    FooterCopyrightContainer,
    FooterLink,
    FooterLinkSectionContainer,
    FooterLinksSectionTitleContainer,
    FooterSocialContainer,
    StyledMarketingFooter,
    StyledSocialLinkImage,
} from "./styles"
import {LabelBoldWhite, Title2White} from "../../../Styles/Fonts"
import {useNavigate} from "react-router-dom"
import {URLPickerButtonProps} from "../../NavigationListButton/Web/URLPickerButton"
import {MenuItemProps} from "../../NavigationListButton/model"
import {constants} from "../../../../../config/constants"

export const WebFooter = () => {
    return (
        <StyledMarketingFooter>
            <FooterSocial />
        </StyledMarketingFooter>
    )
}

export const FooterLinkSection = (props: URLPickerButtonProps) => (
    <FooterLinkSectionContainer>
        <>
            <FooterLinksSectionTitleContainer>
                <Title2White>{props.title}</Title2White>
            </FooterLinksSectionTitleContainer>
            {props.items.map((item) => {
                return <FooterLinkItem key={item.item} {...item} />
            })}
        </>
    </FooterLinkSectionContainer>
)

export const FooterLinkItem = (props: MenuItemProps) => {
    const navigate = useNavigate()

    return (
        <FooterLink
            onClick={() => {
                if (props.url !== undefined) {
                    navigate(props.url)
                }
                if (props.external_url !== undefined) {
                    window.open(props.external_url, "_newtab")
                }
            }}
        >
            {props.item}
        </FooterLink>
    )
}

export const FooterSocial = () => (
    <FooterSocialContainer>
        <StyledSocialLinkImage
            onClick={() => {
                window.open(constants.LINKED_IN, "_newtab")
            }}
            alt=""
            src="/linked_in.png"
            width="40"
        />
        <FooterCopyrightContainer>
            <LabelBoldWhite>{"Copyright © 2024 TimberEye, Inc."}</LabelBoldWhite>
        </FooterCopyrightContainer>
    </FooterSocialContainer>
)
