import CommodityGroupForm from "./CommodityGroupForm"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {LinkedBusiness} from "../../../../types/Business"
import ArrayUtils from "@common/utils/ArrayUtils"
import Logger from "../../../../helpers/Logger"
import {CommodityGroupCache} from "../../../../firebase/cache/IdCaches"

const log = new Logger("CommodityGroupForm")

export default class CommodityGroupPickerForm extends CommodityGroupForm {
    add_partners: boolean = false
    picker_options: CommodityGroup[] = []
    linked_businesses: LinkedBusiness[] = []
    linked_groups?: CommodityGroup[]
    private readonly newLinkedGroupIds: string[] = []
    private readonly groupCache = new CommodityGroupCache()

    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group)
        this.picker_options = pickerOptions
        this.linked_groups = linkedGroups
        if (linkedGroups) {
            this.groupCache.setAll(linkedGroups, "id")
        }
        this.linked_businesses = group.linked_businesses
        if (this.linked_businesses.length) {
            this.add_partners = true
        }
    }

    update() {
        super.update()
    }

    linkedGroupOfType(type: CommodityGroupType) {
        let group = this.linked_groups?.find((group) => group.type === type)
        if (group === undefined) {
            return undefined
        }
        if (group.id === this.group.id) {
            return
        }
        return group
    }

    matchingOption = (id: string) => {
        let match = this.picker_options.find((match) => match.id === id)
        return match
    }

    protected addLinkedGroup(group: CommodityGroup | undefined) {
        if (group) {
            this.newLinkedGroupIds.push(group.id)
        }
    }

    protected async updateLinkedCommodityGroupsOneGroupPerType() {
        const flog = log.functionLogger("updateLinkedCommodityGroupsOneGroupPerType")
        const typeGroupMap = new Map<CommodityGroupType, CommodityGroup>()

        for (const groupId of this.newLinkedGroupIds) {
            await CommodityGroupPickerForm.handleLinkedGroup(groupId, this.group.id, this.groupCache, typeGroupMap)
        }

        const unknownGroupIds: string[] = []
        for (const groupId of this.group.linked_commodity_group_ids) {
            await CommodityGroupPickerForm.handleLinkedGroup(groupId, this.group.id, this.groupCache, typeGroupMap, unknownGroupIds)
        }

        const linkedGroups = [this.group.id]
        linkedGroups.push(...Array.from(typeGroupMap.values()).map((group) => group.id))
        linkedGroups.push(...ArrayUtils.distinct(unknownGroupIds))

        flog.d(`linkedGroups:${linkedGroups.length} [${linkedGroups}]`)

        this.group = {...this.group, linked_commodity_group_ids: linkedGroups}
    }

    private static async handleLinkedGroup(
        groupId: string,
        selfGroupId: string,
        groupCache: CommodityGroupCache,
        typeGroupMap: Map<CommodityGroupType, CommodityGroup>,
        unknownGroupIds: string[] | undefined = undefined,
    ) {
        if (groupId === selfGroupId) {
            return
        }

        const group = await groupCache.get(groupId)
        if (!group) {
            log.d("handleLinkedGroup", `Unknown groupId:${groupId}`)
            unknownGroupIds?.push(groupId)
            return
        }

        const existingGroup = typeGroupMap.get(group.type)
        if (existingGroup) {
            return
        }

        typeGroupMap.set(group.type, group)
    }

    toggleAddPartners() {
        this.add_partners = !this.add_partners
    }
}
