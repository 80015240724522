import {firebase, store} from "../../app/store"
import {Unsubscribe} from "firebase/firestore"
import {Forest} from "@common/types/Forest"

export default class Forests {
    async set(forest: Forest): Promise<void> {
        return firebase.businessForests.set(this.businessId, forest)
    }

    listen(completion: (invites: Forest[]) => void): Unsubscribe {
        return firebase.businessForests.listen(this.businessId, completion)
    }

    private get businessId() {
        return store.getState().authentication.business!.id
    }
}
