import React, {useState} from "react"
import Form from "react-bootstrap/Form"
import {useAppSelector} from "../../../app/hooks"
import {components, RootState, store} from "../../../app/store"
import {constants} from "../../../config/constants"
import {createUserWithEmailAndPassword, fetchSignInMethodsForEmail, getAuth, signInWithEmailAndPassword} from "firebase/auth"
import {AuthForm, AuthMagicLinkButton, AuthMagicLinkEmailInput, AuthReminderLabel, AuthTitle, MagicLinkBox, MagicLinkBoxImage} from "../../newStyles/AuthStyles"
import {useTranslation} from "react-i18next"
import {AppPresentationState, AuthenticationState} from "../../../components/authentication/state"
import {Label3, Label4, LabelBlack3} from "../../newStyles/Labels"
import {setAppPresentationState} from "../../../components/authentication/slice"
import {SpacerV16, SpacerV32} from "../../newStyles/Spacer"

function isMagicLinkSentSelector(state: RootState) {
    let presentationState = (state.authentication as AuthenticationState).presentation_state
    return presentationState === AppPresentationState.magicLinkEmailSent
}

export function MagicLinkEmailForm() {
    const {t} = useTranslation("auth")
    const [email, setEmail] = useState("")
    const isMagicLinkSent = useAppSelector(isMagicLinkSentSelector)

    const onSubmit = (event: any) => {
        event.preventDefault()
        if (!components.authentication) return

        if (!constants.USE_FIREBASE_EMULATORS) {
            // Production
            components.authentication.magicLink.userDidRequestMagicLink(email)
        } else {
            // Development
            store.dispatch(setAppPresentationState(AppPresentationState.magicLinkEmailSent))
            fetchSignInMethodsForEmail(getAuth(), email).then((signInMethods) => {
                if (signInMethods.length > 0) {
                    signInWithEmailAndPassword(getAuth(), email, "111111").then((_) => {
                        console.log("MagicLinkEmailForm.onSubmit() user [" + email + "] exists")
                    })
                } else {
                    createUserWithEmailAndPassword(getAuth(), email, "111111").then((_) => {
                        console.log("MagicLinkEmailForm.onSubmit() user [" + email + "] created")
                    })
                }
            })
        }
    }

    if (isMagicLinkSent) {
        return (
            <MagicLinkBox>
                <MagicLinkBoxImage src="/magic_link_success_icon.png" alt="" />
                <SpacerV32 />
                <LabelBlack3>{t("magic_link_sent")}</LabelBlack3>
                <Label4 style={{textAlign: "center"}}>{t("check_email", {email: email})}</Label4>
            </MagicLinkBox>
        )
    }

    return (
        <AuthForm onSubmit={onSubmit}>
            <AuthTitle>{t("hello")}</AuthTitle>
            <Label3>{t("sign_in_to_account")}</Label3>
            <AuthReminderLabel>{t("reminder")}</AuthReminderLabel>
            <Form.Group controlId="formBasicEmail">
                <AuthMagicLinkEmailInput type="email" placeholder={t("email")} value={email} onChange={(event: any) => setEmail(event.target.value)} />
                <SpacerV16 />
                <AuthMagicLinkButton type="submit">{t("get_magic_link")}</AuthMagicLinkButton>
            </Form.Group>
        </AuthForm>
    )
}
