import {getAuth, signOut} from "firebase/auth"
import {components, firebase, store} from "../../app/store"
import {
    changeBusiness,
    setAppPresentationState,
    setBusiness,
    setBusinesses,
    setBusinessMember,
    setBusinessMembers,
    setBusinessSettings,
    setCurrentBusinessMemberOpenZones,
    setGlobalAdmin,
    setUser,
    setUserId,
    setUserSettings,
    signOutDispatch,
} from "./slice"
import {AppPresentationState} from "./state"
import {ErrorManager} from "../errorManager"
import {MagicLinkHelper} from "./helpers/MagicLinkHelper"
import {Unsubscribe} from "firebase/firestore"
import {BusinessMember} from "../../types/BusinessMember"
import {Business} from "../../types/Business"
import {PersistentStorage} from "../../PersistentStorage"
import {CommodityGroup} from "../../types/commodity/CommodityGroup"
import {UserSettings} from "@common/types/UserSettings"
import {BusinessSettings} from "@common/types/business/BusinessSettings"
import {User} from "../../types/User"
import ArrayUtils from "@common/utils/ArrayUtils"

export default class Authentication {
    magicLink = new MagicLinkHelper()
    private unsubscribes: Unsubscribe[] = []

    /**
     * Start sign-in process (step 0)
     */
    async startSignIn(email: string, userId: string) {
        if (store.getState().authentication.user_id === userId) {
            console.debug("Authentication.startSignIn Already started signing-in")
            return
        }
        console.debug("Authentication.startSignIn")
        store.dispatch(setUserId(userId))
        return await this.signInWithUser(email, userId)
    }

    /**
     * Sign-in step 1
     */
    private async signInWithUser(email: string, userId: string) {
        console.debug("Authentication.signInWithUser")
        try {
            // Get or create user

            let user = await firebase.user.getUser(userId)
            if (user === undefined) {
                console.log("Authentication.signInWithUser no user")
                user = await firebase.user.createUser(userId, email)
            }
            store.dispatch(setUser(user))

            // Get business members

            let isUserGlobalAdmin = await firebase.global_admins.isUserGlobalAdmin(userId)
            store.dispatch(setGlobalAdmin(isUserGlobalAdmin))

            let businessMembers = await firebase.business_members.read.getUserBusinessMembers(user)
            let businessMember: BusinessMember | undefined

            // If user has no business - create business and business member.
            // It is normal for GlobalAdmin to not be member of any business on the first run. No business&member creation needed.
            if (ArrayUtils.isEmpty(businessMembers) && !isUserGlobalAdmin) {
                const business = await firebase.business.createBusiness(user)
                businessMember = await firebase.business_members.create.createBusinessMember(user, business)
                if (businessMember === undefined) {
                    throw new Error("No business member")
                }
                businessMembers = [businessMember]
            }
            store.dispatch(setBusinessMembers(businessMembers))

            // Get businesses

            let businesses: Business[]
            if (isUserGlobalAdmin) {
                businesses = await firebase.business.getAllBusinesses()
            } else {
                let businessIds = businessMembers.map((member) => member.business_id)
                businesses = await firebase.business.getBusinesses(businessIds)
                if (ArrayUtils.isEmpty(businesses)) {
                    throw new Error("No business assigned to a user")
                }
            }
            store.dispatch(setBusinesses(businesses))

            // For new user session, try to sign-in with the previous signed-in business
            let lastSignInBusinessId = PersistentStorage.lastSignInBusinessId
            if (lastSignInBusinessId) {
                let business = businesses.find((biz) => biz.id === lastSignInBusinessId)
                let businessMember = businessMembers.find((member) => member.business_id === lastSignInBusinessId)
                if (business && businessMember) {
                    this.signInWithBusiness(business.id).then()
                    return
                }
            }

            if (businesses.length > 1) {
                // Multiple businesses - proceed to business picker form
                store.dispatch(setAppPresentationState(AppPresentationState.pickBusiness))
            } else {
                // Single business - proceed to signInWithBusiness()
                this.signInWithBusiness(businesses[0].id).then()
            }
        } catch (_error) {
            const error = _error as Error
            console.error(`Authentication.signInUntilPickingBusiness error: ${error}`)
            this.errorAndSignOut(error.message)
        }
    }

    /**
     * Sign-in step 2
     */
    async signInWithBusiness(businessId: string) {
        PersistentStorage.lastSignInBusinessId = businessId

        // Set business and business member

        let businesses = store.getState().authentication.businesses!
        let business = businesses.find((biz) => biz.id === businessId)!
        store.dispatch(setBusiness(business))
        let businessMembers = store.getState().authentication.business_members!
        let businessMember = businessMembers.find((member) => member.business_id === businessId)

        // At this point, if GlobalAdmin is not a member of the selected business, create business member.
        if (businessMember === undefined && store.getState().authentication.isUserGlobalAdmin) {
            businessMember = await firebase.business_members.create.createBusinessMember(store.getState().authentication.user!, business)
        }

        store.dispatch(setBusinessMember(businessMember!))

        // After everything is fetched - move app into loggedIn state

        store.dispatch(setAppPresentationState(AppPresentationState.loggedIn))
        components.commodity_groups.listenForCommodityGroups()

        // Subscribe to updates

        console.debug(`Authentication.signInWithBusiness unsubscribes: ${this.unsubscribes.length}`)
        this.unsubscribeThis() // Just in case

        const user = store.getState().authentication.user!
        this.listenAuthenticationData(user, user.id, business.id)
    }

    changeBusiness() {
        console.debug("Authentication.changeBusiness")
        store.dispatch(changeBusiness())
        this.unsubscribeAll()
    }

    signOut() {
        console.debug("Authentication.signOut")
        signOut(getAuth()).catch((error: Error) => {
            this.error(error)
        })
        store.dispatch(signOutDispatch())
        this.unsubscribeAll()
    }

    private listenAuthenticationData(user: User, userId: string, businessId: string) {
        // Listen for business member

        let unsubscribe = firebase.business_members.read.listenForUserBusinessMembers(user, (businessMembers: BusinessMember[]) => {
            console.debug(`Authentication.listenAuthenticationData business members: ${businessMembers.length}`)
            if (ArrayUtils.isEmpty(businessMembers)) {
                this.errorAndSignOut("Business member is gone 1")
            }
            let businessMember = businessMembers.find((member) => member.business_id === businessId)
            businessMember ? store.dispatch(setBusinessMember(businessMember)) : this.errorAndSignOut("Business member is gone 2")
        })
        this.unsubscribes.push(unsubscribe)

        // Listen for open zones

        unsubscribe = firebase.commodity_group.read.listenForOpenZones(businessId, (openZones: CommodityGroup[]) => {
            if (ArrayUtils.isEmpty(openZones)) {
                console.debug("Authentication.listenAuthenticationData no open zones")
                return
            }
            let businessMember = store.getState().authentication.business_member!
            let zones = Authentication.getCurrentBusinessMemberOpenZones(businessMember, openZones)
            console.debug(`Authentication.listenAuthenticationData open zones: ${zones.length}`)
            store.dispatch(setCurrentBusinessMemberOpenZones(zones))
        })
        this.unsubscribes.push(unsubscribe)

        // Listen for business

        unsubscribe = firebase.business.listenForBusiness(businessId, (businesses: Business[]) => {
            console.debug(`Authentication.listenAuthenticationData businesses: ${businesses.length}`)
            if (ArrayUtils.isEmpty(businesses)) {
                this.errorAndSignOut("Business is gone 1")
            }
            let business = businesses.find((biz) => biz.id === businessId)
            business ? store.dispatch(setBusiness(businesses[0])) : this.errorAndSignOut("Business is gone 2")
        })
        this.unsubscribes.push(unsubscribe)

        // Listen for user settings

        unsubscribe = firebase.settings.listenForUserSettings(userId, (settings?: UserSettings) => {
            console.debug(`Authentication.listenAuthenticationData user settings: ${settings}`)
            store.dispatch(setUserSettings(settings))
        })
        this.unsubscribes.push(unsubscribe)

        // Listen for business settings

        unsubscribe = firebase.businessSettings.listenForBusinessSettings(businessId, (settings?: BusinessSettings) => {
            console.debug(`Authentication.listenAuthenticationData business settings: ${settings?.scale}`)
            if (settings) {
                store.dispatch(setBusinessSettings(settings))
            }
        })
        this.unsubscribes.push(unsubscribe)
    }

    private static getCurrentBusinessMemberOpenZones(member: BusinessMember, zones: CommodityGroup[]): CommodityGroup[] {
        let visibility = member.zone_visibility
        if (visibility === undefined) {
            return zones
        }
        let memberZones: CommodityGroup[] = []

        zones.forEach((zone) => {
            if (visibility?.find((zoneId) => zoneId === zone.id)) {
                memberZones.push(zone)
            }
        })
        return memberZones
    }

    private unsubscribeAll() {
        components.commodity_groups.removeAllUnsubscribes()
        this.unsubscribeThis()
    }

    private unsubscribeThis() {
        for (let unsubscribe of this.unsubscribes) {
            unsubscribe()
        }
        this.unsubscribes = []
    }

    private error = (err: Error) => {
        ErrorManager.handleError(Authentication.name, err)
    }

    private errorAndSignOut(error: string) {
        ErrorManager.handleError(Authentication.name, new Error(error))
        this.signOut()
    }
}
