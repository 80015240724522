import {Locale} from "../../../i18n/Localization"
import {DiameterLabel} from "./DiameterLabel"
import {MeasurementSystem} from "@common/types/commodity/commodity"
import {WeightUnits} from "@common/types/WeightsUnits"
import {WeightLabel} from "./WeightLabel"
import {VolumeLabel} from "./VolumeLabel"
import {LengthLabel} from "./LengthLabel"
import {store} from "../../../app/store"

export class MeasurementLabels {
    language: Locale
    system: MeasurementSystem
    diameter: DiameterLabel
    weight: WeightLabel
    volume: VolumeLabel
    length: LengthLabel

    constructor(language: Locale, system: MeasurementSystem) {
        this.language = language
        this.system = system
        this.diameter = new DiameterLabel(language)
        const units = store.getState().authentication.business_settings?.scale_weight_unit ?? WeightUnits.kilograms
        this.weight = new WeightLabel(language, system, units)
        this.volume = new VolumeLabel(language)
        this.length = new LengthLabel(language)
    }
}
