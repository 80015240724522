import {firebase, store} from "../../../app/store"
import {setAppPresentationState} from "../slice"
import {AppPresentationState} from "../state"
import {browserLocalPersistence, getAuth, isSignInWithEmailLink, setPersistence, signInWithEmailLink} from "firebase/auth"
import {ErrorManager} from "../../errorManager"
import Utils from "../../../utils/Utils"

export class MagicLinkHelper {
    private firebaseAuth = getAuth()

    userDidRequestMagicLink = (email: string) => {
        if (!Utils.isValidEmail(email)) {
            return
        }
        store.dispatch(setAppPresentationState(AppPresentationState.magicLinkEmailSent))
        firebase.api_gateway.create
            .createMagicLinkTrigger(email)
            .then(() => {
                window.localStorage.setItem("emailForSignIn", email)
            })
            .catch(this.error)
    }

    handleMagicLinkRedirectIfLinkPresent = () => {
        if (!isSignInWithEmailLink(this.firebaseAuth, window.location.href)) {
            return
        }
        this.handleMagicLinkRedirect()
    }

    private handleMagicLinkRedirect() {
        let email = window.localStorage.getItem("emailForSignIn")
        if (email === null) {
            return
        }

        setPersistence(this.firebaseAuth, browserLocalPersistence)
            .then(() => {
                signInWithEmailLink(this.firebaseAuth, email as string, window.location.href)
                    .then((result) => {
                        if (result.user.email !== null) {
                            window.localStorage.removeItem("emailForSignIn")
                        }
                    })
                    .catch(this.error)
            })
            .catch(this.error)
    }

    private error = (err: Error) => {
        ErrorManager.handleError(MagicLinkHelper.name, err)
    }
}
