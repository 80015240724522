import {FormControlLabel, Switch} from "@mui/material"

export const SwitchView = (props: SwitchViewProps) => (
    <FormControlLabel sx={{mb: 2}} control={<Switch value={props.value} checked={props.value} onChange={props.onChange} />} label={props.label} />
)

export interface SwitchViewProps {
    label: string
    onChange: () => void
    value: boolean
}
