import styled from "styled-components"
import {FontSize} from "./Generic"

export const DropdownStyleWrapper5 = styled.div`
    .btn {
        font-size: ${FontSize._5};
    }

    .dropdown-item {
        font-size: ${FontSize._5};
    }
`
