import CommodityGroupViewModel from "../commodityGroups/viewModel/CommodityGroupViewModel"
import {setModalType, setShowModal} from "../../modal/slice"
import {firebase, store} from "../../../app/store"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {ModalType} from "../../modal/state"
import NewCommodityGroup from "./Helpers/NewCommodityGroup"
import {CommodityGroup} from "../../../types/commodity/CommodityGroup"
import CommodityGroupFormViewModel from "../commodityGroups/viewModel/CommodityGroupFormModel"
import {setForm, setFormLoading} from "../commodityGroup/slice"
import WeightsFormActionsHandler from "./Helpers/FormActionsHandler"
import Utils from "@common/utils/Utils"

export default class CommodityGroupFormComponent {
    weights = new WeightsFormActionsHandler()

    userDidTap(model?: CommodityGroupViewModel) {
        store.dispatch(setModalType(ModalType.CommodityGroupForm))
        store.dispatch(setShowModal(true))
        store.dispatch(setFormLoading(true))
        this.handleTap(model).then(() => store.dispatch(setFormLoading(false)))
    }

    handleTap = async (model?: CommodityGroupViewModel) => {
        let pickerOptions = await this.pickerOptions()
        let group = this.getOrCreateGroup(model)
        if (group === undefined) {
            return
        }
        let form = new CommodityGroupFormViewModel(group.group, pickerOptions, group.linked_groups)
        store.dispatch(setForm(form))
        return await Utils.delay(300)
    }
    pickerOptions = async (): Promise<CommodityGroup[]> => {
        let groupTypes: CommodityGroupType[] = [
            CommodityGroupType.zone,
            CommodityGroupType.row,
            CommodityGroupType.order,
            CommodityGroupType.vessel,
            CommodityGroupType.vessel_section,
        ]
        return await firebase.commodity_group.read.getCommodityGroupsByTypes(groupTypes)
    }
    getOrCreateGroup = (group?: CommodityGroupViewModel): CommodityGroupViewModel | undefined => {
        if (group === undefined) {
            return new NewCommodityGroup().get()
        }
        return group
    }
}
