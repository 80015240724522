import React from "react"
import Navbar from "react-bootstrap/Navbar"
import {MarketingNavbarLogo} from "../Web/styles"
import {useNavigate} from "react-router-dom"
import {StyledMobileMarketingNavbar} from "./styles"

export const MobileMarketingNavbar = () => {
    const navigate = useNavigate()
    return (
        <StyledMobileMarketingNavbar>
            <Navbar.Collapse className="justify-content-start">
                <MarketingNavbarLogo onClick={() => navigate("/")} />
            </Navbar.Collapse>
        </StyledMobileMarketingNavbar>
    )
}
