import {SiteContainer, SiteContentContainer} from "../../Styles/style"
import {NavigationLinkBar} from "../../Navigation/NavigationLinkBar"
import {MarketingNavbar} from "../../Navigation/NavigationBar"
import React from "react"
import {useAppSelector} from "../../../../app/hooks"
import {marketingSectionProps, marketingTheme} from "../../../../components/marketing/selectors"
import {useLocation} from "react-router"
import {MarketingCover} from "../../Content/MarketingCover"
import {LeftSection, RightSection} from "../../Content/Section"
import {QRs} from "../../Content/QRs"
import {Footer} from "../../Navigation/Footer"
import ScrollToTop from "../../Navigation/ScrollToTop"
import Intercom from "@intercom/messenger-js-sdk"

export const ReusableMarketingPage = () => {
    Intercom({app_id: "hv0p4ppc"})

    return (
        <SiteContainer>
            <ScrollToTop />
            <SiteContentContainer>
                <NavigationLinkBar />
                <MarketingNavbar />
                <MarketingSections />
                <QRs />
                <Footer />
            </SiteContentContainer>
        </SiteContainer>
    )
}

export const MarketingSections = () => {
    const theme = useAppSelector(marketingTheme)
    const urlLocation = useLocation()

    return (
        <>
            <MarketingCover {...marketingSectionProps(theme, 1, urlLocation.pathname)} />
            <LeftSection {...marketingSectionProps(theme, 2, urlLocation.pathname)} />
            <RightSection {...marketingSectionProps(theme, 3, urlLocation.pathname)} />
            <LeftSection {...marketingSectionProps(theme, 4, urlLocation.pathname)} />
        </>
    )
}
