import {Locale} from "../../../i18n/Localization"

export default class DateLabel {
    language: Locale

    constructor(language: Locale) {
        this.language = language
    }

    since = (): string => {
        switch (this.language) {
            case Locale.en:
                return "From"
            case Locale.pt:
                return "De"
            case Locale.es:
                return "Desde"
        }
    }

    until = (): string => {
        switch (this.language) {
            case Locale.en:
                return "To"
            case Locale.pt:
                return "Até"
            case Locale.es:
                return "Hasta Que"
        }
    }
}
