import {LogClass} from "../types/commodity/commodity"

class SouthAfricaClassHandler {
    class_string = "NA"
    short_cm = 0
    long_cm = 0
    avg_cm = 0
    length = 0
    log_class: LogClass = LogClass.saw_log

    constructor(shortCm: number, longCm: number, length: number, logClass?: LogClass) {
        if (logClass === undefined) {
            return
        }
        this.short_cm = shortCm
        this.long_cm = longCm
        this.avg_cm = (shortCm + longCm) / 2
        this.length = length
        this.log_class = logClass
        this.setBuildingAndFencing()
        this.setTransmission()
        this.setSawLog()
        this.setVeneerAndPruned()
        this.setHewsaw()
    }
    setBuildingAndFencing = () => {
        if (this.log_class !== LogClass.building_and_fencing) {
            return
        }
        if (this.avg_cm >= 5) {
            this.class_string = "50-74"
        }
        if (this.avg_cm >= 7.5) {
            this.class_string = "75-99"
        }
        if (this.avg_cm >= 10) {
            this.class_string = "100-124"
        }
        if (this.avg_cm >= 12.5) {
            this.class_string = "125-149"
        }
        if (this.avg_cm >= 15) {
            this.class_string = "150-174"
        }
        if (this.avg_cm >= 17.5) {
            this.class_string = "175-199"
        }
        if (this.avg_cm >= 20) {
            this.class_string = "200-224"
        }
    }
    setTransmission = () => {
        if (this.log_class !== LogClass.transmission) {
            return
        }
        if (this.avg_cm >= 80) {
            this.class_string = "80-99"
        }
        if (this.avg_cm >= 100) {
            this.class_string = "100-119"
        }
        if (this.avg_cm >= 120) {
            this.class_string = "120-139"
        }
        if (this.avg_cm >= 140) {
            this.class_string = "140-159"
        }
        if (this.avg_cm >= 160) {
            this.class_string = "160-179"
        }
        if (this.avg_cm >= 180) {
            this.class_string = "180-199"
        }
        if (this.avg_cm >= 200) {
            this.class_string = "200-219"
        }
        if (this.avg_cm >= 220) {
            this.class_string = "220-239"
        }
    }
    setSawLog = () => {
        if (this.log_class !== LogClass.saw_log) {
            return
        }
        if (this.avg_cm >= 8) {
            this.class_string = "Pulp"
        }
        if (this.avg_cm >= 13) {
            this.class_string = "Class A"
        }
        if (this.avg_cm >= 18) {
            this.class_string = "Class B"
        }
        if (this.avg_cm >= 26) {
            this.class_string = "Class C"
        }
        if (this.avg_cm >= 34) {
            this.class_string = "Class D"
        }
    }
    setVeneerAndPruned = () => {
        if (this.log_class !== LogClass.veneer && this.log_class !== LogClass.pruned) {
            return
        }
        if (this.avg_cm < 26) {
            this.class_string = "Under Spec"
        }
        if (this.avg_cm >= 26) {
            this.class_string = "Class C1"
        }
        if (this.avg_cm >= 30) {
            this.class_string = "Class C2"
        }
        if (this.avg_cm >= 34) {
            this.class_string = "Class D1"
        }
        if (this.avg_cm >= 38) {
            this.class_string = "Class D2"
        }
        if (this.avg_cm >= 42) {
            this.class_string = "Class D3"
        }
        if (this.avg_cm >= 46) {
            this.class_string = "Class D4"
        }
    }
    setHewsaw = () => {
        if (this.log_class !== LogClass.hew_saw) {
            return
        }
        this.class_string = "Out Of Spec"
        if (this.avg_cm < 11) {
            return
        }
        if (this.avg_cm > 23) {
            return
        }
        if (this.length < 2.4) {
            return
        }
        if (this.length > 3.3) {
            return
        }
        this.class_string = "In Spec"
    }
}

export default SouthAfricaClassHandler
