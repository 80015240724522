import NavLink from "react-bootstrap/NavLink"
import styled from "styled-components"
import {Font, FontSize} from "./Generic"
import {useNavigate} from "react-router-dom"

export function Link(props: {route: string; children: string}) {
    const navigate = useNavigate()
    const {route, children, ...rest} = props

    return (
        <StyledNavLink onClick={() => navigate(route)} {...rest}>
            {children}
        </StyledNavLink>
    )
}

const StyledNavLink = styled(NavLink)`
    font-family: ${Font.Regular};
`

export const Link4 = styled(Link)`
    font-size: ${FontSize._4};
`

export const Link5 = styled(Link4)`
    font-size: ${FontSize._5};
`

export const Link6 = styled(Link4)`
    font-size: ${FontSize._6};
`
