import React from "react"
import {MarketingTheme} from "../../../../../../components/marketing/state"
import {store} from "../../../../../../app/store"
import {setTheme} from "../../../../../../components/marketing/slice"
import {useAppSelector} from "../../../../../../app/hooks"
import {marketingTheme} from "../../../../../../components/marketing/selectors"
import {Button} from "../Button"

export const ThemePickerButton = () => (
    <Button
        isDark={true}
        items={Object.values(MarketingTheme).map((theme) => {
            return {item: theme}
        })}
        title={useAppSelector(marketingTheme)}
        select={(item) => store.dispatch(setTheme(item.item as MarketingTheme))}
    />
)
