import {DialogContent, DialogTitle, Divider} from "@mui/material"
import React from "react"
import {ModalCloseButton} from "../ModalCloseButton"
import {ConnectionsView} from "./ConnectionsView"
import {InvitesView} from "./InvitesView"
import {RowFlexContainer} from "../../../../styles"

export function ConnectionsModalView() {
    return (
        <RowFlexContainer>
            <div>
                <DialogTitle fontSize={"large"}>Connections</DialogTitle>
                <Divider />
                <DialogContent>
                    <ConnectionsView />
                </DialogContent>
            </div>
            <div>
                <DialogTitle fontSize={"large"}>Invites</DialogTitle>
                <ModalCloseButton />
                <Divider />
                <DialogContent>
                    <InvitesView />
                </DialogContent>
            </div>
        </RowFlexContainer>
    )
}
