export interface CommodityMeasurements {
    count?: number
    diameters?: CommodityDiameters // Cm
    weight?: CommodityWeight // Kg
    length?: CommodityLength // Meters
    volume?: CommodityVolume
    deductions?: CommodityDeductions // Meter (length) | Cm (diameter)
}

export interface CommodityDiameters {
    short: number // Cm
    long: number
    average: number
    rounded_short?: number
    cm_per_width?: number
    cm_per_height?: number
}

export interface CommodityWeight {
    weight: number // Kg
    gross_weight?: number // Kg
    empty_weight?: number // Kg
}

export interface CommodityLength {
    length: number // Meters
}

export interface CommodityVolume {
    jas?: number
    international?: number
    scribner?: number
    doyle?: number
    huber?: number
    cylinder_short?: number
    cylinder_average?: number
    lumber_board_feet?: number
}

export interface CommodityDeductions {
    measurement?: CommodityDeduction // Cm (diameter)
    length?: CommodityDeduction // Meter (length)
    operator_notes?: string
    defect_codes?: string[]
}

export interface CommodityDeduction {
    deduction?: number // Meter (length) | Cm (diameter)
}

export interface CommodityPoints {
    id: string
    diameter_one_points?: GridPoint[]
    diameter_two_points?: GridPoint[]
    qr?: GridPoint[]
    perimeter_points?: GridPoint[]
    largest_interior_rectangle_points?: GridPoint[]
    largest_defect_points?: GridPoint[]
}

export interface PointGroup {
    top_left: GridPoint
    top_right: GridPoint
    bottom_left: GridPoint
    bottom_right: GridPoint
    mid_points: MidPoints
    points: OrderedPoints
}

export interface MidPoints {
    first: GridPoint[]
    second: GridPoint[]
    width: number
    height: number
}

export interface OrderedPoints {
    points: GridPoint[]
}

export interface CommodityProperties {
    log?: LogProperties
    lumber?: LumberProperties
}

export interface LogProperties {
    log_class: LogClass
    species: Species
    pruned: boolean
}

export interface GridPoint {
    x: number
    y: number
}

export interface LumberProperties {
    grade: LumberGrade
    species: Species
    pruned: boolean
}

export enum MeasurementSystem {
    metric = "metric",
    imperial = "imperial",
}

export function isMetric(system: MeasurementSystem) {
    return system === MeasurementSystem.metric
}

export enum Species {
    Pine = "Pine",
    Eucalyptus = "Eucalyptus",
    PineElliottii = "PineElliottii",
    PineSouthernYellow = "PineSouthernYellow",
    PineTaeda = "PineTaeda",
    PinePatula = "PinePatula",
    EucalyptusBenthammii = "EucalyptusBenthammii",
    EucalyptusDennii = "EucalyptusDennii",
    EucalyptusGrandis = "EucalyptusGrandis",
    EucalyptusSaligna = "EucalyptusSaligna",
    EucalyptusUrograndis = "EucalyptusUrograndis",
    Other = "Other",
}

export enum LogClass {
    building_and_fencing = "building_and_fencing",
    mining_timber = "mining_timber",
    special_mining_timber = "special_mining_timber",
    pulp = "pulp",
    saw_log = "saw_log",
    transmission = "transmission",
    pruned = "pruned",
    veneer = "veneer",
    hew_saw = "hew_saw",
}

export enum LumberGrade {
    A = "A",
}

export interface IdentifiableGridPoint {
    id: string
    point: GridPoint
}

export interface IdentifiablePointDistance {
    point: IdentifiableGridPoint
    distance: number
}
