import {MeasurementSystem} from "@common/types/commodity/commodity"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {WeightUnits} from "@common/types/WeightsUnits"
import {Locale} from "../../../i18n/Localization"

export class WeightLabel {
    language: Locale
    system: MeasurementSystem
    scaleUnits: WeightUnits

    constructor(language: Locale, system: MeasurementSystem, units: WeightUnits) {
        this.language = language
        this.system = system
        this.scaleUnits = units
    }

    label = (type: CommodityGroupType): string => {
        switch (type) {
            case CommodityGroupType.inbound_load:
                switch (this.system) {
                    case MeasurementSystem.metric:
                        return "MT"
                    case MeasurementSystem.imperial:
                        return "Short Tons"
                }
                break
            case CommodityGroupType.row:
                switch (this.system) {
                    case MeasurementSystem.metric:
                        return "MT"
                    case MeasurementSystem.imperial:
                        return "Short Tons"
                }
                break
            case CommodityGroupType.outbound_load:
                switch (this.system) {
                    case MeasurementSystem.metric:
                        return "MT"
                    case MeasurementSystem.imperial:
                        return "Short Tons"
                }
                break
            default:
                return ""
        }
    }

    gross = (): string => {
        switch (this.language) {
            case Locale.en:
                switch (this.scaleUnits) {
                    case WeightUnits.kilograms:
                        return "Gross KG"
                    case WeightUnits.pounds:
                        return "Gross Pounds"
                }
                break
            case Locale.pt:
                switch (this.scaleUnits) {
                    case WeightUnits.kilograms:
                        return "KG Bruto"
                    case WeightUnits.pounds:
                        return "Libras Brutas"
                }
                break
            case Locale.es:
                switch (this.scaleUnits) {
                    case WeightUnits.kilograms:
                        return "KG Bruto"
                    case WeightUnits.pounds:
                        return "Libras Brutas"
                }
        }
    }

    empty = (): string => {
        switch (this.language) {
            case Locale.en:
                switch (this.scaleUnits) {
                    case WeightUnits.kilograms:
                        return "Empty KG"
                    case WeightUnits.pounds:
                        return "Empty Pounds"
                }
                break
            case Locale.pt:
                switch (this.scaleUnits) {
                    case WeightUnits.kilograms:
                        return "Tara (KG)"
                    case WeightUnits.pounds:
                        return "Tara (Libras)"
                }
                break
            case Locale.es:
                switch (this.scaleUnits) {
                    case WeightUnits.kilograms:
                        return "Tara (KG)"
                    case WeightUnits.pounds:
                        return "Tara (Libras)"
                }
        }
    }
}
