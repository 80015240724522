import * as React from "react"
import {DialogContent, Divider} from "@mui/material"
import {setShowModal} from "../../../components/modal/slice"
import {components, store} from "../../../app/store"
import DialogTitle from "@mui/material/DialogTitle"
import {FormCancelSaveButtonsView} from "../CommodityGroup/fields/sfc/FormCancelSaveButtonsView"
import {useAppSelector} from "../../../app/hooks"
import {actionLabels} from "../../../components/localization/selectors"
import {CommodityGroupAction} from "../../../components/commodities/commodityGroups/viewModel/CommodityGroupActionsOptions"
import {WeightsFormFields} from "./fields/WeightsFormFields"

export const WeightsFormModalView = () => {
    const labels = useAppSelector(actionLabels)

    return (
        <>
            <DialogTitle fontSize={22} fontWeight={"bold"}>
                {labels.label(CommodityGroupAction.Weights)}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <WeightsFormFields />
            </DialogContent>
            <Divider />
            <FormCancelSaveButtonsView
                cancel={() => store.dispatch(setShowModal(false))}
                submit={() => components.commodity_group_form.weights.submit()}
                cancel_label={"Cancel"}
                submit_label={"Save"}
            />
        </>
    )
}
