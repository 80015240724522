import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {initialState} from "./state"
import {formatIdentifiablePerimeterPoints} from "./selectors"
import {GridPoint, IdentifiableGridPoint, MidPoints} from "@common/types/commodity/commodity"
import {Commodity} from "../../../types/commodity/Commodity"

export const slice = createSlice({
    name: "edit_commodity",
    initialState,
    reducers: {
        setSelectedCommodity: (state, action: PayloadAction<Commodity>) => {
            let commodity = action.payload
            state.selected_commodity_id = commodity.id
            state.perimeter_points = formatIdentifiablePerimeterPoints(commodity.points?.perimeter_points, state.image_width_px)
        },
        setActivePoint: (state, action: PayloadAction<IdentifiableGridPoint | undefined>) => {
            let point = action.payload
            state.active_point = point

            if (point !== undefined) {
                let index = state.perimeter_points.findIndex((identifiable) => identifiable.id === point?.id)
                if (~index) {
                    state.perimeter_points[index] = point
                }
            }
        },
        setUserIsClicking: (state, action: PayloadAction<boolean>) => {
            state.user_is_clicking = action.payload
        },
        setShortLongCm: (state, action: PayloadAction<[shortCm: number, longCm: number]>) => {
            state.short_cm = action.payload[0]
            state.long_cm = action.payload[1]
        },
        setUpdatedDiameters: (state, action: PayloadAction<Commodity>) => {
            state.updated_editing_commodity = action.payload
        },
        setUpdatedLogCenter: (state, action: PayloadAction<GridPoint>) => {
            state.updated_center = action.payload
        },
        setUpdatedMidpoints: (state, action: PayloadAction<MidPoints>) => {
            state.updated_midpoints = action.payload
        },
    },
})

export const {setSelectedCommodity, setActivePoint, setUserIsClicking, setShortLongCm, setUpdatedDiameters, setUpdatedLogCenter, setUpdatedMidpoints} =
    slice.actions
export default slice.reducer
