export class Constants {
    readonly FLAVOR = process.env.REACT_APP_FLAVOR
    readonly USE_FIREBASE_EMULATORS = this.FLAVOR === "Development"
    MARKETING_PUBLIC_IMAGE_PATH = "https://storage.googleapis.com/timbereye_public/web/"
    APP_STORE_URL = "https://apps.apple.com/us/app/copilot-buy-sell-logs/id6499224336"
    PRINT_APP_URL = "https://apps.apple.com/us/app/timbereye-print-qr-codes/id1592918221"
    AD_URL = "https://www.youtube.com/watch?v=wBTJGy5KG5A"
    HOW_IT_WORKS_URL = "https://www.youtube.com/watch?v=XbhIzNsl_i4&ab_channel=TimberEye"
    LINKED_IN = "https://www.linkedin.com/company/74768050"
    static readonly MobileMaximumWindowWidth = `(max-width: 950px)`

    static Firebase = class {
        static Firestore = class {
            // Firestore is channelling requests, but there is some undefined concurrent maximum.
            // We have to manually watch some reasonable limit.
            static readonly MaxReadBatchSize = 500
            static readonly MaxConcurrentQueries = 50
            static readonly MaxInQueryBatchSize = 10 // Documented 30, but in reality 10: https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_array-contains-any_limits
        }
    }
}

export const constants = new Constants()
