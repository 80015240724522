import {CommodityDeductions, CommodityLength, CommodityVolume, MeasurementSystem, Species} from "../types/commodity/commodity"
import MathUtils from "../utils/MathUtils"
import Convert from "../utils/Convert"
import {Commodity} from "../../backend/Backend/functions/src/Types/Commodity"

export default class CalculateLogVolume {
    short_cm: number
    long_cm: number
    avg_cm: number
    avg_in: number
    length_meters: number
    length_feet: number
    species: Species

    constructor(longCm: number, shortCm: number, species: Species, len?: CommodityLength, deductions?: CommodityDeductions) {
        this.length_meters = (len?.length ?? 5.8) - (deductions?.length?.deduction ?? 0)
        this.length_feet = Convert.metersToFeet(this.length_meters)
        this.short_cm = shortCm - (deductions?.measurement?.deduction ?? 0)
        this.long_cm = longCm
        this.avg_cm = MathUtils.mean([this.short_cm, longCm])
        this.avg_in = Convert.cmToInches(this.avg_cm)
        this.species = species
    }

    static create(commodity: Commodity) {
        const diameters = commodity.measurements.diameters
        if (!diameters) {
            throw new Error("Diameters are required")
        }
        const species: Species = commodity.properties?.log?.species ?? Species.Pine
        const length = commodity.measurements.length
        const deductions = commodity.measurements.deductions
        return new CalculateLogVolume(diameters.long, diameters.short, species, length, deductions)
    }

    volume = (): CommodityVolume => ({
        jas: this.jas(),
        international: this.international(),
        scribner: this.scribner(),
        doyle: this.doyle(),
        huber: this.huber(),
        cylinder_short: CalculateLogVolume.cylinderShort(this.short_cm, this.length_meters),
        cylinder_average: CalculateLogVolume.cylinderAverage(this.short_cm, this.long_cm, this.length_meters),
    })

    jas = (): number => {
        let cbm = 0
        let roundedShortCm = CalculateLogVolume.getJasRoundededShortDiameter(this.short_cm, this.long_cm)
        let lengthRoundedDown = Math.floor(this.length_meters)

        if (roundedShortCm === 0) {
            roundedShortCm = 10
        }

        if (lengthRoundedDown === 0) {
            lengthRoundedDown = 2
        }

        if (this.length_meters < 6) {
            cbm = (Math.pow(roundedShortCm, 2) * this.length_meters) / 10000
        }

        if (this.length_meters >= 6) {
            const multiplier = this.length_meters / 10000
            cbm = Math.pow(roundedShortCm + (lengthRoundedDown - 4) / 2, 2) * multiplier
        }
        return cbm
    }

    // Cylinder = (Pi * Radius^2 * Length) / 10000
    // Radius: Use rounded small end short diameter to calculate radius
    // Rounding
    //     For <= 0.5 round down
    //     For > 0.5 round up
    static cylinderShort(shortCm: number, lengthMeters: number) {
        const radius = MathUtils.round0Special(shortCm) / 2
        return (Math.PI * Math.pow(radius, 2) * lengthMeters) / 10000
    }

    // Cylinder = (Pi * Radius^2 * Length) / 10000
    // Radius: Use average of short and long diameter to calculate radius
    // Rounding
    //     For <= 0.5 round down
    //     For > 0.5 round up
    static cylinderAverage(shortCm: number, longCm: number, lengthMeters: number) {
        const radius = MathUtils.round0Special((shortCm + longCm) / 2) / 2
        return (Math.PI * Math.pow(radius, 2) * lengthMeters) / 10000
    }

    huber = (): number => {
        let taper = 1

        switch (this.species) {
            case Species.Pine:
            case Species.PineElliottii:
            case Species.PineSouthernYellow:
            case Species.PineTaeda:
                taper = 1
                break
            case Species.EucalyptusBenthammii:
            case Species.EucalyptusDennii:
            case Species.EucalyptusGrandis:
            case Species.EucalyptusSaligna:
            case Species.EucalyptusUrograndis:
            case Species.Other:
            case Species.Eucalyptus:
                taper = 0.7
                break
        }

        const mid = this.avg_cm + (this.length_meters / 2) * taper
        const cbm = Math.pow(mid, 2) * this.length_meters * 0.00007854
        return cbm
    }

    doyle = (): number => {
        const boardFeet = Math.pow(this.avg_in - 4, 2) * (this.length_feet / 16)
        return boardFeet
    }

    scribner = (): number => {
        const a = 0.79
        const b = 2
        const c = 4
        const d = 16
        const first = a * Math.pow(this.avg_in, 2)
        const second = b * this.avg_in
        const third = this.length_feet / d
        const boardFeet = (first - second - c) * third
        return boardFeet
    }

    international = (): number => {
        const a = 0.04976191
        const b = 0.006220239
        const c = 0.1854762
        const d = 0.0002591767
        const e = 0.01159226
        const f = 0.04222222
        const first = a * this.length_feet * Math.pow(this.avg_in, 2)
        const second = b * Math.pow(this.length_feet, 2) * this.avg_in
        const third = c * this.length_feet * this.avg_in
        const fourth = d * Math.pow(this.length_feet, 3)
        const fifth = e * Math.pow(this.length_feet, 2)
        const sixth = f * this.length_feet
        const boardFeet = first + second - third + fourth - fifth + sixth
        return boardFeet
    }

    static getJasRoundededShortDiameter(short: number, long: number) {
        let multiplier = 0
        let roundedShort = Math.floor(short)

        if (roundedShort >= 14) {
            if (MathUtils.isOdd(roundedShort)) {
                roundedShort = roundedShort - 1
            }
        }
        const difference = long - roundedShort

        if (difference > 0) {
            if (roundedShort >= 14 && roundedShort <= 40) {
                multiplier = Math.floor(difference / 6)
            }

            if (roundedShort > 40) {
                multiplier = Math.floor(difference / 8)
            }
        }
        roundedShort = roundedShort + 2 * multiplier
        return Math.round(roundedShort)
    }
}
