import {useMediaQuery} from "react-responsive"
import {QRsWeb} from "./Web"
import {QRsMobile} from "./Mobile"
import {Constants} from "../../../../config/constants"

export const QRs = () => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <QRsWeb />}
            {isMobile && <QRsMobile />}
        </>
    )
}

export interface QRsProps {
    qrs: QRProps[]
    title: string
}

export interface QRProps {
    key: string
    url: string
    label: string
    image_url: string
}
