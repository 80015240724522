import * as React from "react"
import {useAppSelector} from "../../../app/hooks"
import Dialog from "@mui/material/Dialog"
import {setShowModal} from "../../../components/modal/slice"
import {store} from "../../../app/store"
import {WeightsFormModalView} from "../../form/Weights/WeightsFormModalView"
import {modalMaxWidth, modalType, showModal} from "../../../components/modal/selectors"
import {CommodityGroupFormModalView} from "../../form/CommodityGroup/CommodityGroupFormModalView"
import {ActionsModalView} from "./ModalViews/ActionsModalView"
import {Transition} from "../fullscreen/sfc/FullscreenTransition"
import {ModalType} from "../../../components/modal/state"
import {EditCommodityModalView} from "./ModalViews/EditCommodityModalView"
import {EditBusinessModalView} from "./ModalViews/EditBusinessModalView"
import {ConnectionsModalView} from "./ModalViews/Connections/ConnectionsModalView"
import {ForestsModalView} from "./ModalViews/Forests/ForestsModalView"
import {CommodityHistoryModalView} from "./ModalViews/CommodityHistoryModalView"

export const ModalView = () => {
    const show = useAppSelector(showModal)
    const type = useAppSelector(modalType)
    const maxWidth = useAppSelector(modalMaxWidth)

    const renderModal = () => {
        switch (type) {
            case ModalType.Actions:
                return <ActionsModalView />
            case ModalType.CommodityGroupForm:
                return <CommodityGroupFormModalView />
            case ModalType.Connections:
                return <ConnectionsModalView />
            case ModalType.Forests:
                return <ForestsModalView />
            case ModalType.EditBusiness:
                return <EditBusinessModalView />
            case ModalType.CommodityHistory:
                return <CommodityHistoryModalView />
            case ModalType.EditCommodity:
                return <EditCommodityModalView />
            case ModalType.WeightsForm:
                return <WeightsFormModalView />
        }
    }

    return (
        <Dialog
            TransitionComponent={Transition}
            maxWidth={maxWidth}
            open={show}
            onClose={(e) => store.dispatch(setShowModal(false))}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {renderModal()}
        </Dialog>
    )
}
