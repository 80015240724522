import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import CommodityGroupPickerForm from "./CommodityGroupPickerForm"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"
import {setOrderForm} from "../../commodityGroup/slice"
import CommodityUtils from "../../commodityGroups/listeners/CommodityUtils"

// const log = new Logger("OrderForm")

export default class OrderForm extends CommodityGroupPickerForm {
    vessel?: CommodityGroup
    open_vessels: CommodityGroup[] = []

    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group, pickerOptions, linkedGroups)
        this.setVessel()
        this.setOpenVessels()
        this.setState()
    }

    update() {
        super.update()
        this.addLinkedGroup(this.vessel)
    }

    private setOpenVessels() {
        this.open_vessels = CommodityUtils.openGroupsOfType(this.picker_options, CommodityGroupType.vessel)
        if (this.vessel !== undefined) {
            return
        }

        this.vessel = this.open_vessels[0]
    }

    private setVessel() {
        let vessel = this.linkedGroupOfType(CommodityGroupType.vessel)
        if (vessel === undefined) {
            return
        }
        this.vessel = vessel
    }

    //user interaction

    private setState() {
        store.dispatch(setOrderForm(this))
    }

    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }

    didSelectVessel(id: string) {
        let vessel = this.matchingOption(id)
        if (vessel === undefined) {
            return
        }
        this.vessel = vessel
        this.setState()
    }
}
