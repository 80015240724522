import React from "react"
import {ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap"
import {Locale, Localization} from "../../../i18n/Localization"
import {useTranslation} from "react-i18next"
import {DropdownStyleWrapper5} from "../../newStyles/DropdownButton"
import {FontSize} from "../../newStyles/Generic"

export function LanguageSelector2() {
    const {t} = useTranslation("language")
    const [locale, setLocale] = React.useState<Locale>(Localization.userLocale)

    const onClick = (localeString: string | null) => {
        if (!localeString) return
        const locale = localeString as Locale
        setLocale(locale)
        Localization.userLocale = locale
    }

    return (
        <DropdownStyleWrapper5>
            <DropdownButton bsSize="small" style={{fontSize: FontSize._6}} as={ButtonGroup} variant={locale} title={t(locale)} onSelect={onClick}>
                <Dropdown.Item eventKey={Locale.en}>{t(Locale.en)}</Dropdown.Item>
                <Dropdown.Item eventKey={Locale.pt}>{t(Locale.pt)}</Dropdown.Item>
                <Dropdown.Item eventKey={Locale.es}>{t(Locale.es)}</Dropdown.Item>
            </DropdownButton>
        </DropdownStyleWrapper5>
    )
}
