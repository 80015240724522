import React from "react"
import {ButtonGroupSelectors} from "../sfc/ButtonGroupSelectors"
import {useAppSelector} from "../../../app/hooks"
import {RootState} from "../../../app/store"
import {locale} from "../../../components/authentication/selectors"
import {useTranslation} from "react-i18next"
import {Locale, Localization} from "../../../i18n/Localization"

function localeToLanguage(locale: Locale) {
    switch (locale) {
        case Locale.en:
            return "english"
        case Locale.es:
            return "spanish"
        case Locale.pt:
            return "portuguese"
    }
}

function languageToLocale(language: string) {
    switch (language) {
        case "english":
            return Locale.en
        case "spanish":
            return Locale.es
        case "portuguese":
            return Locale.pt
        default:
            return undefined
    }
}

export function language(state: RootState): string {
    return localeToLanguage(locale(state))
}

// Deprecated
// TODO replace with LanguageSelector2
export const LanguageSelector = () => {
    const {t} = useTranslation("language")
    const selected = useAppSelector(language)
    let options = Object.keys(Locale).map((locale) => localeToLanguage(locale as Locale))

    const onClick = (language: string) => {
        const locale = languageToLocale(language)
        if (!locale) {
            return
        }

        Localization.userLocale = locale
    }

    return <ButtonGroupSelectors handleClick={onClick} options={options} localization={options.map((option) => t(option))} selected={selected} />
}
