import React from "react"
import {MenuItem, TextField} from "@mui/material"
import {MovementType} from "@common/types/MovementType"

export const MovementTypeSelectorView = (props: MovementTypeSelectorProps) => {
    return (
        <TextField
            id={"Movement-Type"}
            error={props.value === undefined}
            select
            label={"Movement"}
            value={props.value}
            onChange={(e) => props.on_change(e.target.value)}
        >
            {Object.keys(MovementType).map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    )
}

interface MovementTypeSelectorProps {
    value?: MovementType
    on_change: (logSpecies: string) => void
}
