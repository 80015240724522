import {StyledDataGrid} from "../../../../dataTable/tables/sfc/styledDataGrid"
import Form from "react-bootstrap/Form"
import {Button} from "@mui/material"
import React, {MutableRefObject, useEffect, useRef, useState} from "react"
import {Unsubscribe} from "firebase/firestore"
import {components} from "../../../../../app/store"
import Utils from "../../../../../utils/Utils"
import {GridRenderCellParams} from "@mui/x-data-grid-premium"
import {RowFlexContainer} from "../../../../styles"
import {ErrorManager} from "../../../../../components/errorManager"
import {SpacerV14} from "../../../../newStyles/Spacer"

export function InvitesView() {
    const [inviteEmail, setInviteEmail] = useState("")
    const [rows, setRows] = useState<{id: number; email: string}[]>([])
    const invitesUnsubscribe: MutableRefObject<Unsubscribe | null> = useRef(null)

    useEffect(() => {
        invitesUnsubscribe.current = components.invites.listenForInvitesOfMyBusiness((invites) => {
            console.log(`InvitesView.businessInvite.listen invites.length:${invites.length}`)

            let id = 0
            const rows = invites.map((invite) => {
                return {id: id++, email: invite.invitee_email}
            })
            setRows(rows)
        })

        return () => {
            if (invitesUnsubscribe.current) {
                invitesUnsubscribe.current()
                invitesUnsubscribe.current = null
            }
        }
    }, []) // Empty array deps = useEffect() runs once

    function setEmailChecked(email: string) {
        setInviteEmail(Utils.isValidEmail(email) ? email : "")
    }

    function onSubmitInvite(event: any) {
        event.preventDefault()
        const email = inviteEmail
        setInviteEmail("")

        components.invites
            .sendInvite(email)
            .then((success) => {
                alert(
                    success
                        ? `Invite is sent successfully to "${email}". Now wait for them to join Timbereye!`
                        : `Cannot send an invite - user with email "${email}" already exists.`,
                )
            })
            .catch((error) => showErrorAlert("onSubmitInvite", error))
    }

    function onReInviteButtonClick(email: string) {
        components.invites
            .reInvite(email)
            .then((_) => alert(`Invite is sent successfully to "${email}". Now wait for them to join Timbereye!`))
            .catch((error) => showErrorAlert("onReInviteButtonClick", error))
    }

    function onDeleteInviteButtonClick(email: string) {
        components.invites
            .deleteInvite(email)
            .then()
            .catch((error) => showErrorAlert("onDeleteInviteButtonClick", error))
    }

    const columns = () => [
        {field: "email", headerName: "Email", width: 250},
        {
            field: "buttons",
            headerName: "",
            width: 175,
            renderCell: (params: GridRenderCellParams) => (
                <RowFlexContainer justify-content="space-between">
                    <Button variant={"contained"} sx={{fontSize: "small"}} onClick={() => onReInviteButtonClick(params.row.email)}>
                        Re-invite
                    </Button>
                    <Button sx={{fontSize: "small"}} onClick={() => onDeleteInviteButtonClick(params.row.email)}>
                        Delete
                    </Button>
                </RowFlexContainer>
            ),
        },
    ]

    return (
        <>
            <div style={{height: 230, width: "100%"}}>
                <StyledDataGrid rows={rows} columns={columns()} disableColumnMenu disableChildrenSorting hideFooter />
            </div>
            <SpacerV14 />
            <Form id="invites_form">
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="emailInput">Invite a business</Form.Label>
                    <Form.Control id="emailInput" type="email" placeholder="Enter email" onChange={(e) => setEmailChecked(e.target.value)} />
                    <Button type="submit" onClick={onSubmitInvite} disabled={inviteEmail.length < 1}>
                        Invite
                    </Button>
                </Form.Group>
            </Form>
        </>
    )
}

function showErrorAlert(functionName: string, error: unknown) {
    ErrorManager.recordErrorAndAlert("InvitesView", functionName, error as Error)
}
