import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupIdentifiers, CommodityGroupType, CommodityGroupUserEntries} from "@common/types/commodity/commodityGroup"
import {firebase, store} from "../../../../app/store"
import {setShowModal} from "../../../modal/slice"
import CommodityGroupIDLabels from "../../../localization/labels/CommodityGroupIDLabels"
import BusinessTypeLabels from "../../../localization/labels/BusinessTypeLabels"
import ArrayUtils from "@common/utils/ArrayUtils"
import Logger from "../../../../helpers/Logger"
import {Localization} from "../../../../i18n/Localization"

const log = new Logger("CommodityGroupForm")

export default class CommodityGroupForm {
    id_labels: CommodityGroupIDLabels
    business_type_labels: BusinessTypeLabels
    group: CommodityGroup
    first_id: string = ""

    constructor(group: CommodityGroup) {
        this.group = group
        this.first_id = group.user_entries.identifiers.first_id
        let language = Localization.userLocale
        this.id_labels = new CommodityGroupIDLabels(language)
        this.business_type_labels = new BusinessTypeLabels(language)
    }

    update() {
        let updatedIdentifiers: CommodityGroupIdentifiers = {...this.group.user_entries.identifiers, first_id: this.first_id}
        let updatedUserEntries: CommodityGroupUserEntries = {...this.group.user_entries, identifiers: updatedIdentifiers}
        this.group = {...this.group, user_entries: updatedUserEntries}
    }

    submit() {
        store.dispatch(setShowModal(false))
        log.d("submit", `Length: ${this.group.user_entries.length?.length}`)
        this.submitAsync().then()
    }

    private async submitAsync() {
        await this.updateLinkedCommodityGroupsOneGroupPerType()
        await firebase.commodity_group.create.createCommodityGroup(this.group)
        this.reassignInboundCommoditiesToNewRowUponFormRowChange()
    }

    protected async updateLinkedCommodityGroupsOneGroupPerType() {}

    private reassignInboundCommoditiesToNewRowUponFormRowChange() {
        if (this.group?.type !== CommodityGroupType.inbound_load) {
            return
        }
        firebase.api_gateway.create.createInboundRowChangeTrigger(this.group.id).then()
    }

    updateIdentifiers = (identifiers: CommodityGroupIdentifiers) => {
        let updatedEntries: CommodityGroupUserEntries = {...this.group.user_entries, identifiers: identifiers}
        this.updateUserEntries(updatedEntries)
    }

    updateUserEntries = (userEntries: CommodityGroupUserEntries) => {
        let updatedGroup = {...this.group, user_entries: userEntries}
        this.group = updatedGroup
    }

    uniqueUpdatingIds = (formIds: string[], existingIds: string[]): string[] => {
        let allIds = formIds.concat(existingIds)
        return ArrayUtils.distinct(allIds)
    }

    //user interaction
    didEditFirstId(id: string) {
        log.d("didEditFirstId", `Form id:${id}`)
        this.first_id = id
    }
}
