import {RootState} from "../../../../app/store"
import {GridValidRowModel} from "@mui/x-data-grid-premium"
import CommodityGroupViewModel from "../../../commodities/commodityGroups/viewModel/CommodityGroupViewModel"
import {CommodityGroupColumnID, groupColumnTypes} from "../Columns/selectors"
import {LogClass, MeasurementSystem, Species} from "@common/types/commodity/commodity"
import {showCurrentStock} from "../selectors"
import {MovementType} from "@common/types/MovementType"
import MeasurementsTransformations from "../../helpers/MeasurementsTransformations"
import {BusinessType} from "@common/types/business/businessTypes"
import MathUtils from "@common/utils/MathUtils"
import DateFormatters from "../../../../utils/DateFormatters"
import Utils from "../../../../utils/Utils"
import {measurementSystem} from "../../../authentication/selectors"
import Display from "../../../../utils/Display"

export const rows = (state: RootState): GridValidRowModel[] => {
    let groups = state.commodityGroups.groups
    return groups.map((group) => row(group, state))
}

const getVisionCount = (model: CommodityGroupViewModel): number => {
    let images = model.group.images?.images
    if (images === undefined) {
        return 0
    }
    let counts = images.map((image) => image.bounding_box_count)
    let noUndefineds = counts.filter((count) => count !== undefined) as number[]
    if (!noUndefineds.length) {
        return 0
    }
    let sum = noUndefineds.reduce((a, b) => a + b)
    return sum
}

const row = (model: CommodityGroupViewModel, state: RootState) => {
    const showCurrent = showCurrentStock(state)
    const system = measurementSystem(state)
    let group = model.group
    let columnTypes = groupColumnTypes(state)
    let stock = showCurrent ? model.group.stock?.current : model.group.stock?.all_time
    let transformations = new MeasurementsTransformations(state, model.group.user_entries.properties?.log?.log_class, stock)
    let length = displayLength(model.group.user_entries.length?.length, system)
    let scanWeight = displayWeightTons(stock?.weight?.weight, system)
    let weight = displayWeightTons(group.user_entries.weight?.weight, system)
    let gross = displayWeightTons(group.user_entries.weight?.gross_weight, system)
    let empty = displayWeightTons(group.user_entries.weight?.empty_weight, system)
    let density = Utils.safeDensity(weight, transformations.volume)
    let spec = model.group.user_entries.properties?.log?.species ? model.group.user_entries.properties.log?.species : Species.Pine
    let logClass = model.group.user_entries.properties?.log?.log_class ? model.group.user_entries.properties.log?.log_class : LogClass.saw_log
    let mov = model.group.user_entries.movement ? model.group.user_entries.movement : MovementType.atOrigin
    let created = DateFormatters.getFormattedDateFromTimestamp(group.times.created) + ", " + DateFormatters.getFormattedTimeFromTimestamp(group.times.created)
    let ready = DateFormatters.getFormattedDateFromTimestamp(group.times.ready) + ", " + DateFormatters.getFormattedTimeFromTimestamp(group.times.ready)
    let closed = DateFormatters.getFormattedDateFromTimestamp(group.times.closed) + ", " + DateFormatters.getFormattedTimeFromTimestamp(group.times.closed)
    let archived =
        DateFormatters.getFormattedDateFromTimestamp(group.times.archived) + ", " + DateFormatters.getFormattedTimeFromTimestamp(group.times.archived)
    let row = model.row ? model.row.user_entries.identifiers.first_id : "-"
    let order = model.order ? model.order.user_entries.identifiers.first_id : "-"
    let zone = model.zone ? model.zone.user_entries.identifiers.first_id : "-"
    let vessel = model.vessel ? model.vessel.user_entries.identifiers.first_id : "-"
    let hold = model.vessel_section ? model.vessel_section.user_entries.identifiers.first_id : "-"
    let audited: boolean = model.group.audit_complete ? model.group.audit_complete : false
    let forester = model.group.linked_businesses.find((business) => business.type === BusinessType.Forester)
    let logger = model.group.linked_businesses.find((business) => business.type === BusinessType.Logger)
    let trucker = model.group.linked_businesses.find((business) => business.type === BusinessType.Trucker)

    const obj: Record<string, any> = {}
    obj.id = group.id
    columnTypes.forEach((column) => {
        switch (column.id) {
            case CommodityGroupColumnID.business:
                obj.business = model.business_name ? model.business_name : "-"
                break
            case CommodityGroupColumnID.vision_count:
                obj.vision_count = getVisionCount(model)
                break
            case CommodityGroupColumnID.first_id:
                obj.first_id = group.user_entries.identifiers.first_id
                break
            case CommodityGroupColumnID.second_id:
                obj.second_id = group.user_entries.identifiers.second_id
                break
            case CommodityGroupColumnID.third_id:
                obj.third_id = group.user_entries.identifiers.third_id
                break
            case CommodityGroupColumnID.created_date:
                obj.created_date = created
                break
            case CommodityGroupColumnID.ready_date:
                obj.ready_date = ready
                break
            case CommodityGroupColumnID.closed_date:
                obj.closed_date = closed
                break
            case CommodityGroupColumnID.archived_date:
                obj.archived_date = archived
                break
            case CommodityGroupColumnID.status:
                obj.status = group.status
                break
            case CommodityGroupColumnID.count:
                obj.count = transformations.count
                break
            case CommodityGroupColumnID.sed:
                obj.sed = transformations.short
                break
            case CommodityGroupColumnID.length:
                obj.length = length
                break
            case CommodityGroupColumnID.species:
                obj.species = spec
                break
            case CommodityGroupColumnID.log_class:
                obj.log_class = logClass
                break
            case CommodityGroupColumnID.movement:
                obj.movement = mov
                break
            case CommodityGroupColumnID.audited:
                obj.audited = audited ? "Audited" : "Not Audited"
                break
            case CommodityGroupColumnID.forester:
                obj.forester = forester ? forester.business.name : "-"
                break
            case CommodityGroupColumnID.forest:
                obj.forest = forester?.forest?.name ?? "-"
                break
            case CommodityGroupColumnID.logger:
                obj.logger = logger ? logger.business.name : "-"
                break
            case CommodityGroupColumnID.trucker:
                obj.trucker = trucker ? trucker.business.name : "-"
                break
            case CommodityGroupColumnID.scan_weight:
                obj.scan_weight = scanWeight
                break
            case CommodityGroupColumnID.weight:
                obj.weight = weight
                break
            case CommodityGroupColumnID.gross:
                obj.gross = gross
                break
            case CommodityGroupColumnID.empty:
                obj.empty = empty
                break
            case CommodityGroupColumnID.volume:
                obj.volume = transformations.volume
                break
            case CommodityGroupColumnID.density:
                obj.density = density
                break
            case CommodityGroupColumnID.zone:
                obj.zone = zone
                break
            case CommodityGroupColumnID.row:
                obj.row = row
                break
            case CommodityGroupColumnID.order:
                obj.order = order
                break
            case CommodityGroupColumnID.vessel:
                obj.vessel = vessel
                break
            case CommodityGroupColumnID.hold:
                obj.hold = hold
                break
        }
    })
    return obj
}

function displayWeightTons(weight: number | undefined, system: MeasurementSystem) {
    if (weight === undefined) return 0
    return MathUtils.round3(Display.displayWeightTons(weight, system))
}

function displayLength(lengthM: number | undefined, system: MeasurementSystem) {
    return lengthM ? MathUtils.round2(Display.displayLengthM(lengthM, system)) : 0
}
