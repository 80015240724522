import RowForm from "../../../../components/commodities/commodityGroupForm/Forms/RowForm"
import {Section} from "./sfc/Section"
import {CommodityGroupPickerView} from "./sfc/CommodityGroupPickerView"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {useAppSelector} from "../../../../app/hooks"
import {commodityGroupIDLabels} from "../../../../components/localization/selectors"
import {FormTextField} from "./sfc/FormTextFieldView"
import {LengthSelectorView} from "./sfc/LengthSelectorView"
import {SpeciesSelectorView} from "./sfc/SpeciesSelectorView"
import {LogClassSelectorView} from "./sfc/LogClassSelectorView"
import {LinkedBusinessSelectorView} from "./sfc/LinkedBusinessSelectorView"
import {MovementTypeSelectorView} from "./sfc/MovementTypeSelectorView"
import {SwitchView} from "./sfc/SwitchView"
import {LengthOptionsRange} from "../../../../components/commodities/commodityGroupForm/Helpers/LengthOption"

export const RowFields = (props: RowFormProps) => {
    let labels = useAppSelector(commodityGroupIDLabels)

    return (
        <>
            <Section>
                <CommodityGroupPickerView
                    selection={props.form.zone?.id}
                    type={CommodityGroupType.zone}
                    options={props.form.open_zones}
                    on_change={(id) => props.form.didSelectZone(id)}
                />
            </Section>
            <Section>
                <FormTextField value={props.form.first_id} label={labels.firstId(CommodityGroupType.row)} onChange={(id) => props.form.didEditFirstId(id)} />
                <FormTextField value={props.form.second_id} label={labels.secondId(CommodityGroupType.row)} onChange={(id) => props.form.didEditSecondId(id)} />
                <FormTextField value={props.form.third_id} label={labels.thirdId(CommodityGroupType.row)} onChange={(id) => props.form.didEditThirdId(id)} />
            </Section>
            <Section>
                <SwitchView
                    value={props.form.edit_log_properties}
                    onChange={() => props.form.toggleEditLogProperties()}
                    label={props.form.business_type_labels.logProperties()}
                />
                {props.form.edit_log_properties && (
                    <>
                        <LengthSelectorView value={LengthOptionsRange.rounded(props.form.length)} on_change={(length) => props.form.didSelectLength(length)} />
                        <SpeciesSelectorView value={props.form.properties.species} on_change={(species) => props.form.didSelectSpecies(species)} />
                        <LogClassSelectorView value={props.form.properties.log_class} on_change={(logClass) => props.form.didSelectLogClass(logClass)} />
                    </>
                )}
            </Section>
            <Section>
                <SwitchView
                    value={props.form.add_partners}
                    onChange={() => props.form.toggleAddPartners()}
                    label={props.form.business_type_labels.partners()}
                />
                {props.form.add_partners &&
                    props.form.linked_business_types.map((type) => (
                        <LinkedBusinessSelectorView
                            key={type}
                            linked={props.form.linked_businesses}
                            link={(bus) => props.form.linkBusiness(bus)}
                            unlink={(type) => props.form.unLinkBusiness(type)}
                            type={type}
                        />
                    ))}
            </Section>
            <Section>
                <MovementTypeSelectorView value={props.form.movement} on_change={(movement) => props.form.didSelectMovement(movement)} />
            </Section>
        </>
    )
}

export interface RowFormProps {
    form: RowForm
}
