import * as React from "react"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker"
import Logger from "../../../helpers/Logger"
import {Box} from "@mui/material"

const log = new Logger("DatePicker")

export default function DatePicker(props: DatePickerProps) {
    const handleChange = (newValue: Date | null) => {
        if (!newValue || isNaN(newValue.getDate())) {
            log.d("handleChange", "Invalid date")
            return
        }

        props.handleChange(newValue)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{width: 180}}>
                <DesktopDatePicker label={props.label} value={props.date} onChange={handleChange} />
            </Box>
        </LocalizationProvider>
    )
}

export interface DatePickerProps {
    marginRight: number
    label: string
    date: Date
    handleChange: (date: Date) => void
}
