import {constants} from "../../../../../config/constants"
import {LabelBoldBlack, LabelBoldBlackUnderlinedLink} from "../../../Styles/Fonts"

export const NavigationLinkBarCenterContent = () => (
    <>
        <LabelBoldBlack>{"Copilot has landed. Check out the video"}</LabelBoldBlack>
        <span> </span>
        <LabelBoldBlackUnderlinedLink target="_blank" rel="noopener noreferrer" href={constants.HOW_IT_WORKS_URL}>
            {"here."}
        </LabelBoldBlackUnderlinedLink>
    </>
)
