import {CommodityGroupImage} from "@common/types/commodity/commodityGroup"

export enum ModalType {
    Actions = "Actions",
    CommodityGroupForm = "InboundLoadForm",
    EditCommodity = "EditCommodity",
    WeightsForm = "WeightsForm",

    // Navbar->Offcanvas dialogs

    Connections = "Connections",
    Forests = "Forests",
    EditBusiness = "EditBusiness",
    CommodityHistory = "CommodityHistory",
}

export enum FullscreenModalType {
    CommodityGroup = "CommodityGroup",
    Reports = "Reports",
}

export interface ModalState {
    show: boolean
    show_fullscreen: boolean
    modal_type: ModalType
    full_screen_modal_type: FullscreenModalType
    modal_max_width: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined
    images?: CommodityGroupImage[]
}

export const initialState: ModalState = {
    show: false,
    show_fullscreen: false,
    modal_type: ModalType.CommodityGroupForm,
    full_screen_modal_type: FullscreenModalType.CommodityGroup,
    modal_max_width: "xs",
}
