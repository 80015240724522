import {CommodityGroup} from "../../types/commodity/CommodityGroup"
import {firebase} from "../../app/store"
import {Business} from "../../types/Business"
import {IdCache, LoadMultiple} from "@common/firebase/firestore/IdCache"

export class BusinessCache extends IdCache<Business> {
    constructor() {
        super("BusinessCache", firebase.business.getBusiness, new LoadMultiple(firebase.business.getBusinesses, "id"))
    }
}

export class CommodityGroupCache extends IdCache<CommodityGroup> {
    constructor() {
        super("CommodityGroupCache", firebase.commodity_group.read.getCommodityGroup, new LoadMultiple(firebase.commodity_group.read.getCommodityGroups, "id"))
    }
}
