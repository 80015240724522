import LoadForm from "./LoadForm"
import {CommodityGroup} from "../../../../types/commodity/CommodityGroup"
import {CommodityGroupStatus} from "@common/types/commodity/commodityGroup"
import {store} from "../../../../app/store"
import {setInboundForm} from "../../commodityGroup/slice"
import {LinkedBusiness} from "../../../../types/Business"
import {BusinessType} from "@common/types/business/businessTypes"

export default class InboundForm extends LoadForm {
    open_rows: CommodityGroup[] = []
    constructor(group: CommodityGroup, pickerOptions: CommodityGroup[], linkedGroups?: CommodityGroup[]) {
        super(group, pickerOptions, linkedGroups)
        this.setOpenRows()
        this.setState()
    }
    setOpenRows() {
        this.open_rows = this.zone_rows.filter((row) => row.status === CommodityGroupStatus.Open)
        if (this.row !== undefined) {
            this.open_rows.push(this.row)
            return
        }
        this.row = this.open_rows[0]
    }

    //user interaction
    setState() {
        store.dispatch(setInboundForm(this))
    }
    didEditFirstId(id: string) {
        super.didEditFirstId(id)
        this.setState()
    }
    didEditSecondId(id: string) {
        super.didEditSecondId(id)
        this.setState()
    }
    didEditThirdId(id: string) {
        super.didEditThirdId(id)
        this.setState()
    }
    didSelectLength(length: string) {
        super.didSelectLength(length)
        this.setState()
    }
    didSelectSpecies(species: string) {
        super.didSelectSpecies(species)
        this.setState()
    }
    didSelectLogClass(logClass: string) {
        super.didSelectLogClass(logClass)
        this.setState()
    }
    linkBusiness(newLink: LinkedBusiness) {
        super.linkBusiness(newLink)
        this.setState()
    }
    unLinkBusiness(type: BusinessType) {
        super.unLinkBusiness(type)
        this.setState()
    }
    didSelectZone(id: string) {
        super.didSelectZone(id)
        this.setOpenRows()
        this.setState()
    }
    didSelectRow(id: string) {
        super.didSelectRow(id)
        this.setState()
    }
    toggleEditLogProperties() {
        super.toggleEditLogProperties()
        this.setState()
    }
    toggleAddPartners() {
        super.toggleAddPartners()
        this.setState()
    }
}
