import {PersistentStorage} from "../PersistentStorage"
import i18n from "i18next"
import {setLocale} from "../components/authentication/slice"
import {store} from "../app/store"

export enum Locale {
    en = "en",
    pt = "pt",
    es = "es",
}

export class Localization {
    static defaultLocale = Locale.en

    static get userLocale() {
        const languageString = PersistentStorage.userLocale
        if (languageString) {
            return languageString as Locale
        }

        return Localization.defaultLocale
    }

    static set userLocale(locale: Locale) {
        i18n.changeLanguage(locale).then()
        PersistentStorage.userLocale = locale
        // TODO remove after "switch" type localizations will be migrated to i18n
        // This call only needed to update their state
        // Note: this must be called after [PersistentStorage.userLocale = locale] for getter to return the correct value
        store.dispatch(setLocale(locale))
    }
}
