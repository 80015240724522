import styled from "styled-components"
import {Subtitle3, Title5} from "../../../Styles/Fonts"
import {LabelContainer} from "../../MarketingCover/Mobile/styles"

export const MobileSectionSubtitle = styled(Subtitle3)`
    margin-top: 25px;
    padding-bottom: 25px;
    padding-bottom: 35px;
`

export const MobileSectionContainer = styled(LabelContainer)`
    background-color: white;
`

export const MobileSectionTitle = styled(Title5)`
    -webkit-text-stroke: 2px black;
    line-height: 60px;
`
