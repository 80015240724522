import {Commodity} from "../../../../types/commodity/Commodity"
import {CommodityPoints, GridPoint, IdentifiableGridPoint, MidPoints, OrderedPoints} from "@common/types/commodity/commodity"
import {getCommodityImageUrlFromServer} from "../../../../components/commodities/editCommodity/selectors"

export default class CommodityImageDrawing {
    RED = "#FF0000"
    TIMBEREYE_GREEN = "#00EF0C"
    TRANSPARENT_TIMBEREYE_GREEN = "rgba(0,239,12,0.25)"
    TRANSPARENT_WHITE = "rgba(244,244,244,0.55)"
    WHITE = "#FFF"

    draw = (isEditable: boolean, log: Commodity, height: number, perimeter: IdentifiableGridPoint[], center?: GridPoint, logPoints?: MidPoints) => {
        let tag = isEditable ? "B" : "A"
        const canvas: HTMLCanvasElement | null = document.querySelector("#" + tag + log.id)
        if (canvas == null) {
            return
        }
        const context = canvas.getContext("2d")
        if (context == null) {
            return
        }
        getCommodityImageUrlFromServer(log).then((url) => {
            if (url === undefined) {
                return
            }
            var img = new Image()
            img.onload = () => {
                context.clearRect(0, 0, canvas.width, canvas.height)
                context.drawImage(img, 0, 0, canvas.width, canvas.height)
                if (!isEditable) {
                    this.staticDraw(context, log, height, perimeter)
                }
                if (isEditable) {
                    this.editableDraw(context, height, perimeter, log, center, logPoints)
                }
            }
            img.src = url.toString()
        })
    }

    staticDraw = (context: any, log: Commodity, height: number, perimeter: IdentifiableGridPoint[]) => {
        if (!perimeter.length) {
            return
        }
        this.drawAndFillPerimeter(context, height, perimeter)
        this.drawQrLines(context, height, log.points)
        this.drawLogLines(context, height, log.points)
        this.drawPerimeterPointsWithLines(context, height, perimeter)
        this.drawDefectPoints(context, height, log?.points?.largest_defect_points)
    }

    editableDraw = (context: any, height: number, perimeter: IdentifiableGridPoint[], log?: Commodity, center?: GridPoint, logPoints?: MidPoints) => {
        this.drawAndFillPerimeter(context, height, perimeter)
        this.drawPerimeterPoints(context, perimeter)
        this.drawDefectPoints(context, height, log?.points?.largest_defect_points)
        if (logPoints !== undefined) {
            this.drawMidPoints(this.WHITE, context, logPoints, height)
        }

        if (center !== undefined) {
            this.drawCenterPoint(context, center.x * height, center.y * height)
        }
    }

    drawQrLines = (context: any, height: number, points?: CommodityPoints) => {
        if (points === undefined) {
            return
        }
        let qrPoints = points.qr
        if (qrPoints === undefined) {
            return
        }
        let orderedPoints = this.getOrderedPoints(qrPoints)
        if (orderedPoints === undefined) {
            return
        }
        this.drawPoints(this.TIMBEREYE_GREEN, context, orderedPoints, height)
    }

    drawLogLines = (context: any, height: number, points?: CommodityPoints) => {
        if (points === undefined) {
            return
        }
        let first = points.diameter_one_points
        let second = points.diameter_two_points
        if (first === undefined) {
            return
        }
        if (second === undefined) {
            return
        }
        let midPoints: MidPoints = {
            first: first,
            height: 0,
            second: second,
            width: 0,
        }
        this.drawMidPoints(this.WHITE, context, midPoints, height)
    }

    drawAndFillPerimeter = (context: any, height: number, points?: IdentifiableGridPoint[]) => {
        if (points === undefined) {
            return
        }
        if (!points.length) {
            return
        }
        let region = this.drawPerimeter(context, height, points)
        if (region === undefined) {
            return
        }
        context.fillStyle = this.TRANSPARENT_TIMBEREYE_GREEN
        context.fill(region)
    }

    drawPerimeter = (context: any, height: number, points: IdentifiableGridPoint[]): Path2D | undefined => {
        if (points === undefined) {
            return
        }
        if (!points.length) {
            return
        }
        let start = points[0]
        let region = new Path2D()
        region.moveTo(start.point.x, start.point.y)
        points.map((point) => region.lineTo(point.point.x, point.point.y))
        region.closePath()
        return region
    }

    drawPerimeterPointsWithLines = (context: any, height: number, points: IdentifiableGridPoint[]) => {
        if (points.length < 2) {
            return
        }
        if (!points.length) {
            return
        }
        let reducedPoints = points
        let originalFirst = reducedPoints[0]

        while (reducedPoints.length >= 0) {
            let first = reducedPoints[0]
            let second = reducedPoints[1]

            reducedPoints = reducedPoints.filter((point) => {
                if (point.point.x === first.point.x && point.point.y === first.point.y) {
                    return false
                }
                return true
            })
            let altSecond = second
            if (first === undefined) {
                return
            }
            if (second === undefined) {
                return
            }
            if (reducedPoints.length <= 1) {
                altSecond = originalFirst
            }
            this.drawTwoPoints(this.TIMBEREYE_GREEN, context, first.point, altSecond.point)
        }
    }

    drawPerimeterPoints = (context: any, points: IdentifiableGridPoint[]) => {
        points.map((point) => this.drawEditablePoint(context, point.point.x, point.point.y))
    }

    drawDefectPoints = (context: any, height: number, points?: GridPoint[]) => {
        if (points === undefined) {
            return
        }
        let ordered = this.getOrderedPoints(points)
        if (ordered === undefined) {
            return
        }
        this.drawPoints(this.RED, context, ordered, height)
    }

    getOrderedPoints = (original: GridPoint[]): OrderedPoints | undefined => {
        if (original.length !== 4) {
            return
        }
        let points = [...original]
        let sortVertically = points.sort((a, b) => a.y + b.y)
        let top = sortVertically[0]
        let altTop = sortVertically[1]
        let bottom = sortVertically[3]
        let altBottom = sortVertically[2]
        let tops = [top, altTop]
        let bottoms = [bottom, altBottom]
        let topsSortedX = tops.sort((a, b) => a.x - b.x)
        let botSortedX = bottoms.sort((a, b) => a.x - b.x)
        let ordered: OrderedPoints = {points: [topsSortedX[0], topsSortedX[1], botSortedX[0], botSortedX[1]]}
        return ordered
    }

    drawTwoPoints = (color: string, context: any, first: GridPoint, second: GridPoint) => {
        const x1 = first.x
        const y1 = first.y
        const x2 = second.x
        const y2 = second.y
        this.drawLine(context, [x1, y1], [x2, y2], color)
    }

    drawPoints = (color: string, context: any, points: OrderedPoints, height: number) => {
        const x1 = points.points[0].x * height
        const y1 = points.points[0].y * height
        const x2 = points.points[1].x * height
        const y2 = points.points[1].y * height
        const x3 = points.points[2].x * height
        const y3 = points.points[2].y * height
        const x4 = points.points[3].x * height
        const y4 = points.points[3].y * height
        this.drawLine(context, [x1, y1], [x2, y2], color)
        this.drawLine(context, [x1, y1], [x3, y3], color)
        this.drawLine(context, [x3, y3], [x4, y4], color)
        this.drawLine(context, [x2, y2], [x4, y4], color)
    }

    drawMidPoints = (color: string, context: any, points: MidPoints, height: number) => {
        if (points.first.length < 2) {
            return
        }
        if (points.second.length < 2) {
            return
        }
        let topMid = points.first[0]
        let bottomMid = points.first[1]
        let leftMid = points.second[0]
        let rightMid = points.second[1]
        const x1 = topMid.x * height
        const y1 = topMid.y * height
        const x2 = bottomMid.x * height
        const y2 = bottomMid.y * height
        const x3 = leftMid.x * height
        const y3 = leftMid.y * height
        const x4 = rightMid.x * height
        const y4 = rightMid.y * height
        this.drawLine(context, [x1, y1], [x2, y2], color)
        this.drawLine(context, [x3, y3], [x4, y4], color)
    }

    drawLine = (context: any, start: [number, number], end: [number, number], color: string) => {
        context.strokeStyle = color
        context.lineWidth = 3
        context.beginPath()
        context.moveTo(...start)
        context.lineTo(...end)
        context.stroke()
        context.closePath()
    }

    drawEditablePoint = (context: any, x: number, y: number) => {
        context.strokeStyle = this.WHITE
        context.fillStyle = this.TRANSPARENT_WHITE
        context.beginPath()
        context.roundRect(x - 10, y - 10, 25, 25, 80)
        context.fill()
        context.stroke()
    }
    drawCenterPoint = (context: any, x: number, y: number) => {
        context.strokeStyle = this.TRANSPARENT_WHITE
        context.fillStyle = this.TRANSPARENT_WHITE
        context.beginPath()
        context.roundRect(x - 50, y - 50, 100, 100, 80)
        context.fill()
        context.stroke()
    }
}
