import {firebase, store} from "../../app/store"
import {BusinessInvite} from "../../types/BusinessInvite"
import {Unsubscribe} from "firebase/firestore"

export default class Invites {
    async sendInvite(inviteeEmail: string): Promise<boolean> {
        const isUserExists = await firebase.user.isUserExistsWithEmail(inviteeEmail)
        if (isUserExists) {
            return false
        }

        await firebase.businessInvite.create(this.businessId, inviteeEmail)
        return true
    }

    async reInvite(inviteeEmail: string) {
        await firebase.businessInvite.reInvite(this.businessId, inviteeEmail)
    }

    async deleteInvite(inviteeEmail: string) {
        await firebase.businessInvite.delete(this.businessId, inviteeEmail)
    }

    listenForInvitesOfMyBusiness(completion: (invites: BusinessInvite[]) => void): Unsubscribe {
        return firebase.businessInvite.listen(this.businessId, completion)
    }

    private get businessId() {
        return store.getState().authentication.business!.id
    }
}
