import {store} from "../../../app/store"
import CommodityGroupViewModel from "../commodityGroups/viewModel/CommodityGroupViewModel"
import {setModalType, setShowModal} from "../../modal/slice"
import {ModalType} from "../../modal/state"

export default class CommodityGroupWeightsForm {
    userDidTap = (group: CommodityGroupViewModel) => {
        store.dispatch(setModalType(ModalType.WeightsForm))
        store.dispatch(setShowModal(true))
    }
}
