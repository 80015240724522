import {useMediaQuery} from "react-responsive"
import {WebFooter} from "./Web"
import {MobileFooter} from "./Mobile"
import {Constants} from "../../../../config/constants"

export const Footer = () => {
    const isMobile = useMediaQuery({query: Constants.MobileMaximumWindowWidth})

    return (
        <>
            {!isMobile && <WebFooter />}
            {isMobile && <MobileFooter />}
        </>
    )
}
