import styled from "styled-components"

export const StyledMarketingFooterMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;
    padding-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #000;
`

export const FooterLinksSectionContainerMobile = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    margin-top: 50px;
`
