import React from "react"
import {useAppSelector} from "../../../app/hooks"
import {store} from "../../../app/store"
import {FormControlLabel, Switch} from "@mui/material"
import {showCurrentStock} from "../../../components/dataTables/commodityGroup/selectors"
import {showCurrent} from "../../../components/dataTables/commodityGroup/slice"
import {currentStockLabels} from "../../../components/localization/selectors"

export const StockPerspectiveRadioView = () => {
    const show = useAppSelector(showCurrentStock)
    const labels = useAppSelector(currentStockLabels)
    return (
        <FormControlLabel
            sx={{ml: 1}}
            control={<Switch value={show} onChange={(_, checked) => store.dispatch(showCurrent(checked))} />}
            label={labels.label()}
        />
    )
}
