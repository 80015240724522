import styled from "styled-components"
import {Title4} from "../../../Styles/Fonts"
import {MarketingSectionContainer} from "../../MarketingCover/Web/styles"

export const SectionContainer = styled(MarketingSectionContainer)`
    background-color: white;
    padding-top: 75px;
`

export const SectionTitle = styled(Title4)`
    -webkit-text-stroke: 2px black;
    line-height: 50px;
    max-width: 460px;
`
