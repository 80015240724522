import OrderForm from "../../../../components/commodities/commodityGroupForm/Forms/OrderForm"
import {FormTextField} from "./sfc/FormTextFieldView"
import {CommodityGroupType} from "@common/types/commodity/commodityGroup"
import {Section} from "./sfc/Section"
import {CommodityGroupPickerView} from "./sfc/CommodityGroupPickerView"

export const OrderFields = (props: OrderFormProps) => (
    <Section>
        <FormTextField
            value={props.form.first_id}
            label={props.form.id_labels.firstId(CommodityGroupType.order)}
            onChange={(id) => props.form.didEditFirstId(id)}
        />
        <CommodityGroupPickerView
            selection={props.form.vessel?.id}
            type={CommodityGroupType.vessel}
            options={props.form.open_vessels}
            on_change={(id) => props.form.didSelectVessel(id)}
        />
    </Section>
)

export interface OrderFormProps {
    form: OrderForm
}
