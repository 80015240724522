import React from "react"
import {MenuItem, TextField} from "@mui/material"
import {Species} from "@common/types/commodity/commodity"

export const SpeciesSelectorView = (props: SpeciesSelectorProps) => {
    return (
        <TextField
            id={"Log-Species"}
            error={props.value === undefined}
            select
            label={"Species"}
            value={props.value}
            onChange={(e) => props.on_change(e.target.value)}
        >
            {Object.keys(Species).map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    )
}

interface SpeciesSelectorProps {
    value?: Species
    on_change: (logSpecies: string) => void
}
