import Authentication from "./authentication/Authentication"
import DataTableComponent from "./dataTables/commodityGroup"
import CommodityGroups from "./commodities/commodityGroups/listeners/CommodityGroupsListeners"
import CommodityGroupWeightsForm from "./commodities/commodityGroupWeightsForm"
import CommodityGroupForm from "./commodities/commodityGroupForm"
import CommodityGroupComponent from "./commodities/commodityGroup/listeners/CommodityGroupListeners"
import EditCommodityComponent from "./commodities/editCommodity"
import Invites from "./invites/Invites"
import Forests from "./forests/Forests"
import {CommodityHistoryComponent} from "./commodityHistory/CommodityHistoryComponent"

export default class Components {
    authentication: Authentication = new Authentication()
    commodity_group: CommodityGroupComponent = new CommodityGroupComponent()
    commodity_groups: CommodityGroups = new CommodityGroups()
    commodity_group_form: CommodityGroupForm = new CommodityGroupForm()
    data_table: DataTableComponent = new DataTableComponent()
    edit_commodity: EditCommodityComponent = new EditCommodityComponent()
    weights_form: CommodityGroupWeightsForm = new CommodityGroupWeightsForm()
    invites = new Invites()
    forests = new Forests()
    commodityHistory = new CommodityHistoryComponent()
}
