import React from "react"
import {GridToolbar} from "@mui/x-data-grid-premium"
import {useAppSelector} from "../../../app/hooks"
import {commodityGroupsLoading} from "../../../components/commodities/commodityGroups/selectors"
import {Loader} from "../../loader"
import {CommodityStyledDataTableView} from "./sfc/styledDataGrid"
import {commodityColumns} from "../../../components/dataTables/commodities/Columns/selectors"
import {commodityRows} from "../../../components/dataTables/commodities/Rows/selectors"

export const CommoditiesDataTableView = () => {
    const tableRows = useAppSelector(commodityRows)
    const tableColumns = useAppSelector(commodityColumns)
    const loading = useAppSelector(commodityGroupsLoading)
    return (
        <>
            {loading && <Loader />}
            {!loading && <CommodityStyledDataTableView rows={tableRows} columns={tableColumns} toolbar={GridToolbar} page_size={500} />}
        </>
    )
}
