import {Autocomplete, TextField} from "@mui/material"
import {AutocompleteInputChangeReason} from "@mui/base/useAutocomplete/useAutocomplete"
import {Forest} from "@common/types/Forest"
import Logger from "../../../../../helpers/Logger"

const log = new Logger("ForestSelectorView")

export const ForestSelectorView = (props: {forests: Forest[]; forest?: Forest; link: (forest?: Forest) => void}) => {
    function textFieldDidChange(query: string) {
        if (query.length === 0) {
            unlinkForest()
            return
        }

        const queryLowercase = query.toLowerCase()
        const filteredForests = props.forests.filter((forest) => forest.name.toLowerCase().includes(queryLowercase))
        if (filteredForests.length === 1) {
            log.d("useMemo", `filteredForest:${filteredForests[0].name}`)
            linkForest(filteredForests[0])
        }
    }

    function onInputChange(_event: React.SyntheticEvent, _value: string, reason: AutocompleteInputChangeReason) {
        if (reason === "clear") unlinkForest()
    }

    // Forest picked from dropdown list
    function onForestPicked(forestName: string | null) {
        log.d("onForestPicked", `forestName:${forestName}`)
        if (forestName === null) return
        const forest = props.forests.find((forest) => forest.name === forestName)
        if (forest === undefined) return

        linkForest(forest)
    }

    function getSelectedItem() {
        log.d("getSelectedItem", "")
        return props.forest?.name ?? null
    }

    function linkForest(forest: Forest) {
        log.d("linkForest", `forest:${forest.name}`)
        props.link(forest)
    }

    function unlinkForest() {
        log.d("unlinkForest", "")
        props.link(undefined)
    }

    return (
        <Autocomplete
            disablePortal
            id="forest-selector-view"
            sx={{width: 300}}
            onInputChange={onInputChange}
            options={props.forests.map((forest) => forest.name)}
            value={getSelectedItem()}
            onChange={(_event, newValue) => {
                onForestPicked(newValue)
            }}
            renderInput={(params) => <TextField {...params} onChange={(e) => textFieldDidChange(e.target.value)} label="Forest" />}
        />
    )
}
